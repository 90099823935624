import React from 'react'
import ReasonsList from './reasonList/ReasonList'

function Reasons() {
  return (
    <>
      <ReasonsList/>
    </>
  )
}

export default Reasons
