// import React, { useEffect, useState } from 'react'
// import Breadcrumbs from '../../common/breadcrumb/Breadcrumbs'
// import {  getDataPacageSales, getDataReports } from '../../api/login/Login'
// import SameCompFilter from './SameCompFilter'
// import MuncilpeListReport from './MobileListReport'
// import MobileListReport from './MobileListReport'
// import { useParams } from 'react-router-dom'

// function MobilePreMainComp({title_1,title_2,title}) {
//   const breadCrumbsTitle = {
//     id: "1",
//     title_1: title_1,
//     title_2:  title_2,
//   }
//   const [count, setCount] = useState(10)
//   const [page, setPage] = useState(0)
//   const [aepsData, setAepsData] = useState()
//   const [loading, setLoading] = useState(false)
//   const [userData, setUserData] = useState(false)
//   const token = window.localStorage.getItem('userToken')
//   const param = useParams()
//   const [initialValues, setIntialValues] = useState({
//     name: "",
//     start_date: "",
//     end_date: "",
//     biller_id: "",
//     txn_id: "",
//     customer_mobile: "",
//     category_id: param.id
//   })
//   const [operatorId, setOpratorID] = useState("")


//   const handleChangeOp = (e) => {
//     setOpratorID(e.target.value)
//   }
//   const submitForm = async (values) => {
//     const txnId = values.txn_id ? values.txn_id.trim() : '';
//     setLoading(true);
//     setIntialValues({
//       ...values, count, biller_id: operatorId, txn_id: txnId
//     })
//     setPage(0)
//     try {

//       const res = await getDataReports({ ...values, page: 0, count, txn_id: txnId, biller_id: operatorId, category_id: param.id });
//       setAepsData(res);
//     } catch (error) {
//       console.error("Error during AEPS transaction filter:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const getDmtTxnData = async (num) => {
//     setLoading(true)
//     try {
//       const res = await getDataReports({ ...initialValues, page: num, count, category_id: param.id });

//       setAepsData(res);
//       setLoading(false)
//     } catch (error) {

//     }
//   }




//   const onChangeVal = (e) => {
//     setPage(e - 1)
//     setTimeout(() => {
//       getDmtTxnData(e - 1)
//     }, 1000);
//   };

//   useEffect(() => {
//     getDmtTxnData(0)
//   }, [])
//   return (
//     <>
//       <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />
//       <SameCompFilter title={title} initialValues={initialValues} setIntialValues={setIntialValues} submitForm={submitForm} aepsData={aepsData} handleChangeOp={handleChangeOp} operatorId={operatorId} getDmtTxnData={getDmtTxnData} page={page}/>
//       <MobileListReport title={title} onChangeVal={onChangeVal} aepsData={aepsData} getDmtTxnData={getDmtTxnData}/>
//     </>
//   )
// }

// export default MobilePreMainComp



import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../common/breadcrumb/Breadcrumbs'
// import { getDataPacage, getDataPacageSales, getDataReports  ,getDataReportsAllexcel} from '../../api/login/Login'
import SameCompFilter from './SameCompFilter'

import { useParams } from 'react-router-dom'
import Loadar from '../../common/loader/Loader'

import * as XLSX from 'xlsx'; 

import { message } from 'antd';
import { getDataReports ,getDataReportsAllexcel } from '../../api/login/Login'
import MobileListReport from './MobileListReport'


function MobilePreMainComp({ title_1, title_2, title }) {
  const breadCrumbsTitle = {
    id: "1",
    title_1: title_1,
    title_2: title_2,
  };

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().substr(0, 10);
  };

  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [aepsData, setAepsData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(false);
  const token = window.localStorage.getItem('userToken');
  const param = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    start_date: currentDate,
    end_date: currentDate,
    biller_id: "",
    txn_id: "",
    customer_mobile: "",
    category_id: param.id,
    user_id: "",
    member_code: "",
    member_name: "",
    operator_id: "",
  });

  const submitForm = async (values) => {
    console.log(values);
    
    const txnId = values.txn_id ? values.txn_id.trim() : '';
    setLoading(true);
    setInitialValues({
      ...values,
      count,
      txn_id: txnId,
      start_date: values.start_date ? values.start_date : currentDate,
      end_date: values.end_date ? values.end_date : currentDate,
    });
    setPage(0);
    try {
      const res = await getDataReports({ 
        ...values, 
        page: 0, 
        count, 
        txn_id: txnId, 
        category_id: param.id,
      });
      if (res && res.data) {
        setAepsData(res);
      } else {
        setAepsData({ data: [] });
        message.info('No data available');
      }
    } catch (error) {
      console.error("Error during AEPS transaction filter:", error);
      message.error('Failed to fetch data. Please try again.');
      setAepsData({ data: [] });
    } finally {
      setLoading(false);
    }
  };

  const getDmtTxnData = async (num) => {
    setLoading(true);
    try {
      const res = await getDataReports({ 
        ...initialValues, 
        page: num, 
        count, 
        category_id: param.id 
      });
      if (res && res.data) {
        setAepsData(res);
      } else {
        setAepsData({ data: [] });
        message.info('No data available');
      }
    } catch (error) {
      console.error("Error fetching DMT transaction data:", error);
      message.error('Failed to fetch transaction data. Please try again.');
      setAepsData({ data: [] });
    } finally {
      setLoading(false);
    }
  };

  const downloadExcel = async () => {
    try {
      setLoading(true);
      const res = await getDataReportsAllexcel({
        ...initialValues,
        category_id: param.id
      });

      if (res && res.data && res.data.length > 0) {
        const dataForExcel = res.data.map((item, i) => ({
          "Sr. No": i + 1,
          "Date / Time": item?.createdAt,
          "Name": item?.name,
          "Retailer code": item?.refer_id,
          "Retailer Mobileno": item?.retailer_mobile,
          "Transaction ID": item?.txn_id,
          "Ref ID": item?.refId,
          "Ca Number": item?.ca_num,
          "Operator name": item?.operator_name,
          "Operator ID": item?.biller_id,
          "Transction  Reference ID": item?.txnReferenceceId,
          "Updated ": item?.updatedAt,
          "Status Updated ": item?.status_update_time,
          "Amount": item?.amount,
          "Opening Balance": item?.open_bal,
          "Clsing Balance":item?.close_bal,
          "Status  ": item?.status === 2 ? 'Success' :
          item?.status === 1 ? 'Pending' :
          item?.status === 3 ? 'Failed' :
          item?.status === 4 ? 'Refund' :
          'Unknown',
          'Distributor Name': item.distributor_name || "",
          'Distributor Commission': item.distributor_commission || "",
          'Super Distributor Name': item.master_dist_name || "",
          'Super Distributor Commission': item.master_dist_commission || "",
          'Distributor Contact': item.distributor_mobile || "",
          'Super Distributor Contact': item.master_dist_mobile || "",
          'Distributor Refer Id': item.distributor_refer_id || "",
          'Super Distributor Refer Id': item.master_dist_refer_id || "",
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Elelctric city Report");
        XLSX.writeFile(wb, `Elelctric_city_Report_${new Date().toLocaleDateString()}.xlsx`);
      } else {
        message.error('No data available to export!');
      }
    } catch (error) {
      console.error("Error downloading Excel:", error);
      message.error('Failed to download Excel. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageSizeChange = (e) => {
    const newPageSize = parseInt(e.target.value);
    setCount(newPageSize);
    setPage(0);
    getDmtTxnData(0);
  };

  const onChangeVal = (e) => {
    setPage(e - 1);
    setTimeout(() => {
      getDmtTxnData(e - 1);
    }, 1000);
  };

  useEffect(() => {
    getDmtTxnData(0);
  }, []);

  return (
    <>
      {loading && <Loadar />}
      <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />
      <SameCompFilter 
        title={title} 
        initialValues={initialValues} 
        userData={userData} 
        submitForm={submitForm} 
        aepsData={aepsData}   
        setInitialValues={setInitialValues} 
        getDmtTxnData={getDmtTxnData} 
      />
      {aepsData.data.length > 0 ? (
        <MobileListReport 
          title={title} 
          onChangeVal={onChangeVal} 
          aepsData={aepsData} 
          getDmtTxnData={getDmtTxnData} 
          downloadExcel={downloadExcel}  
          count={count}
          handlePageSizeChange={handlePageSizeChange} 
        />
      ) : (
        <div className="text-center mt-4">
          <h3>No data available</h3>
        </div>
      )}
    </>
  );
}

export default MobilePreMainComp


