
import React, { useState, useEffect } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../../../common/breadcrumb/Breadcrumbs";
import { addServiceMaster, clodinaryImage, getServiceCategory, getServiceMasterId, languageList, updateServiceMaster } from "../../../../../api/login/Login";
import { Tab, Tabs } from "react-bootstrap";
import NewtabAddServiceMaster from "./NewtabAddServiceMaster";
import { ToastContainer, toast } from "react-toastify";

import { baseUrl } from "../../../../../baseUrl";


const breadCrumbsTitle = {
    id: "1",
    title_1: "Master",
    title_2: "Service Master",
    title_3: "Add Service Master",
};

function AddServiceMaster() {
    const [language, setLanguage] = useState(null);
    const [service_category, set_serviceCateg] = useState(null);
    const [tab, setTab] = useState();
    const [selectData, setSelectData] = useState([]);
    const [profileImage, setProfileImage] = useState(null);
    const [ProfileImageBanner, setProfileImageBanner] = useState();
    const [isIconUploading, setIsIconUploading] = useState(false);
    const params = useParams();
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const initialValues = {
        service_name: '',
        service_image: "",
        slug: "",
        service_charge: 100,
        bbps: 1,
        background_color: "",
        order_by: null,
        service_category: '',
        short_description: "",
        full_description: "",
        icon: "",
        banner_img: "",
        area_id: "",
        meta_title: "",
        meta_description: "",
        meta_keyword: "",
        meta_image: "",
        language_id: "",
        is_active: null,
        ...(params?.id && { seq_no: "" })
    };

    const validate = (values) => {
        let errors = {};
        if (!values.service_name) {
            errors.service_name = "Service Name is required";
        }
        if (!values.order_by) {
            errors.order_by = "Code is required";
        }
        if (!values.icon) {
            errors.icon = "Icon Image is required";
        }
        if (!values.permit_by_area) {
            errors.permit_by_area = "Permit By Area is required";
        }
        if (!values.service_category) {
            errors.service_category = "Service category is required";
        }
        return errors;
    };

    const toastSuccessMessage = () => {
        toast.success(`${params?.id ? "Update" : "Add"} Add Service Successfully.`, {
            position: "top-center",
        });
    };

    const initialData = () => {
        if (language) {
            const data = language?.map((item) => {
                return { ...initialValues, language_id: item?._id, langName: item?.name };
            });
            setSelectData(data);
        } else {
            const data = [{ ...initialValues, language_id: 22, langName: 'English' }];
            setSelectData(data);
        }
    };

    const submitForm = async (formData) => {
        try {
            const dataToSend = Object.values(formData).map(item => ({
                ...item
            }));
            if (!params?.id) {
                const res = await addServiceMaster({ list: dataToSend });
                if (res?.statusCode === "200") {
                    toastSuccessMessage();
                    setTimeout(() => {
                        navigate('/admin/service-master');
                    }, 4000);
                }
            } else {
                await updateServiceMaster(params?.id, { list: dataToSend });
                toastSuccessMessage();
                setTimeout(() => {
                    navigate('/admin/service-master');
                }, 4000);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleChangeCus = (e, id) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: {
                ...prevData[id],
                [name]: value
            }
        }));
    };

    const getLanguageIdTab = async () => {
        const res = await languageList();
        setLanguage(res?.data);
        setTab(res?.data[0]?._id);
    };

    const getServicesCategory = async () => {
        const _serviceCategoery = await getServiceCategory();
        set_serviceCateg(_serviceCategoery?.data);
    };

    useEffect(() => {
        getLanguageIdTab();
        getServicesCategory();
    }, []);

    useEffect(() => {
        const fetchCurrency = async () => {
            try {
                if (params?.id) {
                    const response = await getServiceMasterId(params.id);
                    const serviceData = response.data;
                    const data = serviceData?.map((item, i) => {
                        return { id: item.id, service_name: item.service_name, meta_title: item.meta_title, full_description: item.full_description, short_description: item.short_description, meta_keyword: item.meta_keyword, language_id: item?.language_id, is_active: item.is_active ? item.is_active : false, langName: language[i].name, ...item };
                    });
                    setSelectData(data);
                } else {
                    initialData();
                }
            } catch (error) {
                console.error("Error fetching currency:", error);
            }
        };

        fetchCurrency();
    }, [params?.id, language]);

    const colodinaryImage = async (e, id, fieldName) => {
       
            const imgs = new FormData();
            imgs.append("image", e.target.files[0]);
            
            try {
                const res = await clodinaryImage(imgs);
                setTimeout(() => {
                    if (e.target.name === 'icon') {
                        setProfileImage(res?.data?.data?.url);
                    } else {
                        setProfileImageBanner(res?.data?.data?.url);
                    }
                }, 1000);
            } catch (error) {
                if (e.target.name === 'icon') {
                    console.error("Error uploading icon image:", error);
                } else {
                    console.error("Error uploading banner image:", error);
                }
            }
        };
        console.log("profileImage",profileImage);
        

    return (
        <>
            <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />
            <ToastContainer />
            <div className="row m-4">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div className="tbl-caption tbl-caption-2">
                                    <h4 className="heading mb-0">{params.id ? "UPDATE" : 'ADD'} SERVICE MASTER</h4>
                                </div>
                                <Tabs
                                    defaultActiveKey={tab}
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    {selectData?.map((item, i) => {
                                        return (
                                            <Tab eventKey={item?.language_id} title={item?.langName} key={item?.language_id}>
                                                <NewtabAddServiceMaster 
                                                    i={i} 
                                                    colodinaryImage={colodinaryImage} 
                                                    language={language} 
                                                    item={formData[item.language_id] || item} 
                                                    languageId={item?.language_id} 
                                                    submitForm={submitForm} 
                                                    handleChangeCus={handleChangeCus} 
                                                    service_category={service_category} 
                                                    params={params} 
                                                    validate={validate} 
                                                    profileImage={profileImage} 
                                                    ProfileImageBanner={ProfileImageBanner} 
                                                    isIconUploading={isIconUploading}
                                                />
                                            </Tab>
                                        );
                                    })}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddServiceMaster;
