import React from 'react'
import IndoNepalMoneyTrans from '../../../components/additionalReports/indioNepalMonTrans/IndoNepalMoneyTrans'

function IndoNepalMoneyPage() {
  return (
    <>
    <IndoNepalMoneyTrans/>
    </>

  )
}

export default IndoNepalMoneyPage