import React from 'react'
import AccountReport from '../../../components/additionalReports/accountReport/AccountReport'

function AccountReportPage() {
    return (
        <>
            <AccountReport />
        </>
    )
}

export default AccountReportPage