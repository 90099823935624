import React from 'react'
import AadharPayRepor from '../../../components/additionalReports/aadharpayreport/AadharPayRepor'

function AadharRepPage() {
  return (
    <>
    <AadharPayRepor/>
    </>

  )
}

export default AadharRepPage