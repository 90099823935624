// import { useState } from "react"
// import {  updateDistIdAgainst } from "../../../api/login/Login";
// function ApprovedArea({ handleChange, submitApproved, initialValues }) {
//     // const [initialValues, setInitialValue] = useState()
//     // const handleChange = (e) => {
//     //     setInitialValue(e.target.value)
//     // }
//     // const submitApproved = () => {
//     //     console.log(initialValues);
//     // }

//     const submitForm = async (e, data) => {
//         e.preventDefault()
//         const cloneMerg = { permission: permission }
//         const clone = { ...cloneMerg }
//         console.log('clone', clone);
//         try {
//           const res = await updateDistIdAgainst(params?.id, clone);
//           console.log(res);
//           if (res?.statusCode == "200") {
//             toastSuccessMessage();
//             /* āsetTimeout(() => {
//               navigate(`/admin/member-list/${params.id}/${params.name}`)
//             }, [4000]) */
//           }
//         } catch (error) {
//           alert(error)
//         }

//       }
//     return (
//         <>
//             <section className="ListDistributer m-4">
//                 <div className="row">
//                     <div className="col-xl-12">
//                         <div className="card">
//                             <div className="card-body p-0">
//                                 <div className="table-responsive active-projects style-1">
//                                     <div className="tbl-caption justify-content-center">
//                                         <h4 className="heading mb-0 border p-1 rounded"><b>Approval</b></h4>

//                                     </div>
//                                     <div id="empoloyees-tblwrapper_wrapper" className=" no-footer"><div className="dt-buttons"></div>
//                                         <div class="container pb-4">
//                                             <div className='row'>
//                                                 <div className="col-lg-6">
//                                                     <div className="approved">
//                                                         <select className="form-select" aria-label="Default select example" name="is_approved" value={initialValues.is_approved} onChange={handleChange}>
//                                                             <option selected>Open this select menu</option>
//                                                             <option value={"false"}>Not Approved</option>
//                                                             <option value={"true"}>Approved</option>
//                                                         </select>

//                                                     </div>
//                                                 </div>
//                                                 <div className="col-lg-6">
//                                                     <div className="approved">
//                                                         <button type="button" className="btn btn-warning" onClick={submitApproved}>Submit</button>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div >

//             </section>
//         </>
//     )
// }
// export default ApprovedArea


import React, { useState } from "react";
import { message, Checkbox, Input, Button, Select } from "antd";
import { approveunapproveUser } from "../../../../api/login/Login";
import { useParams } from "react-router-dom";

const { Option } = Select;

function ApprovedArea() {
    const params = useParams();
    const id = params?.id;

    const [formData, setFormData] = useState({
        is_adhaar_verified: false,
        is_pan_verified: false,
        isIdentity_verified: false,
        is_bank: false,
        is_document: false,
        is_kycVid: false,
        is_personalDetails: false,
        is_self_declare: false,
        remark: "",
        is_approved: null,
    });

    // Handle dropdown change
    const handleApprovalChange = (value) => {
        setFormData({
            ...formData,
            is_approved: value === "true",
            ...(value === "true" && {
                is_adhaar_verified: true,
                is_pan_verified: true,
                isIdentity_verified: true,
                is_bank: true,
                is_document: true,
                is_kycVid: true,
                is_personalDetails: true,
                is_self_declare: true,
                remark: "",
            }),
        });
    };

    // Handle checkbox change
    const handleCheckboxChange = (key) => {
        setFormData((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    // Handle remark change
    const handleRemarkChange = (e) => {
        setFormData({ ...formData, remark: e.target.value });
    };

    // Submit handler
    const handleSubmit = async (action) => {
        try {
            const res = await approveunapproveUser(formData, id);
            console.log("Response object:", res);
    
            if (res?.error === false) {
                // Use `res.message` directly, since `res.data` is undefined
                console.log("sucess")
                message.success(res.message || `${action} action successful!`);
            } else {
                // Fall back to the correct error message
                console.log("failed")
                message.error(res.message || `${action} action failed!`);
            }
        } catch (error) {
            console.error("Submission error:", error); // Log actual error
            message.error("An error occurred during submission.");
        }
    };
    

    // Validation
    const allChecked = Object.keys(formData)
        .filter((key) => key.startsWith("is_"))
        .every((key) => formData[key] === true);
    const atLeastOneUnchecked = Object.keys(formData)
        .filter((key) => key.startsWith("is_"))
        .some((key) => formData[key] === false);

    const isApproveButtonEnabled = formData.is_approved === true && allChecked;
    const isUnapproveButtonEnabled =
        formData.is_approved === false &&
        atLeastOneUnchecked &&
        formData.remark.trim().length > 0;

    return (
        <div className="approved-area">
            <h3>Approval</h3>
            <Select
                defaultValue="Select Approval Status"
                style={{ width: "100%", marginBottom: "1rem" }}
                onChange={handleApprovalChange}
            >
                <Option value="true">Approved</Option>
                <Option value="false">Not Approved</Option>
            </Select>

            <div>
                <Checkbox
                    checked={formData.is_adhaar_verified}
                    onChange={() => handleCheckboxChange("is_adhaar_verified")}
                >
                    Aadhaar Verified
                </Checkbox>
                <Checkbox
                    checked={formData.is_pan_verified}
                    onChange={() => handleCheckboxChange("is_pan_verified")}
                >
                    PAN Verified
                </Checkbox>
                <Checkbox
                    checked={formData.isIdentity_verified}
                    onChange={() => handleCheckboxChange("isIdentity_verified")}
                >
                    Identity Verified
                </Checkbox>
                <Checkbox
                    checked={formData.is_bank}
                    onChange={() => handleCheckboxChange("is_bank")}
                >
                    Bank Details Verified
                </Checkbox>
                <Checkbox
                    checked={formData.is_document}
                    onChange={() => handleCheckboxChange("is_document")}
                >
                    Documents Verified
                </Checkbox>
                <Checkbox
                    checked={formData.is_kycVid}
                    onChange={() => handleCheckboxChange("is_kycVid")}
                >
                    KYC Verified
                </Checkbox>
                <Checkbox
                    checked={formData.is_personalDetails}
                    onChange={() => handleCheckboxChange("is_personalDetails")}
                >
                    Personal Details Verified
                </Checkbox>
                <Checkbox
                    checked={formData.is_self_declare}
                    onChange={() => handleCheckboxChange("is_self_declare")}
                >
                    Self Declaration
                </Checkbox>
            </div>

            {formData.is_approved === false && (
                
                <Input
                    placeholder="Enter Remark"
                    value={formData.remark}
                    onChange={handleRemarkChange}
                    style={{ marginTop: "1rem" }}
                />
            )}

            <div style={{ marginTop: "1rem" }}>
                <Button
                   
                    onClick={() => handleSubmit("Approve")}
                    disabled={!isApproveButtonEnabled}
                    style={{ marginRight: "1rem" }}
                >
                    Approve
                </Button>
                <Button
                 
                  
                    onClick={() => handleSubmit("Unapprove")}
                    disabled={!isUnapproveButtonEnabled}
                 
                >
                    Unapprove
                </Button>

            </div>
        </div>
    );
}

export default ApprovedArea;
