import React from 'react'
import SearchReachargeTransaction from '../../../components/additionalReports/searchReachargeTransaction/SearchReachargeTransaction'

function SearchReTarnsactionPage() {
  return (
    <>
    <SearchReachargeTransaction/>
    </>
  )
}

export default SearchReTarnsactionPage