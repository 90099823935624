import WalletsReport from "../../components/walletsReport/WalletsReports"
import { useParams } from "react-router-dom";


function WalletsReportsPage() {
    const { userId  } = useParams();
    console.log("userId ",userId )
    return (
        <>
            <WalletsReport userId={userId } />
        </>
    )
}
export default WalletsReportsPage