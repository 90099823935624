import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, DatePicker, Typography, Button, Table, message, Empty, Modal, Space, Input, Select } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { payoutreport, payoutreportAll } from '../../../api/login/Login';

const { Title } = Typography;
const { Option } = Select;

const Aepswallet2 = () => {
  const [reportData, setReportData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [startDate, setStartDate] = useState(dayjs().startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [filters, setFilters] = useState({
    mobile: "",
    refer_id: "",
    user_type: "",
  });

  useEffect(() => {
    fetchReportData(1);
  }, []);

  const fetchReportData = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await payoutreport({
        mobile: filters.mobile,
        refer_id: filters.refer_id,
        user_type: filters.user_type,
        page: page - 1,
        count: 10,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
      });
      console.log("responsemianallet ", response);
      if (response?.statusCode === 200 && response?.data?.users) {
        setReportData(response.data.users);
        setTotalCount(response.data.totalCount);
        setPagination({
          ...pagination,
          current: page,
          total: response.data.totalCount,
        });
      } else {
        throw new Error(response?.message || 'Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching payout report data:', error);
      setReportData([]);
      message.error(error.message || 'Failed to fetch payout report data');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (date) => {
    return date ? date.format('YYYY-MM-DD') : null;
  };

  const handleTableChange = (pagination) => {
    fetchReportData(pagination.current);
  };

  const showModal = (record) => {
    setSelectedUser(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Serial No.',
      key: 'serial',
      render: (text, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'Refer ID',
      dataIndex: 'refer_id',
      key: 'refer_id',
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      key: 'user_type',
    },
   
    {
      title: 'AEPS Wallet',
      dataIndex: 'aeps_wallet',
      key: 'aeps_wallet',
      render: (text) => `₹${text || 0}`,
    },
    {
      title: 'Details',
      key: 'details',
      render: (_, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => showModal(record)}
        />
      ),
    },
  ];

  const handleSearch = () => {
    fetchReportData(1);
  };

  const handleClearFilters = () => {
    setStartDate(dayjs().startOf('day'));
    setEndDate(dayjs().endOf('day'));
    setFilters({
      mobile: "",
      refer_id: "",
      user_type: "",
    });
    fetchReportData(1);
  };

  const handleExportExcel = async () => {
    try {
      const response = await payoutreportAll({
        mobile: filters.mobile,
        refer_id: filters.refer_id,
        user_type: filters.user_type,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
      });

      if (response?.statusCode === 200 && response?.data?.users) {
        const data = response.data.users;

        const excelData = data.map(item => ({
          'Name': item.name,
          'Mobile': item.mobile,
          'Refer ID': item.refer_id,
          'User Type': item.user_type,
        //   'Main Wallet': parseFloat(item.main_wallet || 0).toFixed(2),
          'AEPS Wallet': parseFloat(item.aeps_wallet || 0).toFixed(2),
          'Merchant Code': item.refer_id || '',
        }));

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(excelData);

        const colWidths = Object.keys(excelData[0]).map(key => ({
          wch: Math.max(key.length, 15)
        }));
        ws['!cols'] = colWidths;

        XLSX.utils.book_append_sheet(wb, ws, "Payout Report");

        const timestamp = dayjs().format('YYYY-MM-DD_HH-mm');
        XLSX.writeFile(wb, `payoutreport_${timestamp}.xlsx`);

        message.success('Excel file downloaded successfully');
      } else {
        throw new Error(response?.message || 'Failed to fetch data for Excel export');
      }
    } catch (error) {
      console.error('Error exporting Excel:', error);
      message.error(error.message || 'Failed to export Excel file');
    }
  };

  return (
    <div className="container-fluid py-4">
      <Card className="shadow-sm mb-4" style={{ borderRadius: '10px' }}>
        <Title level={3}>Aeps Wallet Report</Title>
        <Form layout="inline" className="mb-3">
          {/* <Form.Item label="Start Date">
            <DatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </Form.Item>
          <Form.Item label="End Date">
            <DatePicker
              value={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Form.Item> */}
          <Form.Item label="Mobile">
            <Input
              value={filters.mobile}
              onChange={(e) => setFilters({ ...filters, mobile: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Refer ID">
            <Input
              value={filters.refer_id}
              onChange={(e) => setFilters({ ...filters, refer_id: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="User Type">
            <Select
              value={filters.user_type}
              onChange={(value) => setFilters({ ...filters, user_type: value })}
              style={{ width: 150 }}
            >
              <Option value="">All</Option>
              <Option value="Retailer">Retailer</Option>
              <Option value="Distributor">Distributor</Option>
              <Option value="Super Distributor">Super Distributor</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={handleClearFilters}>
              Clear Filters
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <Card className="shadow-sm" style={{ borderRadius: '10px' }}>
        <Button onClick={handleExportExcel} type="primary" className="mb-3">
          Export Excel
        </Button>
        <Table
          columns={columns}
          dataSource={reportData}
          rowKey="_id"
          pagination={{
            ...pagination,
            total: totalCount,
          }}
          loading={isLoading}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data available"
              />
            ),
          }}
          footer={() => (
            <div>
              Total Count: {totalCount}
            </div>
          )}
        />
      </Card>
      <Modal
        title="User Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={600}
        footer={[
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>
        ]}
      >
        <Card>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Typography.Text strong>Name:</Typography.Text>
              <Typography.Paragraph>{selectedUser.name}</Typography.Paragraph>
            </Col>
            <Col span={12}>
              <Typography.Text strong>Mobile:</Typography.Text>
              <Typography.Paragraph>{selectedUser.mobile}</Typography.Paragraph>
            </Col>
            <Col span={12}>
              <Typography.Text strong>Refer ID:</Typography.Text>
              <Typography.Paragraph>{selectedUser.refer_id}</Typography.Paragraph>
            </Col>
            <Col span={12}>
              <Typography.Text strong>User Type:</Typography.Text>
              <Typography.Paragraph>{selectedUser.user_type}</Typography.Paragraph>
            </Col>
            {/* <Col span={12}>
              <Typography.Text strong>Main Wallet:</Typography.Text>
              <Typography.Paragraph>₹{parseFloat(selectedUser.main_wallet || 0).toFixed(2)}</Typography.Paragraph>
            </Col> */}
            <Col span={12}>
              <Typography.Text strong>AEPS Wallet:</Typography.Text>
              <Typography.Paragraph>₹{parseFloat(selectedUser.aeps_wallet || 0).toFixed(2)}</Typography.Paragraph>
            </Col>
            <Col span={24}>
              <Typography.Text strong>Merchant Code:</Typography.Text>
              <Typography.Paragraph>{selectedUser.merchant_code || 'N/A'}</Typography.Paragraph>
            </Col>
          </Row>
        </Card>
      </Modal>
    </div>
  );
};

export default Aepswallet2;