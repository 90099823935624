import { Formik } from 'formik';
import CustomInputField from '../../../common/CustomInputField';
import { useEffect, useState } from 'react';

function VirtualWalletFills({ submitForm, page }) {
    const today = new Date();

    const [initialValues, setInitialValues] = useState({
        min_amt: '',
        max_amt: "",
        start_date: ``,
        end_date: ``,
        type: '',
        trans_type: "",
        page: page,
    });

    const validate = (values) => {
        let errors = {};
        // Add any necessary validation logic here
        return errors;
    };

    const handleReset = (resetForm, values) => {
        resetForm({ values: initialValues });

        submitForm(initialValues);
    };

    return (
        <>
            <div className="row m-4">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div className="tbl-caption tbl-caption-2">
                                    <h4 className="heading mb-0"><b>Virtual Report Search</b></h4>
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    validate={validate}
                                    onSubmit={submitForm}
                                    enableReinitialize
                                >
                                    {(formik) => {
                                        const {
                                            values,
                                            handleChange,
                                            handleSubmit,
                                            resetForm,
                                            errors,
                                            touched,
                                            handleBlur,
                                        } = formik;

                                        return (
                                            <form className="tbl-captionn" onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-xl-4 mb-3">
                                                        <select 
                                                            className="form-select" 
                                                            aria-label="Default select example" 
                                                            value={values.trans_type} 
                                                            name="trans_type" 
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select Transaction type</option>
                                                            <option value="refund">Refund</option>
                                                            <option value="recharge">Recharge</option>
                                                            <option value="transfer">Transfer</option>
                                                            <option value="order">Order</option>
                                                            <option value="bill_pay">Bill Pay</option>
                                                            <option value="pan">Pan</option>
                                                            <option value="fastag">Fastag</option>
                                                            <option value="credit_card">Credit Card</option>
                                                            <option value="lic_bill">LIC Bill</option>
                                                            <option value="qr_code">QR Code</option>
                                                            <option value="add_wallet">Add Wallet</option>
                                                            <option value="onBoarding">OnBoarding</option>
                                                            <option value="fromAEPS">From AEPS</option>
                                                            <option value="reversal">Reversal</option>
                                                            <option value="ureversal">Ureversal</option>
                                                            <option value="ucommission">Ucommission</option>
                                                            <option value="validate_beneficiary">Validate Beneficiary</option>
                                                            <option value="dmt">DMT</option>
                                                            <option value="dmt_rev">DMT Rev</option>
                                                            <option value="admintrf">Admin Transfer</option>
                                                            <option value="cms">CMS</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-xl-4 mb-3">
                                                        <CustomInputField
                                                            type="date"
                                                            placeholder="From"
                                                            value={values.start_date}
                                                            hasError={errors.start_date && touched.start_date}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.start_date}
                                                            id="start_date"
                                                        />
                                                    </div>
                                                    <div className="col-xl-4 mb-3">
                                                        <CustomInputField
                                                            type="date"
                                                            placeholder="To"
                                                            value={values.end_date}
                                                            hasError={errors.end_date && touched.end_date}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.end_date}
                                                            id="end_date"
                                                        />
                                                    </div>
                                                    <div className="col-xl-4 mb-3">
                                                        <select 
                                                            className="form-select" 
                                                            aria-label="Default select example" 
                                                            value={values.type} 
                                                            name="type" 
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select type</option>
                                                            <option value="credit">Credit</option>
                                                            <option value="debit">Debit</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-xl-4 mb-3">
                                                        <CustomInputField
                                                            type="number"
                                                            placeholder="Min Amount"
                                                            value={values.min_amt}
                                                            hasError={errors.min_amt && touched.min_amt}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.min_amt}
                                                            id="min_amt"
                                                        />
                                                    </div>
                                                    <div className="col-xl-4 mb-3">
                                                        <CustomInputField
                                                            type="number"
                                                            placeholder="Max Amount"
                                                            value={values.max_amt}
                                                            hasError={errors.max_amt && touched.max_amt}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.max_amt}
                                                            id="max_amt"
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 mg-t-10 mg-sm-t-25">
                                                        <button
                                                            className="btn btn-primary me-1"
                                                            type="submit"
                                                        >
                                                            Search
                                                        </button>
                                                        <button
                                                            className="btn btn-warning pd-x-20"
                                                            type="button"
                                                            onClick={() => handleReset(resetForm, values)} 
                                                        >
                                                            <i className="fas fa-refresh"></i> Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VirtualWalletFills;
