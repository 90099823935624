import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Table, Pagination, Button, Space, Dropdown, Menu, message , Modal, Input } from 'antd';
import { Card } from 'react-bootstrap';
import { DownloadOutlined, EditOutlined, EllipsisOutlined ,RollbackOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import moment from 'moment';

// Import Modal Components
import FundTransfer from "../createUserDistributer/fundTrnsfer/FundTrnasfer";
import SchemeManager from "../createUserDistributer/schemeManager/SchemeManager";
import IdStocks from "../createUserDistributer/IdsStoks/IdStoks";
import MemberPermission from "../createUserDistributer/memberPermission/MemberPermission";
import LockAmount from "../createUserDistributer/lockAmount/LockAmount";

import './distributor.css';
import { reversePayment } from '../../../api/login/Login';

const DistributorList = ({
  loading,
  params,
  state,
  handleChange,
  onChangeVal,
  getReailerDistIdAgainst
  ,submitForm,initialValues ,page
}) => {
  // Modal states
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [error, setError] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true)
  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true)
  const [show5, setShow5] = useState(false);
  const [reverseModalLoading, setReverseModalLoading] = useState(false);
  const [showReverseModal, setShowReverseModal] = useState(false);
  const [reversePaymentData, setReversePaymentData] = useState({
    userId: '',
    amount: '',
    remark: '',
    tpin: ''
  });
  const handleClose5 = () => setShow5(false);
  const [getItem, setGetItem] = useState()
  const handleShow5 = (item) => {
      setGetItem({ ...item, paramId: params?.id })
      setTimeout(() => {
          setShow5(true)
      }, 1000);
  }

  console.log("page",page)

  // Modal handlers
  // const handleLockAmount = (item) => {
  //   setSelectedItem({ ...item, paramId: params?.id });
  //   setShowLockAmount(true);
  // };

  const handleReverseModalOpen = (record) => {
    setReversePaymentData(prev => ({
      ...prev,
      userId: record._id
    }));
    setShowReverseModal(true);
  };

  const handleReverseModalClose = () => {
    setShowReverseModal(false);
    setReverseModalLoading(false);
    setReversePaymentData({
      userId: '',
      amount: '',
      remark: '',
      tpin: ''
    });
  };

  const handleReversePaymentSubmit= async () => {
    try {
      setReverseModalLoading(true); // Show loader
      const response = await reversePayment(reversePaymentData);
  
      if (response.statusCode === "200") {
        message.success(response.data.message || response.message || 'Payment reversed successfully');
        handleReverseModalClose(); // Close the modal
        // if (submitForm) {
        //   await submitForm(initialValues); 
        //   setReverseModalLoading(false);
        // }
        if (getReailerDistIdAgainst ) {
          await getReailerDistIdAgainst(page ? page : 0);
        }

      } else {
        message.error(response.data.message || response.message || 'Failed to reverse payment');
      }
    } catch (err) {
      message.error(err.data?.message || err.message || 'Failed to reverse payment');
    } finally {
      setReverseModalLoading(false); // Hide loader
    }
  };
  
  const handleApprovalChange = (value, id) => {
    try {
      handleChange({ target: { value, name: 'is_approved' }}, id);
    } catch (err) {
      message.error('Failed to update approval status');
      setError('Failed to update approval status. Please try again.');
    }
  };

  const actionMenu = (record) => (
    <Menu>
      <Menu.Item key="1">
        <Link to={`/admin/certificate`}>BC Authorization Letter</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="#">ID Card</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="#">Certificate</Link>
      </Menu.Item>
      <Menu.Item key="4" onClick={() => handleShow()}>
        Fund Transfer / Return
      </Menu.Item>
      <Menu.Item key="5" onClick={() => handleShow2()}>
        Scheme
      </Menu.Item>
      <Menu.Item key="6" onClick={() => handleShow3()}>
        Add Id Stock
      </Menu.Item>
      <Menu.Item key="7" onClick={() => handleShow4()}>
        Permission
      </Menu.Item>
      <Menu.Item key="8" onClick={() => { handleShow5(record) }}>

        Locked Amount
      </Menu.Item>
      <Menu.Item key="9">
        <Link to="/admin/member/profile/view/3">View Profile</Link>
      </Menu.Item>
    </Menu>
  );

  const reportMenu = (record) => (
    <Menu>
      <Menu.Item key="1">
        <Link to={`/admin/statement/apes/report/${record._id}`}>AEPS</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to={`/admin/statement/billpayment/report/${record._id}`}>Billpayment</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to={`/admin/statement/recharge/report/${record._id}`}>Recharge</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to={`/admin/statement/moneyTransfer/report/${record._id}`}>Money Transfer</Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link to="/admin/report/pancard/3">UtiPancard</Link>
      </Menu.Item>
      <Menu.Item key="6">
        <Link to="/admin/statement/report/2">Account Statement</Link>
      </Menu.Item>
      <Menu.Item key="7">
        <Link to="/admin/statementwallet/report/2">Aeps Statement</Link>
      </Menu.Item>
      <Menu.Item key="8">
        <Link to={`/admin/wallets/report/${record._id}`}>Wallets Reports</Link>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Serial No.',
      key: 'serial',
      render: (text, record, index) => {
        // Calculate the serial number based on currentPage and the index in the current page
        // const serialNo = index + 1 + currentPage * pageSize; // Assuming pageSize is the number of rows per page
        const serialNo = index + 1 
        return <span>{serialNo}</span>;
      },
    },
    {
      title: 'Name & Type',
      key: 'name',
      render: (text, record) => (
        <div>
          <div className="fw-bold">{record.name || '-'}</div>
          <small className="text-muted">{record.member_type || '-'}</small>
        </div>
      ),
    },
    {
      title: 'Reference Code',
      dataIndex: 'refer_id',
      key: 'refer_id',
      render: (text) => text || '-',
    },
    {
      title: 'Contact Info',
      key: 'contact',
      render: (text, record) => (
        <div>
          <div>{record.mobile || '-'}</div>
          <small className="text-muted">{record.email || '-'}</small>
        </div>
      ),
    },
    {
      title: 'Main Balance',
      dataIndex: 'main_wallet',
      key: 'main_wallet',
      render: (text) => <span>₹{text || '0'}</span>,
    },
    {
      title: 'Joining Date/Time',
      key: 'createdAt',
      render: (text, record) => (
        <div>
          <div>{record.createdAt ? moment(record.createdAt).format('DD/MM/YYYY') : '-'}</div>
          <small className="text-muted">
            {record.createdAt ? moment(record.createdAt).format('HH:mm:ss') : '-'}
          </small>
        </div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (text, record) => (
        <select
          className={`form-select form-select-sm ${record.is_approved ? 'border-success' : 'border-danger'}`}
          value={record.is_approved?.toString() || "false"}
          onChange={(e) => handleApprovalChange(e.target.value, record._id)}
        >
          <option value="true">Approved</option>
          <option value="false">Not Approved</option>
        </select>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="small" className="d-flex">
          <Dropdown overlay={actionMenu(record)} trigger={['click']}>
            <Button size="small" className="btn-action">
              Action <EllipsisOutlined />
            </Button>
          </Dropdown>
          <Dropdown overlay={reportMenu(record)} trigger={['click']}>
            <Button size="small" className="btn-report">
              Reports <EllipsisOutlined />
            </Button>
          </Dropdown>
          <Link 
            to={`/admin/create-user/${record._id}`} 
            className="btn btn-primary btn-sm"
          >
            <EditOutlined />
          </Link>
        </Space>
      ),
    },
    {
      title: 'Reverse Payment',
      key: 'reversePayment',
      render: (text, record) => (
        <Button 
          type="primary" 
          danger 
          icon={<RollbackOutlined />}
          onClick={() => handleReverseModalOpen(record)}
        >
          Reverse
        </Button>
      ),
    },
  ];

  const csvData = state?.user?.map(item => ({
    Name: item.name || '',
    'Member Type': item.member_type || '',
    'Reference Code': item.refer_id || '',
    Mobile: item.mobile || '',
    Email: item.email || '',
    Balance: item.main_wallet || '0',
    'Joining Date': item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : '',
    Status: item.is_approved ? 'Approved' : 'Not Approved'
  })) || [];

  return (
    <div className="m-4">
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0">{params?.name || 'Distributor'} List</h4>
          <div>
            <Space>
              <CSVLink 
                data={csvData} 
                filename={`${params?.name || 'distributor'}_list.csv`}
                className="btn btn-success btn-sm"
              >
                <DownloadOutlined /> Export CSV
              </CSVLink>
            </Space>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {error && (
            <div className="alert alert-danger m-3">
              {error}
            </div>
          )}
          <Table
            columns={columns}
            dataSource={state?.user || []}
            rowKey={(record) => record?._id || Math.random()}
            loading={loading}
            pagination={false}
            className="custom-table"
            scroll={{ x: true }}
          />
          <div className="p-3 d-flex justify-content-between align-items-center">
            <div>
              Total {state?.totalCount || 0} entries
            </div>
            <Pagination
              total={state?.totalCount || 0}
              onChange={onChangeVal}
              showSizeChanger={false}
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </Card.Body>
      </Card>

      {/* Modals */}
      <FundTransfer show={show} handleClose={handleClose} />
            <SchemeManager show2={show2} handleClose2={handleClose2} />
            <IdStocks show3={show3} handleClose3={handleClose3} />
            <MemberPermission show4={show4} handleClose4={handleClose4} />
            <LockAmount show5={show5} handleClose5={handleClose5} getItem={getItem} getReailerDistIdAgainst={getReailerDistIdAgainst} />


            {/* New Reverse Payment Modal */}
            <Modal
    title="Reverse Payment"
    open={showReverseModal}
    onCancel={handleReverseModalClose}
    // confirmLoading={reverseModalLoading}
    footer={[
      <Button key="cancel" onClick={handleReverseModalClose} disabled={reverseModalLoading}>
        Cancel
      </Button>,
      <Button 
        key="submit" 
        type="primary" 
        onClick={handleReversePaymentSubmit}
        loading={reverseModalLoading}
        disabled={
          !reversePaymentData.amount || 
          !reversePaymentData.remark.trim() || 
          !reversePaymentData.tpin.trim()
        }
        // disabled={
        //   !reversePaymentData.amount || // Check if amount is empty or zero
        //   !reversePaymentData.remark || // Check if remark is empty
        //   !reversePaymentData.tpin // Check if TPIN is empty
        // }
      >
        Submit
      </Button>,
    ]}
  >
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Input
        type="number"
        placeholder="Enter Amount"
        value={reversePaymentData.amount}
        onChange={(e) => setReversePaymentData(prev => ({ ...prev, amount: Number(e.target.value) }))}
        disabled={reverseModalLoading}
      />
      <Input
        placeholder="Enter Remark"
        value={reversePaymentData.remark}
        onChange={(e) => setReversePaymentData(prev => ({ ...prev, remark: e.target.value }))}
        disabled={reverseModalLoading}
      />
      <Input.Password
        placeholder="Enter TPIN"
        value={reversePaymentData.tpin}
        onChange={(e) => setReversePaymentData(prev => ({ ...prev, tpin: e.target.value }))}
        disabled={reverseModalLoading}
        autoComplete="new-password"
      />
    </Space>
  </Modal>
    </div>
  );
};

export default DistributorList;