import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import CustomInputField from '../../../common/CustomInputField';
import Item from 'antd/es/list/Item';

function PayoutBenificiaryReportsFillter({ submitForm, initialValues, setIntialValues, getDmtTxnData, page, aepsData }) {
    
    const handleReset = (resetForm) => {
        setIntialValues({
            start_date: "",
            end_date: "",
            approval_type: "",
            status: ""
        })
        setTimeout(() => {
            getDmtTxnData(0)
        }, 1000)
        resetForm()
    };
    const selectedApproval = aepsData?.data?.find((item) => {
        console.log(item); // Logs each item in the array
        return item.approval_type;
    });
    console.log(selectedApproval);
    
    






    return (
        <>
            <div className="row m-4">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div className="tbl-caption tbl-caption-2">
                                    <h4 className="heading mb-0"><b> Payout Search</b></h4>
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={submitForm}
                                    className="tbl-captionn"
                                >
                                    {(formik) => {
                                        const {
                                            values,
                                            handleChange,
                                            handleSubmit,
                                            resetForm,
                                            errors,
                                            touched,
                                            handleBlur,
                                            isValid,
                                            dirty,
                                        } = formik;
                                        return (
                                            <form className="tbl-captionn" onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-xl-3 mb-3">
                                                        <CustomInputField
                                                            type="date"
                                                            placeholder="Start Date"
                                                            name="start_date"
                                                            value={values.start_date}
                                                            hasError={errors.start_date && touched.start_date}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.start_date}
                                                            id="start_date"
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-3">
                                                        <CustomInputField
                                                            type="date"
                                                            placeholder="End Date"
                                                            name="end_date"
                                                            value={values.end_date}
                                                            hasError={errors.end_date && touched.end_date}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.end_date}
                                                            id="end_date"
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-3">
                                                        <select
                                                            className={`form-select ${errors.approval_type && touched.approval_type ? 'is-invalid' : ''}`}
                                                            aria-label="Default select example"
                                                            name="approval_type"
                                                            value={values.approval_type ? values?.approval_type : selectedApproval?.approval_type}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option value={""} disabled>Select Approval</option>
                                                            <option value={"Self"}>Self</option>
                                                            <option value={"Vendor"}>Vendor</option>
                                                        </select>
                                                        {errors.approval_type && touched.approval_type && (
                                                            <div className="invalid-feedback">{errors.approval_type}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-xl-3 mb-3">
                                                        <select
                                                            className={`form-select ${errors.status && touched.status ? 'is-invalid' : ''}`}
                                                            aria-label="Default select example"
                                                            name="status"
                                                            value={values.status}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option value={""} disabled>Select Status</option>
                                                            <option value={1}>Pending</option>
                                                            <option value={2}>Success</option>
                                                            <option value={3}>Failed</option>
                                                        </select>
                                                        {errors.status && touched.status && (
                                                            <div className="invalid-feedback">{errors.status}</div>
                                                        )}
                                                    </div>


                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="d-flex justify-content-start">
                                                            <button className="btn btn-primary pd-x-20 color2" type="submit">
                                                                <i className="fas fa-search"></i> Search
                                                            </button>
                                                            <button className="btn btn-primary pd-x-20 color2" type="button" onClick={() => handleReset(resetForm)}>
                                                                <i className="fas fa-refresh"></i> Reset
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PayoutBenificiaryReportsFillter
