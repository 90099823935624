import React from 'react'
import TypeReasonList from './typeReasonList/TypeReasonList'

function TypeReason() {
  return (
    <>
     <TypeReasonList/> 
    </>
  )
}

export default TypeReason
