import React, { useState } from 'react';
import { Pagination } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import { DownloadExcel } from '../../../common/downloadExcel/DownLoadExcel';
import ExportPdf from '../../../common/exportPdf/ExportPdf';
import { baseUrlImage } from '../../../baseUrl';

function PayoutBeneFiciaryReportsList({ onChangeVal, aepsData }) {

    return (
        <>
            <ToastContainer />
            <section className="ListDistributer exppdf">
                <div className="row m-4">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1">
                                    <div className="tbl-caption">
                                        <h4 className="heading mb-0"><b> Payout BeneFiciary Report List</b></h4>
                                        <div className='d-flex'>
                                            <ExportPdf />
                                            <button className='btn btn-success bg-info' onClick={DownloadExcel}>Download Excel</button>
                                        </div>
                                    </div>
                                    <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="table-to-xls" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                            <thead>

                                                <tr role="row">

                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{ width: '96.125px' }}>
                                                        User Name					</th>
                                                    <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
                                                        Bank Acc Number
                                                    </th>
                                                    <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
                                                        Bank Acc Name
                                                    </th>
                                                    <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
                                                        Bank ID
                                                    </th>

                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{ width: '96.125px' }}>
                                                        Type 				</th>


                                                    <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
                                                        Bank Name
                                                    </th>
                                                    <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
                                                        Bank IFSC
                                                    </th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{ width: '96.125px' }}>
                                                        Bank Branch  					</th>

                                                    <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
                                                        Bank Proof
                                                    </th>
                                                    <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
                                                        Mobile No
                                                    </th>
                                                    <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
                                                        Approval Type
                                                    </th>
                                                    <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
                                                        Beneficiary Id
                                                    </th>
                                                    <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{ width: '96.125px' }}>
                                                        Status				</th>


                                                </tr>
                                            </thead>
                                            <tbody>

                                                {aepsData?.data && aepsData.data.length > 0 ? (
                                                    aepsData.data.map((item, i) => (
                                                        <tr role="row" className="odd" >
                                                            <td>{item?.user_id?.name}</td>
                                                            <td>{item?.bank_account_number}</td>
                                                            <td>{item?.bank_account_name}</td>
                                                            <td>{item?.bank_id}</td>
                                                            <td>{item?.account_type}</td>

                                                            <td>{item?.bank_name}</td>

                                                            <td>{item?.bank_ifsc}</td>
                                                            <td>{item?.bank_branch}</td>
                                                            <td>
                                                                {item?.bank_proof ? (<img src={`${baseUrlImage}${item?.bank_proof}`} alt="bank proof" />) : "Bank Proof Not Found"}
                                                            </td>
                                                            <td>{item?.mobile_number}</td>
                                                            <td> <span className="badge badge-success text-light border-0" style={{ backgroundColor: `${item?.approval_type === "Self" ? 'blue' : '#bc3922ab'}`, fontSize: `${item?.approval_type === "Vendor" ? '0.8rem' : ''}` }}>{item?.approval_type === "Self" ? 'SELF' : 'VENDOR'}</span>
                                                            </td>
                                                            <td>{item?._id}</td>
                                                            <td><span
                                                                className={`${item?.status === 1 ? "btn btn-warning w-100" :
                                                                    item?.status === 2 ? "btn btn-primary w-100" :
                                                                        item?.status === 3 ? "btn btn-danger w-100" :
                                                                            ""
                                                                    }`}
                                                            >
                                                                {item?.status === 1 && 'Pending'}
                                                                {item?.status === 2 && 'Success'}
                                                                {item?.status === 3 && 'Failed'}
                                                            </span></td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr role="row" className="odd">
                                                        <td colSpan="10" style={{ textAlign: "center" }}><strong>No Data Found</strong></td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="dataTables_info" id="empoloyees-tblwrapper_info" role="status" aria-live="polite">
                                    Total {aepsData?.totalCount} entries
                                </div>
                                <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                    <Pagination
                                        /* showSizeChanger
                                        onShowSizeChange={''} */

                                        defaultCurrent={1}
                                        onChange={onChangeVal}
                                        total={aepsData?.totalCount}
                                    />
                                </div>
                            </div>
                            <div className="dataTables_info" id="empoloyees-tblwrapper_info" role="status" aria-live="polite">
                                {aepsData ? <h4> Total Amount : {aepsData?.totalAmount} </h4> : ""}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default PayoutBeneFiciaryReportsList
