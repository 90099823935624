import React from 'react'
import BillPaymentHistory from '../../../components/additionalReports/billPaymentHistory/BillPaymentHistory'

function BillPaymentHistoryPage() {
  return (
    <>
    <BillPaymentHistory/>
    </>
  )
}

export default BillPaymentHistoryPage