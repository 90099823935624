import React from 'react'
import AepsTxn from '../../components/aepsMaster/aepsTxn/AepsTxn'

function AepsTxnPage() {
  return (
    <>
      <AepsTxn/>
    </>
  )
}

export default AepsTxnPage
