import React from 'react';
import { Pagination ,Button} from 'antd';
import ExportPdf from '../../common/exportPdf/ExportPdf';
import { ToastContainer, toast } from 'react-toastify';

function CreditReport({ onChangeVal, aepsData, title, downloadExcel, count, handlePageSizeChange ,responseEnquiry ,loading2 }) {
    return (
        <>
            <ToastContainer />
            <section className="ListDistributer exppdf">
                <div className="row m-4">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                {/* Total Amount at the Top */}
                                <div className="p-3">
                                    <h3>Total Amount: <b>{aepsData?.totalAmount || 0}</b></h3>
                                </div>

                                <div className="table-responsive active-projects style-1">
                                    <div className="tbl-caption">
                                        <h4 className="heading mb-0"><b>{title} List</b></h4>
                                        <div className='d-flex'>
                                            <ExportPdf />
                                            <button className='btn btn-info' onClick={downloadExcel} style={{background:'green'}}>Download Excel</button>
                                        </div>
                                    </div>

                                    <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="table-to-xls" className="table dataTable no-footer" role="grid">
                                            <thead>
                                                <tr role="row">
                                                    <th>Sr. No</th>
                                                    <th>Date / Time</th>
                                                    <th>Transaction ID</th>
                                                    <th>Opening Balance</th>
                                                    <th>Amount</th>
                                                    <th>Charge</th>
                                                    <th>Closing Balance</th>
                                                    <th>Status</th>
                                                    <th>Operator Name</th>
                                                    <th>Card Number</th>
                                                    <th>Biller ID</th>
                                                    <th>Name</th>
                                                    <th>Retailer Code</th>
                                                    <th>Mobile No</th>
                                                   
                                                    
                                                    <th>Distributor Name</th>
                                                    <th>Distributor Refer ID</th>
                                                    <th>Distributor Contact</th>
                                                    <th>Super Distributor Name</th>
                                                   
                                                    <th>Super Distributor Contact</th>
                                                    
                                                    <th>Super Distributor Refer ID</th>
                                                    <th>Ref ID</th>
                                                    <th>Txn. Reference ID</th>
                                                    <th>Updated</th>
                                                    
                                                    <th>Enquery</th>
                                                  
                                                    
                                                   
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {aepsData?.data?.map((item, i) => (
                                                    <tr key={i} role="row">
                                                        <td>{i + 1}</td>
                                                        <td>{item?.createdAt}</td>
                                                        <td>{item?.txn_id}</td>
                                                        <td>{item?.open_bal}</td>
                                                        <td>{item?.amount}</td>
                                                        <td>{item?.charge}</td>
                                                        <td>{item?.close_bal}</td>
                                                        <td>
                                                            {item?.status === 2 && <button className="btn btn-success">Success</button>}
                                                            {item?.status === 1 && <button className="btn btn-warning">Pending</button>}
                                                            {item?.status === 3 && <button className="btn btn-danger">Failed</button>}
                                                            {item?.status === 4 && <button className="btn btn-light">Refund</button>}
                                                        </td>
                                                        <td>{item?.operator_name}</td>
                                                        <td>{item?.ca_num}</td>
                                                        <td>{item?.biller_id}</td>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.refer_id}</td>
                                                        <td>{item?.retailer_mobile}</td>
                                                       
                                                       
                                                        <td>{item?.distributor_name || ""}</td>
                                                        <td>{item?.distributor_refer_id || ""}</td>
                                                        <td>{item?.distributor_mobile || ""}</td>
                                                        <td>{item?.master_dist_name || ""}</td>
                                                        
                                                        <td>{item?.master_dist_mobile || ""}</td>
                                                       
                                                        <td>{item?.master_dist_refer_id || ""}</td>
                                                        <td>{item?.refId}</td>
                                                        <td>{item?.txnReferenceId}</td>
                                                        <td>{item?.updatedAt}</td>

                                                        <td><Button variant="outline-primary" onClick={() => responseEnquiry(item._id)}
                              disabled={loading2 || item?.status == 2 || item?.status == 4 }>
                            Enquiry
                            </Button></td>
                                                       
                                                        
                                                       
                                                        
                                                     
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {/* Pagination and Entry Info */}
                                <div style={{ display: "flex", justifyContent: "space-between" }} className="p-3">
                                    <div className="dataTables_info">
                                        Total {aepsData?.totalCount || 0} entries
                                    </div>
                                    <div className="dataTables_paginate paging_simple_numbers">
                                        <Pagination
                                            defaultCurrent={1}
                                            onChange={onChangeVal}
                                            total={aepsData?.totalCount || 0}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CreditReport;
