import { Navigate, Route, Routes } from "react-router-dom";

import "./assets/css/style.css";
import "react-toastify/dist/ReactToastify.css";

// import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "./common/CustomInputField/index.module.scss";
import DasBoardPage from "./pages/dasBoardPage";
import DasBoardRight from "./pages/dasBoardRight/DasBoardRight";
import DistributerPage from "./pages/distributer";
import CreateUserDistributerPage from "./pages/distributer/createUserDistributer/CreateUserDistributer";
import RetailerPage from "./pages/retailer";
import CreateUserRetailerPage from "./pages/retailer/createUserRetailer";
import SuspendedUserPage from "./pages/suspendedUsers";
import NotWorkingUserPage from "./pages/notWorkingUser";
// import Footer from './common/footer/Footer';
import Dashboardpage from "./pages/dashboard";
import AlltransportationReportsPage from "./pages/alltransportationReports";
import PanCardReportPages from "./pages/panCardReport";
import PendingTransactionReport from "./components/reportMaster/pendingTransactionReport/PendingTransactionReport";
import OperatorWiseSalePage from "./pages/operatorWiseSale";
import LedgerReportPage from "./pages/LedgerReport";
import BalanceTransferPage from "./pages/balanceTransfer";
import BalanceReturnRequestPage from "./pages/balanceReturnRequest";
import PaymentRequestViewPage from "./pages/paymentRequestView";
import PaymentRequestPage from "./pages/PaymentRequest";
import PendingDisputPage from "./pages/pendingDisput";
import SolveDisputePage from "./pages/solveDispute";
import DistributerIncomePage from "./pages/distributerIncome";
import RetailerIncomePage from "./pages/retailerIncome";
import MyIncomePage from "./pages/myIncome";
import DebitReportPage from "./pages/debitReport";
import CreditReportPage from "./pages/creditReport";
import MyCommissionRechargePage from "./pages/profile/myCommisionRecharge";
import MasterPages from "./pages/topNavigationPages/Master";
import CompanyStaffPages from "./pages/topNavigationPages/companyStaff/CompanyStaffPages";
import BoardcastPage from "./pages/boardcast";
import BankMasterP from "./pages/topNavigationPages/Master/bankMaster";
import Provider from "./components/topNavigationComp/apimaster/provider/Provider";
import AddproviderLogo from "./components/topNavigationComp/apimaster/provider/providerForm/addproviderLogo/AddproviderLogo";
import AddBank from "./components/topNavigationComp/masters/bankMaster/addBank/AddBank";
import EditAddBank from "./components/topNavigationComp/masters/bankMaster/editAddBank/EditAddbank";
import EditRoleMaster from "./components/topNavigationComp/masters/roleMaster/editRoleMaster/EditRoleMaster";
import RoleMasterPage from "./pages/topNavigationPages/Master/roleMaster";
import StatusMasterPage from "./pages/topNavigationPages/Master/statusMaster";
import EditStatusMaster from "./components/topNavigationComp/masters/statusMaster/editStatusMaster/EditStatusMaster";
import ServiceMasterPage from "./pages/topNavigationPages/Master/serviceMaster";
import EditServiceMaster from "./components/topNavigationComp/masters/serviceMaster/serviceMasterEdit/ServiceMasterEdit";
import AddRoleMaster from "./components/topNavigationComp/masters/roleMaster/addRoleMaster/AddRoleMaster";
import AddStatusMaster from "./components/topNavigationComp/masters/statusMaster/addStatusMaster/AddStatusMaster";
// import AddServiceMaster from "./components/topNavigationComp/masters/serviceMaster/admin/add-service-masterMaster/AddServiceMaster";
import AddServiceMaster from "./components/topNavigationComp/masters/serviceMaster/addServiceMaster/AddServiceMaster";
import PaymentMethodMasterPage from "./pages/topNavigationPages/Master/paymentMethodMaster";
import AddPaymentMethod from "./components/topNavigationComp/masters/paymentMethodMaster/addPaymentMethod/AddPaymentMethod";
import EditPaymentMethod from "./components/topNavigationComp/masters/paymentMethodMaster/editPaymentMethod/EditPaymentMethod";
import PayoutBeneficiaryMasterPage from "./pages/topNavigationPages/payoutBenefisiaryMaster";
import AddPayoutBeneficiaryMaster from "./components/topNavigationComp/masters/PayoutBeneficiaryMaster/addPayoutBeneficiaryMaster/AddPayoutBeneficiaryMaster";
import EditPayoutBeneficiaryMaster from "./components/topNavigationComp/masters/PayoutBeneficiaryMaster/EditPayoutBeneficiaryMaster/EditPayoutBeneficiaryMaster";
import ServiceCategoryMasterPage from "./pages/topNavigationPages/Master/serviceCategoryMaster";
import AddServiceCategoryMaster from "./components/topNavigationComp/masters/serviceCategoryMaster/addServiceCategoryMaster/AddServiceCategoryMaster";
import UpdateServiceCategoryMaster from "./components/topNavigationComp/masters/serviceCategoryMaster/updateServiceCategoryMaster/UpdateServiceCategoryMaster";
import AreaPage from "./pages/topNavigationPages/ZoneModule/area";
import AddArea from "./components/topNavigationComp/ZoneModule/area/addArea/AddArea";
import EditArea from "./components/topNavigationComp/ZoneModule/area/editArea/EditArea";
import AgentOnbordingPage from "./pages/topNavigationPages/agentOnbording/index";
import AddAgentOnbording from "./components/topNavigationComp/masters/agentOnbording/addAgentOnbording/AddAgentOnbording";
import UpdateAgentOnbording from "./components/topNavigationComp/masters/agentOnbording/UpdateAgentOnbording/UpdateAgentOnbording";
import PageNotFound from "./common/pageNotFound/PageNotFound";
import BalanceTransferAdd from "./components/balanceTransfer/blanceTrnsferAdd/BlanceTransferAdd";
import UpdateBlanceTransfer from "./components/balanceTransfer/UpdateBlanceTransfer/UpdateBlanceTransfer";
import AddBlanceReturnRequest from "./components/balanceReturnRequest/addBlanceReturnRequest/AddBlanceReturnRequest";
import UpdateBlanceReturnRequest from "./components/balanceReturnRequest/updateBlanceReturnRequest/UpdateBlanceReturnRequest";
import AddPaymentRequestView from "./components/paymentRequestView/addPaymentRequestView/AddPaymentRequestView";
import AddPendingDispute from "./components/pendingDisput/addPendingDisput/AddPendingDipute";
import UpdatePendingDisput from "./components/pendingDisput/updatePendingDisput/UpdatePendingDisput";
import AddSolveDispute from "./components/solveDispute/addSolveDispute/AddSolveDispute";
import UpdateSolveDispute from "./components/solveDispute/updateSolveDispute/UpdateSolveDispute";
import AddTransactionReport from "./components/reportMaster/alltransationsReports/addTransactionReport/AddTransactionReport";
import AddpanCardReport from "./components/reportMaster/pancardReport/addPanCardReport/AddpanCardReport";
import AddPendingTransactionReport from "./components/reportMaster/pendingTransactionReport/addPendingTarnsactionReport/AddPendingTransactionReport";
import AddNotWorkingUserList from "./components/notWorkingUser/addNotWorkingUserList/AddNotWorkingUserList";
import AddSuspendedUser from "./components/suspendedUsers/add SuspendedUser/AddSuspendedUser";
import AddOperatorWiseReport from "./components/reportMaster/operatorWiseSale/addOperatorWiseReports/AddOperatorWiseReport";
import AddDebitReport from "./components/reportMaster/debitReport/addDebitReport/AddDebitReport";
import AddCreditReport from "./components/reportMaster/creditReport/addCreditReport/AddCreditReport";
import AddProvider from "./components/topNavigationComp/apimaster/provider/addProvider/AddProvider";
import UpdateProvider from "./components/topNavigationComp/apimaster/provider/updateProvider/UpdateProvider";
import ApiMasterPage from "./pages/topNavigationPages/apisMaster/apiMaster";
import AddApiMaster from "./components/topNavigationComp/apimaster/appiMaster/addApiMaster/AddApiMaster";
import UpdateApiMaster from "./components/topNavigationComp/apimaster/appiMaster/updateApiMaster/UpdateApiMaster";
import DominationWiseMasterPage from "./pages/topNavigationPages/apisMaster/dominationWiseMaster";
import AddDominationWiseMaster from "./components/topNavigationComp/apimaster/dominationWiseMaster/addDominationWiseMaster/AddDominationWiseMaster";
import UpdateDonimationWiseMaster from "./components/topNavigationComp/apimaster/dominationWiseMaster/updateDominationWiseMaster/UpdateDominationWiseMaster";
import NumberSeriesMasterPage from "./pages/topNavigationPages/apisMaster/numberSeriesMaster";
import AddNumberSeriesMaster from "./components/topNavigationComp/apimaster/numberSeriesMaster/addNumberSeriesMaster/AddNumberSeriesMaster";
import UpdateNumberSeriesMaster from "./components/topNavigationComp/apimaster/numberSeriesMaster/updateNumberSeriesMaster/UpdateNumberSeriesMaster";
import StateWiseApiPage from "./pages/topNavigationPages/apisMaster/stateWiseApi";
import StateWiseProviderSetting from "./components/topNavigationComp/apimaster/stateWiseApi/stateWiseProviderSettings/StateWiseProviderSetting";
import BackupApiMasterPage from "./pages/topNavigationPages/apisMaster/backupApiMaster";
import AddBackupApiMaster from "./components/topNavigationComp/apimaster/backupApiMaster/addBackupApiMaster/AddBackupApiMaster";
import UpdateBackupApiMaster from "./components/topNavigationComp/apimaster/backupApiMaster/updateBackupApiMaster/UpdateBackupApiMaster";
import ApiSwitchingPage from "./pages/topNavigationPages/apisMaster/apiSwitch";
import UserOperatorLimitPage from "./pages/topNavigationPages/apisMaster/userOperatorLimit";
import ViewOperatorLimit from "./components/topNavigationComp/apimaster/userOperatorLimit/viewOperatorLimit/ViewOperatorLimit";
import ApiVandorPaymentPage from "./pages/topNavigationPages/apisMaster/apiVandorPayment";
import SiteSettingPage from "./pages/topNavigationPages/settings/siteSetting";
import SmsSettingsPage from "./pages/topNavigationPages/settings/smsSetting";
import PackageSettingsPage from "./pages/topNavigationPages/settings/packageSettings";
import CommissionSetup from "./components/topNavigationComp/settings/packageSettings/commissionSetup/CommissionSetup";
import SetOperatorSettings from "./components/topNavigationComp/settings/packageSettings/commissionSetup/setOperatorSettings/SetOperatorsettings";
import BankSettingsPage from "./pages/topNavigationPages/settings/bankSettings";
import AddBankSettings from "./components/topNavigationComp/settings/bankSettings/addBankSetting/AddBankSettings";
import UpdateBankSettings from "./components/topNavigationComp/settings/bankSettings/updateBankSettings/UpdateBanksettings";
import AddDistributerInc from "./components/distributerIncome/distributerIncomeForm/addDistributerInc/AddDistributerInc";
import AddRetailerInc from "./components/retailerIncome/addRetailerInc/AddRetailerInc";
import ContactInquery from "./pages/topNavigationPages/Master/contactInquery/index";
import AddContactEnquiry from "./components/topNavigationComp/masters/contactEnquiry/addContactEnquiry/AddContactEnquiry";
import AddCompanyStaff from "./components/topNavigationComp/companyStaff/addCompanyStaff/AddCompanyStaff";
import CashfreeGateWayMasterPages from "./pages/topNavigationPages/Master/cashfreeGateWayMasterPages";
import AddCashFreeGateway from "./components/topNavigationComp/masters/cashfreeGatewayMaster/addCashFreeGateway/AddCashFreeGateway";
import SendRoleWisepage from "./pages/topNavigationPages/whatsAppPages";
import CompanySettings from "./components/topNavigationComp/settings/companySetting/CompanySettings";
import LogoUploadPage from "./pages/topNavigationPages/settings/logoUpload";
import SignatureUploadPage from "./pages/topNavigationPages/settings/signatureUpload";
import ServiceBannerPage from "./pages/topNavigationPages/settings/serviceBanner";
import NotificationSettingsPage from "./pages/topNavigationPages/settings/notificationSettings";
import DynemicPage from "./pages/topNavigationPages/websiteMaster/dynemicPage";
import FrontBannerPage from "./pages/topNavigationPages/websiteMaster/frontBanner";
import UserPannelSliderPage from "./pages/topNavigationPages/websiteMaster/userPannelSlider";
import LoginPage from "./pages/login";
import CurrencyPage from "./pages/currency";
import AddCurrency from "./components/currency/addCurrency/AddCurrency";

import UpdatePaymentRequestView from "./components/paymentRequestView/updatePaymentRequestView/updatePaymentRequestView";
import UpdateCurrency from "./components/currency/updateCurrency/UpdateCurrency";
import CurrencyForm from "./components/currency/CurrencyForm";
import CountryPage from "./pages/country";
import CountryForm from "./components/country/countryForm/CountryForm";
import LanguagePage from "./pages/language";
import FormLanguage from "./components/language/formLanguage.js/FormLanguage";
import CountryZoneMudle from "./components/topNavigationComp/ZoneModule/country/Country";
import CountryAddZone from "./components/topNavigationComp/ZoneModule/country/zoneCountryAdd/ZoneCountryAdd";
import StaffPage from "./pages/staffModule/staff";
import StaffForm from "./components/staffModule/staffForm/StaffForm";
import StaffTypePage from "./pages/staffModule/staffType";
import StaffTypeAdd from "./components/staffModule/staffType/staffTypeAdd/StaffTypeAdd";
import Customer from "./components/topNavigationComp/customer/Customer";
import AddUsers from "./components/topNavigationComp/customer/users/addUsers/AddUsers";
import IndexZone from "./components/topNavigationComp/ZoneModule/country/zoneCountryAdd/IndexZone";
import UsersType from "./pages/topNavigationPages/userType";
import AddUsertType from "./components/topNavigationComp/customer/addUserType/AddUsertType";
import StateMasterPage from "./pages/stateMaster";
import FormStateMaster from "./components/stateMaster/formStateMaster/FormStateMaster";
import Profile from "./common/profileUser/Profile";
import ServiceAreaPermissionPage from "./pages/servicePermission/serviceAreaPermission/index";
import FormServicePermission from "./components/servicePermission/formServiceAreaPermission/FormServiceAreaPermission";
import ApiTransitionMasterPage from "./pages/topNavigationPages/apisMaster/apiTransitionMaster";
import PrivateRoute from "./privateRote/PrivateRoute";
import { useSelector } from "react-redux";
import { getToken2 } from "./utils/localStorage";
import { useDispatch } from "react-redux";
import { setIsLogin } from "./slice/auth";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SalesReportsPage from "./pages/salesRep";
import PerformanceReports from "./components/salesReports/performanceReports/PerformanceReports";
import AddpadateSummary from "./components/salesReports/summaryReports/addUpdateSummary/AddpadateSummary";
import AddUpdatePerformanceReports from "./components/salesReports/performanceReports/addupdatePerformanceReport/AddUpdatePerformanceReports";
import DmtCommisionp from "./pages/dmt/dmtCommision";
import DmtCommiosionSche from "./pages/dmt/dmtCommisionSche";
import DmtCommisionForm from "./components/dmtModule/dmtCommision/dmtCommisionForm/DmtCommisionForm";
import DmtcommschForm from "./components/dmtModule/dmtCommSche/dmtcommschForm/DmtcommschForm";
import ZoonArea from "./pages/zoonArea";
import Statement from "./pages/statement/page";
import StatementWallet from "./pages/walletstatement/page";
import Transactions from "./pages/transactions";
import ViewProfileMemberPage from "./pages/viewProfileMember";
import UtiPancardPage from "./pages/utiPandcard";
import BillPayment from "./pages/billpayment";
import WalletsReportsPage from "./pages/walletsReport";
import AddWalletsReports from "./components/walletsReport/AddWalletsReports/AddWalletsReports";
import Recharge from "./pages/recharge";
import MoneyTransfer from "./pages/moneyTransfer";
import RechargeReportsPage from "./pages/additionalReportsPages/rechargeReports";
import SearchReTarnsactionPage from "./pages/additionalReportsPages/searchReTransactions";
import IndoNepalMoneyPage from "./pages/additionalReportsPages/indoNepalMoney";
import FastTagReportsPage from "./pages/additionalReportsPages/fastTagReports";
import DmtReportsPages from "./pages/additionalReportsPages/dmtReport";
import DmtReportSearch from "./components/additionalReports/dmtReportSearch/DmtReportSearch";
import DmtKycReportPage from "./pages/additionalReportsPages/dmtKycReport";
import CcbilPayPage from "./pages/additionalReportsPages/ccbillPaymentRep";
import AepsXpressPage from "./pages/additionalReportsPages/aepsXpress";
import AepsTezRepPage from "./pages/additionalReportsPages/aepsTezRep";
import AepsIcici from "./pages/additionalReportsPages/aepsIcici";
import AadharRepPage from "./pages/additionalReportsPages/aadharPayReport";
import InsurenceDekhoPage from "./pages/additionalReportsPages/insurenceDekho";
import BillPaymentHistoryPage from "./pages/additionalReportsPages/billPaymentHistory";
import AccountReportPage from "./pages/additionalReportsPages/accountReport";
import BalanceTopUpHisPage from "./pages/additionalReportsPages/balanceTopupHistory";
import BankListPage from "./pages/additionalReportsPages/bankListPage";
import PayMentWithDrawlReportpage from "./pages/additionalReportsPages/paymentWithDrawalRep";
import CmsBillReportsPage from "./pages/additionalReportsPages/cmsBillReport";
import PostPaidBillPage from "./pages/additionalReportsPages/postPaidBill";
import TurboElinePage from "./pages/additionalReportsPages/turboEline";
import PaymentGatewayPage from "./pages/additionalReportsPages/paymentGatway";
import RupayGiftCardPage from "./pages/additionalReportsPages/rupayGiftCard";
import WallRep from "./pages/walletReport";
import AddUpdateVirtualWall from "./components/virtualWallet/addUpdateVirtualWall/AddUpdateVirtualWall";
import ChangePinPage from "./pages/changePinPage";
import ChangePasswordPage from "./pages/changePassword";
import AllBeneficiariesPage from "./pages/allBeneficiaries";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import LoginPasswordReset from "./pages/paswordResetPages";
import ChargesSettingsPage from "./pages/chargeSettings/index";
import AepsTxnPage from "./pages/aepsTxn";
import AadharpayTxnPage from "./pages/aadharPayTxnPage";
import QuickDhanPage from "./pages/quickDhan";
import QuickDhanTxnPage from "./pages/quickDhanTxn";
import ReasonsPage from "./pages/dmt/reasonsPage";
import ReasonForms from "./components/dmtModule/reasons/reasonsForm/ReasonsForm";
import DmtDisputesPage from "./pages/dmt/disputes";
import DmtStatusPage from "./pages/dmt/dmtStatus/DmtStatus";
import DmtStatusForm from "./components/dmtModule/status/dmtStatusForm/DmtStatusForm";
import PriorityPages from "./pages/dmt/priorityPages";
import PriorityMasterForm from "./components/dmtModule/priorityMaster/priorityMasterForm/PriorityMasterForm";
import ReasonTypePages from "./pages/dmt/reasonType";
import TypeReasonForm from "./components/dmtModule/typeReason/typeReasonForm/TypeReasonForm";
import TicketPage from "./pages/ticketPage";
import TicketForm from "./components/ticketMaster/addTicket/TicketForm";
import AddTicket from "./pages/dmt/replyDisput/ReplyDisputs";
import DepartmentMasterPage from "./pages/topNavigationPages/Master/department";
import AddDepartmentMaster from "./components/topNavigationComp/masters/roleMaster/addDepartment/AddRoleMaster";
import TicketReply from "./pages/dmt/replyDisput/TicketReply";
import PackageMasterPage from "./pages/packagemaster";
import AddPackegs from "./pages/packagemaster/addPackegs/AddPackegs";
import PackageSaleReplort from "./pages/Package-Sales-Report";
import { SameReportsPage } from "./pages/sameReport/indexSame";
import { WaterSupplyPage } from "./pages/watersupply/indexSame";
import { ElectricityReport } from "./pages/electric/indexSame";
import { BroadbandReportPage } from "./pages/broadband/indexSame";
import { CableTvPage } from "./pages/cable-tv/indexSame";
import { DigitalVocherPage } from "./pages/digital-voucher/indexSame";
import { FastTagReportsPages } from "./pages/fastag/indexSame";
import { InsuranceReportsPages } from "./pages/insurance/indexSame";
import { LoanreypaymentReportsPages } from "./pages/loan-repayment/indexSame";
import { MunicipaltaxPage } from "./pages/municipal-taxes/indexSame";
import { MobilePrepaidPage } from "./pages/molbile-prepaid/indexSame";
import { MobilePostPaid } from "./pages/broadband-postpaid/indexSame";
import { DTHpage } from "./pages/dth/indexSame";
import { LpgBookingPage } from "./pages/lpg-booking/indexSame";
import Tcertificate from "./pages/tCertificate/tCertificate";
import NewCommition from "./components/dmtModule/dmtCommision/newCommision/NewCommition";
import EditPackegs from "./pages/packagemaster/addPackegs/EditPackege";
import DmtCommisionpStucture from "./pages/dmt/dmt-commision-structure";
import AepsCAshReport from "./pages/aeps-report";
import AepsPayoutReport from "./pages/aeps-payout";
// import { PayoutReport } from "./pages/payout-reports/indexSame";
import { CreditCardReport } from "./pages/credit-card-bill-payment-report/indexSame";
import MoveMember from "./pages/move-member";
import { AllMemberReport } from "./pages/all-members-reports/indexSame";
import { MemberWalletBalace } from "./pages/members-wallet-balance/indexSame";
import { AEPSBalanceReport } from "./pages/aeps-balance-enquiry-reports/indexSame";
import { AEPSMiniStatmentReport } from "./pages/aeps-mini-statement/indexSame";
import { AdharPayReport } from "./pages/aadhar-pay-report/indexSame";
import { AEPSCashReport } from "./pages/aeps-cash-deposit-reports/indexSame";
import { AEPSBankReport } from "./pages/aeps-bank-withdraw-reports/indexSame";
import { FligthBookingReport } from "./pages/flight-booking-report/indexSame";
import { BusBookingReport } from "./pages/bus-booking-report/indexSame";
import { HotelBookingReport } from "./pages/hotel-booking-report/indexSame";
import UpgradeMember from "./pages/upgrade-member";
import AddPermision from "./components/rolesPermision/addStaffPermision/AddPermision";
import ListStaffPermision from "./components/rolesPermision/listStaffPermision/ListStaffPermision";
import BuildingMaster from "./components/permisionMaster/buildingPer/BuildingMaster";
import BlockMaster from "./components/permisionMaster/blockpermission/BlockMaster";
import Area from "./components/topNavigationComp/ZoneModule/area/Area";
import AreaPermision from "./components/permisionMaster/areaPerMaster/AreaPermision";
import FloorPermission from "./components/permisionMaster/floorMaster/floorPermission/FloorPermission";
import WardMaster from "./components/permisionMaster/wardMaster/WardMaster";
import OfficeMaster from "./components/permisionMaster/officeMaster/OfficeMaster";
import DepartmentMaster from "./components/permisionMaster/departmentMaster/DepartmentMaster";
import DesignationMaster from "./components/permisionMaster/designationMaster/DesignationMaster";
// --------------------------mustafa import routes----------------

import AddMainModulePage from "./pages/RolePermission/addMainModule/AddMainModule";
import MainListModule from "./pages/RolePermission/addMainModule/mailListModule/MailLisyModule";
import AddSubModule from "./pages/RolePermission/subModule/addSubModule/AddSubModule";
import MainSubListModule from "./pages/RolePermission/subModule/mailSubListModule/MainSubListModule";
import AddRole from "./pages/RolePermission/addRole/AddRole";
import ListMainRole from "./pages/RolePermission/addRole/listmainRole/ListMainRole";
import ComplaintsDashboardPages from "./pages/complaintsDashboardPages";
import AddCompalnePage from "./pages/createCompalne/addCompalne/AddComplane";
import MainListComplane from "./components/createCompalne/listComplane/MainListCompalne";
import StaffKycMaster from "./components/staffKycMaster/StaffKycMaster";
import StaffKycMasterByid from "./components/staffKycMaster/StaffKycMaster";
import StaffKycDetailsMasterByid from "./components/staffKycDetailsByStaffId/StaffKyDetailsMasterByid";
import { NotificationReport } from "./pages/Notification-Report/indexSame";
import { getToken } from 'firebase/messaging';
import { messaging } from "./firebase/fireBase";
import { sendNotification } from "./api/login/Login";
import BbpsCommiosionSche from "./pages/bbps/bbpsCommisionSche";
import BbpsCommisionForm from "./components/bbps/BbpsCommisionForm/DmtCommisionForm";
import BusniessReport from "./pages/dasBoardRight/BusniessReport";
import TdsReport from "./pages/dasBoardRight/Tdsreport";
import ChargeReport from "./pages/dasBoardRight/ChargeReport";
import LedgerReport from "./pages/dasBoardRight/LedgerReport";
import PayoutReportsPage from "./pages/payoutChargesPages";
import ChargesPayoutForm from "./components/chargesPayout/chargesPayoutForm/ChargesPayoutForm";
import AEPSSettings from "./components/topNavigationComp/settings/chargeSettings/Aepsettings";
import PayoutBeneFiciaryReports from "./components/payoutBeneficiaryReports/PayoutBeneFiciaryReports";
import Allcommisionreport from "./allcommisionreport";
import Payout from "./components/chargesPayout/chargesPayoutList/Payout";
import Aepswallet2 from "./components/chargesPayout/chargesPayoutList/Aepswallet";
import MobileRechargeComp from "./components/elecreic/MobileRechargeComp";
import Reverpaymentreport from "./pages/dasBoardRight/Reverpaymentreport";
import Airtelcms from "./components/aeps-report/Airtelcms";


// -----------------current import --11-09-24-------------------
/* import FlightRequestPages from "./pages/flightAllPages";
import FlightAmbd from "./components/flightComp/flightAmbd/FlightAmbd";
import FlightCancel from "./components/flightComp/flightCancel/FlightCancel";
import FlightRefunds from "./components/flightComp/flightRefundlList/FlightRefunds";
import FlightBookingCalender from "./components/flightComp/flightBCalender/flightBookingCalender/FlightBookingCalender";
import HoteltAmbd from "./components/hotelComp/hotelAmbd/HotelAmbd";
import HotelBooking from "./components/hotelComp/hotelBooking/HotelBooking";
import HotelBCalender from "./components/hotelComp/hotelBCalender/HotelBCalender";
import HotelCancel from "./components/hotelComp/HotelCancel/HotelCancel";
import HotelRefunds from "./components/hotelComp/HotelRefundlList/HotelRefunds";
import BusMarkup from "./components/busComp/busMarkup/BusMarkup";
import BusAmendments from "./components/busComp/busAmendment/BusAmendments ";
import BusBooking from "./components/busComp/busBooking/BusBooking";
import BusCancel from "./components/busComp/busCancel/BusCancel";
import BusRefunds from "./components/busComp/BusRefund/BusRefund";
import BusGroupRequest from "./components/busComp/busGroupRequest/BusGroupRequest";
import BusDiscount from "./components/busComp/busDiscount/BusDiscount";
import BusUpload from "./components/busComp/busUpload/BusUpload";
import VisaMarkup from "./components/visaComp/visaMarkup/Visamarkup";
import VisaDiscount from "./components/visaComp/VisaDiscount/VisaDiscount";
import VisaList from "./components/visaComp/VisaList/Visalist";
import VisaAmendmentsList from "./components/visaComp/VisaAmendmentsList/VisaAmendmentslist";
import VisaBooking from "./components/visaComp/visaBooking/visaBooking";
import VisaSetings from "./components/visaComp/visaSettings/VisaSetings";
import VisaGroupReq from "./components/visaComp/visaGroupReq/VisaGroupReq";
import VisaRList from "./components/visaComp/VisaRList/VisaRlist";
import VisaRForm from "./components/visaComp/VisaRList/visaRForm/VisaRForm";
import FlightGroupRequest from "./components/flightComp/flightGroupRequest/FlightGroupRequest";
import FlightOfline from "./components/flightComp/flightOfline/FlightOfline";
import FlightMarkUp from "./components/flightComp/flightMarkup/FlightMarkUp";
import FlightDiscount from "./components/flightComp/flightDiscount/FlightDiscount";
import FlightUploadTicket from "./components/flightComp/flightUploadTicket/FlightUploadTicket";
import HotelHoldQues from "./components/hotelComp/hotelHoldQues/HotelHoldQues";
import HotelMarkup from "./components/hotelComp/hotelMarkup/HotelMarkUp";
import HotelDiscount from "./components/hotelComp/hotelDiscount/HotelDiscount";
import HotelGroupRequest from "./components/hotelComp/HotelGroupRequest/HotelGroupRequest";
import HotelCoupon from "./components/hotelComp/hotelCoupon/HotelCoupon";
import HotelVocherUpload from "./components/hotelComp/hotelVocherUpload/HotelVocherUpload"; */
//---------------------end--------------------------
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { isLogin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // console.log(getToken());
    requestPermissions()
    if (getToken2()) {
      dispatch(setIsLogin({ isLogin: true }));
      navigate(location?.pathname);
    }
  }, []);

  useEffect(() => {
    setIsAuthenticated(isLogin);

  }, [isLogin]);




  const [tokenNoti, setokenNoti] = useState(null);

  const sendNotification2 = async (token) => {
    try {
      let result = await sendNotification({ type: 'Browser', token: token });

    } catch (error) {

    }
  };

  async function requestPermissions() {


    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        getToken(messaging, { vapidKey: 'BPmnN4enu6SLX6ASW7dctK6Q0j3GnTUhL5ZRi16I6RDqGav4khN2JIHmdKcL4eTqwRBu-PWmaUa1G-Oaor7AcF4' }).then((currentToken) => {
          if (currentToken) {
            console.log('Got FCM device token:', currentToken);
            setokenNoti(currentToken)
            if (isLogin) {
              sendNotification2(currentToken)

            }
            // Send the token to your server or display it on the UI
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
      }
    })



  }
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  }, []);

  return (
    <>
      <Routes>
        {!isAuthenticated ? (
          <>
            <Route path="/" element={<Navigate to={"/loginPage"} />} />
            <Route path="/loginPage" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<LoginPasswordReset />} />
            <Route path="*" element={<Navigate to="/loginPage" />} />
          </>
        ) : (
          <>

            {/* <Route path="/loginPage" element={<LoginPage />} />
            <Route path="/" element={<Navigate to="/loginPage" />} />
        {/* <Route path="*" element={<Navigate to="/admin" />} /> */}
            <Route path="/" element={<Navigate to={"/admin"} />} />
            <Route
              path="/admin"
              element={<PrivateRoute isAuthenticated={isAuthenticated} />}
            >
              {/* <Route path="/admin" element={<DasBoardPage />} /> */}
              <Route path="" element={<DasBoardRight />} />
              <Route path="bank-master" element={<BankMasterP />} />
              <Route path="zoon_area" element={<ZoonArea />} />
              <Route path="add-bank" element={<AddBank />} />
              <Route path="update-bank/:id" element={<AddBank />} />
              {/* <Route path="edit-add-bank" element={<EditAddBank />} /> */}
              <Route path="role-master" element={<RoleMasterPage />} />
              <Route path="add-role-master" element={<AddRoleMaster />} />
              <Route
                path="update-role-master/:id"
                element={<AddRoleMaster />}
              />

              {/* <Route path="edit-role-master" element={<EditRoleMaster />} /> */}
              <Route
                path="company-staff/welcome"
                element={<CompanyStaffPages />}
              />
              <Route
                path="add-company-staff/welcome"
                element={<AddCompanyStaff />}
              />
              <Route
                path="company-staff-permission"
                element={<MasterPages />}
              />
              <Route path="status-master" element={<StatusMasterPage />} />
              <Route path="add-status-master" element={<AddStatusMaster />} />
              <Route
                path="update-status-master/:id"
                element={<AddStatusMaster />}
              />
              {/* <Route path="edit-status-master" element={<EditStatusMaster />} /> */}
              <Route path="service-master" element={<ServiceMasterPage />} />
              <Route path="add-service-master" element={<AddServiceMaster />} />
              <Route
                path="update-service-master/:id"
                element={<AddServiceMaster />}
              />
              {/* <Route path="edit-service-master" element={<EditServiceMaster />} /> */}
              <Route
                path="service-category"
                element={<ServiceCategoryMasterPage />}
              />
              <Route
                path="add-service-category"
                element={<AddServiceCategoryMaster />}
              />
              <Route
                path="update-service-category/:id"
                element={<AddServiceCategoryMaster />}
              />
              {/* <Route
            path="update-service-category"
            element={<UpdateServiceCategoryMaster />}
          /> */}
              <Route path="boardcast" element={<BoardcastPage />} />
              <Route
                path="payment-method"
                element={<PaymentMethodMasterPage />}
              />
              <Route path="add-payment-method" element={<AddPaymentMethod />} />
              <Route
                path="update-payment-method/:id"
                element={<AddPaymentMethod />}
              />
              {/* <Route path="update-payment-method" element={<EditPaymentMethod />} /> */}
              <Route
                path="payout-beneficiary-master"
                element={<PayoutBeneficiaryMasterPage />}
              />
              <Route
                path="add-payout-beneficiary-master"
                element={<AddPayoutBeneficiaryMaster />}
              />
              <Route
                path="update-payout-beneficiary-master/:id"
                element={<AddPayoutBeneficiaryMaster />}
              />
              {/* <Route
            path="update-payout-beneficiary-master"
            element={<EditPayoutBeneficiaryMaster />}
          /> */}
              <Route path="area" element={<AreaPage />} />
              <Route path="add-area" element={<AddArea />} />
              <Route path="update-area/:id" element={<AddArea />} />
              <Route
                path="agent-onboarding-list"
                element={<AgentOnbordingPage />}
              />
              <Route path="country-zone" element={<CountryZoneMudle />} />
              <Route path="add-country-zone" element={<IndexZone />} />
              {/* <Route
            path="add-country-zone/:id"
            element={<CountryAddZone />}
          /> */}

              <Route
                path="add-agent-onboarding"
                element={<AddAgentOnbording />}
              />
              <Route
                path="update-agent-onboarding/:id"
                element={<AddAgentOnbording />}
              />
              {/* <Route
            path="update-agent-onboarding"
            element={<UpdateAgentOnbording />}
          /> */}
              {/*  Shoib Route start */}
              <Route path="provider" element={<Provider />} />
              <Route path="add-provider" element={<AddProvider />} />
              <Route path="update-provider/:id" element={<AddProvider />} />
              <Route path="add-provider-logo" element={<AddproviderLogo />} />
              <Route path="update-provider-logo" element={<UpdateProvider />} />
              <Route path="api-master" element={<ApiMasterPage />} />
              <Route path="add-api-master" element={<AddApiMaster />} />
              <Route path="update-api-master/:id" element={<AddApiMaster />} />
              {/* <Route path="update-api-master" element={<UpdateApiMaster />} /> */}
              <Route
                path="api-transaction-master"
                element={<ApiTransitionMasterPage />}
              />
              <Route
                path="denomination-wise-api"
                element={<DominationWiseMasterPage />}
              />
              <Route
                path="add-denomination-wise-api"
                element={<AddDominationWiseMaster />}
              />
              <Route
                path="update-denomination-wise-api"
                element={<UpdateDonimationWiseMaster />}
              />
              <Route
                path="number-series-master"
                element={<NumberSeriesMasterPage />}
              />
              <Route
                path="add-number-series-master"
                element={<AddNumberSeriesMaster />}
              />
              <Route
                path="update-number-series-master"
                element={<UpdateNumberSeriesMaster />}
              />
              <Route path="state-wise-api" element={<StateWiseApiPage />} />
              <Route
                path="state-provider-settings"
                element={<StateWiseProviderSetting />}
              />
              <Route
                path="backup-api-master"
                element={<BackupApiMasterPage />}
              />
              <Route
                path="add-backup-api-master"
                element={<AddBackupApiMaster />}
              />
              <Route
                path="update-backup-api-master"
                element={<UpdateBackupApiMaster />}
              />
              <Route path="api-switching" element={<ApiSwitchingPage />} />
              <Route
                path="user-operator-limit"
                element={<UserOperatorLimitPage />}
              />
              <Route
                path="view-operator-limit"
                element={<ViewOperatorLimit />}
              />
              <Route
                path="vandor/payment/welcome"
                element={<ApiVandorPaymentPage />}
              />
              <Route
                path="site-setting/welcome"
                element={<SiteSettingPage />}
              />
              <Route
                path="sms-templete/welcome"
                element={<SmsSettingsPage />}
              />
              <Route
                path="package-settings"
                element={<PackageSettingsPage />}
              />
              <Route path="commission-setup" element={<CommissionSetup />} />
              <Route
                path="set-operator-commission"
                element={<SetOperatorSettings />}
              />
              <Route path="bank-settings" element={<BankSettingsPage />} />
              <Route path="user" element={<Customer />} />
              <Route path="user/add-user" element={<AddUsers />} />
              <Route path="update-user/:id" element={<AddUsers />} />
              <Route path="user-type" element={<UsersType />} />
              <Route path="add/user-type" element={<AddUsertType />} />
              <Route path="update/user-type/:id" element={<AddUsertType />} />
              <Route path="add-bank-settings" element={<AddBankSettings />} />
              <Route
                path="update-bank-settings"
                element={<UpdateBankSettings />}
              />
              <Route path="logo-upload" element={<LogoUploadPage />} />
              <Route
                path="signature-upload"
                element={<SignatureUploadPage />}
              />
              <Route path="service-banner" element={<ServiceBannerPage />} />
              <Route
                path="notification/welcome"
                element={<NotificationSettingsPage />}
              />
              <Route
                path="change-pin"
                element={<ChangePinPage />}
              />

              <Route
                path="charges-settings/welcome"
                element={<ChargesSettingsPage />}
              />
              <Route
                path="change-password"
                element={<ChangePasswordPage />}
              />
              <Route path="dynemic-page" element={<DynemicPage />} />
              <Route path="front-banner" element={<FrontBannerPage />} />
              <Route
                path="user-pannel-slider"
                element={<UserPannelSliderPage />}
              />
              <Route
                path="user-pannel-slider"
                element={<UserPannelSliderPage />}
              />

              {/* Top Navigation End */}
              {/*  Navigation Start */}
              {/*  Shoib Route */}
              {/* <Route path="/dashboard" element={<Dashboardpage />} />*/}
              <Route
                path="member-list/:id/:name"
                element={<DistributerPage />}
              />
              <Route
                path="/admin/certificate"
                element={<Tcertificate />}
              />

              <Route
                path="create-user/:id"
                element={<CreateUserDistributerPage />}
              />
              <Route path="member-list/:id/:name" element={<RetailerPage />} />

              {/*  <Route
                path="create-user/retailer"
                element={<CreateUserRetailerPage />}
              /> */}
              <Route path="member/profile/view/:id" element={<ViewProfileMemberPage />} />
              <Route path="report/pancard/:id" element={<UtiPancardPage />} />
              <Route path="suspended-users" element={<SuspendedUserPage />} />
              <Route
                path="add-suspended-users"
                element={<AddSuspendedUser />}
              />
              <Route
                path="not-working-users"
                element={<NotWorkingUserPage />}
              />
              <Route
                path="add-not-working-user"
                element={<AddNotWorkingUserList />}
              />
              <Route
                path="all-transactions-report"
                element={<AlltransportationReportsPage />}
              />
              <Route
                path="add-transaction-report"
                element={<AddTransactionReport />}
              />
              <Route path="pancard-report" element={<PanCardReportPages />} />
              <Route path="add-pancard-report" element={<AddpanCardReport />} />
              <Route
                path="pending-transaction-report"
                element={<PendingTransactionReport />}
              />
              <Route
                path="add-pending-transaction-report"
                element={<AddPendingTransactionReport />}
              />
              <Route path="summary-report" element={<SalesReportsPage />} />
              <Route path="add-summary-report" element={<AddpadateSummary />} />
              <Route
                path="performance-report"
                element={<PerformanceReports />}
              />
              <Route
                path="add-performance-reports"
                element={<AddUpdatePerformanceReports />}
              />
              <Route
                path="operator-wise-sale"
                element={<OperatorWiseSalePage />}
              />
              <Route
                path="add-operator-wise-sale"
                element={<AddOperatorWiseReport />}
              />
              {/* <Route path="ledger-report" element={<LedgerReportPage />} /> */}
              <Route
                path="balance-trasnfer"
                element={<BalanceTransferPage />}
              />
              <Route
                path="add-balance-trasnfer"
                element={<BalanceTransferAdd />}
              />
              <Route
                path="update-balance-trasnfer"
                element={<UpdateBlanceTransfer />}
              />
              <Route
                path="balance-return-request"
                element={<BalanceReturnRequestPage />}
              />
              <Route
                path="add-balance-return-request"
                element={<AddBlanceReturnRequest />}
              />
              <Route
                path="update-balance-return-request"
                element={<UpdateBlanceReturnRequest />}
              />
              <Route
                path="payment-request-view"
                element={<PaymentRequestViewPage />}
              />
              <Route
                path="add-payment-request-view"
                element={<AddPaymentRequestView />}
              />
              <Route
                path="update-payment-request-view"
                element={<UpdatePaymentRequestView />}
              />
              <Route path="payment-request" element={<PaymentRequestPage />} />
              <Route path="pending-dispute" element={<PendingDisputPage />} />
              <Route
                path="add-pending-dispute"
                element={<AddPendingDispute />}
              />
              <Route
                path="update-pending-dispute"
                element={<UpdatePendingDisput />}
              />
              <Route path="solve-dispute" element={<SolveDisputePage />} />
              <Route path="add-solve-dispute" element={<AddSolveDispute />} />
              <Route
                path="update-solve-dispute"
                element={<UpdateSolveDispute />}
              />
              <Route
                path="user-income/distributor"
                element={<DistributerIncomePage />}
              />
              <Route
                path="add/distributor/income"
                element={<AddDistributerInc />}
              />
              <Route
                path="user-income/retailer"
                element={<RetailerIncomePage />}
              />
              <Route path="add/retailer/income" element={<AddRetailerInc />} />
              <Route path="income/my-income" element={<MyIncomePage />} />
              <Route path="debit-report" element={<DebitReportPage />} />
              <Route path="add-debit-report" element={<AddDebitReport />} />
              <Route path="credit-report" element={<CreditReportPage />} />
              <Route path="add-credit-report" element={<AddCreditReport />} />
              {/* onClick Profile Route start  */}
              {/* <Route path="agent" element={<MyCommissionRechargePage />} />  */}
              {/* onClick Profile Route End  */}
              <Route path="contact-enquiry" element={<ContactInquery />} />
              <Route
                path="add-contact-enquiry"
                element={<AddContactEnquiry />}
              />
              <Route
                path="update-contact-enquiry/:id"
                element={<AddContactEnquiry />}
              />
              <Route
                path="cashfree-gateway-master"
                element={<CashfreeGateWayMasterPages />}
              />
              <Route
                path="add-cashfree-gateway-master"
                element={<AddCashFreeGateway />}
              />
              <Route
                path="whatapp/send-role-wise"
                element={<SendRoleWisepage />}
              />
              <Route path="company-setting" element={<CompanySettings />} />
              <Route path="currency" element={<CurrencyPage />} />
              <Route path="add-currency" element={<CurrencyForm />} />
              <Route path="update-currency/:id" element={<CurrencyForm />} />
              <Route path="country" element={<CountryPage />} />
              <Route path="add-country" element={<CountryForm />} />
              <Route path="update-country/:id" element={<CountryForm />} />
              <Route path="language" element={<LanguagePage />} />
              <Route path="satff" element={<StaffPage />} />
              <Route path="add-satff" element={<StaffForm />} />
              <Route path="update-satff/:id" element={<StaffForm />} />
              <Route path="staff-type" element={<StaffTypePage />} />
              <Route path="staff-type-add" element={<StaffTypeAdd />} />
              <Route path="staff-type-update/:id" element={<StaffTypeAdd />} />
              <Route path="add-language" element={<FormLanguage />} />
              <Route path="update_language/:id" element={<FormLanguage />} />
              <Route path="state-master" element={<StateMasterPage />} />
              <Route path="add-state-master" element={<FormStateMaster />} />
              <Route
                path="update-state-master/:id"
                element={<FormStateMaster />}
              />
              <Route
                path="service-area-permision"
                element={<ServiceAreaPermissionPage />}
              />
              <Route
                path="add-service-area-permision"
                element={<FormServicePermission />}
              />
              <Route
                path="dmt-commision"
                element={<DmtCommisionp />}
              />
              <Route
                path="dmt-commision-structure"
                element={<DmtCommisionpStucture />}
              />
              <Route
                path="dmt-commision-new"
                element={<NewCommition />}
              />
              <Route
                path="add-dmt-commision"
                element={<DmtCommisionForm />}
              />
              <Route
                path="update-add-dmt-commision/:id"
                element={<DmtCommisionForm />}
              />
              <Route
                path="dmt-commision-scheme"
                element={<DmtCommiosionSche />}
              />
              <Route
                path="add-dmt-commision-scheme"
                element={<DmtcommschForm />}
              />
              <Route
                path="update-dmt-commision-scheme/:id"
                element={<DmtcommschForm />}
              />


              <Route
                path="bbps-commision"
                element={<BbpsCommiosionSche />}
              />
              <Route
                path="add-bbps-commision"
                element={<BbpsCommisionForm />}
              />
              <Route
                path="add-bbps-commision/:id"
                element={<BbpsCommisionForm />}
              />





              <Route
                path="reasons-data"
                element={<ReasonsPage />}
              />
              <Route
                path="add-reasons-data"
                element={<ReasonForms />}
              />
              <Route
                path="update-reasons-data/:id"
                element={<ReasonForms />}
              />
              <Route
                path="disputes/welcome"
                element={<DmtDisputesPage />}
              />
              <Route
                path="package-master"
                element={<PackageMasterPage />}
              />

              {/* Report AEPS */}
              <Route
                path="aeps-cash-reports"
                element={<AepsCAshReport />}
              />

                <Route
                path="airtel-cms-report"
                element={<Airtelcms />}
                />
              <Route
                path="aeps-payout-reports"
                element={<AepsPayoutReport />}
              />

              <Route
                path="/admin/quick-dhan-transactions"
                element={<QuickDhanPage />}
              />
              <Route
                path="Package-Sales-Report"
                element={<PackageSaleReplort />}
              />
              <Route
                path="add-package"
                element={<AddPackegs />}
              />
              <Route
                path="add-package/:id"
                element={<EditPackegs />}
              />
              <Route
                path="disputes/create"
                element={<AddTicket />}
              />
              <Route
                path="disputes/view/:id"
                element={<TicketReply />}
              />
              <Route
                path="department-master"
                element={<DepartmentMasterPage />}
              />
              <Route
                path="add-department-master"
                element={<AddDepartmentMaster />}
              />




              <Route
                path="move-member"
                element={<MoveMember />}
              />
              {/* New report  */}
              <Route
                path="Notification-Report"
                element={<NotificationReport title_1={'Notification Report'} title_2={"Notification Report"} title={'Notification Report'} />}
              />
              <Route
                path="all-members-reports"
                element={<AllMemberReport title_1={'All Member Report'} title_2={"All Member Report"} title={'All Member Report'} />}
              />
              <Route
                path="members-wallet-balance-reports"
                element={<MemberWalletBalace title_1={'Members Wallet Balance Report'} title_2={"Members Wallet Balance Report"} title={'Members Wallet Balance Report'} />}
              />
              <Route
                path="aeps-balance-enquiry-reports"
                element={<AEPSBalanceReport title_1={'AEPS Balance Enquiry Report'} title_2={"AEPS Balance Enquiry Report"} title={'AEPS Balance Enquiry Report'} />}
              />
              <Route
                path="aeps-cash-deposit-reports"
                element={<AEPSCashReport title_1={'AEPS Cash Deposit Report'} title_2={"AEPS Cash Deposit Report"} title={'AEPS Cash Deposit Report'} />}
              />
              <Route
                path="aeps-bank-withdraw-reports"
                element={<AEPSBankReport title_1={'AEPS Bank Withdraw  Report'} title_2={"AEPS Bank Withdraw  Report"} title={'AEPS Bank Withdraw  Report'} />}
              />
              <Route
                path="aeps-mini-statement"
                element={<AEPSMiniStatmentReport title_1={'AEPS Mini Statement Report'} title_2={"AEPS Mini Statement Report"} title={'AEPS Mini Statement Report'} />}
              />
              <Route
                path="aadhar-pay-report"
                element={<AdharPayReport title_1={'Aadhar Pay Report'} title_2={"Aadhar Pay Report"} title={'Aadhar Pay Report'} />}
              />
              <Route
                path="flight-booking-report"
                element={<FligthBookingReport title_1={'Flight Booking Report'} title_2={"Flight Booking Report"} title={'Flight Booking Report'} />}
              />
              <Route
                path="bus-booking-report"
                element={<BusBookingReport title_1={'Bus Booking Report'} title_2={"Bus Booking Report"} title={'Bus Booking Report'} />}
              />
              <Route
                path="hotel-booking-report"
                element={<HotelBookingReport title_1={'Hotel Booking Report'} title_2={"Hotel Booking Report"} title={'Hotel Booking Report'} />}
              />

              <Route
                path="upgrade-member"
                element={<UpgradeMember />}
              />




              {/* SameReportsPage */}


              <Route
                path="credit-card-bill-payment-report/:id"
                element={<CreditCardReport title_1={'Credit card bill payment report'} title_2={"Credit card bill  report"} title={'Credit Card report'} />}
              />

              {/* <Route
                path="payout-reports"
                element={<PayoutReport title_1={'Payout Report'} title_2={"Payout Report"} title={'Payout Report'} />}
              /> */}


              <Route
                path="piped-gas/:id"
                element={<SameReportsPage title_1={'Piped Gas'} title_2={"Piped Gas Transaction Report"} title={'Piped Gas Transaction'} />}
              />
              <Route
                path="water/:id"
                element={<WaterSupplyPage title_1={'Water Supplier'} title_2={"Water Supplier Transaction Report"} title={'Water Supplier Transaction'} />}
              />
              <Route
                path="electricity/:id"
                element={<ElectricityReport title_1={'Electricity'} title_2={"Electricity Transaction Report"} title={'Electricity Transaction'} />}
              />

<Route
                path="mobile-recharge/:id"
                element={<MobileRechargeComp title_1={'Mobile Recharge'} title_2={"Mobile Transaction Report"} title={'Mobile Transaction'} />}
              />
             
              <Route
                path="broadband/:id"
                element={<BroadbandReportPage title_1={'Broadband'} title_2={"Broadband Transaction Report"} title={'Broadband Transaction'} />}
              />
              <Route
                path="cable-tv/:id"
                element={<CableTvPage title_1={'Cable TV'} title_2={"Cable TV Transaction Report"} title={'Cable TV Transaction'} />}
              />
              <Route
                path="digital-voucher/:id"
                element={<DigitalVocherPage title_1={'Digital Voucher'} title_2={"Digital Voucher Transaction Report"} title={'Digital Voucher Transaction'} />}
              />
              <Route
                path="fastag/:id"
                element={<FastTagReportsPages title_1={'Fastag'} title_2={"Fastag Transaction Report"} title={'Fastag Transaction'} />}
              />
              <Route
                path="insurance/:id"
                element={<InsuranceReportsPages title_1={'Insurance'} title_2={"Insurance Transaction Report"} title={'Insurance Transaction'} />}
              />
              <Route
                path="loan-repayment/:id"
                element={<LoanreypaymentReportsPages title_1={'Loan Repayment'} title_2={"Loan Repayment Transaction Report"} title={'Loan Repayment Transaction'} />}
              />
              <Route
                path="municipal-taxes/:id"
                element={<MunicipaltaxPage title_1={'Municipal Taxes'} title_2={"Municipal Taxes Transaction Report"} title={'Municipal Taxes Transaction'} />}
              />
              <Route
                path="molbile-prepaid/:id"
                element={<MobilePrepaidPage title_1={'Prepaid'} title_2={"Prepaid Transaction Report"} title={'Prepaid Transaction'} />}
              />
              {/* <Route
                path="bbps-transaction"
                element={<SameReportsPage title_1={'BBPS Transaction'} title_2={"BBPS Transaction Transaction Report"} title={'BBPS Transaction Transaction'} />}
              /> */}
              <Route
                path="mobile-postpaid/:id"
                element={<MobilePostPaid title_1={'Mobile Postpaid'} title_2={"Mobile Postpaid Transaction Report"} title={'Mobile Postpaid Transaction'} />}
              />
              {/* <Route
                path="datacard-prepaid/:id"
                element={<SameReportsPage title_1={'Datacard Prepaid'} title_2={"Datacard Prepaid Transaction Report"} title={'Datacard Prepaid Transaction'} />}
              /> */}
              <Route
                path="dth/:id"
                element={<DTHpage title_1={'DTH'} title_2={"DTH Transaction Report"} title={'DTH Transaction'} />}
              />
              {/* <Route
                path="emi"
                element={<SameReportsPage title_1={'EMI'} title_2={"EMI Transaction Report"} title={'EMI Transaction'} />}
              /> */}
              <Route
                path="landline/:id"
                element={<SameReportsPage title_1={'Landline'} title_2={"Landline Transaction Report"} title={'Landline Transaction'} />}
              />
              <Route
                path="lpg-booking/:id"
                element={<LpgBookingPage title_1={'LPG Booking'} title_2={"LPG Booking Transaction Report"} title={'LPG Booking Transaction'} />}
              />
              {/* <Route
                path="postpaid"
                element={<SameReportsPage title_1={'Postpaid'} title_2={"Postpaid Transaction Report"} title={'Postpaid Transaction'} />}
              />
              <Route
                path="traffic-challan"
                element={<SameReportsPage title_1={'Traffic Challan'} title_2={"Traffic Challan Transaction Report"} title={'Traffic Challan Transaction'} />}
              />
              <Route
                path="recharge-transaction"
                element={<SameReportsPage title_1={'Recharge Transaction'} title_2={"Recharge Transaction Transaction Report"} title={'Recharge Transaction Transaction'} />}
              />
              <Route
                path="jio-recharge-transaction"
                element={<SameReportsPage title_1={'JIO Recharge Transaction'} title_2={"JIO Recharge Transaction Report"} title={'JIO Recharge Transaction'} />}
              /> */}



              <Route
                path="update-department-master/:id"
                element={<AddDepartmentMaster />}
              />
              <Route
                path="dmt-status/welcome"
                element={<DmtStatusPage />}
              />
              <Route
                path="add-dmt-status/welcome"
                element={<DmtStatusForm />}
              />
              <Route
                path="update-dmt-status/welcome/:id"
                element={<DmtStatusForm />}
              />
              <Route
                path="priority-master/welcome"
                element={<PriorityPages />}
              />
              <Route
                path="add-priority-master/welcome"
                element={<PriorityMasterForm />}
              />
              <Route
                path="update-priority-master/:id/welcome"
                element={<PriorityMasterForm />}
              />
              <Route
                path="type-reason-data"
                element={<ReasonTypePages />}
              />
              <Route
                path="add-reason-type"
                element={<TypeReasonForm />}
              />
              <Route
                path="update-type-reason/:id"
                element={<TypeReasonForm />}
              />

              <Route
                path="all-beneficiaries"
                element={<AllBeneficiariesPage />}
              />

              <Route path="profile" element={<Profile position ={position}/>} />
              <Route
                path="/admin/statement/report/:id"
                element={<Statement />}
              />
              <Route
                path="/admin/statementwallet/report/:id"
                element={<StatementWallet />}
              />
              <Route
                path="/admin/statement/apes/report/:id"
                element={<Transactions />}
              />
              <Route
                path="/admin/wallets/report/:id"
                element={<WalletsReportsPage />}
              />
              <Route
                path="/admin/add-wallets/report"
                element={<AddWalletsReports />}
              />
              <Route
                path="/admin/statement/billpayment/report/:id"
                element={<BillPayment />}
              />
              <Route
                path="/admin/statement/recharge/report/:id"
                element={<Recharge />}
              />
              <Route
                path="/admin/statement/moneyTransfer/report/:id"
                element={<MoneyTransfer />}
              />
              <Route
                path="/admin/additional-reports/recharge-reports"
                element={<RechargeReportsPage />}
              />
              <Route
                path="/admin/additional-reports/search-trans-reports"
                element={<SearchReTarnsactionPage />}
              />
              <Route
                path="/admin/additional-reports/indo-nepal-money-transfer"
                element={<IndoNepalMoneyPage />}
              />
              <Route
                path="/admin/additional-reports/fast-tag-reports"
                element={<FastTagReportsPage />}
              />
              <Route
                path="/admin/additional-reports/dmt-reports"
                element={<DmtReportsPages />}
              />
              <Route
                path="/admin/additional-reports/dmt-reports-search"
                element={<DmtReportSearch />}
              />
              <Route
                path="/admin/additional-reports/dmt-kyc-reports"
                element={<DmtKycReportPage />}
              />
              <Route
                path="/admin/additional-reports/credit-card-bill-payment-report"
                element={<CcbilPayPage />}
              />
              <Route
                path="/admin/additional-reports/aeps-Xpress-report"
                element={<AepsXpressPage />}
              />
              <Route
                path="/admin/additional-reports/aeps-tez-report"
                element={<AepsTezRepPage />}
              />
              <Route
                path="/admin/additional-reports/aeps-icici-report"
                element={<AepsIcici />}
              />
              <Route
                path="/admin/additional-reports/aadhaarpay_pay_report"
                element={<AadharRepPage />}
              />
              <Route
                path="/admin/additional-reports/insurancedekho_report"
                element={<InsurenceDekhoPage />}
              />
              <Route
                path="/admin/additional-reports/bill_report_history"
                element={<BillPaymentHistoryPage />}
              />
              <Route
                path="/admin/additional-reports/accountreport"
                element={<AccountReportPage />}
              />
              <Route
                path="/admin/additional-reports/topuphistory"
                element={<BalanceTopUpHisPage />}
              />
              <Route
                path="/admin/additional-reports/list_bank"
                element={<BankListPage />}
              />
              <Route
                path="/admin/additional-reports/aeps_payout_report"
                element={<PayMentWithDrawlReportpage />}
              />
              <Route
                path="/admin/additional-reports/cms_bill_report"
                element={<CmsBillReportsPage />}
              />
              <Route
                path="/admin/additional-reports/postpaid_bill_transaction"
                element={<PostPaidBillPage />}
              />
              <Route
                path="/admin/additional-reports/Turbo_payment_request"
                element={<TurboElinePage />}
              />
              <Route
                path="/admin/additional-reports/Pg_report_rz"
                element={<PaymentGatewayPage />}
              />
              <Route
                path="/admin/additional-reports/ruapay_gift_cards_report"
                element={<RupayGiftCardPage />}
              />
              <Route
                path="/admin/wallet/virtual-wallet"
                element={<WallRep />}
              />
              <Route
                path="/admin/wallet/add/virtual-wallet"
                element={<AddUpdateVirtualWall />}
              />
              <Route
                path="/admin/aeps-transactions"
                element={<AepsTxnPage />}
              />
              <Route
                path="/admin/aadharpay-transactions"
                element={<AadharpayTxnPage />}
              />
              {/* <Route
                path="/admin/quick-dhan-transactions"
                element={<QuickDhanPage />}
              /> */}
              <Route
                path="/admin/tickets/welcome"
                element={<TicketPage />}
              />
              <Route
                path="/admin/add-tickets"
                element={<TicketForm />}
              />
              <Route
                path="/admin/staff-list"
                element={<ListStaffPermision />}
              />
              <Route
                path="/admin/add-staff"
                element={<AddPermision position={position} />}
              />
              <Route
                path="/admin/update-staff/:id"
                element={<AddPermision />}
              />
              <Route
                path="/admin/building-master"
                element={<BuildingMaster />}
              />
              <Route
                path="/admin/building-master/:id"
                element={<BuildingMaster />}
              />
              <Route
                path="/admin/block-master"
                element={<BlockMaster />}
              />
              <Route
                path="/admin/block-master/:id"
                element={<BlockMaster />}
              />
              <Route
                path="/admin/area-master"
                element={<AreaPermision />}
              />
              <Route
                path="/admin/area-master/:id"
                element={<AreaPermision />}
              />
              <Route
                path="/admin/floor-master"
                element={<FloorPermission />}
              />
              <Route
                path="/admin/floor-master/:id"
                element={<FloorPermission />}
              />
              <Route
                path="/admin/ward-master"
                element={<WardMaster />}
              />
              <Route
                path="/admin/ward-master/:id"
                element={<WardMaster />}
              />
              <Route
                path="/admin/office-master"
                element={<OfficeMaster />}
              />
              <Route
                path="/admin/office-master/:id"
                element={<OfficeMaster />}
              />
              <Route
                path="/admin/departments-master"
                element={<DepartmentMaster />}
              />
              <Route
                path="/admin/departments-master/:id"
                element={<DepartmentMaster />}
              />
              <Route
                path="/admin/designation-master"
                element={<DesignationMaster />}
              />
              <Route
                path="/admin/designation-master/:id"
                element={<DesignationMaster />}
              />
              <Route
                path="/admin/staff-kyc_details"
                element={<StaffKycMaster />}
              />
              <Route
                path="/admin/staff-kyc_details/:id"
                element={<StaffKycDetailsMasterByid />}
              />
              <Route
                path="/admin/payout-beneficiary-report-list"
                element={<PayoutBeneFiciaryReports />}
              />

              {/* ----------mustafa routes ------ */}
              <Route
                path="/admin/add-main-module"
                element={<AddMainModulePage />}
              />
              <Route
                path="/admin/update-main-module/:id"
                element={<AddMainModulePage />}
              />
              <Route
                path="/admin/listMainModule"
                element={<MainListModule />}
              />

              <Route
                path="/admin/add-sub-module"
                element={<AddSubModule />}
              />
              <Route
                path="/admin/update-sub-module/:id"
                element={<AddSubModule />}
              />
              <Route
                path="/admin/list-sub-module"
                element={<MainSubListModule />}
              />

              <Route
                path="/admin/add-role"
                element={<AddRole />}
              />
              <Route
                path="/admin/list-role"
                element={<ListMainRole />}
              />
              <Route
                path="/admin/update-role/:id"
                element={<AddRole />}
              />
              {/* Create-Complaint */}

              <Route
                path="/admin/employees-complaints-dashboard"
                element={<ComplaintsDashboardPages />}
              />

              <Route
                path="/admin/Create-Complaint"
                element={<AddCompalnePage />}
              />
              <Route
                path="/admin/update-Complaint/:id"
                element={<AddCompalnePage />}
              />
              <Route
                path="/admin/list-Complaint"
                element={<MainListComplane />}
              />
              <Route
                path="/admin/all-commision"
                element={<Payout/>}
              />
               <Route
                path="/admin/aepswallet"
                element={<Aepswallet2/>}
              />
              <Route
                path="/admin/business-report"
                element={<BusniessReport />}
              />
              <Route
                path="/admin/tds-report"
                element={<TdsReport />}
              />
              <Route
                path="/admin/charge-report"
                element={<ChargeReport />}
              />
              <Route
                path="/admin/ledger-report"
                element={<LedgerReport />}
              />
              {/* <Route
                path="/admin/all-commision"
                element={<Allcommisionreport />}
              /> */}
              <Route
                path="/admin/payout-charges"
                element={<PayoutReportsPage />}
              />
              <Route
                path="/admin/add-payout-charges"
                element={<ChargesPayoutForm />}
              />
              <Route
                path="/admin/aespupadte"
                element={<AEPSSettings />} />

                <Route
                path="/admin/rever-paymentreport"
                element={<Reverpaymentreport />} />





              {/* -------current-routes 11-09-24-------- */}
              {/* <Route path="flight-booking-txn-list" element={<FlightRequestPages />} />
              <Route path="flight-amendments-list" element={<FlightAmbd />} />
              <Route path="flight-refunds-list" element={<FlightRefunds />} />
              <Route path="flight-Cancellation-request" element={<FlightCancel />} />
              <Route path="flight-booking-calendar" element={<FlightBookingCalender />} />
              <Route path="flight-group-request" element={<FlightGroupRequest />} />
              <Route path="offline-flight" element={<FlightOfline />} />
              <Route path="flight-markup" element={<FlightMarkUp />} />
              <Route path="flight-deal-dicount" element={<FlightDiscount />} />
              <Route path="flight-upload-ticket" element={<FlightUploadTicket />} />
              <Route path="hotel-amendments-list" element={<HoteltAmbd />} />
              <Route path="hotel-hold-queues" element={<HotelHoldQues />} />
              <Route path="hotels-markup" element={<HotelMarkup />} />
              <Route path="hotel-discounts" element={<HotelDiscount />} />
              <Route path="hotel-booking-list" element={<HotelBooking />} />
              <Route path="hotel-group-request" element={<HotelGroupRequest />} />
              <Route path="my-booking-hotel-calender" element={<HotelBCalender />} />
              <Route path="hotel-cancellations-request" element={<HotelCancel />} />
              <Route path="hotel-refunds-list" element={<HotelRefunds />} />
              <Route path="coupon/hotel-coupon" element={<HotelCoupon />} />
              <Route path="hotel-upload" element={<HotelVocherUpload />} />
              <Route path="bus-markup" element={<BusMarkup />} />
              <Route path="bus-amendments" element={<BusAmendments />} />
              <Route path="bus-booking-list" element={<BusBooking />} />
              <Route path="bus-cancellation-request" element={<BusCancel />} />
              <Route path="bus-refund" element={<BusRefunds />} />
              <Route path="bus-group-request" element={<BusGroupRequest />} />
              <Route path="bus-discount" element={<BusDiscount />} />
              <Route path="bus-upload" element={<BusUpload />} />
              <Route path="visa-markup" element={<VisaMarkup />} />
              <Route path="visa-discount" element={<VisaDiscount />} />
              <Route path="Visa-refunds-list" element={<VisaList />} />
              <Route path="visa-amendments-list" element={<VisaAmendmentsList />} />
              <Route path="visa-booking-list" element={<VisaBooking />} />
              <Route path="visa-setting-list" element={<VisaSetings />} />
              <Route path="visa-group-request-list" element={<VisaGroupReq />} />
              <Route path="visa-list" element={<VisaRList />} />
              <Route path="visa-list/add-visa" element={<VisaRForm />} /> */}
              <Route path="*" element={<h2>No Page Found</h2>} />
            </Route>
            {/* <Route path="*" element={<Navigate to="/loginPage" />}/> */}
          </>
        )}
      </Routes>
    </>
  );
}

export default App;
