import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../../common/breadcrumb/Breadcrumbs';
import DmtReportSearchFill from './dmtReportSearchFill/DmtReportSearchFill';
import DmtReportSearchList from './dmtReportSearchList/DmtReportSearchList';
import { exportExcelDmt_txnList, fillterDmtTxnReport, usersList ,paymentEnquirydmt} from '../../../api/login/Login';
import * as XLSX from 'xlsx';
import { message } from "antd";

function DmtReportSearch() {
  const [userData, setUserData] = useState();
  const [dmtTtxn, setDmtTtxn] = useState();
  const [currentFilters, setCurrentFilters] = useState(null);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const token = window.localStorage.getItem('userToken');
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const submitForm = async (values) => {
    console.log("pagesvalue",values);
    setLoading(true);
    try {
      const res = await fillterDmtTxnReport(values);
      setDmtTtxn(res?.data);
      setCurrentFilters({ ...values, page })
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };



  const handleExcelExport = async () => {
    setExportLoading(true);
    try {
      // Use current filters if they exist, otherwise use default parameters
      const exportData = currentFilters ? 
        { ...currentFilters, token } : 
        { token };

      const res = await exportExcelDmt_txnList(exportData);
      if (res?.data?.dmtTransaction) {
        const transactions = res.data.dmtTransaction;
        
        // Transform data for Excel
        const excelData = transactions.map(item => ({
         
          'Transaction Date': item.createdAt || "",
  'Last Updated': item.updatedAt || "",
  'Transaction ID': item.txn_id || "",
  'Batch ID': item.batchId || "",
  'UTR Number': item.utr || "",
  
  // User Information
  'Username': item.username || "",
  'Mobile Number': item.mobile || "",
  
  // Customer Information
  'Customer Name': item.customer_name || "",
  'Customer Mobile': item.customer_mobile || "",
  'Refer id': item.refer_id || "",
  // Beneficiary Information
  'Beneficiary Name': item.beneficiary_name || "",
  'Bank Name': item.bank_name || "",
  'Account Number': item.account_number || "",
  'IFSC Code': item.ifsc_code || "",
  
  // Transaction Details
  'Amount': item.amount || "",
  'Opening Balance': item.o_bal || "",
  'Closing Balance': item.c_bal || "",
  'Charges': item.charge || "",
  'Commission': item.commission || "",
  'TDS': item.tds || "",
  
  // Partner Information
  'Distributor Name': item.distributor_name || "",
  'Distributor Commission': item.distributor_commission || "",
  'Distributor Tds': item.distributor_tds || "",
  'Super Distributor Name': item.master_dist_name || "",
  'Super Distributor Commission': item.master_dist_commission || "",
  'Super Distributor Tds': item.master_dist_tds || "",
  'Distributor Contact': item.distributor_mobile || "",
  'Super Distributor Contact': item.master_dist_mobile || "",
  'Distributor Refer Id': item.distributor_refer_id || "",
  'Super Distributor Refer Id': item.master_dist_refer_id || "",
  
  // Status Information
  'Transaction Status': getStatusText(item.response),
  'Vendor': item.vender_name || "",
  'Is Refunded': item.is_refunded ? 'Yes' : 'No',
  'Is Commission Applied': item.is_commision ? 'Yes' : 'No'
        }));

        // Create workbook and worksheet
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        
        // Set column widths
        const colWidths = Object.keys(excelData[0]).map(key => ({
          wch: Math.max(key.length, 15)
        }));
        worksheet['!cols'] = colWidths;

        // Add styling (headers)
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_col(C) + "1";
          if (!worksheet[address]) continue;
          worksheet[address].s = {
            font: { bold: true },
            fill: { fgColor: { rgb: "CCCCCC" } }
          };
        }

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "DMT Transactions");

        // Generate filename with timestamp
        const timestamp = new Date().toISOString().split('T')[0];
        const filename = `DMT_Transactions_${timestamp}.xlsx`;

        // Write file and trigger download
        XLSX.writeFile(workbook, filename);
      }
    } catch (error) {
      console.error('Export failed:', error);
    } finally {
      setExportLoading(false);
    }
  };

  // Helper function to convert status codes to text
  const getStatusText = (status) => {
    switch (String(status)) {
      // case '0': return 'Success';
      case '1': return 'Pending';
      case '2': return 'Success';
      case '3': return 'Failed';
      case '4': return 'Refunded';
      case '5': return 'Not Initiated';
      // case '5': return 'Hold';
      default: return 'Unknown';
    }
  };

  const getDmtTxnData = async () => {
    console.log("page",page)
    setLoading(true);
    try {
      const res = await fillterDmtTxnReport({ page, count, token });
      setDmtTtxn(res?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onChangeVal = (e) => {
    const apiPage = e - 1;
    console.log("UI Page:", e, "API Page:", apiPage);
    setPage(apiPage);
    if (currentFilters) {
      submitForm({ ...currentFilters, page: apiPage });
    } else {
      getDmtTxnData();
    }
  }

  useEffect(() => {
    getDmtTxnData();
    
    const fetchUserType = async () => {
      setLoading(true);
      try {
        const response = await usersList();
        setUserData(response?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert("Error fetching User type:", error);
      }
    };
    fetchUserType();
  }, []);

  const responseEnquiry = async (id) => {
    setLoading2(true); // Start loader before API call
    try {
        const res = await paymentEnquirydmt(id);
        if (res?.data?.statusCode == "200") {
            message.success(res?.data?.message || "Operation successful");
            getDmtTxnData(page);
        } else if (res?.data?.statusCode == "300") {
            message.info(res?.data?.message || "Transaction requires attention");
            getDmtTxnData(page);
        } else {
            message.error(res?.message || res?.data?.message || "Operation failed");
            getDmtTxnData(page);
        }
    } catch (error) {
        console.error("Error in payment enquiry:", error);
        message.error("An error occurred while processing your request");
    } finally {
        setLoading2(false); // Stop loader after API call completes
    }
};

  return (
    <>
      <Breadcrumbs breadCrumbsTitle={"DMT Transaction Search"} />
      <DmtReportSearchFill 
        submitForm={submitForm} 
        userData={userData} 
        page={page} 
        count={count} 
        token={token} 
        loading={loading} 
        getDmtTxnData={getDmtTxnData} 
      />
      <DmtReportSearchList 
        onChangeVal={onChangeVal} 
        dmtTtxn={dmtTtxn} 
        loading={loading} 
        handleExcelExport={handleExcelExport}
        exportLoading={exportLoading}
        page={page} 
        count={count} 
        responseEnquiry={responseEnquiry}
      />
    </>
  );
}

export default DmtReportSearch;