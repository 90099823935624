// import React, { useState } from 'react'
// import { Pagination } from 'antd';
// import ExportPdf from '../../common/exportPdf/ExportPdf';
// import { ToastContainer, toast } from 'react-toastify';
// import { DownloadExcel } from '../../common/downloadExcel/DownLoadExcel';

// function BrodBandPostPaidReport({ onChangeVal, aepsData, title }) {
//     return (
//         <>
//             <ToastContainer />
//             <section className="ListDistributer exppdf">
//                 <div className="row m-4">
//                     <div className="col-xl-12">
//                         <div className="card">
//                             <div className="card-body p-0">
//                                 <div className="table-responsive active-projects style-1">
//                                     <div className="tbl-caption">
//                                         <h4 className="heading mb-0"><b>{title} List</b></h4>
//                                         <div className='d-flex'>
//                                             <ExportPdf />
//                                              <button className='btn btn-success bg-info' onClick={DownloadExcel}>Download Excel</button>
//                                         </div>

//                                     </div>
//                                     <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
//                                        <table id="table-to-xls" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
//                                             <thead>

//                                                 <tr role="row">
//                                                     <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
//                                                         Sr. No
//                                                     </th>
//                                                     <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{ width: '96.125px' }}>
//                                                         Date / Time 					</th>
//                                                     <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
//                                                         Member code
//                                                     </th>
//                                                     <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
//                                                         Transaction ID
//                                                     </th>

//                                                     <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{ width: '96.125px' }}>
//                                                         Name 				</th>
//                                                     <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{ width: '96.125px' }}>
//                                                         Amount  					</th>

//                                                     {/* <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{ width: '96.125px' }}>
//                                                         Customer name 				</th> */}

//                                                     <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
//                                                         Operator name
//                                                     </th>
//                                                     <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
//                                                         Operator ID
//                                                     </th>

//                                                     <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
//                                                         Status
//                                                     </th>
//                                                     {/* <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
//                                                         Action
//                                                     </th> */}
//                                                     <th className="sorting_asc" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Employee ID: activate to sort column descending" style={{ width: '122.312px' }}>
//                                                         Dispute
//                                                     </th>


//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {aepsData?.data?.map((item, i) => {
//                                                     console.log(item);
//                                                     return <tr role="row" className="odd" style={{ cursor: "pointer" }}>
//                                                         <td>{i + 1}</td>
//                                                         <td>{item?.createdAt}</td>
//                                                         <td>{item?.package_name}</td>
//                                                         <td>{item?.txn_id}</td>
//                                                         <td>{item?.name}</td>
//                                                         <td>{item?.user_mobile}</td>
//                                                         {/* <td>{item?.services[0]}</td> */}
//                                                         <td>{item?.operator_name}</td>
//                                                         <td>{item?.tax}</td>

//                                                         <td>
//                                                             {item?.status == 1 && 'Success'}
//                                                             {item?.status == 2 && 'Pending'}
//                                                             {item?.status == 3 && 'Failed'}
//                                                         </td>
//                                                         <td>--</td>

//                                                     </tr>
//                                                 })}

//                                             </tbody>
//                                         </table>


//                                     </div>
//                                 </div>
                           
//                             <div className="dataTables_info" id="empoloyees-tblwrapper_info" role="status" aria-live="polite">
//                                 Total Amount {aepsData?.totalAmount} 
//                             </div>
//                             </div>
//                         </div>
//                         <div style={{ display: "flex", justifyContent: "space-between" }}>
//                             <div className="dataTables_info" id="empoloyees-tblwrapper_info" role="status" aria-live="polite">
//                                 Total {aepsData?.totalCount} entries
//                             </div>
//                             <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
//                                 <Pagination
//                                     /* showSizeChanger
//                                     onShowSizeChange={''} */

//                                     defaultCurrent={1}
//                                     onChange={onChangeVal}
//                                     total={aepsData?.totalCount}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div >
//             </section>
//         </>
//     )
// }

// export default BrodBandPostPaidReport



import React, { useState } from 'react'
import { Pagination } from 'antd';
import ExportPdf from '../../common/exportPdf/ExportPdf';
import { ToastContainer, toast } from 'react-toastify';
import { DownloadExcel } from '../../common/downloadExcel/DownLoadExcel';

function BrodBandPostPaidReport({ onChangeVal, aepsData, title ,downloadExcel,count, handlePageSizeChange}) {
    return (
        <>
            <ToastContainer />
            <section className="ListDistributer exppdf">
                <div className="row m-4">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1">
                                    <div className="tbl-caption">
                                        <h4 className="heading mb-0"><b>{title} List</b></h4>
                                        <div className='d-flex'>
                                            <ExportPdf />
                                            <button className='btn btn-success bg-info' onClick={downloadExcel}>Download Excel</button>
                                        </div>
                                        {/* <select value={count} onChange={handlePageSizeChange} className="ml-3 form-select">
                                                <option value={10}>10 per page</option>
                                                <option value={20}>20 per page</option>
                                                <option value={50}>50 per page</option>
                                                <option value={100}>100 per page</option>
                                            </select> */}

                                    </div>
                                    <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer"> 
    <table id="table-to-xls" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
        <thead>
            <tr role="row">
                <th>Sr. No</th>
                <th>Date / Time</th>
                <th>Name</th>
                <th>Retailer code</th>
                <th>Retailer Mobile No</th>
                <th>Transaction ID</th>
                <th>Ref ID</th>
                <th>CA Number</th>
                <th>Operator name</th>
                <th>Biller ID</th>
                
                <th>Transaction Reference ID</th>
                <th>Updated</th>
                <th>Amount</th>
                <th>Opening Balance</th>
                <th>Closeing Balance</th>
                <th>Status</th>
                <th>Status Update AT</th>
                <th>Distributor Name</th>
                {/* <th>Distributor Commission</th> */}
                <th>Super Distributor Name</th>
                {/* <th>Super Distributor Commission</th> */}
                <th>Distributor Contact</th>
                <th>Super Distributor Contact</th>
                <th>Distributor Refer Id</th>
                <th>Super Distributor Refer Id</th>
            </tr>
        </thead>
        <tbody>
            {aepsData?.data?.map((item, i) => (
                <tr key={i} role="row" className="odd">
                    <td>{i + 1}</td>
                    <td>{item?.createdAt}</td>
                    <td>{item?.name}</td>
                    <td>{item?.refer_id}</td>
                    <td>{item?.retailer_mobile}</td>
                    <td>{item?.txn_id}</td>
                    <td>{item?.refId}</td>
                    <td>{item?.ca_num}</td>
                    <td>{item?.operator_name}</td>
                    <td>{item?.biller_id}</td>
                    <td>{item?.txnReferenceId}</td>
                    <td>{item?.updatedAt}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.open_bal}</td>
                    <td>{item?.close_bal}</td>
                    <td>
                    {item?.status === 1 && (
                            <button className="btn btn-success">Success</button>
                        )}
                        {item?.status === 2 && (
                            <button className="btn btn-warning">Pending</button>
                        )}
                        {item?.status === 3 && (
                            <button className="btn btn-danger">Failed</button>
                        )}
                    </td>
                    <td>{item?.status_update_time || ""}</td>
                    <td>{item?.distributor_name || ""}</td>
                    {/* <td>{item?.distributor_commission || ""}</td> */}
                    <td>{item?.master_dist_name || ""}</td>
                    {/* <td>{item?.master_dist_commission || ""}</td> */}
                    <td>{item?.distributor_mobile || ""}</td>
                    <td>{item?.master_dist_mobile || ""}</td>
                    <td>{item?.distributor_refer_id || ""}</td>
                    <td>{item?.master_dist_refer_id || ""}</td>
               
                </tr>
            ))}
        </tbody>
    </table>
</div>

                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="dataTables_info" id="empoloyees-tblwrapper_info" role="status" aria-live="polite">
                                        Total {aepsData?.totalCount} entries
                                    </div>
                                    <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                        <Pagination
                                            /* showSizeChanger
                                            onShowSizeChange={''} */

                                            defaultCurrent={1}
                                            onChange={onChangeVal}
                                            total={aepsData?.totalCount}
                                        />
                                    </div>
                                </div>
                            </div>
                            <h3>Total : {aepsData?.totalAmount}</h3>
                        </div>

                    </div>

                </div >
            </section>
        </>
    )
}

export default BrodBandPostPaidReport

