import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Card, Input, Select, Form, Typography, Row, Col } from "antd";
import { HomeOutlined, GlobalOutlined, NumberOutlined, WalletOutlined } from "@ant-design/icons";
import { countryList, getStateMaster } from "../../../../api/login/Login";
import './PersonalDetails.css';
const { Title } = Typography;
const { Option } = Select;

function PersonalDetails({ value, submitForm, handleInput_B }) {
    const [country, setCountry] = useState();
    const [state, setState] = useState();

    const getCountry = async () => {
        const res = await countryList();
        setCountry(res?.data);
        const response = await getStateMaster();
        setState(response?.data);
    };

    useEffect(() => {
        getCountry();
    }, []);

    const validate = (values) => {
        let errors = {};
        if (!values.address) errors.address = "Address is required";
        if (!values.state) errors.state = "State Name is required";
        if (!values.pincode) errors.pincode = "Pincode is required";
        if (!values.lockAmount) errors.lockAmount = "Lock Amount is required";
        if (!values.officeAddress) errors.officeAddress = "Office Address is required";
        return errors;
    };

    return (
        <Card 
            className="shadow-sm"
            title={
                <Title level={4} className="m-0">
                    Personal Details
                </Title>
            }
        >
            <Formik
                initialValues={value}
                validate={validate}
                enableReinitialize
            >
                {(formik) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleBlur,
                    } = formik;
                    
                    return (
                        <Form layout="vertical" className="p-3">
                            <Row gutter={[24, 16]}>
                                {/* Present Address */}
                                <Col xs={24} sm={24} md={12} lg={8}>
                                    <Form.Item
                                        label="Present Address"
                                        validateStatus={errors.presentAddr && touched.presentAddr ? "error" : ""}
                                        help={touched.presentAddr && errors.presentAddr}
                                    >
                                        <Input
                                            prefix={<HomeOutlined className="text-secondary" />}
                                            placeholder="Enter your address"
                                            id="presentAddr"
                                            name="presentAddr"
                                            value={values?.presentAddr}
                                            onChange={handleInput_B}
                                            onBlur={handleBlur}
                                            className="rounded-2"
                                        />
                                    </Form.Item>
                                </Col>

                                {/* Country Selection */}
                                <Col xs={24} sm={24} md={12} lg={8}>
                                    <Form.Item label="Country">
                                        <Select
                                            placeholder="Select your country"
                                            value={values?.country}
                                            onChange={(value) => 
                                                handleInput_B({
                                                    target: { name: "country", value }
                                                })
                                            }
                                            className="rounded-2"
                                            suffixIcon={<GlobalOutlined />}
                                        >
                                            {country?.map((item) => (
                                                <Option key={item?._id} value={item?._id}>
                                                    {item?.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {/* State */}
                                <Col xs={24} sm={24} md={12} lg={8}>
                                    <Form.Item
                                        label="State"
                                        validateStatus={errors.state && touched.state ? "error" : ""}
                                        help={touched.state && errors.state}
                                    >
                                        <Input
                                            prefix={<GlobalOutlined className="text-secondary" />}
                                            placeholder="Enter state"
                                            id="state"
                                            name="state"
                                            value={values?.state}
                                            onChange={handleInput_B}
                                            onBlur={handleBlur}
                                            className="rounded-2"
                                        />
                                    </Form.Item>
                                </Col>

                                {/* Pincode */}
                                <Col xs={24} sm={24} md={12} lg={8}>
                                    <Form.Item
                                        label="Pincode"
                                        validateStatus={errors.pincode && touched.pincode ? "error" : ""}
                                        help={touched.pincode && errors.pincode}
                                    >
                                        <Input
                                            prefix={<NumberOutlined className="text-secondary" />}
                                            placeholder="Enter pincode"
                                            id="pinCode"
                                            name="pinCode"
                                            value={values?.pinCode}
                                            onChange={handleInput_B}
                                            onBlur={handleBlur}
                                            className="rounded-2"
                                        />
                                    </Form.Item>
                                </Col>

                                {/* Main Wallet */}
                                <Col xs={24} sm={24} md={12} lg={8}>
                                    <Form.Item label="Main Wallet">
                                        <Input
                                            prefix={<WalletOutlined className="text-secondary" />}
                                            placeholder="Main wallet"
                                            id="main_wallet"
                                            name="main_wallet"
                                            value={values?.main_wallet}
                                            onChange={handleInput_B}
                                            onBlur={handleBlur}
                                            disabled
                                            className="rounded-2 bg-light"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </Card>
    );
}

export default PersonalDetails;