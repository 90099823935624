import React from 'react'
import DmtKycReport from '../../../components/additionalReports/dmtKycReport/DmtKycReport'

function DmtKycReportPage() {
  return (
    <>
    <DmtKycReport/>
    </>

  )
}

export default DmtKycReportPage