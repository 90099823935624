import { baseUrl } from "../../baseUrl";
import axiosInstance from "../../axiosServiseFactory/AxiosServiseFactory";
import axios from "axios";
// Export  Excel Apis  ---------------------!

export const exportExcelDmt_txnList = (data) => {
  console.log("data",data)
  return axiosInstance.post(`${baseUrl}dmt_txn/st/report/all`, data);
};
//count Length RetDist ----------------------!

export const getLength = () => {
  return axiosInstance.get(`${baseUrl}user/memberType/count`);
}


export const getMenusdata = () => {
  return axiosInstance.get(`${baseUrl}role/navigate`);
}




export const department = (val) => {
  return axiosInstance.post(`${baseUrl}department/admin`, val, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};

export const dmtDisputePriority = () => {
  return axiosInstance.get(`${baseUrl}dmtDisputePriority/admin`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};

export const getNameUser = (val) => {
  return axiosInstance.get(`${baseUrl}user/member/filter/search?name=${val}&count=10&page=1`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};

export const getTicketByIdTicket = (id) => {
  return axiosInstance.get(`${baseUrl}dmtdisputechat/admin?dispute_id=${id}&count=10&page=0`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};

export const getByIdTicket = (id) => {
  return axiosInstance.get(`${baseUrl}dmtDisputes/${id}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};

export const relatedService = () => {
  return axiosInstance.get(`${baseUrl}service/serviceListForBBPSCommision`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};
export const relatedService2 = () => {
  const arr = ["65def1e41c20b7a8ba230200", "65def1e41c20b7a8ba2301ff"]
  return axiosInstance.get(`${baseUrl}service/fetchByServiceType?ids=${JSON.stringify(arr)}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};
export const lockAmount = (data) => {
  return axiosInstance.post(`${baseUrl}staff/lockAmt`, data, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};
export const relatedService23 = () => {
  const arr = ["65d70e989e6fb337fd07fdb6"]
  return axiosInstance.get(`${baseUrl}service/fetchByServiceType?ids=${JSON.stringify(arr)}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};
export const relatedServiceType = () => {
  return axiosInstance.get(`${baseUrl}servicecategory`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};






export const LoginSubmit = (data) =>
  axiosInstance.post(`${baseUrl}staff/login`, data);


export const sendOtpByEmail = (data) => {
  return axios.post(`${baseUrl}auth/mb/verifyEmail`, data);
};
export const submitReplay = (data) => {
  return axiosInstance.post(`${baseUrl}dmtdisputeChat/add_dispute/admin`, data);
};
export const sendVerifyEmailOtp = (data) => {
  return axios.post(`${baseUrl}auth/mb/verifyEmailOtp`, data);
}

export const sendOtpByPhone = (data) => {
  return axios.post(`${baseUrl}auth/mb/verifyMobileNo`, data);
};
export const sendVerifyPhoneOtp = (data) => {

  return axios.post(`${baseUrl}auth/mb/verifyMobileNoOtp`, data);
}
export const sendTicketCreate = (data) => {

  return axiosInstance.post(`${baseUrl}dmtDisputes/add_disputestaff`, data);
}
export const sendPackegesCreate = (data) => {

  return axiosInstance.post(`${baseUrl}package/addPackage`, data);
}
export const sendPackegesUpdate = ({ id, data }) => {

  return axiosInstance.put(`${baseUrl}package/Updatpackage/${id}`, data);
}

export const getServicePacage = (data) => {

  return axiosInstance.get(`${baseUrl}service/ids?ids=${data}`);
}
export const getPackegById = (id) => {
  return axiosInstance.get(`${baseUrl}package/${id}`);
};
export const PackegDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}package/deletePackage/${id}`);
};
export const getDataPacage = (data) => {
  return axiosInstance.get(`${baseUrl}package?start_date=${data.start_date}&end_date=${data.end_date}&page=${data.page}&count=${data.count}&name=${data.name}`);
};
export const getDataPacageSales = (data) => {
  return axiosInstance.get(`${baseUrl}packagePayment?page=${data?.page}&count=${data?.count}&package_id=${data?.package_id ? data.package_id : ''}`);
};
export const DashboardGet = (date) => {
  return axiosInstance.get(`${baseUrl}dashboard/admin?date=${date.data}`);
};
export const getDataReports = (data) => {
  console.log(data);
  return axiosInstance.get(`${baseUrl}bbps_txn/admin?start_date=${data.start_date}&end_date=${data.end_date}&biller_id=${data.biller_id}&page=${data.page}&count=${data.count}&category_id=${data.category_id}&txn_id=${data.txn_id}&user_id=${data.user_id}&member_code=${data.member_code}&member_name=${data.member_name}&operator_id=${data.operator_id}`);
};

export const getDataReportsAllexcel = (data) => {
  console.log(data);
  return axiosInstance.get(`${baseUrl}bbps_txn/admin/all?start_date=${data.start_date}&end_date=${data.end_date}&biller_id=${data.biller_id}&category_id=${data.category_id}&txn_id=${data.txn_id}&user_id=${data.user_id}&member_code=${data.member_code}&member_name=${data.member_name}&operator_id=${data.operator_id}`);
};
export const paymentReport = (data) => {
  return axiosInstance.get(`${baseUrl}payout/admin?start_date=${data.start_date}&end_date=${data.end_date}&txn_id=${data.txn_id}&page=${data.page}&count=${data.count}`);
};
export const ApespaymentReport = (data) => {
  return axiosInstance.post(`${baseUrl}adhaarPay/staff`, data, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};
export const getOperater = (id) => {
  return axiosInstance.get(`${baseUrl}operator/public?api_id=1&service=${id}`);
};

export const currencyAdd = (data) => {
  return axiosInstance.post(`${baseUrl}currency/addcurrency`, data);
};
export const resetPassword = (data) => {
  return axiosInstance.put(`${baseUrl}staff/changePass`, data);
};
export const currencyList = (data) => {
  return axiosInstance.get(`${baseUrl}currency`);
};
export const currencyDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}currency/deletecurrency/${id}`);
};

export const getCurrency = (id) => {
  return axiosInstance.get(`${baseUrl}currency/${id}`);
};

export const currencyUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}currency/updatcurrency/${id}`, value);
};


export const countryAdd = (data) => {
  return axiosInstance.post(`${baseUrl}country/addcountry`, data);
};
export const getCountryAdd = () => {
  return axiosInstance.get(`${baseUrl}currency`);
};
export const countryList = (data) => {
  return axiosInstance.get(`${baseUrl}country`);
};
export const countryDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}country/deletecountry/${id}`);
};

export const getcountry = (id) => {
  return axiosInstance.get(`${baseUrl}country/${id}`);
};


export const sendNotification = (data) => {
  return axiosInstance.post(`${baseUrl}fcm/staff/save`, data);
};
export const notificaltionSendAll = (data) => {
  return axiosInstance.post(`${baseUrl}notification/sendNotificationToAll`, data);
};


export const countryUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}country/updatecountry/${id}`, value);
};
export const languageAdd = (data) => {
  return axiosInstance.post(`${baseUrl}language/addlanguage`, data);
};
export const languageList = () => {
  return axiosInstance.get(`${baseUrl}language`);
};
export const languageDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}language/delete_language/${id}`);
};

export const getlanguage = (id) => {
  return axiosInstance.get(`${baseUrl}language/${id}`);
};

export const languageUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}language/update_language/${id}`, value);
};

export const areaAdd = (data) => {
  return axiosInstance.post(`${baseUrl}area/addarea`, data);
};


// ---------Zone Module Intrigation--------------

export const zoneCountryAdd = (data) => {
  return axiosInstance.post(`${baseUrl}zone/addzone`, data);
};

export const areaList = () => {
  return axiosInstance.get(`${baseUrl}area`);
};
export const areaDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}area/deletearea/${id}`);
};

export const getarea = (id) => {
  return axiosInstance.get(`${baseUrl}area/${id}`);
};

export const areaUpdate = ({ id, value }) => {
  return axiosInstance.put(`${baseUrl}area/updatearea/${id}`, value);
};

export const staffAdd = (data) => {
  return axiosInstance.post(`${baseUrl}staff/addstaff`, data);
};
export const staffList = () => {
  return axiosInstance.get(`${baseUrl}staff`);
};
export const staffType = () => {
  return axiosInstance.get(`${baseUrl}stafftype`);
};
export const staffDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}staff/deletestaff/${id}`);
};

export const getstaff = (id) => {
  return axiosInstance.get(`${baseUrl}staff/${id}`);
};

export const staffUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}staff/updatestaff/${id}`, value);
};

export const staffTypeAdd = (data) => {
  return axiosInstance.post(`${baseUrl}stafftype/addstaff`, data);
};
export const staffTypeList = () => {
  return axiosInstance.get(`${baseUrl}stafftype`);
};
export const staffTypeDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}stafftype/deleteStaff/${id}`);
};

export const getstaffType = (id) => {
  return axiosInstance.get(`${baseUrl}stafftype/${id}`);
};

export const staffTypeUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}stafftype/updateStaff/${id}`, value);
};



// Zoon master
export const getZoon = () => {
  return axiosInstance.get(`${baseUrl}zone`);
};

export const zoonById = ({ id }) => {
  return axiosInstance.get(`${baseUrl}zone/${id}`);
};
export const zoonPost = ({ data }) => {
  return axiosInstance.post(`${baseUrl}zone/addzone`, data);
};

// Users
export const addUsers = (data) => {
  return axiosInstance.post(`${baseUrl}user/adduser`, data);
};
export const usersList = () => {
  return axiosInstance.get(`${baseUrl}user`);
};

export const userDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}user/deleteuser/${id}`);
};

export const getUserId = (id) => {
  return axiosInstance.get(`${baseUrl}user/${id}`);
};

export const updateUser = (id, value) => {
  return axiosInstance.put(`${baseUrl}user/updateuser/${id}`, value);
};


// userType Intrigation 

export const addUserType = (data) => {
  return axiosInstance.post(`${baseUrl}usertype/adduser`, data);
};
export const changeSTatuss = (data) => {
  return axiosInstance.post(`${baseUrl}virtual_wallet/fund-transfer`, data, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};
export const listUserType = () => {
  return axiosInstance.get(`${baseUrl}usertype`);
};
export const deleteUserType = (id) => {
  return axiosInstance.delete(`${baseUrl}usertype/deleteuser/${id}`);
};
export const getUserType = (id) => {
  return axiosInstance.get(`${baseUrl}usertype/${id}`);
};
export const UserTypeUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}usertype/updateuser/${id}`, value);
};










// Role Master 

export const addRole = (data) => {
  return axiosInstance.post(`${baseUrl}role/addrole`, data);
}
export const addDepartment = (data) => {
  return axiosInstance.post(`${baseUrl}department/add_Department`, data);
}
export const getListRole = (page, count) => {
  return axiosInstance.get(`${baseUrl}role?page=${page}&count=${count}`);
}
export const getListDepartment = (data) => {
  return axiosInstance.post(`${baseUrl}department/admin`,
    {
      page: data.page, count: 10
    }
  )
}
export const DeleteRole = (id) => {
  return axiosInstance.delete(`${baseUrl}role/deleterole/${id}`,);
}
export const DeleteDepartment = (id) => {
  return axiosInstance.delete(`${baseUrl}department/delete_department/${id}`,);
}
export const getRoleEdit = (id) => {
  return axiosInstance.get(`${baseUrl}role/${id}`);
};
export const getDepartmentEdit = (id) => {
  return axiosInstance.get(`${baseUrl}department/${id}`);
};
export const updateRole = (id, value) => {
  return axiosInstance.put(`${baseUrl}role/updatrole/${id}`, value);
};
export const updateDepartment = (id, value) => {
  return axiosInstance.put(`${baseUrl}department/update_department/${id}`, value);
};

//Payout Beneficiary Api Intrigation :-

export const addBeneficiaryData = (data) => {
  return axiosInstance.post(`${baseUrl}payout-beneficiary/addpayout`, data);
}
export const getBeneficiaryData = () => {
  return axiosInstance.get(`${baseUrl}payout-beneficiary`);
}
export const DeleteBeneficiaryData = (id) => {
  return axiosInstance.delete(`${baseUrl}payout-beneficiary/deletepayout/${id}`,);
}
export const getBeneficiaryDataEdit = (id) => {
  return axiosInstance.get(`${baseUrl}payout-beneficiary/${id}`);
};
export const updateBeneficiaryData = (id, value) => {
  return axiosInstance.put(`${baseUrl}payout-beneficiary/updatpayout/${id}`, value);
};

export const stateMasterAdd = (data) => {
  return axiosInstance.post(`${baseUrl}state/addstate`, data);
};
export const getStateMaster = () => {
  return axiosInstance.get(`${baseUrl}state`);
};
// export const countryList = (data) => {
//   return axiosInstance.get(`${baseUrl}country`);
// };
export const stateMasterDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}state/deletestate/${id}`);
};

export const getstateMaster = (id) => {
  return axiosInstance.get(`${baseUrl}state/${id}`);
};

export const stateMasterUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}state/updatestate/${id}`, value);
};

// bankMaster
export const bankMasterAdd = (data) => {
  return axiosInstance.post(`${baseUrl}bank/addbank`, data);
};
export const getBankMaster = () => {
  return axiosInstance.get(`${baseUrl}bank`);
};

export const BankMasterDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}bank/deletebank/${id}`);
};

export const getBnakMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}bank/${id}`);
};

export const bankMasterUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}bank/updatebank/${id}`, value);
};

// statusMaster
export const statusMasterAdd = (data) => {
  return axiosInstance.post(`${baseUrl}status/addstatus`, data);
};
export const getStatusMaster = () => {
  return axiosInstance.get(`${baseUrl}status`);
};
export const dmtstatus = () => {
  return axiosInstance.get(`${baseUrl}dmtstatus/admin`);
};

export const statusMasterDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}status/deletestatus/${id}`);
};

export const getStatusMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}status/${id}`);
};

export const statusMasterUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}status/updatstatus/${id}`, value);
};

// paymentMaster
export const paymentMethodAdd = (data) => {
  return axiosInstance.post(`${baseUrl}payment-method/addpayment`, data);
};
export const getpaymentMethod = () => {
  return axiosInstance.get(`${baseUrl}payment-method`);
};

export const paymentMethodDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}payment-method/deletepayment/${id}`);
};

export const getpaymentMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}payment-method/${id}`);
};

export const paymentMasterUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}payment-method/updatpayment/${id}`, value);
};

// -------------Service Master ---------------!
export const addServiceMaster = (data) => {
  console.log("addservicedata",data)

  return axiosInstance.post(`${baseUrl}service/addservice`, data);
};
export const getServiceMaster = (page,count) => {
  return axiosInstance.get(`${baseUrl}service/pagination?page=${page}&count=${count}&name=&service_type_id=`)
}
export const getServiceMasterFilter = (data) => {
  return axiosInstance.get(`${baseUrl}service/pagination?page=${data?.page}&count=${data?.count}&name=${data?.name}&service_type_id=${data?.service_type_id}`)
}
export const getServiceMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}service/${id}`);
};
export const updateServiceMaster = (id, value) => {
  return axiosInstance.put(`${baseUrl}service/updateservice/${id}`, value);
};
export const deleteServiceMasterList = (id, value) => {
  return axiosInstance.delete(`${baseUrl}service/deleteservice/${id}`);
};

// serviceCategory

export const serviceCategoryAdd = (data) => {
  return axiosInstance.post(`${baseUrl}servicecategory/addcategory`, data);
};
export const getServiceCategory = () => {
  return axiosInstance.get(`${baseUrl}servicecategory`);
};
export const getServiceCategoryByPagination = (count,page) => {
  return axiosInstance.get(`${baseUrl}servicecategory/admin?&count=${count}&page=${page}`);
};

export const ServiceCategoryDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}servicecategory/deletecategory/${id}`);
};

export const getServiceCategoryId = (id) => {
  return axiosInstance.get(`${baseUrl}servicecategory/${id}`);
};

export const ServiceCategoryUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}servicecategory/updatecategory/${id}`, value);
};

// staff------//

export const staffUpdateProfile = (value) => {
  return axiosInstance.put(`${baseUrl}staff/profile/update`, value);
};

export const getUpdateProfile = (value) => {
  return axiosInstance.get(`${baseUrl}staff/profile/view`);
};


// provider

export const providerAdd = (data) => {
  return axiosInstance.post(`${baseUrl}provider/addprovider`, data);
};
export const getprovider = () => {
  return axiosInstance.get(`${baseUrl}provider`);
};

export const providerDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}provider/deleteprovider/${id}`);
};

export const getProviderId = (id) => {
  return axiosInstance.get(`${baseUrl}provider/${id}`);
};

export const providerUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}provider/updateprovider/${id}`, value);
};

// clodinaryImage

export const clodinaryImage = (value) => {
  return axios.post(`${baseUrl}cloudinary/addImage`, value);
};

// clodinaryImage

// Zone get filter

export const ZoneGetFilter = (id) => {
  return axiosInstance.get(`${baseUrl}zone/get/filter`, id);
};

// Api  Master

export const ApiTransactionAdd = (data) => {
  return axiosInstance.post(`${baseUrl}apiList/addApi`, data);
};
export const getApiTransaction = () => {
  return axiosInstance.get(`${baseUrl}apiList`);
};

export const apiMasterDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}apiList/deleteApi/${id}`);
};

export const getapiMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}apiList/${id}`);
};

export const apiMasterUpdate = (id, value) => {
  return axiosInstance.put(`${baseUrl}apiList/updateApi/${id}`, value);
};

// Api Transaction Master

export const getApiTransactionMaster = () => {
  return axiosInstance.get(`${baseUrl}apiTrans`);
};
export const getTransactionList = (id) => {
  return axiosInstance.delete(`${baseUrl}apiTrans/deleteApiTrans/${id}`);
};


// Contact Enquiries Api

export const contactEnquiryAdd = (data) => {
  return axiosInstance.post(`${baseUrl}contact-enquiries/addContactEnquiries`, data);
};
export const getcontactEnquiry = () => {
  return axiosInstance.get(`${baseUrl}contact-enquiries`);
};

export const contactEnquiryDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}contact-enquiries/deleteContactEnquiries/${id}`);
};

export const getcontactEnquiryId = (id) => {
  return axiosInstance.get(`${baseUrl}contact-enquiries/${id}`);
};

export const apiContactEnquiry = (id, value) => {
  return axiosInstance.put(`${baseUrl}contact-enquiries/updateContactEnquiries/${id}`, value);
};

// agent onBoarding -------!

export const addAgentOnboarding = (data) => {
  return axiosInstance.post(`${baseUrl}agentonboarding/addOnboarding`, data);
};
export const getAgentOnboarding = () => {
  return axiosInstance.get(`${baseUrl}agentonboarding`)
}
export const getAgentOnboardingEdit = (id) => {
  return axiosInstance.get(`${baseUrl}agentonboarding/${id}`)
}
export const updateAgentOnboarding = (id, value) => {
  return axiosInstance.put(`${baseUrl}agentonboarding/updateOnBoarding/${id}`, value);
};
export const deleteAgentOnboarding = (id) => {
  return axiosInstance.delete(`${baseUrl}agentonboarding/deleteOnBoarding/${id}`);
};

export const getAreaZoon = () => {
  return axiosInstance.get(`${baseUrl}area`)
}

// pagination

export const paginationApiArea = (page, count) => {
  return axiosInstance.get(`${baseUrl}area/page/${page}&${count}`)
}

export const paginationApiMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}bank/page/${page}&${count}`)
}
export const paginationUserTypeMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}usertype/page/${page}&${count}`)
}
export const paginationstaffMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}staff/page/${page}&${count}`)
}
export const paginationstaffTypeMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}stafftype/page/${page}&${count}`)
}
export const paginationPayoutbeneficiaryMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}payout-beneficiary/page/${page}&${count}`)
}
export const paginationCountryMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}country/page/${page}&${count}`)
}


// pagination

// Distributor Retailer

export const reailerDistIdAgainst = (page, count, id) => {
  return axiosInstance.get(`${baseUrl}user/member/filter?id=${id}&page=${page}&count=${count}`)
}
export const reailerDistIdAgainstFillers = (data) => {
  return axiosInstance.get(`${baseUrl}user/member/filter/search?mobile=${data.mobile}&page=${data.page}&count=${data.count}&id=${data.id}&email=${data.email}&is_approved=${data.kycStatus ? data.kycStatus : ''}&name=${data.name}&refer_id=${data.refer_id}`)
}
export const updateDistributerApproval = (val, id) => {
  return axiosInstance.put(`${baseUrl}user/updateuserStatus/${id}`, val)
}

export const changePin = (val) => {
  return axiosInstance.put(`${baseUrl}staff/pinChange`, val)
}
export const sendForgotPinOtp = (val) => {
  return axiosInstance.put(`${baseUrl}staff/forgotpinsendotp`, val)
}
export const sendForgotPinOtpVerify = (val) => {
  return axiosInstance.put(`${baseUrl}staff/forgotpinverifyotp`, val)
}

export const changePassword = (val) => {
  return axiosInstance.put(`${baseUrl}staff/passwordChange`, val)
}

// Distributor Retailer upDate

export const getDistIdAgainst = (id) => {
  return axiosInstance.get(`${baseUrl}user/${id}`)
}
export const updateDistIdAgainst = (id, value) => {
  return axiosInstance.put(`${baseUrl}user/updateuser/${id}`, { ...value.basicDetails, ...value.permanentDetails, ...value.serviceDetails, permission: value.permission, is_approved: value.is_approved, kycDetails: value.kycDetails })
}
export const approveunapproveUser = (data,id) => {
  return axiosInstance.post(`${baseUrl}verification/kycApprove/${id}`, data);
};


export const ZoonFilter = ({ areaid, level }) => {
  return axiosInstance.get(`${baseUrl}zone/get/filter?area=${areaid}&level=${level}`);
};
export const ZoonFilterSec = ({ zone, area }) => {
  return axiosInstance.get(`${baseUrl}zone/get/filter?zone=${zone}&area=${area}`);
}
// ------------- billing Address Api---------------

export const addBillingAddress = (data) => {
  return axiosInstance.post(`${baseUrl}deliveryaddress/addDeliveryAddress`, data);
};
export const getBillingAddress = (id) => {
  return axiosInstance.get(`${baseUrl}deliveryaddress/user/billing/${id}`)
}
export const getBillingAddressDetails = (id) => {
  return axiosInstance.get(`${baseUrl}deliveryaddress/${id}`)
}
export const updateBillingAddress = (id, value) => {
  return axiosInstance.put(`${baseUrl}deliveryaddress/updatDeliveryAddress/${id}`, value);
};
export const deletBillingAddress = (id) => {
  return axiosInstance.delete(`${baseUrl}deliveryaddress/deleteDeliveryAddress/${id}`);
};
// ------------- shipping Address Api---------------

export const addshippingAddress = (data) => {
  return axiosInstance.post(`${baseUrl}deliveryaddress/addDeliveryAddress`, data);
};
export const getshippingAddress = (id) => {
  return axiosInstance.get(`${baseUrl}deliveryaddress/user/shipping/${id}`)
}
export const getshippingAddressDetails = (id) => {
  return axiosInstance.get(`${baseUrl}deliveryaddress/${id}`)
}
export const updateshippingAddress = (id, value) => {
  return axiosInstance.put(`${baseUrl}deliveryaddress/updatDeliveryAddress/${id}`, value);
};
export const deletshippingAddress = (id) => {
  return axiosInstance.delete(`${baseUrl}deliveryaddress/deleteDeliveryAddress/${id}`);
};


//  DMT Apis

export const adddmtcommscheme = (data) => {
  return axiosInstance.post(`${baseUrl}dmtCommisionSchema/add`, data)
}
export const adddmtcommscheme2 = (data) => {
  return axiosInstance.post(`${baseUrl}dmtCommision/add`, data)
}
export const getdmtcommscheme = (data) => {
  return axiosInstance.get(`${baseUrl}dmtCommisionSchema/findCommisionByDMT?start_date=${data?.start_date ? data?.start_date : ''}&end_date=${data?.end_date ? data?.end_date : ""}&service_id=${data?.service_id ? data?.service_id : ''}&page=${data?.page ? data?.page : ''}&name=${data?.name ? data?.name : 'BBPPSS'}&count=${data?.count ? data?.count : ''}`)
}
export const getBbpscommscheme = (data) => {
  return axiosInstance.get(`${baseUrl}dmtCommisionSchema/findCommisionByBBPS?count=${data?.count ? data?.count : '10'}&page=${data?.page ? data?.page : '0'}`)
}

export const getdmtcommschemeagId = (id) => {
  return axiosInstance.get(`${baseUrl}dmtCommisionSchema/${id}`)
}
export const updatedmtcommschemeagId = (id, value) => {
  return axiosInstance.put(`${baseUrl}dmtCommisionSchema/update/${id}`, value)
}
export const updatedmtcommschemeagId2 = (id, value) => {
  return axiosInstance.put(`${baseUrl}dmtCommision/update/${id}`, value)
}
export const deletedmtcommschemeagId = (id) => {
  return axiosInstance.delete(`${baseUrl}dmtCommisionSchema/delete/${id}`,)
}
// -------------DMT API ---------
export const adddmtcomm = (data) => {
  return axiosInstance.post(`${baseUrl}dmtCommisionSchema/add`, data)
}
export const addBbpsPost = (data) => {
  return axiosInstance.post(`${baseUrl}bbps_Commision/addCommision`, data)
}
export const getdmtcomm = (data) => {
  return axiosInstance.get(`${baseUrl}dmtCommision?start_date=${data?.start_date ? data?.start_date : ''}&end_date=${data?.end_date ? data?.end_date : ""}&service_id=${data?.service_id ? data?.service_id : ''}&page=${data?.page ? data?.page : ''}`)
}
export const getdmtcomm2 = (data) => {
  return axiosInstance.get(`${baseUrl}dmtCommision?start_date=${data?.start_date ? data?.start_date : ''}&end_date=${data?.end_date ? data?.end_date : ""}&service_id=${data?.service_id ? data?.service_id : ''}&count=${data?.count ? data?.count : ''}&page=${data?.page ? data?.page : ''}`)
}
export const getdmtbbpscommSchema = (data) => {
  return axiosInstance.get(`${baseUrl}dmtCommisionSchema/findCommisionByBBPS?&count=${data?.count ? data?.count : ''}&page=${data?.page ? data?.page : ''}`)
}
export const getOperater4 = (data) => {
  return axiosInstance.get(`${baseUrl}bbps_Commision/getCommisionById?id=${data?.id ? data?.id : ''}&service_id=${data?.service_id ? data?.service_id : ''}`)
}
export const getdmtcomm3 = (data) => {
  return axiosInstance.get(`${baseUrl}operator/service/${data.service_id}`)
}
export const getdmtcommagId = (id) => {
  return axiosInstance.get(`${baseUrl}dmtCommisionSchema/${id}`)
}
export const getbbpscommagId = (id) => {
  return axiosInstance.get(`${baseUrl}bbps_Commision/getCommisionById?id=${id}`)
}
export const updatedmtcommagId = (id, value) => {
  return axiosInstance.put(`${baseUrl}dmtCommisionSchema/update/${id}`, value)
}
export const updateBbpscommagId = (id, value) => {
  return axiosInstance.put(`${baseUrl}bbps_Commision/updateCommision/${id}`, value)
}
export const deletedmtcommagId = (id) => {
  return axiosInstance.delete(`${baseUrl}dmtCommision/delete/${id}`,)
}
export const PostComitionVs = (data) => {
  return axiosInstance.post(`${baseUrl}bbps_Commision/addCommision`, data)
}

// ----------------for id------------
export const getBppsID = () => {
  return axiosInstance.get(`${baseUrl}service/serviceListForBBPSCommision`,)
}
export const getDmtCommSchemeID = () => {
  return axiosInstance.get(`${baseUrl}service/serviceListForDMTCommision`,)
}
export const getDmtCommSchemeNew = () => {
  return axiosInstance.get(`${baseUrl}service/serviceListForBBPSCommision`,)
}
export const getReason = () => {
  return axiosInstance.get(`${baseUrl}dmtdisputeReasons/admin`)
}
export const deleteReasonId = (id, token) => {
  return axiosInstance.delete(`${baseUrl}dmtdisputeReasons/delete_dispute/${id}`, token)
}
export const addReasons = (data) => {
  return axiosInstance.post(`${baseUrl}dmtdisputeReasons/add_dispute`, data)
}
export const updateReasons = (id, token) => {
  return axiosInstance.put(`${baseUrl}dmtdisputeReasons/update_dispute/${id}`, token)
}
export const getReasonId = (id) => {
  return axiosInstance.get(`${baseUrl}dmtdisputeReasons/${id}`)
}

export const getStatus = () => {
  return axiosInstance.get(`${baseUrl}dmtstatus/admin`)
}
export const deleteStatusId = (id, token) => {
  return axiosInstance.delete(`${baseUrl}dmtstatus/delete_dispute/${id}`)
}
export const addStatus = (data) => {
  return axiosInstance.post(`${baseUrl}dmtstatus/add_dispute`, data)
}
export const updateStatus = (id, token) => {
  return axiosInstance.put(`${baseUrl}dmtstatus/update_dispute/${id}`, token)
}
export const getStatusId = (id) => {
  return axiosInstance.get(`${baseUrl}dmtstatus/${id}`)
}

export const getPriority = (data) => {
  return axiosInstance.get(`${baseUrl}dmtDisputePriority/admin?page=${data?.page}&count=${data?.count}`)
}
export const deletePriorityId = (id, token) => {
  return axiosInstance.delete(`${baseUrl}dmtDisputePriority/delete_dispute/${id}`)
}
export const adddmtPriority = (data) => {
  return axiosInstance.post(`${baseUrl}dmtDisputePriority/add_dispute`, data)
}
export const updatePriority = (id, token) => {
  return axiosInstance.put(`${baseUrl}dmtDisputePriority/update_dispute/${id}`, token)
}
export const getPriorityId = (id) => {
  return axiosInstance.get(`${baseUrl}dmtDisputePriority/${id}`)
}


// ---------------DMT Txn Fillter --------------- 

export const fillterDmtTxnReport = (data) => {
  return axiosInstance.post(`${baseUrl}dmt_txn/st/report`, data)
}

export const walletsReport = (data) => {
  return axiosInstance.post(`${baseUrl}mainwallet/create`, data)
}

export const walletsReportFilter = (page, count,userId, data) => {
  return axiosInstance.post(`${baseUrl}mainwallet/filter`, { page, ...data, count,userId })
}
// --------------------paymentRequest-------------------
export const fillterRequestPayment = (data) => {
  return axiosInstance.post(`${baseUrl}paymentRequest/admin`, data)
}
export const fillterRequestPaymentAllexcel = (data) => {
  return axiosInstance.post(`${baseUrl}paymentRequest/admin/payment/excel`, data)
}
// -----------------------Virtual Wallelt --------------------------
export const virtualWallets = (data) => {
  return axiosInstance.post(`${baseUrl}virtual_wallet/initiateTrans`, data)
}
export const addVirtualWallets = (data) => {
  return axiosInstance.post(`${baseUrl}virtual_wallet/create`, data)
}
export const virtualWalletsFilter = (page, count, data) => {
  return axiosInstance.post(`${baseUrl}virtual_wallet/filter`, { ...data, count: count, page: page })
}
// -------------------------------------chargesSettings-----------------------------------

export const getdmtchargesSettings = (token) => {
  return axiosInstance.get(`${baseUrl}dmt_vendor/admin`, token)
}
export const getchargesSettings = (token) => {
  return axiosInstance.get(`${baseUrl}charges/settings`, token)
}
export const getPayoutChargeDetails = (token) => { return axiosInstance.get(`${baseUrl}payout/payout_settings`, token)
}
export const getchargesSettingIds = (id,token) => {
  return axiosInstance.get(`${baseUrl}dmt_vendor/admin/${id}`, token)
}
export const chargesUpdateSettings = (token) => {
  return axiosInstance.put(`${baseUrl}setting/update/`, token)
}
export const updatePayoutSetting = (token) => {
  return axiosInstance.put(`${baseUrl}setting/update/`, token)
}


// _________________AEPS________________

export const aepsTransctionFilter = (data) => {
  return axiosInstance.post(`${baseUrl}aepstxn/staff`, data)
}

export const aepsaadharpayTransctionFilter = (data) => {
  return axiosInstance.post(`${baseUrl}adhaarPay/staff`, data)
}
export const aepsaadharpayTransctionFilterAll = (data) => {
  return axiosInstance.post(`${baseUrl}adhaarPay/staff/all`, data)
}
export const aepsTransctionAll = (data) => {
  return axiosInstance.post(`${baseUrl}aepstxn/staff/all`, data)
}
export const aadharPayTransctionFilter = (data) => {
  return axiosInstance.post(`${baseUrl}adhaarpay/staff`, data)
}
export const aadharPayTransctionAll = (data) => {
  return axiosInstance.post(`${baseUrl}adhaarpay/staff/all`, data)
}
export const apesPayOutReport = (data) => {
  return axiosInstance.post(`${baseUrl}payout/admin?txn_id=&start_date=&end_date=&page=&count=`, data)
}

// _________________Quick Dhan ________________

export const quickDhanFilter = (data) => {
  return axiosInstance.post(`${baseUrl}quickdhan/staff`, data)
}
export const quickDhanAll = (data) => {
  return axiosInstance.post(`${baseUrl}quickdhan/staff/all`, data)
}


// Disputs

export const DisputeData = (data) => {
  return axiosInstance.post(`${baseUrl}dmtdisputes/admin`, data)
}



// export const aadharPayTransctionFilter = (data) => {
//   return axiosInstance.post(`${baseUrl}adhaarpay/staff`, data)
// }
// export const aadharPayTransctionAll = (data) => {
//   return axiosInstance.post(`${baseUrl}adhaarpay/staff/all`, data)
// }


// -----staff Address Apis All Masters ------

// ------------------Building Apis------
export const getBuildingsMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}staffaddr/building?page=${page}&count=${count}`);
};

export const addBuildingMaster = (data) => {
  return axiosInstance.post(`${baseUrl}staffaddr/building/addbuilding`, data);
};
export const deleteBuildingMaster = (id) => {
  console.log(id);
  return axiosInstance.delete(`${baseUrl}staffaddr/building/deletebuilding/${id}`);
};
export const updateBuildingMaster = (id, value) => {
  return axiosInstance.put(`${baseUrl}staffaddr/building/updatebuilding/${id}`, value);
};
export const getBuildingsMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}staffaddr/building/${id}`);
};

// ------------------Block Apis------

export const getBlockMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}staffaddr/block?page=${page}&count=${count}`);
};

export const addBlockMaster = (data) => {
  return axiosInstance.post(`${baseUrl}staffaddr/block/addblock`, data);
};
export const deleteBlockMaster = (id) => {
  return axiosInstance.delete(`${baseUrl}staffaddr/block/deleteblock/${id}`);
};
export const updateBlockMaster = (id, value) => {
  return axiosInstance.put(`${baseUrl}staffaddr/block/updateblock/${id}`, value);
};
export const getBlockMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}staffaddr/block/${id}`);
};
// ------------------Area Apis------

export const getAreaMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}staffaddr/area?page=${page}&count=${count}`);
};

export const addAreaMaster = (data) => {
  return axiosInstance.post(`${baseUrl}staffaddr/area/addarea`, data);
};
export const deleteAreaMaster = (id) => {
  return axiosInstance.delete(`${baseUrl}staffaddr/area/deletearea/${id}`);
};
export const updateAreaMaster = (id, value) => {
  return axiosInstance.put(`${baseUrl}staffaddr/area/updatearea/${id}`, value);
};
export const getAreaMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}staffaddr/area/${id}`);
};
// ------------------Floor Apis------

export const getFloorMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}staffaddr/floor?page=${page}&count=${count}`);
};

export const addFloorMaster = (data) => {
  return axiosInstance.post(`${baseUrl}staffaddr/floor/addfloor`, data);
};
export const deleteFloorMaster = (id) => {
  return axiosInstance.delete(`${baseUrl}staffaddr/floor/deletefloor/${id}`);
};
export const updateFloorMaster = (id, value) => {
  return axiosInstance.put(`${baseUrl}staffaddr/floor/updatefloor/${id}`, value);
};
export const getFloorMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}staffaddr/floor/${id}`);
};
// ------------------Wards Apis------

export const getWardMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}staffaddr/ward?page=${page}&count=${count}`);
};

export const addWardMaster = (data) => {
  return axiosInstance.post(`${baseUrl}staffaddr/ward/addward`, data);
};
export const deleteWardMaster = (id) => {
  return axiosInstance.delete(`${baseUrl}staffaddr/ward/deleteward/${id}`);
};
export const updateWardMaster = (id, value) => {
  return axiosInstance.put(`${baseUrl}staffaddr/ward/updateward/${id}`, value);
};
export const getWardMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}staffaddr/ward/${id}`);
};
// ------------------Office Apis------

export const getOfficeMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}staffaddr/office?page=${page}&count=${count}`);
};

export const addOfficeMaster = (data) => {
  return axiosInstance.post(`${baseUrl}staffaddr/office/addoffice`, data);
};
export const deleteOfficeMaster = (id) => {
  return axiosInstance.delete(`${baseUrl}staffaddr/office/deleteoffice/${id}`);
};
export const updateOfficeMaster = (id, value) => {
  return axiosInstance.put(`${baseUrl}staffaddr/office/updateoffice/${id}`, value);
};
export const getOfficeMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}staffaddr/office/${id}`);
};

// ------------------department  Permision Apis------

export const getDepartmentMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}staffaddr/department?page=${page}&count=${count}`);
};

export const addDepartmentMaster = (data) => {
  return axiosInstance.post(`${baseUrl}staffaddr/department/adddepartment`, data);
};
export const deleteDepartmentMaster = (id) => {
  return axiosInstance.delete(`${baseUrl}staffaddr/department/deletedepartment/${id}`);
};
export const updateDepartmentMaster = (id, value) => {
  return axiosInstance.put(`${baseUrl}staffaddr/department/updatedepartment/${id}`, value);
};
export const getDepartmentMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}staffaddr/department/${id}`);
};
// ------------------Designation  Permision Apis------

export const getdesignationMaster = (page, count) => {
  return axiosInstance.get(`${baseUrl}staffaddr/designation?page=${page}&count=${count}`);
};

export const adddesignationMaster = (data) => {
  return axiosInstance.post(`${baseUrl}staffaddr/designation/adddesignation`, data);
};
export const deletedesignationMaster = (id) => {
  return axiosInstance.delete(`${baseUrl}staffaddr/designation/deletedesignation/${id}`);
};
export const updatedesignationMaster = (id, value) => {
  return axiosInstance.put(`${baseUrl}staffaddr/designation/updatedesignation/${id}`, value);
};
export const getdesignationMasterId = (id) => {
  return axiosInstance.get(`${baseUrl}staffaddr/designation/${id}`);
};


// ------------------Staff Permision Apis------

export const getStaffpermision = (page, count) => {
  return axiosInstance.get(`${baseUrl}staff?page=${page}&count=${count}`);
};

export const addStaffpermision = async (data) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}staff/addstaff`, data);
    return response;
  } catch (error) {
    console.error("Error in addStaffpermision:", error);
    throw error;
  }
};

export const updateStaffpermision = async (id, value) => {
  console.log("value",value)
  const token = localStorage.getItem('userToken');
  try {
    const response = await axiosInstance.put(
      `${baseUrl}staff/updatestaff/${id}`, 
      value,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in updateStaffPermission:", error);
    throw error;
  }
};
export const deleteStaffpermision = (id) => {
  return axiosInstance.delete(`${baseUrl}staff/deletestaff/${id}`);
};

export const getStaffpermisionId = (id) => {
  return axiosInstance.get(`${baseUrl}staff/${id}`);
};



// -------------------add staff for selector apis-----------------

export const getStaffSelecAdd = () => {
  return axiosInstance.get(`${baseUrl}staffaddr/building/public`);
};
export const getOfficeSelecStaff = () => {
  return axiosInstance.get(`${baseUrl}staffaddr/office/public`);
};
export const getFloorSelecStaff = () => {
  return axiosInstance.get(`${baseUrl}staffaddr/floor/public`);
};
export const getAreaSelecStaff = () => {
  return axiosInstance.get(`${baseUrl}staffaddr/area/public`);
};
export const getBlockSelecStaff = () => {
  return axiosInstance.get(`${baseUrl}staffaddr/block/public`);
};
export const getWardSelecStaff = () => {
  return axiosInstance.get(`${baseUrl}staffaddr/ward/public`);
};
export const getDepartmentSelecStaff = () => {
  return axiosInstance.get(`${baseUrl}staffaddr/department/public`);
};
export const getDesignationSelecStaff = () => {
  return axiosInstance.get(`${baseUrl}staffaddr/designation/public`);
};
export const getRoleSelecStaff = () => {
  return axiosInstance.get(`${baseUrl}role/public`);
};



// --------------Mustaf's APIS Start-----------------------------------


export const addMainModule = (data) => {
  return axiosInstance.post(`${baseUrl}mainModule/addMainModule`, data)
};

export const updateMainModule = (id) => {
  return axiosInstance.get(`${baseUrl}mainModule/${id}`);
};

export const updateModule = (id, value) => {
  return axiosInstance.put(`${baseUrl}mainModule/updatMainModule/${id}`, value);
};

export const deleteMainModulee = (id) => {
  return axiosInstance.delete(`${baseUrl}mainModule/deleteMainModule/${id}`);
};


export const mainModuleList = (page, count) => {
  // console.log(page, page);
  return axiosInstance.get(`${baseUrl}mainModule?page=${page}&count=${count}`);
}

export const addSubModule = (data) => {
  return axiosInstance.post(`${baseUrl}subModule/addsubModule`, data)
};

export const subModule = (page, count) => {
  // console.log(page, page);
  return axiosInstance.get(`${baseUrl}subModule?page=${page}&count=${count}`);
}

export const updatesubModule = (id, value) => {
  return axiosInstance.put(`${baseUrl}subModule/updatsubModule/${id}`, value);
};

export const updateSubMainModule = (id) => {
  return axiosInstance.get(`${baseUrl}subModule/${id}`);
}
export const deleteSubModulee = (id) => {
  return axiosInstance.delete(`${baseUrl}subModule/deletesubModule/${id}`);
};


export const getControler = (data) => {
  return axiosInstance.get(`${baseUrl}mainmodule/ids?controllers=${JSON.stringify(data)}`)
};

export const addRolee = (data) => {
  return axiosInstance.post(`${baseUrl}role/addRole`, data)
};
export const roleList = (page, count) => {
  // console.log(page, page);
  return axiosInstance.get(`${baseUrl}role?page=${page}&count=${count}`);
}

export const updategetId = (id) => {
  return axiosInstance.get(`${baseUrl}role/${id}`);
};
export const updatesaddRole = (id, value) => {
  return axiosInstance.put(`${baseUrl}role/updatrole/${id}`, value);
};

export const listRoleDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}role/deleterole/${id}`);
};

export const pri = (data) => {
  return axiosInstance.get(`${baseUrl}dmtDisputePriority/admin`)
};

export const sta = (data) => {
  return axiosInstance.get(`${baseUrl}dmtstatus/admin`)
};
export const build = (data) => {
  return axiosInstance.get(`${baseUrl}staffaddr/building/public`)
};

export const bolk = (data) => {
  return axiosInstance.get(`${baseUrl}staffaddr/block/public`)
};
export const oRome = (data) => {
  return axiosInstance.get(`${baseUrl}staffaddr/office/public`)
};
export const oword = (data) => {
  return axiosInstance.get(`${baseUrl}staffaddr/ward/public`)
};
export const oArea = (data) => {
  return axiosInstance.get(`${baseUrl}staffaddr/area/public`)
};

export const flore = (data) => {
  return axiosInstance.get(`${baseUrl}staffaddr/floor/public`)
};

export const addComplain = (data) => {
  return axiosInstance.post(`${baseUrl}staff_dispute/adddispute`, data)
};

export const complaneList = (page, count) => {
  // console.log(page, page);
  return axiosInstance.get(`${baseUrl}staff_dispute?page=${page}&count=${count}`);
}

export const complaneListDelete = (id) => {
  return axiosInstance.delete(`${baseUrl}staff_dispute/deletedispute/${id}`);
};

export const compalneId = (id) => {
  return axiosInstance.get(`${baseUrl}staff_dispute/${id}`);
};
export const updateComplane = (id, value) => {
  return axiosInstance.put(`${baseUrl}staff_dispute/updatdispute/${id}`, value);
};

export const dasComplaient = (data) => {
  return axiosInstance.get(`${baseUrl}dashboard/complaint`, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};

// --------------kyc Documents Apis Staff----------
export const addStaffKycDoc = (data) => {
  return axiosInstance.post(`${baseUrl}staff_document/add_Doc`, data)
};
export const getStaffKycDoc = () => {
  return axiosInstance.get(`${baseUrl}staff_document/kycView`)
};
export const getStaffKycDocStaffId = (id) => {
  return axiosInstance.get(`${baseUrl}staff_document/${id}`)
};
export const updateStatusStaffKycDocStaffId = (id, value) => {
  return axiosInstance.put(`${baseUrl}staff/updatestaffstatus/${id}`, value)
};

export const getsNotification = (value) => {

  return axiosInstance.get(`${baseUrl}notification/filter/admin?readed=${false}&page=${value?.page}&count=${value?.count}`);
};

export const countNotification = (value) => {
  return axiosInstance.get(`${baseUrl}notification/summary/admin`);
};
export const notificationReport = (data) => {
  return axiosInstance.get(`${baseUrl}notification/report?count=10&page=${data.page}&sendFrom&sendBy&message_type&to`)
}

export const bpbsOperatorList = async (serviceId, page, pageSize, packageId) => {
  console.log("packageId",packageId)
  try {
    const response = await axios.get(`${baseUrl}package/bbps/commision/admin`, {
      params: {
        service_id: serviceId,
        package_id: packageId,
        count: pageSize,
        page: page
      },
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching BBPS operators:', error);
    throw error;
  }
};
export const bpbsAllServiceList = async () => {
  try {
    const response = await axios.get(`${baseUrl}service/admin`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching package invoice:', error);
    throw error;
  }
};
export const fetchInitialPackages = async (userId) => {
  const url = `${baseUrl}package/freeList?user_id=${userId}`;
  const token = localStorage.getItem('userToken');

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const fetchPackageDetails = async (packageId) => {
  const url = `${baseUrl}package/admin/${packageId}`;
  const token = localStorage.getItem('userToken');

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const assignPackage = async (userId, selectedPackage) => {
  const url = `${baseUrl}package/packageChange`;
  const token = localStorage.getItem('userToken');

  const response = await axiosInstance.put(
    url,
    {
      userid: userId,
      package_id: selectedPackage,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log("assignPckage",response)

  return response;
};
export const commissionServiceByReport = async ( startDate, endDate) => {

  
  try {
    let url = `${baseUrl}dashboard/commisionReportByServices?`;
    
    const params = new URLSearchParams({
      // service_id: serviceId,
      // page,
      // page_size: pageSize,
      // package_id: packageId
    });

    if (startDate) {
      params.append('start_date', startDate);
    }
    
    if (endDate) {
      params.append('end_date', endDate);
    }

    url += params.toString();

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching commission report:', error);
    throw error;
  }
};
export const tdsServiceByReport = async ( startDate, endDate) => {

  
  try {
    let url = `${baseUrl}dashboard/tdsReportByServices?`;
    
    const params = new URLSearchParams({
     
    });

    if (startDate) {
      params.append('start_date', startDate);
    }
    
    if (endDate) {
      params.append('end_date', endDate);
    }

    url += params.toString();

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching commission report:', error);
    throw error;
  }
};
export const chargeServiceByReport = async ( startDate, endDate) => {

  
  try {
    let url = `${baseUrl}dashboard/chargeReportByServices?`;
    
    const params = new URLSearchParams({
     
    });

    if (startDate) {
      params.append('start_date', startDate);
    }
    
    if (endDate) {
      params.append('end_date', endDate);
    }

    url += params.toString();

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching commission report:', error);
    throw error;
  }
};
export const ledgerReport = async (page, count, startDate, endDate ,vendor,  service,status,txn_id) => {
  try {
    let url = `${baseUrl}dashboard/ledgerReport`;
    
    const params = new URLSearchParams({
      page: page.toString(),
      count: count.toString()
    });

    if (startDate) {
      params.append('start_date', startDate);
    }
    
    if (endDate) {
      params.append('end_date', endDate);
    }
    if (vendor) params.append('vendor', vendor);
    if (service) params.append('service', service);
    if (status) params.append('status', status);
    if (txn_id) params.append('txn_id', txn_id);
   
      

    url += `?${params.toString()}`;

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching ledger report:', error);
    throw error;
  }
};
export const ledgerReportAll = async ( startDate, endDate ,vendor,  service,status,txn_id) => {
  try {
    let url = `${baseUrl}dashboard/ledgerReport/all`;
    
    const params = new URLSearchParams({
     
    });

    if (startDate) {
      params.append('start_date', startDate);
    }
    
    if (endDate) {
      params.append('end_date', endDate);
    }

    if (vendor) params.append('vendor', vendor);
    if (service) params.append('service', service);
    if (status) params.append('status', status);
    if (txn_id) params.append('txn_id', txn_id);

    url += `?${params.toString()}`;

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching ledger report:', error);
    throw error;
  }
};

export const addDmtVendor = (data) => {
  return axiosInstance.post(`${baseUrl}api/dmt_vendor/add_vendor`, data);
};

export const updateDmtVendor = (id, data) => {
  return axiosInstance.put(`${baseUrl}api/dmt_vendor/update_vendor/${id}`, data);
};

export const deleteDmtVendor = (id) => {
  return axiosInstance.delete(`${baseUrl}api/dmt_vendor/delete_vendor/${id}`);
};

export const getDmtVendors = () => {
  return axiosInstance.get(`${baseUrl}api/dmt_vendor/get_vendors`);
};




// ------------payout charges reports----------------

export const addVendors = (data) => {
  return axiosInstance.post(`${baseUrl}staff/addstaff`, data);
};

// Login.js

// Mock data
const mockChargeData = {
  bankVerificationCharge: 10,
  aeps_onBoardingCharge: 20,
  minAepsToMainWallet: 100
};

const mockDmtData = {
  dmtOptions: [
    { id: '1', value: 'dmt1', label: 'DMT Option 1' },
    { id: '2', value: 'dmt2', label: 'DMT Option 2' },
    { id: '3', value: 'dmt3', label: 'DMT Option 3' }
  ],
  dmtApiType: ['1', '2'],
  defaultApi: '1'
};

const mockPayoutData = {
  payoutOptions: [
    { id: '1', value: 'payout1', label: 'Payout Option 1' },
    { id: '2', value: 'payout2', label: 'Payout Option 2' },
    { id: '3', value: 'payout3', label: 'Payout Option 3' }
  ],
  payoutApiType: ['2', '3'],
  payoutDefaultApi: '2'
};

const mockAepsData = {
  aeps_onBoardingDefaultBank: 'bank2'
};

// Mock API functions
export const getChargeData = async (token) => {
  // Simulate API delay
  await new Promise(resolve => setTimeout(resolve, 500));
  return mockChargeData;
};

export const getDmtData = async (token) => {
  return axiosInstance.get(`${baseUrl}setting/defaultSettingDmt`);
};


export const getsetPayoutData = async (token) => {
  return axiosInstance.get(`${baseUrl}payout/payout_settings/defaultSettingPayout`);
};

export const getsetAepsData = async (token) => {
  await new Promise(resolve => setTimeout(resolve, 500));
  return mockAepsData;
};

export const updateCharges = async (values, token) => {
 
    return axios.post(`${baseUrl}charges/settings/updateChargesSetting`, values);
  };

export const updateDmt = async (values, token) => {
  return axiosInstance.put(`${baseUrl}setting/update/`, values)
}

export const updatePayout = async (values, token) => {
  console.log("values",values)
  return axiosInstance.post(`${baseUrl}payout/payout_settings/updatePayoutSetting`, values);
};

export const updateAeps = async (values, token) => {
  await new Promise(resolve => setTimeout(resolve, 500));
  console.log('Updating AEPS:', values);
  return { success: true, message: 'AEPS updated successfully' };
};

export const getServices = async (token) => {
  return axiosInstance.get(`${baseUrl}setting/services`, );
};

export const getVendorList = async (serviceId, token) => {
  return axiosInstance.get(`${baseUrl}setting/vendors/${serviceId}`, );
};

export const getBankList = async (token) => {
  return axiosInstance.get(`${baseUrl}setting/banks`, );
};

export const getSubBankList = async (bankId, token) => {
  return axiosInstance.get(`${baseUrl}setting/subbanks/${bankId}`, );
};

export const updateAEPSSettings = async (data, token) => {
  return axiosInstance.post(`${baseUrl}setting/updateaepssettings`, data, );
};
export const payoutBeneficiaryReports = async (data) => {
  return axiosInstance.get(`${baseUrl}payout/bankList/admin?start_date=${data?.start_date}&end_date=${data?.end_date}&approval_type=${data?.approval_type}&status=${data?.status}&page=${data?.page}&count=${data?.count}`, );
};
export const getvendor = async () => {
  try {
    const response = await axios.get(`${baseUrl}payout/charges/getPayoutVender`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching package invoice:', error);
    throw error;
  }
};
export const addPayout = (data) => {
  return axiosInstance.post(
    `${baseUrl}payout/charges/payoutCharges`,
    data,
    {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      }
    }
  );
};
export const getpayoutlist = async () => {
  try {
    const response = await axios.get(`${baseUrl}payout/charges/payoutChargesList`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error fetching package invoice:', error);
    throw error;
  }
};

export const payoutreport = async (params) => {
  try {
    const url = `${baseUrl}auth/getWalletdetails`;
    
    const requestBody = {
      page: params.page.toString(),
      count: params.count,
      start_date: params.start_date,
      end_date: params.end_date,
      mobile: params.mobile,
      refer_id: params.refer_id,
      user_type: params.user_type
    };

    const response = await axios.post(url, requestBody);
    return response.data;
  } catch (error) {
    console.error('Error fetching payout report:', error);
    throw error;
  }
};

export const payoutreportAll = async (params) => {
  try {
    const url = `${baseUrl}auth/getWalletbalanceExcel`;
    
    const requestBody = {
      start_date: params.start_date,
      end_date: params.end_date,
      mobile: params.mobile,
      refer_id: params.refer_id,
      user_type: params.user_type
    };

    const response = await axios.post(url, requestBody);
    return response.data;
  } catch (error) {
    console.error('Error fetching all payout report:', error);
    throw error;
  }
}


export const reversePayment= (data) => {
  return axiosInstance.post(
    `${baseUrl}paymentRequest/paymet-reverse`,
    data,
    {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      }
    }
  );
};


export const getReversepaylist = async ({ params }) => {
  try {
    const response = await axios.get(`${baseUrl}paymentRequest/reverse-list-by-admin`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
      params // Pass the parameters dynamically
    });
    return response;
  } catch (error) {
    console.error('Error fetching reverse payment data:', error);
    throw error;
  }
};

export const getReversepaylistexcel = async ({ params }) => {
  try {
    const response = await axios.get(`${baseUrl}paymentRequest/reverse-list-by-admin-exl`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
      params // Pass the parameters dynamically
    });
    return response;
  } catch (error) {
    console.error('Error fetching reverse payment data:', error);
    throw error;
  }
};
export const paymentEnquirydmt = (id) => {
  return axios.get(`${baseUrl}dmt_txn/trans-enquiry/${id}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
}

export const paymentEnquiryapes = (id) => {
  return axios.get(`${baseUrl}aeps/admin-enquiry/${id}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
}

export const paymentEnquirypayout = (id) => {
  return axios.get(`${baseUrl}payout/trans/admin-enquiry/${id}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
}

export const paymentEnquirycms = (id) => {
  return axios.get(`${baseUrl}cms/enquiry/${id}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
}

export const paymentEnquirybbps = (id) => {
  return axios.get(`${baseUrl}billPayment/billEnquiry-admin/${id}`, {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
}



export const airtelcmsTransctionFilter = (data) => {
  const queryString = new URLSearchParams(data).toString(); // Convert `data` to query string
  return axios.get(`${baseUrl}cms/admin?${queryString}`, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};

export const airtelExcelcmsTransctionFilter = (data) => {
  const queryString = new URLSearchParams(data).toString(); // Convert `data` to query string
  return axios.get(`${baseUrl}cms/admin-exl?${queryString}`, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};


export const aepspayoutTransctionFilter = (data) => {
  const queryString = new URLSearchParams(data).toString(); // Convert `data` to query string
  return axios.get(`${baseUrl}payout/admin?${queryString}`, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};

export const aepspayoutExcelTransctionFilter = (data) => {
  const queryString = new URLSearchParams(data).toString(); // Convert `data` to query string
  return axios.get(`${baseUrl}payout/admin-exl?${queryString}`, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  });
};