import React from 'react'
import TicketMaster from '../../components/ticketMaster/TicketMaster'

function TicketPage() {
  return (
    <>
      <TicketMaster/>
    </>
  )
}

export default TicketPage
