import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <label>{`${props.label} (${props.value})`}</label>
      <input
        type="checkbox"
        style={{ float: 'right' }}
        checked={props.isSelected}
        onChange={() => null}
      />
    </components.Option>
  );
};

const MultiSelect = ({ options, selectedIds, setSelectedIds, setDefaultApi }) => {
  const [internalSelectedOptions, setInternalSelectedOptions] = useState([]);

  useEffect(() => {
    if (Array.isArray(selectedIds) && Array.isArray(options)) {
      const selectedOptionObjects = options.filter(opt => selectedIds.includes(opt.id));
      setInternalSelectedOptions(selectedOptionObjects);
    } else {
      setInternalSelectedOptions([]);
    }
  }, [selectedIds, options]);

  const handleChange = (selected) => {
    const validOptions = Array.isArray(options) ? options.filter(option => option.label) : [];

    if (selected && selected.some(option => option.value === 'selectAll')) {
      if (internalSelectedOptions.length === validOptions.length - 1) {
        setInternalSelectedOptions([]);
        setSelectedIds([]);
        setDefaultApi('');
      } else {
        const allOptions = validOptions.filter(option => option.value !== 'selectAll');
        setInternalSelectedOptions(allOptions);
        setSelectedIds(allOptions.map(option => option.id));
        setDefaultApi(allOptions.length > 0 ? allOptions[0].id : '');
      }
    } else {
      setInternalSelectedOptions(selected || []);

      if (selected && selected.length > 0) {
        setSelectedIds(selected.map(option => option.id));
        setDefaultApi(selected[0].id);
      } else {
        setSelectedIds([]);
        setDefaultApi('');
      }
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : provided.backgroundColor,
      color: state.isSelected ? 'black' : provided.color,
      fontSize: '10px',
      fontWeight: 'medium',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'lightblue',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'black',
    }),
  };

  // Filter options to only include those with valid labels
  const filteredOptions = Array.isArray(options) 
    ? options.filter(option => option && option.label)
    : [];

  return (
    <Select
      options={filteredOptions}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option: CustomOption,
      }}
      onChange={handleChange}
      value={internalSelectedOptions}
      styles={customStyles}
    />
  );
};

export default MultiSelect;