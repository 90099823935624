import React from 'react'
import CmsBillReport from '../../../components/additionalReports/cmsBillReport/CmsBillReport'

function CmsBillReportsPage() {
  return (
    <>
    <CmsBillReport/>
    </>

  )
}

export default CmsBillReportsPage