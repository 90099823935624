import React from 'react'
import CcbillPayRep from '../../../components/additionalReports/ccBillPaymentReaport/CcbillPayRep'

function CcbilPayPage() {
  return (
    <>
    <CcbillPayRep/>
    </>
  )
}

export default CcbilPayPage