import React from 'react'
import AadharPayTxn from '../../components/aepsMaster/aadharPayTxn/AadharPayTxn'

function AadharpayTxnPage() {
  return (
    <>
      <AadharPayTxn/>
    </>
  )
}

export default AadharpayTxnPage
