import React, { useState } from 'react';
import { Pagination } from 'antd';
import * as XLSX from 'xlsx';
import Loadar from '../../../../common/loader/Loader';
import { CiSaveDown1 } from 'react-icons/ci';
import { Button, Modal, Card } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import './DmtReportSearch.css';

function   DmtReportSearchList({ onChangeVal,
  dmtTtxn,
  loading,
  handleExcelExport,
  exportLoading,
  page,
  count,responseEnquiry }) {
  const curdmtTtxn = dmtTtxn?.dmtTransaction;
  // const curdmtexcelTtxn = dmtExcelTtxn?.dmtTransaction;

  // console.log("curdmtTtxn:", curdmtTtxn);
  // console.log("curdmtexcelTtxn:", curdmtexcelTtxn);

  const [showModal, setShowModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const getStatusText = (status) => {
    // Convert status to string to handle both string and number inputs
    switch (String(status)) {
      case '1': return 'Pending';
      case '2': return 'Success';
      case '3': return 'Failed';
      case '4': return 'Refunded';
      // case '5': return 'Hold';
      default: return 'Unknown';
    }
  };

  // const exportToExcel = () => {
  //   if (curdmtexcelTtxn && curdmtexcelTtxn.length > 0) {
  //     const modifiedData = curdmtexcelTtxn.map(item => ({
  //       ...item,
  //       tx_status: getStatusText(item.tx_status)
  //     }));

  //     const worksheet = XLSX.utils.json_to_sheet(modifiedData);
  //     const workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "DMT Transactions");
  //     XLSX.writeFile(workbook, "dmt_transactions.xlsx");
  //   } else {
  //     console.error("curdmtexcelTtxn is empty or not defined");
  //   }
  // }

  const handleShowModal = (transaction) => {
    setSelectedTransaction(transaction);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTransaction(null);
  };

  return (
    <section className="ListDistributer exppdf">
      <div className="row m-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects style-1">
                <div className="tbl-caption">
                  <h4 className="heading mb-0"><b> DMT Transaction Reports </b></h4>
                  <div>
                    {dmtTtxn?.dmtTransaction?.length > 0 && (
                      <Button
                        onClick={handleExcelExport}
                        variant="success"
                        style={{ backgroundColor: 'green' }}
                        disabled={exportLoading}
                      >
                        {exportLoading ? 'Exporting...' : 'Export Excel'}
                        <CiSaveDown1 className='fs-4 fw-bold text-white' />
                      </Button>
                    )}
                  </div>
                </div>
                <div className="p-3">
                                    <h3>Total Amount: <b>{dmtTtxn?.totalAmount || 0}</b></h3>
                                </div>
                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                  <table id="table-to-xls" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                    <thead>
                      <tr role="row">
                        <th>S.No</th>
                        <th>Transaction Date</th>
                        <th>Transaction ID</th>
                        <th>User Name</th>
                        <th>Bank Name</th>
                        <th>Opening Balance</th>
                        <th>Amount</th>
                        <th>Charge</th>
                        <th>Commission</th>
                        <th>TDS</th>
                        <th>Closing Balance</th>
                        <th>Key Status</th>
                        <th>Vendor</th>
                        <th>Actions</th> {/* New column for actions */}
                        <th>Enquiry</th> 
                      </tr>
                    </thead>
                    {loading && <Loadar />}
                    <tbody>
                      {curdmtTtxn?.map((item, i) => {
                        return <tr role="row" className="odd" key={i}>
                          <td> {(i + 1) + count * page}</td>
                          <td> {item?.createdAt}</td>
                          <td> {item?.txn_id}</td>
                          <td> {item?.username}</td>
                          <td> {item?.bank_name}</td>
                          <td> {item?.o_bal}</td>
                          <td> {item?.amount}</td>
                          <td> {item?.charge}</td>
                          <td> {item?.commission}</td>
                          <td> {item?.tds}</td>
                          <td> {item?.c_bal}</td>
                          <td>
                            {/* {item?.tx_status == 0 && <Button variant="success">Success</Button>} */}
                            {item?.response == 1 && <Button variant="warning">Pending</Button>}
                            {item?.response == 2 && <Button variant="success">Success</Button>}
                            {item?.response == 3 && <Button variant="danger">Failed</Button>}
                            {item?.response == 4 && <Button variant="info">Refunded</Button>}
                            {item?.response == 5 && <Button variant="info">Not Initiated</Button>}
                            {/* {item?.response == 5 && <Button variant="primary">Hold</Button>} */}
                          </td>
                          <td>
                          {item?.vender_name}
                          </td>
                          <td>
                            <Button variant="outline-primary" onClick={() => handleShowModal(item)}>
                              <FaEye />
                            </Button>
                          </td>
                          <td>
                            <Button variant="outline-primary" onClick={() => responseEnquiry(item._id)}
                              disabled={item?.response == 2 || item?.response == 4 || item?.response == 5}
                              style={{
                                color: item?.response === 2 || item?.response === 4 || item?.response === 5 ? "#6c757d" : "#0d6efd",
                                backgroundColor: item?.response === 2 || item?.response === 4 || item?.response === 5 ? "#e9ecef" : "transparent",
                                borderColor: item?.response === 2 || item?.response === 4 || item?.response === 5 ? "#ced4da" : "#0d6efd",
                                cursor: item?.response === 2 || item?.response === 4 || item?.response === 5 ? "not-allowed" : "pointer",
                                pointerEvents: item?.response === 2 || item?.response === 4 || item?.response === 5 ? "none" : "auto",
                              }}>
                            Enquiry
                            </Button>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                  <div className="dataTables_info" id="empoloyees-tblwrapper_info" role="status" aria-live="polite">
                    Total {dmtTtxn?.total} entries
                  </div>
                  <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                    {/* <Pagination
                      defaultCurrent={1}
                      onChange={onChangeVal}
                      total={dmtTtxn?.total}
                    /> */}
                    <Pagination
  current={page + 1} // Convert back to 1-based indexing for the UI
  total={dmtTtxn?.total || 0} // Ensure total count from API is used
  pageSize={count}
  onChange={(e) => onChangeVal(e)}
/>
                  </div>
                </div>
                <div className="dataTables_info" id="empoloyees-tblwrapper_info" role="status" aria-live="polite">
                  <h4> Total Amount : {dmtTtxn?.totalAmount} </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ?
      {/* Modal for displaying transaction details */}
      <Modal show={showModal} onHide={handleCloseModal} centered className="dmt-modal">
        <Modal.Header closeButton className="dmt-modal-header">
          <Modal.Title>Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dmt-modal-body">
          {selectedTransaction && (
            <div className="dmt-transaction-details">
              <Card className="dmt-card mb-2">
                <Card.Header className="dmt-card-header dmt-bg-primary">User Information</Card.Header>
                <Card.Body className="dmt-card-body">
                  <p><strong>Username:</strong> {selectedTransaction?.username}</p>
                  <p><strong>Mobile:</strong> {selectedTransaction?.mobile}</p>
                  <p><strong>Date:</strong> {selectedTransaction?.createdAt}</p>
                  <p><strong>Refer id:</strong> {selectedTransaction?.refer_id}</p>
                </Card.Body>
              </Card>

              <Card className="dmt-card mb-2">
                <Card.Header className="dmt-card-header dmt-bg-success">Customer Information</Card.Header>
                <Card.Body className="dmt-card-body">
                  <p><strong>Name:</strong> {selectedTransaction?.customer_name}</p>
                  <p><strong>Mobile:</strong> {selectedTransaction?.customer_mobile}</p>
                </Card.Body>
              </Card>

              <Card className="dmt-card mb-2">
                <Card.Header className="dmt-card-header dmt-bg-warning">Beneficiary Information</Card.Header>
                <Card.Body className="dmt-card-body">
                  <p><strong>Name:</strong> {selectedTransaction?.beneficiary_name}</p>
                  <p><strong>Bank:</strong> {selectedTransaction?.bank_name}</p>
                  <p><strong>Account:</strong> {selectedTransaction?.account_number}</p>
                  <p><strong>IFSC:</strong> {selectedTransaction?.ifsc_code}</p>
                </Card.Body>
              </Card>

              <Card className="dmt-card mb-2">
                <Card.Header className="dmt-card-header dmt-bg-info">Transaction Information</Card.Header>
                <Card.Body className="dmt-card-body">
                  <p><strong>ID:</strong> {selectedTransaction?.txn_id}</p>
                  <p><strong>UTR:</strong> {selectedTransaction?.utr}</p>
                  <p><strong>Amount:</strong> ₹{selectedTransaction?.amount}</p>
                  <p><strong>Charge:</strong> ₹{selectedTransaction?.charge}</p>
                  <p><strong>Commission:</strong> ₹{selectedTransaction?.commission}</p>
                  <p><strong>TDS:</strong> ₹{selectedTransaction?.tds}</p>
                  <p><strong>Status:</strong>
                    <span className={`dmt-badge dmt-bg-${selectedTransaction?.response === 1 ? 'warning' :
                   
                          selectedTransaction?.response === 2 ? 'success' :
                            selectedTransaction?.response === 3 ? 'danger' :
                              selectedTransaction?.response === 4 ? 'info' :
                                selectedTransaction?.response === 5 ? '' : 'dark'
                      }`}>
                      {selectedTransaction?.response === 1 ? 'Pending' :
                      
                          selectedTransaction?.response === 2 ? 'Success' :
                            selectedTransaction?.response === 3 ? 'Failed' :
                              selectedTransaction?.response === 4 ? 'Refunded' :
                                selectedTransaction?.response === 5 ? 'Not Initiated' : 'Unknown'}
                    </span>
                  </p>

                </Card.Body>
              </Card>
              <Card className="dmt-card mb-2">
                <Card.Header className="dmt-card-header dmt-bg-primary">Partner Information</Card.Header>
                <Card.Body className="dmt-card-body">
                  <p><strong>Super Distributor Name:</strong> {selectedTransaction?.master_dist_name}</p>
                  <p><strong>Super Distributor Id:</strong> {selectedTransaction?.master_dist_refer_id}</p>
                  <p><strong>Super Distributor Commission:</strong> {selectedTransaction?.master_dist_commission}</p>
                  <p><strong>Super Distributor Tds:</strong> {selectedTransaction?.master_dist_tds}</p>
                  <p><strong>Super Distributor Contact:</strong> {selectedTransaction?.master_dist_mobile}</p>
                  <p><strong> Distributor Name:</strong> {selectedTransaction?.distributor_name}</p>
                  <p><strong> Distributor Id:</strong> {selectedTransaction?.distributor_refer_id}</p>
                  <p><strong>Distributor Commission:</strong> {selectedTransaction?.distributor_commission}</p>
                  <p><strong>Distributor Tds:</strong> {selectedTransaction?.distributor_tds}</p>
                  <p><strong>Distributor Contact:</strong> {selectedTransaction?.distributor_mobile}</p>
                </Card.Body>
              </Card>

              <Card className="dmt-card">
                <Card.Header className="dmt-card-header dmt-bg-secondary">Balance</Card.Header>
                <Card.Body className="dmt-card-body">
                  <p><strong>Opening:</strong> ₹{selectedTransaction?.o_bal}</p>
                  <p><strong>Closing:</strong> ₹{selectedTransaction?.c_bal}</p>
                </Card.Body>
              </Card>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default DmtReportSearchList;
