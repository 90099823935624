import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, DatePicker, Typography, Button, Table, Tag, message, Empty, Modal, Space, Divider,Input,Select } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ledgerReport, ledgerReportAll } from '../../api/login/Login';
import './Dashboard.css';
import * as XLSX from 'xlsx';

const { Title } = Typography;

const LedgerReport = () => {
  const [reportData, setReportData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [vendor, setVendor] = useState('');
const [service, setService] = useState('');
const [status, setStatus] = useState('');
const [txnId, setTxnId] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCommission, setSelectedCommission] = useState({});

  useEffect(() => {
    fetchReportData(pagination.current);
  }, []);

  const fetchReportData = async (page = 1 ,customStartDate = startDate, customEndDate = endDate ,customVendor = vendor,
    customService = service,
    customStatus = status,
    customTxnId = txnId) => {
    setIsLoading(true);
    try {
      const response = await ledgerReport(
        page - 1,
        pagination.pageSize,
        formatDate(customStartDate),
      formatDate(customEndDate),
      customVendor,
      customService,
      customStatus,
      customTxnId

      );
      if (response?.error === false && response?.statusCode === 200) {
        setReportData(response?.data);
        setTotalCount(response);
        setPagination({
          ...pagination,
          current: page,
          total: response.totalCount, // Use totalCount from the API response
        });
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching ledger report data:', error);
      setReportData([]);
      message.error('Failed to fetch ledger report data');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (date) => {
    return date ? date.format('YYYY-MM-DD') : null;
  };

  const handleTableChange = (pagination) => {
    fetchReportData(pagination.current);
  };

  const getStatusTag = (status) => {
    const statusMap = {
      1: { color: 'orange', text: 'Pending' },
      2: { color: 'green', text: 'Success' },
      3: { color: 'red', text: 'Failed' },
      4: { color: 'blue', text: 'Refunded' },
      5: { color: 'blue', text: 'Not Initiated' },
    };

    const statusInfo = statusMap[status] || { color: 'default', text: 'Unknown' };

    return <Tag color={statusInfo.color}>{statusInfo.text}</Tag>;
  };

  const getStatusTag2 = (status) => {
    const statusMap = {
      1: { text: 'Pending' },
      2: { text: 'Success' },
      3: { text: 'Failed' },
      4: { text: 'Refunded' },
      5: { text: 'Not Initiated' },
    };

    // Fallback to 'Unknown' if status is not in the statusMap
    const statusInfo = statusMap[status] || { text: 'Unknown' };

    // Return the corresponding text
    return statusInfo.text;
};
  const showModal = (record) => {
    setSelectedCommission(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Serial No.',
      key: 'serial',
      render: (text, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    { title: 'Transaction ID', dataIndex: 'txn_id', key: 'txn_id' },
    { title: 'Date', dataIndex: 'txn_date', key: 'txn_date', render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss') },
    { title: 'Service', dataIndex: 'service', key: 'service' },
    { title: 'BBPS Service Name', dataIndex: 'bbps_operators', key: 'bbps_operators' },
    { title: 'Vendor', dataIndex: 'vender', key: 'vender' },
    { title: 'State', dataIndex: 'state', key: 'state' },
    { title: 'Amount', dataIndex: 'amount', key: 'amount', render: (text) => `₹${parseFloat(text).toFixed(2)}` },
    { title: 'Charges', dataIndex: 'charges', key: 'charges', render: (text) => `₹${parseFloat(text).toFixed(2)}` },
    // { title: 'Commission', dataIndex: 'commission', key: 'commission', render: (text) => `₹${parseFloat(text).toFixed(2)}` },
    // { title: 'TDS', dataIndex: 'user_tds', key: 'user_tds', render: (text) => `₹${parseFloat(text).toFixed(2)}` },
    { title: 'Status', dataIndex: 'status', key: 'status', render: (status) => getStatusTag(status) },
    { title: 'Retailer Name', dataIndex: 'retailer_name', key: 'retailer_name' },
    { title: 'Retailer Mobile', dataIndex: 'retailer_mobile', key: 'retailer_mobile' },
    { title: 'Retailer Refer ID', dataIndex: 'retailer_refer_id', key: 'retailer_refer_id' },
    { title: 'Distributor Name', dataIndex: 'distributor_name', key: 'distributor_name' },
    { title: 'Distributor Mobile', dataIndex: 'distributor_mobile', key: 'distributor_mobile' },
    { title: 'Distributor Refer ID', dataIndex: 'distributor_refer_id', key: 'distributor_refer_id' },
    { title: 'Master Distributor Name', dataIndex: 'master_dist_name', key: 'master_dist_name' },
    { title: 'Master Distributor Mobile', dataIndex: 'master_dist_mobile', key: 'master_dist_mobile' },
    { title: 'Master Distributor Refer ID', dataIndex: 'master_dist_refer_id', key: 'master_dist_refer_id' },
    { title: 'Total Commission', dataIndex: 'total_commission', key: 'total_commission', render: (text) => `₹${parseFloat(text).toFixed(2)}` },
    { title: 'Total TDS', dataIndex: 'total_tds', key: 'total_tds', render: (text) => `₹${parseFloat(text).toFixed(2)}` },
    { title: 'Our Commission', dataIndex: 'our_commission', key: 'our_commission', render: (text) => `₹${parseFloat(text).toFixed(2)}` },
    {
      title: 'Details',
      key: 'details',
      render: (_, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => showModal(record)}
        />
      ),
    },
  ];

  const handleSearch = () => {
    fetchReportData(1);
  };

  // const handleClearDates = () => {
  //   const resetStartDate = "";
  //   const resetEndDate = "";
  //   const resetdata= '';

  
  //   setStartDate(resetStartDate);
  //   setEndDate(resetEndDate);
  //   setVendor(resetdata);
  //   setService(resetdata);
  //   setStatus(resetdata);
  //   setTxnId(resetdata);
  
  //   // Pass the reset dates explicitly to fetchReportData
  //   fetchReportData(1, resetStartDate, resetEndDate);
  // };


  // const handleClearDates = () => {
  //   setStartDate(null);
  //   setEndDate(null);
  //   setVendor('');
  //   setService('');
  //   setStatus('');
  //   setTxnId('');
  
  //   fetchReportData(1, null, null); // Reset the API call with null values for dates
  // };

  const handleClearDates = () => {
    const resetStartDate = null; // Use null to indicate no date filter
    const resetEndDate = null;
    const resetVendor = '';
    const resetService = '';
    const resetStatus = '';
    const resetTxnId = '';
  
    // Update state
    setStartDate(resetStartDate);
    setEndDate(resetEndDate);
    setVendor(resetVendor);
    setService(resetService);
    setStatus(resetStatus);
    setTxnId(resetTxnId);
  
    // Directly pass the reset values to the fetch function
    fetchReportData(1, resetStartDate, resetEndDate, resetVendor, resetService, resetStatus, resetTxnId);
  };
  

  

  const getStatusText = (status) => {
    const statusMap = {
      1: 'Pending',
      2: 'Success',
      3: 'Failed',
      4: 'Refunded',
    };
    return statusMap[status] || 'Unknown';
  };

  const handleExportExcel = async () => {
    try {
      const response = await ledgerReportAll(
        formatDate(startDate),
        formatDate(endDate),
        vendor,
      service,
      status,
      txnId 
      );
  
      if (response?.error === false && response?.statusCode === 200) {
        const data = response?.data;
  
        const excelData = data.map(item => ({
          'Transaction ID': item.txn_id,
          'Date': dayjs(item.txn_date).format('YYYY-MM-DD HH:mm:ss'),
          'Created At': item.createdAt,
          'Updated At': dayjs(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          // 'User ID': item.user_id,
          'Amount': item.amount,
          'Service': item.service,
          'BBps Service Name': item.bbps_operators,
          'Vendor': item.vender,
          'State': item.state,
          'Status': getStatusTag2(item?.status),
          'Charges': item.charges,
          'Retailer Commission': item.commission,
          'Retailer TDS': item.user_tds,
          'Distributor Commission': item.distributor_commission,
          'Distributor TDS': item.distributor_tds,
          'Super Distributor Commission': parseFloat(item.master_dist_commission),
          'Super Distributor TDS': parseFloat(item.master_dist_tds),
          'Total Commission': parseFloat(item.total_commission),
          'Total TDS': parseFloat(item.total_tds),
          'Our Commission': parseFloat(item.our_commission),
          'Retailer Name': item.retailer_name,
          'Retailer Mobile': item.retailer_mobile,
          'Retailer Refer ID': item.retailer_refer_id,
          'Distributor Name': item.distributor_name,
          'Distributor Mobile': item.distributor_mobile,
          'Distributor Refer ID': item.distributor_refer_id,
          'Master Distributor Name': item.master_dist_name,
          'Master Distributor Mobile': item.master_dist_mobile,
          'Master Distributor Refer ID': item.master_dist_refer_id,
         
        }));
  
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(excelData, {
          header: Object.keys(excelData[0]),
          skipHeader: false
        });
  
        const colWidths = Object.keys(excelData[0]).map(key => ({
          wch: Math.max(key.length, 15)
        }));
        ws['!cols'] = colWidths;
  
        XLSX.utils.book_append_sheet(wb, ws, "Ledger Report");
  
        const timestamp = dayjs().format('YYYY-MM-DD_HH-mm');
        XLSX.writeFile(wb, `LedgerReport_${timestamp}.xlsx`);
  
        message.success('Excel file downloaded successfully');
      } else {
        throw new Error('Failed to fetch data for Excel export');
      }
    } catch (error) {
      console.error('Error exporting Excel:', error);
      message.error('Failed to export Excel file');
    }
  };

  return (
    <div className="container-fluid py-4">
      <Card className="shadow-sm mb-4" style={{ borderRadius: '10px' }}>
        <Title level={3}>Ledger Report</Title>
        <Form layout="inline" className="mb-3">
          <Form.Item label="Start Date">
            <DatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </Form.Item>
          <Form.Item label="End Date">
            <DatePicker
              value={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Form.Item>
          <Form.Item label="Vendor">
    <Input
      placeholder="Enter Vendor"
      value={vendor}
      onChange={(e) => setVendor(e.target.value)}
    />
  </Form.Item>
  <Form.Item label="Service">
    <Input
      placeholder="Enter Service"
      value={service}
      onChange={(e) => setService(e.target.value)}
    />
  </Form.Item>
  <Form.Item label="Status">
  <Select
    placeholder="Select Status"
    value={status} // Bind to state
    onChange={(value) => setStatus(value)} // Update state
    style={{ width: 200 }}
  >
    <Select.Option value="">All</Select.Option>
    <Select.Option value="1">Pending</Select.Option>
    <Select.Option value="2">Success</Select.Option>
    <Select.Option value="3">Failed</Select.Option>
    <Select.Option value="4">Refunded</Select.Option>
  </Select>
</Form.Item>

  <Form.Item label="Transaction ID">
    <Input
      placeholder="Enter Transaction ID"
      value={txnId}
      onChange={(e) => setTxnId(e.target.value)}
    />
  </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={handleClearDates}>
              Clear Dates
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <Card className="shadow-sm" style={{ borderRadius: '10px' }}>
        <Button onClick={handleExportExcel} type="primary" className="mb-3">
          Export Excel
        </Button>
        <Table
          columns={columns}
          dataSource={reportData}
          rowKey="_id"
          pagination={{
            ...pagination,
            total: totalCount?.totalCount,
          }}
          loading={isLoading}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data available"
              />
            ),
          }}
          footer={() => (
            <div>
              Total Count: {pagination?.total}
            </div>
          )}
        />
      </Card>
      <Modal
        title="Commission and TDS Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={800}
        footer={[
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>
        ]}
      >
        <Card>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Typography.Text strong>Super Distributor Commission:</Typography.Text>
              <Typography.Title level={4}>
                ₹{parseFloat(selectedCommission.master_dist_commission || 0).toFixed(2)}
              </Typography.Title>
              <Typography.Text>TDS: ₹{parseFloat(selectedCommission.master_dist_tds || 0).toFixed(2)}</Typography.Text>
            </Col>
            <Col xs={24} sm={12}>
              <Typography.Text strong>Distributor Commission:</Typography.Text>
              <Typography.Title level={4}>
                ₹{parseFloat(selectedCommission.distributor_commission || 0).toFixed(2)}
              </Typography.Title>
              <Typography.Text>TDS: ₹{parseFloat(selectedCommission.distributor_tds || 0).toFixed(2)}</Typography.Text>
            </Col>
            <Col xs={24} sm={12}>
              <Typography.Text strong>Retailer Commission:</Typography.Text>
              <Typography.Title level={4}>
                ₹{parseFloat(selectedCommission.commission || 0).toFixed(2)}
              </Typography.Title>
              <Typography.Text>TDS: ₹{parseFloat(selectedCommission.user_tds || 0).toFixed(2)}</Typography.Text>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Typography.Text strong>Total Commission:</Typography.Text>
              <Typography.Title level={4}>
                ₹{parseFloat(selectedCommission.total_commission || 0).toFixed(2)}
              </Typography.Title>
            </Col>
            <Col xs={24}>
              <Typography.Text strong>Total TDS:</Typography.Text>
              <Typography.Title level={4}>
                ₹{parseFloat(selectedCommission.total_tds || 0).toFixed(2)}
              </Typography.Title>
            </Col>
            <Col xs={24}>
              <Typography.Text strong>Our Commission:</Typography.Text>
              <Typography.Title level={4}>
                ₹{parseFloat(selectedCommission.our_commission || 0).toFixed(2)}
              </Typography.Title>
            </Col>
          </Row>
        </Card>
      </Modal>
    </div>
  );
};

export default LedgerReport;