import React from 'react'
import PaymentWithdrawlRep from '../../../components/additionalReports/paymentWitdrawlRep/PaymentWithdrawlRep'

function PayMentWithDrawlReportpage() {
  return (
    <>
    <PaymentWithdrawlRep/>
    </>
  )
}

export default PayMentWithDrawlReportpage