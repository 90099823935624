import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import CustomInputField from '../../common/CustomInputField';
import { getOperater } from '../../api/login/Login';
import { useParams } from 'react-router-dom';

function SameCompFilter({ submitForm, title, initialValues, setIntialValues, handleChangeOp, getDmtTxnData, page }) {
    const param = useParams();
    const [operate, setOperater] = useState();
    const handleReset = (resetForm) => {
        setIntialValues({
            categorey_id: param.id,
            biller_id: "",
            start_date: '',
            end_date: '',
            txn_id: ""
        })
        setTimeout(() => {
            getDmtTxnData(0)
        }, 1000)
        resetForm()
    };

    const getOperate = async () => {
        try {
            const res = await getOperater(param?.id);
            setOperater(res?.data);
        } catch (error) {
            console.error("Error fetching operator data:", error);
        }
    };

    useEffect(() => {
        getOperate();
    }, []);

    return (
        <>
            <div className="row m-4">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div className="tbl-caption tbl-caption-2">
                                    <h4 className="heading mb-0"><b>{title} Search</b></h4>
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={submitForm}
                                    className="tbl-captionn"
                                >
                                    {(formik) => {
                                        const {
                                            values,
                                            handleChange,
                                            handleSubmit,
                                            resetForm,
                                            errors,
                                            touched,
                                            handleBlur,
                                            isValid,
                                            dirty,
                                        } = formik;
                                        return (
                                            <form className="tbl-captionn" onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-xl-3 mb-3">
                                                        <CustomInputField
                                                            type="date"
                                                            placeholder="Start Date"
                                                            name="start_date"
                                                            value={values.start_date}
                                                            hasError={errors.start_date && touched.start_date}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.start_date}
                                                            id="start_date"
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-3">
                                                        <CustomInputField
                                                            type="date"
                                                            placeholder="End Date"
                                                            name="end_date"
                                                            value={values.end_date}
                                                            hasError={errors.end_date && touched.end_date}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.end_date}
                                                            id="end_date"
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 mb-3">
                                                        <select
                                                            className="form-select"
                                                            aria-label="Default select example"
                                                            name="operator_name"
                                                            value={values.operator_name}
                                                            hasError={errors.operator_name && touched.operator_name}
                                                            onChange={handleChangeOp}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option value={""}>Select Operator</option>
                                                            {operate && operate.map((item) => (
                                                                <option key={item._id} value={item._id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-xl-3 mb-3">
                                                        <CustomInputField
                                                            type="text"
                                                            placeholder="TXN ID"
                                                            name="txn_id"
                                                            value={values.txn_id}
                                                            hasError={errors.txn_id && touched.txn_id}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.txn_id}
                                                            id="txn_id"
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="d-flex justify-content-start">
                                                            <button className="btn btn-primary pd-x-20 color2" type="submit">
                                                                <i className="fas fa-search"></i> Search
                                                            </button>
                                                            <button className="btn btn-primary pd-x-20 color2" type="button" onClick={() => handleReset(resetForm)}>
                                                                <i className="fas fa-refresh"></i> Reset
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SameCompFilter;
