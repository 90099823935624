import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { sendForgotPinOtpVerify } from '../../../api/login/Login';
import CustomInputField from '../../../common/CustomInputField';

function ForgotPinModal(props) {
    const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
    const token = window.localStorage.getItem("userToken");

    const toastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
        });
    };

    const toastErrorMessage = (message) => {
        toast.error(message, {
            position: "top-right",
        });
    };

    const handleOtpChange = (index, value, event) => {
        const key = event.key;
        const newOtpDigits = [...otpDigits];
        if (value.length > 1) {
            value = value.slice(0, 1);
        }
        newOtpDigits[index] = value;
        setOtpDigits(newOtpDigits);

        if (value && index < 5) {
            document.getElementById(`otp${index + 1}`).focus();
        }
        if (!value && index > 0 && (key === 'Backspace' || key === 'Delete' || key == undefined )) {
            console.log("test000");
            const d = document.getElementById(`otp${index - 1}`).focus();

        }
    };

    const handleOtpSubmit = async (event) => {
        event.preventDefault();
        const isAnyBlank = otpDigits.some(digit => digit === "");
        if (isAnyBlank) {
            toastErrorMessage("Please fill all OTP fields correctly.");
        } else {
            const otp = otpDigits.join("");
            const otpDatas = { user_id: token, otp };
            try {
                const res = await sendForgotPinOtpVerify(otpDatas);

                if (res.statusCode == "200") {
                    toastSuccessMessage(res?.message);
                    setTimeout(() => {
                        props.onHide();
                        setOtpDigits(["", "", "", "", "", ""]);
                        props.resetTimer();
                    }, 500);
                } else {
                    toastErrorMessage(res?.message);
                    props.setErrorMessage(!props.errorMessage);
                    setOtpDigits(["", "", "", "", "", ""]);
                }
            } catch (error) {
                props.setErrorMessage(!props.errorMessage);
                toastErrorMessage("An error occurred. Please try again.");
            }
        }
    };

    useEffect(() => {
        if (!props.show) {
            setOtpDigits(["", "", "", "", "", ""]); // Reset OTP fields when the modal is closed
        }
    }, [props.show]);

    const isAllOtpFilled = otpDigits.every(digit => digit !== "");

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <form onSubmit={handleOtpSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-center">
                        <h4>FORGOT PIN</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-body">
                        <div className="row">
                            <div className="col-sm-12 mt-3">
                                <div className="form-group">
                                    <div className='d-flex justify-content-center'>
                                        <label htmlFor="name" className="mb-3">ENTER OTP</label>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        {otpDigits.map((digit, index) => (
                                            <div className="position-relative mx-2 otpInp" key={index}>
                                                <CustomInputField
                                                    id={`otp${index}`}
                                                    type="number"
                                                    value={digit}
                                                    onChange={(e) => handleOtpChange(index, e.target.value, e)}
                                                    maxLength={1}
                                                    onKeyDown={(e) => handleOtpChange(index, e.target.value, e)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {props.timer === "00:00" ? (
                        <small
                            type="button"
                            className="text-dark text-decoration-underline"
                            onClick={props.handleForgotPin}
                        >
                            {"Resend OTP"}
                        </small>
                    ) : (
                        <span>{props.timer}</span>
                    )}
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isAllOtpFilled}
                    >
                        {"Submit OTP"}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default ForgotPinModal;
