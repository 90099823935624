import React from 'react'
import RupayGiftCard from '../../../components/additionalReports/rupayGiftCard/RupayGiftCard'

function RupayGiftCardPage() {
  return (
    <>
    <RupayGiftCard/>
    </>
  )
}

export default RupayGiftCardPage