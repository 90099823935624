import React from 'react'
import AllBeneficiariesComp from '../../components/allBeneficiaries/AllBeneficiariesComp'   

function AllBeneficiariesPage() {
    return (
        <>
            <AllBeneficiariesComp />
        </>
    )
}

export default AllBeneficiariesPage