// import React, { useEffect, useState } from 'react'
// import Breadcrumbs from '../../common/breadcrumb/Breadcrumbs'
// import { aepsTransctionFilter, getDataReports, paymentReport } from '../../api/login/Login'
// import { useParams } from 'react-router-dom'
// import Loadar from '../../common/loader/Loader'
// import AepsBalanceFilter from './AepsBalanceFilter'
// import AepsBalanceList from './AepsCashList'
// import AepsCashList from './AepsCashList'

// function AepsCashMain({ title_1, title_2, title }) {
//   const breadCrumbsTitle = {
//     id: "1",
//     title_1: title_1,
//     title_2: title_2,
//   }
//   const [count, setCount] = useState(10)
//   const [page, setPage] = useState(0)
//   const [aepsData, setAepsData] = useState()
//   const [loading, setLoading] = useState(false)
//   const [userData, setUserData] = useState(false)
//   const token = window.localStorage.getItem('userToken')
//   const getCurrentDate = () => {
//     const date = new Date();
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
//     const day = String(date.getDate()).padStart(2, '0');

//     return `${year}-${month}-${day}`;
//   };
//   const [initialValues, setIntialValues] = useState({
//     name: "",
//     amount: "",
//     message: "",
//     start_date: "",
//     end_date: "",
//     customer_mobile: "",
//   })
//   const submitForm = async (values) => {
//     const mobileNo = values.customer_mobile ? values.customer_mobile.trim() : '';
//     const txnId = values.txn_id ? values.txn_id.trim() : '';
//     setLoading(true);
//     setIntialValues({
//       ...values, count, customer_mobile: mobileNo, txn_id: txnId
//     })
//     setPage(0)
//     try {

//       const res = await aepsTransctionFilter({ ...values, page: 0, count, customer_mobile: mobileNo, txn_id: txnId });
//       setAepsData(res);
//     } catch (error) {
//       console.error("Error during AEPS transaction filter:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const getDmtTxnData = async (num) => {
//     setLoading(true)
//     try {
//       const res = await aepsTransctionFilter({ ...initialValues, page:num, count, type: 'CD' });

//       setAepsData(res);
//       setLoading(false)
//     } catch (error) {

//     }
//   }
//   const param = useParams()




//   const onChangeVal = (e) => {
//     setPage(e - 1)
//     setTimeout(() => {
//       getDmtTxnData(e - 1)
//     }, 1000);
//   };


//   useEffect(() => {
//     getDmtTxnData(0)
//   }, [])
//   return (
//     <>
//       {loading && <Loadar />}
//       <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />
//       <AepsBalanceFilter title={title} initialValues={initialValues} userData={userData} submitForm={submitForm} aepsData={aepsData} getDmtTxnData={getDmtTxnData} page={page} />
//       <AepsCashList title={title} onChangeVal={onChangeVal} aepsData={aepsData} getDmtTxnData={getDmtTxnData} page={page} count={count} />
//     </>
//   )
// }

// export default AepsCashMain



import React, { useState, useEffect } from 'react';
import { message, Pagination, Button } from 'antd';
import * as XLSX from 'xlsx';
import { ToastContainer } from 'react-toastify';
import Breadcrumbs from '../../common/breadcrumb/Breadcrumbs';
import { aepsTransctionFilter, aepsTransctionAll, paymentEnquiryapes } from '../../api/login/Login';
import Loadar from '../../common/loader/Loader';
import CustomInputField from '../../common/CustomInputField';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

function AepsCashMain({ title_1, title_2, title }) {
  const breadCrumbsTitle = {
    id: "1",
    title_1: title_1,
    title_2: title_2,
  };

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().substr(0, 10);
  };

  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [aepsData, setAepsData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const token = window.localStorage.getItem('userToken');

  const [initialValues, setInitialValues] = useState({
    customer_mobile: "",
    adhaar_no: "",
    txn_id: "",
    start_date: currentDate,
    end_date: currentDate,
    user_name: "",
    bank_name: "",
    type: "CD",
    user_id: token,
  });

  const submitForm = async (values) => {
    const mobileNo = values.customer_mobile ? values.customer_mobile.trim() : '';
    const txnId = values.txn_id ? values.txn_id.trim() : '';
    setLoading(true);
    setInitialValues({
      ...values,
      count,
      customer_mobile: mobileNo,
      txn_id: txnId,
      start_date: values.start_date ? values.start_date : currentDate,
      end_date: values.end_date ? values.end_date : currentDate,
    });
    setPage(0);

    try {
      const res = await aepsTransctionFilter({
        ...values,
        page: 0,
        count,
        customer_mobile: mobileNo,
        txn_id: txnId
      });
      if (res && res.data) {
        setAepsData(res);
      } else {
        setAepsData({ data: [] });
        message.info('No data available');
      }
    } catch (error) {
      console.error("Error during AEPS transaction filter:", error);
      message.error('Failed to fetch data. Please try again.');
      setAepsData({ data: [] });
    } finally {
      setLoading(false);
    }
  };
  const resetFilters = () => {
    // Clear the filters and reset the state
    const resetValues = {
      ...initialValues,
      customer_mobile: "",
      adhaar_no: "",
      txn_id: "",
      start_date: "",
      end_date: "",
    };

    // Update the state and wait for the update to complete
    setInitialValues(resetValues);
    setPage(0); // Reset page number
  };

  // Trigger API call when `initialValues` changes due to reset
  useEffect(() => {
    if (
      initialValues.customer_mobile === "" &&
      initialValues.adhaar_no === "" &&
      initialValues.txn_id === "" &&
      initialValues.start_date === "" &&
      initialValues.end_date === ""
    ) {
      getAepsData(0); // Fetch data with reset filters
    }
  }, [initialValues]); // Dependency on `initialValues`
  const getAepsData = async (pageNum) => {
    setLoading(true);
    try {
      const res = await aepsTransctionFilter({
        ...initialValues,
        page: pageNum,
        count,
        type: 'CD'
      });
      if (res && res.data) {
        setAepsData(res);
      } else {
        setAepsData({ data: [] });
        // message.info('No data available');
      }
    } catch (error) {
      console.error("Error fetching AEPS data:", error);
      message.error('Failed to fetch transaction data');
      setAepsData({ data: [] });
    } finally {
      setLoading(false);
    }
  };

  const downloadExcel = async () => {
    try {
      const payload = {
        ...initialValues,
        type: 'CD'
      };
      setLoading(true);
      const res = await aepsTransctionAll({
        ...initialValues,

        type: 'CD'
      });

      if (res && res?.data?.dashboard && res?.data?.dashboard.length > 0) {
        const dataForExcel = res.data.dashboard.map((item, i) => ({
          "Sr. No": i + 1,
          "Date / Time": item?.createdAt,
          "Transaction ID": item?.txn_id,
          "Customer Mobile No": item?.customer_mobile || "",
          "Opening Balance": item?.opening_balance,
          "Account Balance": item?.bal_amount,
          "Deposite Amount": item?.amount,
          "Closing Balance": item?.closing_balance,
          "Bank Name": item?.bank_name,
          "Status": item?.response === 1 ? 'Pending' :
            item?.response === 2 ? 'Success' :
              item?.response === 3 ? 'Failed' :
                item?.response === 4 ? 'Refunded' : '',

                'Vendor': item.vender_name || "",
          "Message": item?.message || "",
          "Acknowledgment No": item?.ack_no || "",

          "Member Name": item?.user_name,
          "Member Code": item?.user_member_code,



          "Aadhar No": item?.adhaar_no,






          
          "Super Distributor Name": item?.master_dist_name || "",
"Super Distributor Mobile": item?.master_dist_mobile || "",
          "Super Distributor Code": item?.master_dist_refer_id || "",
          "Distributor Name": item?.distributor_name || "",
          "Distributor Mobile": item?.distributor_mobile || "",
          "Distributor Code": item?.distributor_refer_id || "",
          
          "Bank RRN": item?.bank_rrn || "",
         
          "Is Commison": item?.is_commission ? "Yes" : "No" || " ",
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "AEPS Cash Report");
        XLSX.writeFile(wb, `AEPS_Cash_Report_${new Date().toLocaleDateString()}.xlsx`);
      } else {
        message.error('No data available to export!');
      }
    } catch (error) {
      console.error("Error downloading Excel:", error);
      message.error('Failed to download Excel. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const onChangeVal = (e) => {
    setPage(e - 1);
    getAepsData(e - 1);
  };

  const handlePageSizeChange = (e) => {
    const newPageSize = parseInt(e.target.value);
    setCount(newPageSize);
    setPage(0);
    getAepsData(0);
  };

  useEffect(() => {
    getAepsData(0);
  }, []);


  const responseEnquiry = async (id) => {
    setLoading2(true); // Start loader before API call
    try {
      const res = await paymentEnquiryapes(id);
      if (res?.data?.statusCode == "200") {
        message.success(res?.data?.message || "Operation successful");
        getAepsData(page);
      } else if (res?.data?.statusCode == "300") {
        message.info(res?.data?.message || "Transaction requires attention");
        getAepsData(page);
      } else {
        message.error(res?.message || res?.data?.message || "Operation failed");
        getAepsData(page);
      }
    } catch (error) {
      console.error("Error in payment enquiry:", error);
      message.error("An error occurred while processing your request");
    } finally {
      setLoading2(false); // Stop loader after API call completes
    }
  };

  return (
    <>
      {loading && <Loadar />}
      <ToastContainer />
      <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />

      {/* Search Form */}
      <div className="row m-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects style-1">
                <div className="tbl-caption tbl-caption-2">
                  <h4 className="heading mb-0"><b>AEPS Cash Search</b></h4>
                </div>
                <Formik
                  initialValues={initialValues}
                  onSubmit={submitForm}
                  enableReinitialize
                >
                  {({ values, handleSubmit, resetForm }) => (
                    <Form className="tbl-captionn">
                      <div className="row">
                        <div className="col-xl-3 mb-3">
                          <Field
                            as={CustomInputField}
                            type="date"
                            placeholder="Start Date"
                            name="start_date"
                          />
                        </div>
                        <div className="col-xl-3 mb-3">
                          <Field
                            as={CustomInputField}
                            type="date"
                            placeholder="End Date"
                            name="end_date"
                          />
                        </div>
                        <div className="col-xl-3 mb-3">
                          <Field
                            as={CustomInputField}
                            type="text"
                            placeholder="Mobile No"
                            name="customer_mobile"
                          />
                        </div>
                        <div className="col-xl-3 mb-3">
                          <Field
                            as={CustomInputField}
                            type="text"
                            placeholder="Transaction ID"
                            name="txn_id"
                          />
                        </div>
                        <div className="col-xl-3 mb-3">
                          <Field
                            as={CustomInputField}
                            type="text"
                            placeholder="Adhaar No"
                            name="adhaar_no"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="d-flex justify-content-start">
                            <button className="btn btn-primary pd-x-20 me-2" type="submit">
                              <i className="fas fa-search"></i> Search
                            </button>
                            <button
                              className="btn btn-secondary pd-x-20"
                              type="button"
                              onClick={() => { resetForm(); resetFilters(); }}
                            >
                              <i className="fas fa-refresh"></i> Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Data Table */}
      <div className="row m-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects style-1">
                <div className="tbl-caption">
                  <h4 className="heading mb-0"><b>AEPS Cash List</b></h4>
                  <div>
                    <button className="btn btn-success" onClick={downloadExcel} style={{ background: 'green' }}>
                      Download Excel
                    </button>
                  </div>
                </div>
                <div className="p-3">
                  <h3>Total Amount: <b>{aepsData.totalAmount || 0}</b></h3>
                </div>

                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                  <table id="table-to-xls" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Date / Time</th>
                        <th>Transaction ID</th>
                        <th>Customer Mobile</th>
                        <th>Opening Balance</th>
                        <th>Account Balance</th>
                        <th>Deposite Amount</th>
                        <th>Closing Balance</th>
                        <th>Bank Name</th>
                        <th>Status</th>
                        <th>Vendor</th>
                        <th>Message</th>
                        <th> Acknowledgment No.</th>
                        <th>Member Name</th>
                        <th>Member Code</th>



                        <th>Adhaar No</th>



                        <th>Super Distributor</th>
                        <th>Super Distributor Contact</th>

                        <th>Super Distributor Refer ID</th>
                        <th>Distributor</th>
                        <th>Distributor Refer ID</th>
                        <th>Distributor Contact</th>
                        <th>Is commision</th>
                        <th>Enquery</th>






                      </tr>
                    </thead>
                    <tbody>
                      {aepsData?.data?.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1 + (page * count)}</td>
                          <td>{item?.createdAt}</td>
                          <td>{item?.txn_id}</td>
                          <td>{item?.customer_mobile}</td>
                          <td>{item?.opening_balance}</td>
                          <td>{item?.bal_amount}</td>
                          <td>{item?.amount}</td>
                          <td>{item?.closing_balance}</td>
                          <td>{item?.bank_name}</td>
                          <td>
                            <span className={`btn ${item?.response === 1 ? "btn-warning" :
                              item?.response === 2 ? "btn-success" :
                                item?.response === 3 ? "btn-danger" :
                                  item?.response === 4 ? "btn-info" : ""
                              } w-100`}>
                              {item?.response === 1 ? 'Pending' :
                                item?.response === 2 ? 'Success' :
                                  item?.response === 3 ? 'Failed' :
                                    item?.response === 4 ? 'Refunded' : ''}
                            </span>
                          </td>
                          <td>
                          {item?.vender_name}
                          </td>
                          <td>{item?.message}</td>
                          <td>{item?.ack_no}</td>
                          <td>{item?.user_name}</td>
                          <td>{item?.user_member_code}</td>



                          <td>{item?.adhaar_no}</td>



                          <td>{item?.master_dist_name}</td>
                          <td>{item?.master_dist_mobile || ""}</td>

                          <td>{item?.master_dist_refer_id || ""}</td>
                          <td>{item?.distributor_name}</td>
                          <td>{item?.distributor_refer_id || ""}</td>
                          <td>{item?.distributor_mobile || ""}</td>
                          <td>{item?.is_commission ? "Yes" : "No" || " "}</td>
                          <td><Button variant="outline-primary" onClick={() => responseEnquiry(item._id)}
                            disabled={item?.response == 2 || item?.response == 4}>
                            Enquiry
                          </Button></td>






                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
                  <div>Total {aepsData?.totalCount} entries</div>
                  <Pagination
                    defaultCurrent={1}
                    current={page + 1}
                    onChange={onChangeVal}
                    total={aepsData?.totalCount}
                    pageSize={count}
                  />
                </div>
                {aepsData?.totalAmount && (
                  <div className="p-3">
                    <h4>Total Amount: {aepsData.totalAmount}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AepsCashMain;