// import React, { useEffect, useState } from 'react'
// import Breadcrumbs from '../../common/breadcrumb/Breadcrumbs'
// import { getDataPacage, getDataPacageSales, getDataReports  ,getDataReportsAllexcel} from '../../api/login/Login'
// import SameCompFilter from './SameCompFilter'
// import ElectricListReport from './ElectricListReport'
// import { useParams } from 'react-router-dom'
// import Loadar from '../../common/loader/Loader'

// import * as XLSX from 'xlsx'; 

// import { message } from 'antd';


// function ElectricMainComp({ title_1, title_2, title }) {
//   const breadCrumbsTitle = {
//     id: "1",
//     title_1: title_1,
//     title_2: title_2,
//   }
//   const getCurrentDate = () => {
//     const today = new Date();
//     return today.toISOString().substr(0, 10);
// };

// const [currentDate, setCurrentDate] = useState(getCurrentDate());
//   const [count, setCount] = useState(10)
//   const [page, setPage] = useState(0)
//   const [aepsData, setAepsData] = useState()
//   const [loading, setLoading] = useState(false)
//   const [userData, setUserData] = useState(false)
//   const token = window.localStorage.getItem('userToken')
//   const param = useParams()
//   const [initialValues, setIntialValues] = useState({
//     name: "",
//     start_date: currentDate,
//     end_date: currentDate,
//     biller_id: "",
//     txn_id: "",
//     customer_mobile: "",
//     category_id: param.id
//   })
//   const [operatorId, setOpratorID] = useState("")


//   const handleChangeOp = (e) => {
//     setOpratorID(e.target.value)
//   }
//   const submitForm = async (values) => {
//     console.log(values);
    
//     const txnId = values.txn_id ? values.txn_id.trim() : '';
//     setLoading(true);
//     setIntialValues({
//       ...values,
//       count,
//       biller_id: operatorId,
//       txn_id: txnId,
//       start_date: values.start_date ? values.start_date : currentDate,
//       end_date: values.end_date ? values.end_date: currentDate,
//     });
//     setPage(0)
//     try {

//       const res = await getDataReports({ ...values, page: 0, count, txn_id: txnId, biller_id: operatorId, category_id: param.id });
//       setAepsData(res);
//     } catch (error) {
//       console.error("Error during AEPS transaction filter:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const getDmtTxnData = async (num) => {
//     setLoading(true)
//     const date = new Date();
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
//     const day = String(date.getDate()).padStart(2, '0');
    
//     try {
//       const res = await getDataReports({ ...initialValues, page: num, count, category_id: param.id });

//       setAepsData(res);
//       setLoading(false)
//     } catch (error) {

//     }
//   }
//   const downloadExcel = async () => {
//     try {
//       setLoading(true);

//       // Fetch Excel data from the API without `page` and `count`
//       const res = await getDataReportsAllexcel({
//         ...initialValues, // Include all filters from initialValues
//         biller_id: operatorId, // Include operator ID if necessary
//         category_id: param.id // Keep category ID from the URL params
//       });

//       if (res && res.data && res.data.length > 0) {
//         // Prepare data for Excel
//         const dataForExcel = res.data.map((item, i) => ({
//           "Sr. No": i + 1,
//           "Date / Time": item?.createdAt,
//           "Name": item?.name,
//           "Retailer code": item?.refer_id,
//           "Retailer Mobileno":item?.retailer_mobile,
//           "Transaction ID": item?.txn_id,
//           "Ref ID": item?.refId,
//           "Credit Card Number": item?.ca_num,
//           "Operator name": item?.operator_name,
//           "Operator ID": item?.biller_id,
//           "Transction  Reference ID": item?.txnReferenceceId,
//           "Updated ":item?.updatedAt,
//           "Amount": item?.amount,
//           "Status": item?.status === 1 ? 'Success' : item?.status === 2 ? 'Pending' : 'Failed',
//           'Distributor Name': item.distributor_name || "",
//           'Distributor Commission': item.distributor_commission || "",
//           'Super Distributor Name': item.master_dist_name || "",
//           'Super Distributor Commission': item.master_dist_commission || "",
//           'Distributor Contact': item.distributor_mobile || "",
//           'Super Distributor Contact': item.master_dist_mobile || "",
//           'Distributor Refer Id': item.distributor_refer_id || "",
//           'Super Distributor Refer Id': item.master_dist_refer_id || "",
//         }));

//         // Create a new workbook and add the data
//         const ws = XLSX.utils.json_to_sheet(dataForExcel);
//         const wb = XLSX.utils.book_new();
//         XLSX.utils.book_append_sheet(wb, ws, "Credit Report");

//         // Download the Excel file
//         XLSX.writeFile(wb, `Credit_Report_${new Date().toLocaleDateString()}.xlsx`);
//       } else {
//         console.error('No data available to download');
//         message.error('No data available to export!')
//       }

//     } catch (error) {
//       console.error("Error downloading Excel:", error);
//     } finally {
//       setLoading(false);
//     }
//   };
//   const handlePageSizeChange = (e) => {
//     const newPageSize = parseInt(e.target.value);
//     setCount(newPageSize); // Update count with the new page size
//     setPage(0); // Reset to first page
//     getDmtTxnData(0); // Fetch data with the new page size
//   };


//   const onChangeVal = (e) => {
//     setPage(e - 1)
//     setTimeout(() => {
//       getDmtTxnData(e - 1)
//     }, 1000);
//   };

//   useEffect(() => {
//     getDmtTxnData(0)
//   }, [])


  


//   return (
//     <>
//       {loading && <Loadar />}
//       <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />
//       <SameCompFilter title={title} initialValues={initialValues} userData={userData} submitForm={submitForm} aepsData={aepsData}   setIntialValues ={setIntialValues} getDmtTxnData={getDmtTxnData} />
//       <ElectricListReport title={title} onChangeVal={onChangeVal} aepsData={aepsData} getDmtTxnData={getDmtTxnData} downloadExcel={downloadExcel}  count={count} // Pass count (page size) to the report component
//         handlePageSizeChange={handlePageSizeChange} />
        
//     </>
//   )
// }

// export default ElectricMainComp

import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../common/breadcrumb/Breadcrumbs'
import { getDataPacage, getDataPacageSales, getDataReports  ,getDataReportsAllexcel,paymentEnquirybbps} from '../../api/login/Login'
import SameCompFilter from './SameCompFilter'
import ElectricListReport from './ElectricListReport'
import { useParams } from 'react-router-dom'
import Loadar from '../../common/loader/Loader'

import * as XLSX from 'xlsx'; 

import { message } from 'antd';

function ElectricMainComp({ title_1, title_2, title }) {
  const breadCrumbsTitle = {
    id: "1",
    title_1: title_1,
    title_2: title_2,
  };

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().substr(0, 10);
  };

  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [aepsData, setAepsData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [userData, setUserData] = useState(false);
  const token = window.localStorage.getItem('userToken');
  const param = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    start_date: currentDate,
    end_date: currentDate,
    biller_id: "",
    txn_id: "",
    customer_mobile: "",
    category_id: param.id,
    user_id: "",
    member_code: "",
    member_name: "",
    operator_id: "",
  });

  const submitForm = async (values) => {
    console.log(values);
    
    const txnId = values.txn_id ? values.txn_id.trim() : '';
    setLoading(true);
    setInitialValues({
      ...values,
      count,
      txn_id: txnId,
      start_date: values.start_date ? values.start_date : currentDate,
      end_date: values.end_date ? values.end_date : currentDate,
    });
    setPage(0);
    try {
      const res = await getDataReports({ 
        ...values, 
        page: 0, 
        count, 
        txn_id: txnId, 
        category_id: param.id,
      });
      if (res && res.data) {
        setAepsData(res);
      } else {
        setAepsData({ data: [] });
        message.info('No data available');
      }
    } catch (error) {
      console.error("Error during AEPS transaction filter:", error);
      message.error('Failed to fetch data. Please try again.');
      setAepsData({ data: [] });
    } finally {
      setLoading(false);
    }
  };

  const getDmtTxnData = async (num) => {
    setLoading(true);
    try {
      const res = await getDataReports({ 
        ...initialValues, 
        page: num, 
        count, 
        category_id: param.id 
      });
      if (res && res.data) {
        setAepsData(res);
      } else {
        setAepsData({ data: [] });
        message.info('No data available');
      }
    } catch (error) {
      console.error("Error fetching DMT transaction data:", error);
      message.error('Failed to fetch transaction data. Please try again.');
      setAepsData({ data: [] });
    } finally {
      setLoading(false);
    }
  };

  const downloadExcel = async () => {
    try {
      setLoading(true);
      const res = await getDataReportsAllexcel({
        ...initialValues,
        category_id: param.id
      });

      if (res && res.data && res.data.length > 0) {
        const dataForExcel = res.data.map((item, i) => ({
        "Sr. No": i + 1,
          "Date / Time": item?.createdAt,
          "Transaction ID": item?.txn_id,
          "Opening Balance": item?.open_bal,
          "Amount": item?.amount,
          "Closing Balance":item?.close_bal,
          "Status": item?.status === 2 ? 'Success' :
          item?.status === 1 ? 'Pending' :
          item?.status === 3 ? 'Failed' :
          item?.status === 4 ? 'Refund' :
          'Unknown',
          "Operator name": item?.operator_name,
          "Card Number": item?.ca_num,
          "Operator ID": item?.biller_id,
          "Name": item?.name,
          "Retailer code": item?.refer_id,
          "Retailer Mobileno": item?.retailer_mobile,
          'Distributor Name': item.distributor_name || "",
          'Distributor Commission': item.distributor_commission || "",
          'Distributor Contact': item.distributor_mobile || "",
          'Distributor Refer Id': item.distributor_refer_id || "",
          'Super Distributor Name': item.master_dist_name || "",
          'Super Distributor Commission': item.master_dist_commission || "",
       
          'Super Distributor Contact': item.master_dist_mobile || "",
         
          'Super Distributor Refer Id': item.master_dist_refer_id || "",
          "Ref ID": item?.refId,
          
          
          
          "Transction  Reference ID": item?.txnReferenceceId,
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Elelctric city Report");
        XLSX.writeFile(wb, `Elelctric_city_Report_${new Date().toLocaleDateString()}.xlsx`);
      } else {
        message.error('No data available to export!');
      }
    } catch (error) {
      console.error("Error downloading Excel:", error);
      message.error('Failed to download Excel. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageSizeChange = (e) => {
    const newPageSize = parseInt(e.target.value);
    setCount(newPageSize);
    setPage(0);
    getDmtTxnData(0);
  };

  const onChangeVal = (e) => {
    setPage(e - 1);
    setTimeout(() => {
      getDmtTxnData(e - 1);
    }, 1000);
  };

  useEffect(() => {
    getDmtTxnData(0);
  }, []);

  const responseEnquiry = async (id) => {
    setLoading2(true); // Start loader before API call
    try {
        const res = await paymentEnquirybbps(id);
        if (res?.data?.statusCode == "200") {
            message.success(res?.data?.message || "Operation successful");
            getDmtTxnData(page);
        } else if (res?.data?.statusCode == "300") {
            message.info(res?.data?.message || "Transaction requires attention");
            getDmtTxnData(page);
        } else {
            message.error(res?.message || res?.data?.message || "failed");
            getDmtTxnData(page);
        }
    } catch (error) {
        console.error("Error in payment enquiry:", error);
        message.error("An error occurred while processing your request");
    } finally {
        setLoading2(false); // Stop loader after API call completes
    }
};

  return (
    <>
      {loading && <Loadar />}
      <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />
      <SameCompFilter 
        title={title} 
        initialValues={initialValues} 
        userData={userData} 
        submitForm={submitForm} 
        aepsData={aepsData}   
        setInitialValues={setInitialValues} 
        getDmtTxnData={getDmtTxnData} 
      />
      {aepsData.data.length > 0 ? (
        <ElectricListReport 
          title={title} 
          onChangeVal={onChangeVal} 
          aepsData={aepsData} 
          getDmtTxnData={getDmtTxnData} 
          downloadExcel={downloadExcel}  
          count={count}
          handlePageSizeChange={handlePageSizeChange} 
          responseEnquiry ={responseEnquiry }
          loading2={loading2}
        />
      ) : (
        <div className="text-center mt-4">
          <h3>No data available</h3>
        </div>
      )}
    </>
  );
}

export default ElectricMainComp;
