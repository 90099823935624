import React from 'react'
import VirtualWallet from '../../components/virtualWallet/VirtualWallet'

function WallRep() {
  return (
    <>
    <VirtualWallet/>
    </>

  )
}

export default WallRep