
import Breadcrumbs from '../../../../common/breadcrumb/Breadcrumbs';
import DmtCommisionStruclist from './DmtCommisionlist';


function DmtCommisionpStuc() {
    return (
        <>
            <Breadcrumbs breadCrumbsTitle={""} />
            <DmtCommisionStruclist />
        </>
    )
}

export default DmtCommisionpStuc