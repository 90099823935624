import React from 'react'
import TypeReason from '../../../components/dmtModule/typeReason/TypeReason'

function ReasonTypePages() {
  return (
    <>
      <TypeReason/>
    </>
  )
}

export default ReasonTypePages
