// import React from 'react';
// import { Card, Col, Table, Alert, Typography } from 'antd';

// const { Title } = Typography;

// const ServicesList = ({ services }) => {
//   // Safely handle undefined or null services
//   const safeServices = Array.isArray(services) ? services : [];

//   // Memoize column definitions to prevent unnecessary re-renders
//   const columns = React.useMemo(() => [
//     {
//       title: 'Service Name',
//       dataIndex: 'service_name',
//       key: 'service_name',
//       render: (text) => text || 'Unknown Service'
//     },
//     {
//       title: 'Pending Amount',
//       dataIndex: 'pendingTotalAmount',
//       key: 'pendingTotalAmount',
//       render: (value) => {
//         // Safely convert to number, default to 0
//         const amount = Number(value);
//         return isNaN(amount) ? '₹0' : `₹${amount.toLocaleString()}`;
//       }
//     },
//     {
//       title: 'Success Amount',
//       dataIndex: 'successTotalAmount',
//       key: 'successTotalAmount',
//       render: (value) => {
//         // Safely convert to number, default to 0
//         const amount = Number(value);
//         return isNaN(amount) ? '₹0' : `₹${amount.toLocaleString()}`;
//       }
//     },
//     {
//       title: 'Failed Amount',
//       dataIndex: 'failedTotalAmount',
//       key: 'failedTotalAmount',
//       render: (value) => {
//         // Safely convert to number, default to 0
//         const amount = Number(value);
//         return isNaN(amount) ? '₹0' : `₹${amount.toLocaleString()}`;
//       }
//     },
//     {
//       title: 'Total Amount',
//       key: 'totalAmount',
//       render: (_, record) => {
//         // Calculate total amount safely
//         const pendingAmount = Number(record.pendingTotalAmount) || 0;
//         const successAmount = Number(record.successTotalAmount) || 0;
//         const failedAmount = Number(record.failedTotalAmount) || 0;
        
//         const totalAmount = pendingAmount + successAmount + failedAmount;
//         return `₹${totalAmount.toLocaleString()}`;
//       }
//     }
//   ], []);

//   // Memoize row key to prevent warnings
//   const getRowKey = React.useCallback((record) => {
//     return record._id || record.service_name || Math.random().toString(36).substr(2, 9);
//   }, []);

//   // Render nothing if no services
//   if (safeServices.length === 0) {
//     return (
//       <Card className="services-card">
//         <Alert 
//           message="No Services Data" 
//           description="There are currently no services to display." 
//           type="info" 
//         />
//       </Card>
//     );
//   }

//   return (
//     <Card 
//       className="services-card"
//       title={
//         <Title level={4}>
//           Services Overview ({safeServices.length} Total)
//         </Title>
//       }
//     >
//       <Table 
//         dataSource={safeServices}
//         columns={columns}
//         rowKey={getRowKey}
//         pagination={{
//           pageSize: 10,
//           showSizeChanger: true,
//           pageSizeOptions: [5, 10, 20, 50],
//           showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} services`
//         }}
//         scroll={{ x: 800 }}
//         size="small"
//       />
//     </Card>
//   );
// };

// // Export the component
// export default React.memo(ServicesList);
import React, { useState, useMemo } from 'react';
import { 
  Card, 
  Row, 
  Col, 
  Typography, 
  Space, 
  Badge, 
  Pagination, 
  Alert, 
  Progress 
} from 'antd';
import { 
    SettingOutlined,
  
} from '@ant-design/icons';

const { Title, Text } = Typography;

const ServicesList = ({ services = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const servicesPerPage = 8;

  // Predefined service icons mapping
//   const serviceIcons = {
//     'DMT': DollarCircleOutlined,
//     'Cash Deposit': BankOutlined,
//     'Cash Withdrawal': WalletOutlined,
//     'Aadhar Pay': IdcardOutlined,
//     'CMS': CreditCardOutlined,
//     'Payment Request': PayCircleOutlined,
//     'Payout': TransactionOutlined,
//     'Recharge': BarcodeOutlined,
//     'DTH': GlobalOutlined,
//     'Electricity': WifiOutlined,
//     'Credit Card': CreditCardOutlined,
//     'Broadband': WifiOutlined,
//     'LPG Gas': CarOutlined,
//     'Rental': HomeOutlined,
//     'Hospital': MedicineBoxOutlined,
//     'Life Insurance': HeartOutlined,
//     'Health Insurance': HeartOutlined,
//     'Education Fees': BookOutlined,
//     'Municipal Services': CoffeeOutlined,
//     'Water': WaterOutlined,
//     'Metro Recharge': TrainOutlined,
//     'Fastag': CarOutlined,
//     'Donation': TrophyOutlined
//   };

  // Safe service data extraction
  const safeGetValue = (obj, path, defaultValue = 0) => {
    try {
      return path.split('.').reduce((acc, key) => 
        (acc && acc[key] !== undefined) ? acc[key] : defaultValue, obj);
    } catch (error) {
      console.error(`Error accessing path ${path}:`, error);
      return defaultValue;
    }
  };

  // Render individual service card
  const renderServiceCard = (service) => {
    try {
      const serviceName = safeGetValue(service, 'service_name', 'Unknown Service');
      const totalAmount = safeGetValue(service, 'totalAmount', 0);
      const successAmount = safeGetValue(service, 'successTotalAmount', 0);
      const failedAmount = safeGetValue(service, 'failedTotalAmount', 0);
      const pendingAmount = safeGetValue(service, 'pendingTotalAmount', 0);

      // Dynamic icon selection
      const ServiceIcon = SettingOutlined;

      const successRate = totalAmount 
        ? ((successAmount / totalAmount) * 100).toFixed(1) 
        : 0;

      const cardColors = [
        '#1890ff', '#52c41a', '#722ed1', '#fa8c16', 
        '#eb2f96', '#13c2c2', '#faad14', '#2f54eb'
      ];
      const color = cardColors[Math.floor(Math.random() * cardColors.length)];

      return (
        <Col xs={24} sm={12} lg={6} key={service._id || serviceName}>
          <Card 
            className="service-metric-card"
            title={
              <Space>
                <ServiceIcon style={{ fontSize: '20px', color }} />
                <Text strong>{serviceName}</Text>
              </Space>
            }
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div style={{ textAlign: 'center', marginBottom: 16 }}>
                  <Text strong style={{ fontSize: 18, color }}>
                    ₹{totalAmount.toLocaleString()}
                  </Text>
                  <Progress 
                    percent={Number(successRate)} 
                    strokeColor={color}
                    size="small"
                    className="mt-2"
                  />
                </div>
              </Col>
              <Col span={24}>
                <Row gutter={16} justify="space-between">
                  <Col span={8} style={{ textAlign: 'center' }}>
                    <Badge status="success" text="Success" />
                    <div style={{ fontWeight: 'bold', color: '#52c41a' }}>
                      {successAmount}
                    </div>
                  </Col>
                  <Col span={8} style={{ textAlign: 'center' }}>
                    <Badge status="error" text="Failed" />
                    <div style={{ fontWeight: 'bold', color: '#ff4d4f' }}>
                      {failedAmount}
                    </div>
                  </Col>
                  <Col span={8} style={{ textAlign: 'center' }}>
                    <Badge status="warning" text="Pending" />
                    <div style={{ fontWeight: 'bold', color: '#faad14' }}>
                      {pendingAmount}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      );
    } catch (error) {
      console.error('Error rendering service card:', error);
      return null;
    }
  };

  // Paginated services
  const paginatedServices = useMemo(() => {
    const startIndex = (currentPage - 1) * servicesPerPage;
    return services.slice(startIndex, startIndex + servicesPerPage);
  }, [services, currentPage]);

  // Render component
  if (!services || services.length === 0) {
    return (
      <Card>
        <Alert 
          message="No Services Available" 
          description="There are currently no services to display." 
          type="info" 
          showIcon 
        />
      </Card>
    );
  }

  return (
    <Card 
      title={
        <Title level={4}>
          {/* Services Overview ({services.length} Total Services) */}
          BBPS Services 
        </Title>
      }
    >
      <Row gutter={[16, 16]}>
        {paginatedServices.map(renderServiceCard)}
      </Row>
      
      <Row justify="center" style={{ marginTop: 20 }}>
        <Pagination 
          current={currentPage}
          total={services.length}
          pageSize={servicesPerPage}
          onChange={setCurrentPage}
          showSizeChanger={false}
          showQuickJumper={false}
        />
      </Row>

      <style jsx>{`
        .service-metric-card {
          margin-bottom: 16px;
          border-radius: 12px;
          box-shadow: 0 2px 12px rgba(0,0,0,0.03);
          transition: all 0.3s ease;
        }
        .service-metric-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 16px rgba(0,0,0,0.06);
        }
      `}</style>
    </Card>
  );
};

export default React.memo(ServicesList);