


import React, { useState, useEffect } from 'react';
import { message, Pagination, Button } from 'antd';
import * as XLSX from 'xlsx';
import { ToastContainer } from 'react-toastify';
import Breadcrumbs from '../../common/breadcrumb/Breadcrumbs';

import Loadar from '../../common/loader/Loader';
import CustomInputField from '../../common/CustomInputField';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { airtelcmsTransctionFilter, airtelExcelcmsTransctionFilter, paymentEnquirycms } from '../../api/login/Login';

function Airtelcms() {
    const breadCrumbsTitle = {
        id: "1",
        title_1: "Master",
        title_2: "Airtel Cms Report",
    };

    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().substr(0, 10);
    };

    const [currentDate, setCurrentDate] = useState(getCurrentDate());
    const [count, setCount] = useState(10);
    const [page, setPage] = useState(0);
    const [aepsData, setAepsData] = useState({ data: [] });
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const token = window.localStorage.getItem('userToken');

    const [initialValues, setInitialValues] = useState({
        customer_mobile: "",

        txn_id: "",
        start_date: currentDate,
        end_date: currentDate,
        user_name: "",

        user_id: token,
    });

    const submitForm = async (values) => {
        const mobileNo = values.customer_mobile ? values.customer_mobile.trim() : '';
        const txnId = values.txn_id ? values.txn_id.trim() : '';
        setLoading(true);
        setInitialValues({
            ...values,
            count,
            customer_mobile: mobileNo,
            txn_id: txnId,
            start_date: values.start_date ? values.start_date : currentDate,
            end_date: values.end_date ? values.end_date : currentDate,
        });
        setPage(0);

        try {
            const res = await airtelcmsTransctionFilter({
                ...values,
                page: 0,
                count,
                customer_mobile: mobileNo,
                txn_id: txnId
            });
            if (res && res.data) {
                setAepsData(res);
            } else {
                setAepsData({ data: [] });
                message.info('No data available');
            }
        } catch (error) {
            console.error("Error during AEPS transaction filter:", error);
            message.error('Failed to fetch data. Please try again.');
            setAepsData({ data: [] });
        } finally {
            setLoading(false);
        }
    };
    // const resetFilters = () => {
    //   setInitialValues({
    //     ...initialValues,
    //     customer_mobile: "",
    //     adhaar_no: "",
    //     txn_id: "",
    //     start_date: "",
    //     end_date: "",
    //   });
    //   setPage(0);
    //   getAepsData(0);
    // };
    const resetFilters = (resetForm) => {
        const resetValues = {
            customer_mobile: "",
            txn_id: "",
            start_date: "",
            end_date: "",
            user_name: "",
            user_id: token,
        };
    
        // Reset form values and trigger API fetch
        resetForm({ values: resetValues });
        setInitialValues(resetValues);
        setPage(0);
        getAepsData(0); // Fetch data with reset filters
    };
    


    // Trigger API call when `initialValues` changes due to reset
    useEffect(() => {
        if (
            initialValues.customer_mobile === "" &&
            initialValues.adhaar_no === "" &&
            initialValues.txn_id === "" &&
            initialValues.start_date === "" &&
            initialValues.end_date === ""
        ) {
            getAepsData(0); // Fetch data with reset filters
        }
    }, [initialValues]); // Dependency on `initialValues`
    const getAepsData = async (pageNum) => {
        setLoading(true);
        try {
            const res = await airtelcmsTransctionFilter({
                ...initialValues,
                page: pageNum,
                count,

            });
            if (res && res.data) {
                setAepsData(res);
            } else {
                setAepsData({ data: [] });
                // message.info('No data available');
            }
        } catch (error) {
            console.error("Error fetching AEPS data:", error);
            message.error('Failed to fetch transaction data');
            setAepsData({ data: [] });
        } finally {
            setLoading(false);
        }
    };

    const downloadExcel = async () => {
        try {
            setLoading(true);
            const res = await airtelExcelcmsTransctionFilter({
                ...initialValues,
            });
            console.log("res", res);

            // Check if data exists and has entries
            if (res && res.data?.data && res.data.data.length > 0) {
                const dataForExcel = res.data.data.map((item, i) => ({
                    "Sr. No": i + 1,
                    "User ID": item?.user_id || "",
                    "Transaction ID": item?.txn_id || "",
                    "Longitude": item?.long || "",
                    "Latitude": item?.lat || "",
                    "Wallet Debited": item?.wallet_debited ? "Yes" : "No",
                    //   "Status": item?.status === 1 ? "Active" : "Inactive",
                    "Status": item?.status === 1 ? 'Pending' :
                        item?.status === 2 ? 'Success' :
                            item?.status === 3 ? 'Failed' :
                                item?.status === 4 ? 'Refunded' : '',
                    //   "Transaction Status": item?.txn_status || "",
                    "User Name": item?.userName || "",
                    "User Mobile": item?.userMobile || "",
                }));

                const ws = XLSX.utils.json_to_sheet(dataForExcel);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Airtel CMS Report");
                XLSX.writeFile(wb, `Airtel_CMS_Report_${new Date().toLocaleDateString()}.xlsx`);
            } else {
                message.error("No data available to export!");
            }
        } catch (error) {
            console.error("Error downloading Excel:", error);
            message.error("Failed to download Excel. Please try again.");
        } finally {
            setLoading(false);
        }
    };



    const onChangeVal = (e) => {
        setPage(e - 1);
        getAepsData(e - 1);
    };

    const handlePageSizeChange = (e) => {
        const newPageSize = parseInt(e.target.value);
        setCount(newPageSize);
        setPage(0);
        getAepsData(0);
    };

    useEffect(() => {
        getAepsData(0);
    }, []);

    const responseEnquiry = async (id) => {
        setLoading2(true); // Start loader before API call
        try {
            const res = await paymentEnquirycms(id);
            if (res?.data?.statusCode == "200") {
                message.success(res?.data?.message || "Operation successful");
                getAepsData(page);
            } else if (res?.data?.statusCode == "300") {
                message.info(res?.data?.message || "Transaction requires attention");
                getAepsData(page);
            } else {
                message.error(res?.message || res?.data?.message || "Operation failed");
                getAepsData(page);
            }
        } catch (error) {
            console.error("Error in payment enquiry:", error);
            message.error("An error occurred while processing your request");
        } finally {
            setLoading2(false); // Stop loader after API call completes
        }
    };
    console.log("aepsData", aepsData)

    return (
        <>
            {loading && <Loadar />}
            <ToastContainer />
            <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />

            {/* Search Form */}
            <div className="row m-4">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div className="tbl-caption tbl-caption-2">
                                    <h4 className="heading mb-0"><b>AEPS Cash Search</b></h4>
                                </div>
                                <Formik
    initialValues={initialValues}
    onSubmit={submitForm}
    enableReinitialize
>
    {({ values, handleSubmit, resetForm }) => (
        <Form className="tbl-captionn">
            <div className="row">
                <div className="col-xl-3 mb-3">
                    <Field
                        as={CustomInputField}
                        type="date"
                        placeholder="Start Date"
                        name="start_date"
                    />
                </div>
                <div className="col-xl-3 mb-3">
                    <Field
                        as={CustomInputField}
                        type="date"
                        placeholder="End Date"
                        name="end_date"
                    />
                </div>
                <div className="col-xl-3 mb-3">
                    <Field
                        as={CustomInputField}
                        type="text"
                        placeholder="Mobile No"
                        name="customer_mobile"
                    />
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="d-flex justify-content-start">
                        <button className="btn btn-primary pd-x-20 me-2" type="submit">
                            <i className="fas fa-search"></i> Search
                        </button>
                        <button
                            className="btn btn-secondary pd-x-20"
                            type="button"
                            onClick={() => resetFilters(resetForm)}
                        >
                            <i className="fas fa-refresh"></i> Reset
                        </button>
                    </div>
                </div>
            </div>
        </Form>
    )}
</Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Data Table */}
            <div className="row m-4">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div className="tbl-caption">
                                    <h4 className="heading mb-0"><b>AEPS Cash List</b></h4>
                                    <div>
                                        <button className="btn btn-success" onClick={downloadExcel} style={{ background: 'green' }}>
                                            Download Excel
                                        </button>
                                    </div>
                                </div>
                                <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                    <table id="table-to-xls" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Date / Time</th>
                                                <th>Transaction ID</th>
                                                <th>Customer Mobile</th>
                                                <th>Longitude</th>
                                                <th>Latitude</th>
                                                <th>Wallet Debited</th>
                                                {/* <th>Transaction Status</th> */}
                                                <th>User Name</th>
                                                <th>User Mobile</th>
                                                <th>Status</th>
                                                {/* <th>Member Code</th>
                                                <th>Distributor</th>
                                                <th>Super Distributor</th> */}
                                                <th>Enquiry</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(aepsData?.data?.data) && aepsData.data.data.map((item, i) => (
                                                <tr key={i}>
                                                    <td>{i + 1 + (page * count)}</td>
                                                    <td>{item?.createdAt}</td>
                                                    <td>{item?.txn_id}</td>
                                                    <td>{item?.customer_mobile}</td>
                                                    <td>{item?.long}</td>
                                                    <td>{item?.lat}</td>
                                                    <td>{item?.wallet_debited ? "Yes" : "No"}</td>
                                                    {/* <td>{item?.txn_status}</td> */}
                                                    <td>{item?.userName}</td>
                                                    <td>{item?.userMobile}</td>
                                                    <td>{item?.status == 1 ? 'Pending' : item?.status == 2 ? 'Success' : item?.status == 3 ? 'Failed' : 'Refunded'}</td>
                                                    {/* <td>{item?.user_member_code}</td>
                                                    <td>{item?.distributor_name}</td>
                                                    <td>{item?.master_dist_name}</td> */}
                                                    <td>
                                                        <Button
                                                            variant="outline-primary"
                                                            onClick={() => responseEnquiry(item._id)}
                                                            disabled={loading2 || item?.status === 2 || item?.status === 4}>
                                                            Enquiry
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
                                    <div>Total {aepsData?.totalCount} entries</div>
                                    <Pagination
                                        defaultCurrent={1}
                                        current={page + 1}
                                        onChange={onChangeVal}
                                        total={aepsData?.totalCount}
                                        pageSize={count}
                                    />
                                </div>
                                {aepsData?.totalAmount && (
                                    <div className="p-3">
                                        <h4>Total Amount: {aepsData.totalAmount}</h4>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Airtelcms;
