import React from 'react'
import ChargesPayout from '../../components/chargesPayout/ChargesPayout'

function PayoutReportsPage() {
  return (
    <>
      <ChargesPayout/>
    </>
  )
}

export default PayoutReportsPage
