import React from 'react'
import AepsTezReport from '../../../components/additionalReports/aepsTezReport/AepsTezReport'

function AepsTezRepPage() {
  return (
    <>
    <AepsTezReport/>
    </>
  )
}

export default AepsTezRepPage