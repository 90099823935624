import React from 'react'
import AepsXpressRep from '../../../components/additionalReports/aepsXpressReport/AepsXpressRep'

function AepsXpressPage() {
  return (
    <>
    <AepsXpressRep/>
    </>
  )
}

export default AepsXpressPage