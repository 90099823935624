import React from 'react'
import BankLists from './bankLists/BankLists'
import Breadcrumbs from '../../../common/breadcrumb/Breadcrumbs'

function BankList() {
  return (
    <>
    <Breadcrumbs breadCrumbsTitle={""}/>
    <BankLists/>
    </>

  )
}

export default BankList