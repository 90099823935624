import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../common/breadcrumb/Breadcrumbs'
import { getDataReports, getDataReportsAllexcel ,paymentEnquirybbps } from '../../api/login/Login'
import { useParams } from 'react-router-dom'
import Loadar from '../../common/loader/Loader'
import CreditFilter from './CreditFilter'
import CreditReport from './CreditReport'
import * as XLSX from 'xlsx'; 
import { Button } from 'react-bootstrap';
import { message } from 'antd';

function CreditMain({ title_1, title_2, title }) {
  const breadCrumbsTitle = {
    id: "1",
    title_1: title_1,
    title_2: title_2,
  };

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().substr(0, 10);
  };

  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [aepsData, setAepsData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [userData, setUserData] = useState(false);
  const token = window.localStorage.getItem('userToken');
  const param = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    start_date: currentDate,
    end_date: currentDate,
    biller_id: "",
    txn_id: "",
    customer_mobile: "",
    category_id: param.id,
    user_id: "",
    member_code: "",
    member_name: "",
    operator_id: "",
  });

  const submitForm = async (values) => {
    console.log(values);
    
    const txnId = values.txn_id ? values.txn_id.trim() : '';
    setLoading(true);
    setInitialValues({
      ...values,
      count,
      txn_id: txnId,
      start_date: values.start_date ? values.start_date : currentDate,
      end_date: values.end_date ? values.end_date : currentDate,
    });
    setPage(0);
    try {
      const res = await getDataReports({ 
        ...values, 
        page: 0, 
        count, 
        txn_id: txnId, 
        category_id: param.id,
      });
      if (res && res.data) {
        setAepsData(res);
      } else {
        setAepsData({ data: [] });
        message.info('No data available');
      }
    } catch (error) {
      console.error("Error during AEPS transaction filter:", error);
      message.error('Failed to fetch data. Please try again.');
      setAepsData({ data: [] });
    } finally {
      setLoading(false);
    }
  };

  const getDmtTxnData = async (num) => {
    setLoading(true);
    try {
      const res = await getDataReports({ 
        ...initialValues, 
        page: num, 
        count, 
        category_id: param.id 
      });
      if (res && res.data) {
        setAepsData(res);
      } else {
        setAepsData({ data: [] });
        message.info('No data available');
      }
    } catch (error) {
      console.error("Error fetching DMT transaction data:", error);
      message.error('Failed to fetch transaction data. Please try again.');
      setAepsData({ data: [] });
    } finally {
      setLoading(false);
    }
  };

  const downloadExcel = async () => {
    try {
      setLoading(true);
      const res = await getDataReportsAllexcel({
        ...initialValues,
        category_id: param.id
      });

      if (res && res.data && res.data.length > 0) {
        const dataForExcel = res.data.map((item, i) => ({
          "Sr. No": i + 1,
          "Date / Time": item?.createdAt,
          "Transaction ID": item?.txn_id,
          "Opening Balance": item?.open_bal,
          "Amount": item?.amount,
          "Charge": item?.charge,
          "Closing Balance":item?.close_bal,
          "Status": item?.status === 2 ? 'Success' :
          item?.status === 1 ? 'Pending' :
          item?.status === 3 ? 'Failed' :
          item?.status === 4 ? 'Refund' :
          'Unknown',
          "Operator name": item?.operator_name,
          "Card Number": item?.ca_num,
          "Operator ID": item?.biller_id,
          "Name": item?.name,
          "Retailer code": item?.refer_id,
          "Retailer Mobileno": item?.retailer_mobile,
          'Distributor Name': item.distributor_name || "",
          'Distributor Commission': item.distributor_commission || "",
          'Distributor Contact': item.distributor_mobile || "",
          'Distributor Refer Id': item.distributor_refer_id || "",
          'Super Distributor Name': item.master_dist_name || "",
          'Super Distributor Commission': item.master_dist_commission || "",
       
          'Super Distributor Contact': item.master_dist_mobile || "",
         
          'Super Distributor Refer Id': item.master_dist_refer_id || "",
          "Ref ID": item?.refId,
          
          
          
          "Transction  Reference ID": item?.txnReferenceceId,
          
         
          
          
          
          
         
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Credit Report");
        XLSX.writeFile(wb, `Credit_Report_${new Date().toLocaleDateString()}.xlsx`);
      } else {
        message.error('No data available to export!');
      }
    } catch (error) {
      console.error("Error downloading Excel:", error);
      message.error('Failed to download Excel. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageSizeChange = (e) => {
    const newPageSize = parseInt(e.target.value);
    setCount(newPageSize);
    setPage(0);
    getDmtTxnData(0);
  };

  const onChangeVal = (e) => {
    setPage(e - 1);
    setTimeout(() => {
      getDmtTxnData(e - 1);
    }, 1000);
  };

  useEffect(() => {
    getDmtTxnData(0);
  }, []);


  const responseEnquiry = async (id) => {
    setLoading2(true); // Start loader before API call
    try {
        const res = await paymentEnquirybbps(id);
        if (res?.data?.statusCode == "200") {
            message.success(res?.data?.message || "Operation successful");
            getDmtTxnData(page);
        } else if (res?.data?.statusCode == "300") {
            message.info(res?.data?.message || "Transaction requires attention");
            getDmtTxnData(page);
        } else {
            message.error(res?.message || res?.data?.message || "Operation failed");
            getDmtTxnData(page);
        }
    } catch (error) {
        console.error("Error in payment enquiry:", error);
        message.error("An error occurred while processing your request");
    } finally {
        setLoading2(false); // Stop loader after API call completes
    }
};

  return (
    <>
      {loading && <Loadar />}
      <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />
      <CreditFilter 
        title={title} 
        initialValues={initialValues} 
        userData={userData} 
        submitForm={submitForm} 
        aepsData={aepsData}   
        setInitialValues={setInitialValues} 
        getDmtTxnData={getDmtTxnData} 
      />
      {aepsData.data.length > 0 ? (
        <CreditReport 
          title={title} 
          onChangeVal={onChangeVal} 
          aepsData={aepsData} 
          getDmtTxnData={getDmtTxnData} 
          downloadExcel={downloadExcel}  
          count={count}
          handlePageSizeChange={handlePageSizeChange} 
          responseEnquiry ={responseEnquiry }
          loading2={loading2}
        />
      ) : (
        <div className="text-center mt-4">
          <h3>No data available</h3>
        </div>
      )}
    </>
  );
}

export default CreditMain;