import React from 'react'
import InsuranceDekhoReports from '../../../components/additionalReports/insurenceDekhoReport/InsuranceDekhoReports'

function InsurenceDekhoPage() {
  return (
    <>
    <InsuranceDekhoReports/>
    </>

  )
}

export default InsurenceDekhoPage