import React from 'react'
import AepsPayout from '../../components/aeps-payout/AepsPayout'

function AepsPayoutReport() {
  return (
    <>
      <AepsPayout/>
    </>
  )
}

export default AepsPayoutReport
