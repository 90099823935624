import React from 'react'
import SalesReports from '../../components/salesReports/SalesReports'

function SalesReportsPage() {
  return (
    <>
      <SalesReports/>
    </>
  )
}

export default SalesReportsPage
