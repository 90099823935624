import React from 'react'
import BankList from '../../../components/additionalReports/bankList/BankList'

function BankListPage() {
    return (
        <>
            <BankList />
        </>
    )
}

export default BankListPage