import { useEffect, useState } from "react";
import Breadcrumbs from "../../common/breadcrumb/Breadcrumbs";
import PaymentRequestViewForm from "./paymentRequestViewForm/PaymentRequestViewForm";
import PaymentRequestViewList from "./paymentRequestViewList/PaymentRequestViewList";
import { fillterRequestPayment, usersList ,fillterRequestPaymentAllexcel } from "../../api/login/Login";
import { Pagination , message,} from "antd";
import * as XLSX from 'xlsx';
const breadCrumbsTitle = {
    id: "1",
    title_1: "Payment",
    title_2: "Payment Request View",
};

function PaymentRequestView() {
    const [state, setState] = useState([]);
    const [count, setCount] = useState(10);
    const [userData, setUserData] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filterValues, setFilterValues] = useState({}); 
    const token = window.localStorage.getItem("userToken");

    // Submitting the form and filtering
    const submitForm = async (values) => {
        setLoading(true);
        setFilterValues(values); // Store filter values for later use in pagination
        try {
            const res = await fillterRequestPayment({ ...values, page: 0, count, token }); // Use page 0 when submitting the form
            setState(res?.data);
            setPage(0); // Reset the page to 0 after submitting the form
            setLoading(false);
        } catch (error) {
            console.error("Error submitting form:", error);
            setLoading(false);
        }
    };

    // Paginating using the existing filter values
    const getPaymentRequest = async (num ,str) => {
        
        setLoading(true);
        if (str) {
            try {
                const res = await fillterRequestPayment({page: 0, count :10, token }); // Pass filter values when paginating
                setState(res?.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching payment requests:", error);
                setLoading(false);
            }
            return
        }
        try {
            const res = await fillterRequestPayment({ ...filterValues, page: num, count, token }); // Pass filter values when paginating
            setState(res?.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching payment requests:", error);
            setLoading(false);
        }
      
    };

    // Handling page change
    const onChangeVal = (e) => {
        const newPage = e - 1;
        setPage(newPage);
        setTimeout(() => {
            getPaymentRequest(newPage); // Fetch data with filter and new page
        }, 1000);
    };


    const fetchUserType = async () => {
        setLoading(true);
        try {
            const response = await usersList();
            setUserData(response?.data);
            setLoading(false);
        } catch (error) {
            alert("Error fetching User type:", error);
            setLoading(false);
        }
    };
 
    const downloadExcel = async () => {
        try {
            setLoading(true);
            const res = await fillterRequestPaymentAllexcel({
                ...filterValues,
                page: 0,
                count,
                token
            });

            if (res && res?.data?.requestList && res?.data?.requestList.length > 0) {
                const dataForExcel = res.data.requestList.map((item, i) => ({
                    "S.No": i + 1,
                    "User Name": item?.username,
                    "User Type": item?.user_type,
                    "Member Code": item?.member_code,
                    "Request Date": item?.createdAt,
                    "Payment Date": item?.paymentDate,
                    "Bank": item?.bank,
                    "Method": item?.method,
                    "Amount": item?.amount,
                    "Bank Ref No": item?.bankRef,
                    "Status": item?.status
                }));

                const ws = XLSX.utils.json_to_sheet(dataForExcel);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Payment Requests");
                XLSX.writeFile(wb, `Payment_Requests_${new Date().toLocaleDateString()}.xlsx`);
            } else {
                message.error('No data available to export!');
            }
        } catch (error) {
            console.error("Error downloading Excel:", error);
            message.error('Failed to download Excel. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPaymentRequest(0);
        fetchUserType();
    }, []);

    return (
        <>
            <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />
            <PaymentRequestViewForm
                submitForm={submitForm}
                loading={loading}
                page={page}
                count={count}
                token={token}
                userData={userData}
                getPaymentRequest={getPaymentRequest}
                setFilterValues={setFilterValues}
            />
            <PaymentRequestViewList
                getPaymentRequest={getPaymentRequest}
                onChangeVal={onChangeVal}
                state={state}
                page={page}
                loading={loading}
                downloadExcel={downloadExcel}
            />
        </>
    );
}

export default PaymentRequestView;
