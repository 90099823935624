
// import React, { useEffect, useState } from 'react';
// import { Formik } from 'formik';
// // import CustomInputField from '../../common/CustomInputField';
// // import { getOperater } from '../../api/login/Login';
// import { useParams } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
// import { getOperater } from '../../api/login/Login';
// import CustomInputField from '../../common/CustomInputField';

// function SameCompFilter({ aepsData, submitForm, title ,initialValues,setIntialValues,getDmtTxnData }) {


//     const getCurrentDate = () => {
//         const today = new Date();
//         return today.toISOString().substr(0, 10);
//     };
    
//     const [currentDate, setCurrentDate] = useState(getCurrentDate());
//     const [defaultInitialValues, setdefaultInitialValues] = useState({
//         package_id: '',
//         member_code: '',
//         name: '',
//         startDate: currentDate,
//         endDate: currentDate
//     })

//     const [operate , setOperater] = useState()


//     const param = useParams()
//     const getOperate = async (num) => {
//         try {
//           const res = await getOperater(param?.id);
//           setOperater(res?.data);
//         } catch (error) {
    
//         }
//       }

    
//     // const handleReset = () => {
//     //     setFormValues(initialFormValues); // Reset form values to initial state
//     //     setIntialValues(initialFormValues); // Also reset parent state if necessary
//     //     setTimeout(() => {
//     //         getDmtTxnData(0); // Call function to fetch data after reset
//     //     }, 1000);
//     //     setOpratorID(""); // Reset operator ID
//     // };
    
//     const handleReset = (resetForm) => {
//         // Reset the form fields
//         resetForm({
//             values: {
//                 ...defaultInitialValues,
//                 startDate: '',   // Reset start_date
//                 endDate: '',     // Reset end_date
//             },
//         });
    
//         // Update the parent state with the reset values

//         const resetData={
//             ...initialValues,
//             start_date: '',  // Reset start_date in parent state
//             end_date: '', 

//         }
//         setIntialValues(resetData);
//         getDmtTxnData(0);
    
//         // // Delay API call until state is updated
//         // setTimeout(() => {
//         //     getDmtTxnData(0); // Fetch new data after reset
//         // }, 500);
//     };
    
    
//     useState(() => {
        
//         getOperate()
//     }, [])



//     return (
//         <>
//             <div className="row m-4">
//                 <div className="col-xl-12">
//                     <div className="card">
//                         <div className="card-body p-0">
//                             <div className="table-responsive active-projects style-1">
//                                 <div className="tbl-caption tbl-caption-2">
//                                     <h4 className="heading mb-0"><b>{title} Search</b></h4>
//                                 </div>
//                                 <Formik
//                                     initialValues={defaultInitialValues}
//                                     onSubmit={submitForm}
//                                     className="tbl-captionn"
//                                 >
//                                     {(formik) => {
//                                         const {
//                                             values,
//                                             handleChange,
//                                             handleSubmit,
//                                             resetForm,
//                                             errors,
//                                             touched,
//                                             handleBlur,
//                                             isValid,
//                                             dirty,
//                                         } = formik;
//                                         return (
//                                             <form className="tbl-captionn" onSubmit={(e) => { e.preventDefault(); submitForm(initialValues); }}>
//                                                 <div className="row">
//                                                     <div className="col-xl-3 mb-3">
//                                                         <CustomInputField
//                                                             type="date"
//                                                             placeholder="Stsssart Date"
//                                                             name="startDate"
//                                                             value={values.startDate}
//                                                             hasError={errors.startDate && touched.startDate}
//                                                             onChange={handleChange}
//                                                             onBlur={handleBlur}
//                                                             errorMsg={errors.startDate}
//                                                             id="startDate"
//                                                         />
//                                                     </div>
//                                                     <div className="col-xl-3 mb-3">
//                                                         <CustomInputField
//                                                             type="date"
//                                                             placeholder="End Date"
//                                                             name="endDate"
//                                                             value={values.endDate}
//                                                             hasError={errors.endDate && touched.endDate}
//                                                             onChange={handleChange}
//                                                             onBlur={handleBlur}
//                                                             errorMsg={errors.endDate}
//                                                             id="endDate"
//                                                         />
//                                                     </div>
//                                                     <div className="col-xl-3 mb-3">
//                                                         <select className="form-select" aria-label="Default select example" name="Operator"
//                                                             value={values.Operator}
//                                                             hasError={errors.Operator && touched.Operator}
//                                                             onChange={handleChange}
//                                                             onBlur={handleBlur}>
//                                                             <option >Select Operator </option>
//                                                             {operate && operate?.map((item)=>{
//                                                                 return <option value={item?.operatorid} >{item?.name}</option>
//                                                             }) }
                                                           
//                                                         </select>

//                                                     </div>
//                                                     {/* <div className="col-xl-3 mb-3">
//                                                         <select className="form-select" aria-label="Default select example" name="ServiceProvider"
//                                                             value={values.ServiceProvider}
//                                                             hasError={errors.ServiceProvider && touched.ServiceProvider}
//                                                             onChange={handleChange}
//                                                             onBlur={handleBlur}>
//                                                             <option >Select Service Provider </option>
//                                                             <option value={1}>One</option>
//                                                             <option value={2}>Two</option>
//                                                             <option value={3}>Three</option>
//                                                         </select>

//                                                     </div> */}
//                                                     <div className="col-xl-3 mb-3">
//                                                         <CustomInputField
//                                                             type="text"
//                                                             placeholder="Member Code"
//                                                             name="member_code"
//                                                             value={values.member_code}
//                                                             hasError={errors.member_code && touched.member_code}
//                                                             onChange={handleChange}
//                                                             onBlur={handleBlur}
//                                                             errorMsg={errors.member_code}
//                                                             id="member_code"
//                                                         />
//                                                     </div>
//                                                     <div className="col-xl-3 mb-3">
//                                                         <CustomInputField
//                                                             type="text"
//                                                             placeholder="Member Name"
//                                                             name="name"
//                                                             value={values.name}
//                                                             hasError={errors.name && touched.name}
//                                                             onChange={handleChange}
//                                                             onBlur={handleBlur}
//                                                             errorMsg={errors.name}
//                                                             id="name"
//                                                         />
//                                                     </div>


//                                                     <div className="col-lg-4 col-md-4">
//                                                         <div className='d-flex justify-content-start'>
//                                                             <Button className="btn btn-primary pd-x-20 " type="submit">
//                                                                 <i className="fas fa-search"></i> Search
//                                                             </Button>
//                                                             <Button className="btn btn-primary pd-x-20 " type="button" onClick={() => {handleReset(resetForm); handleReset(resetForm) }}>
//                                                                 <i className="fas fa-refresh"></i> Reset
//                                                             </Button>
                                                            
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </form>
//                                         );
//                                     }}
//                                 </Formik>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default SameCompFilter;


import React, { useEffect, useState,useCallback } from 'react';
import { Formik,Form, Field } from 'formik';
// import CustomInputField from '../../common/CustomInputField';
// import { getOperater } from '../../api/login/Login';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { getOperater } from '../../api/login/Login';
import CustomInputField from '../../common/CustomInputField';

function SameCompFilter({ aepsData, submitForm, title, initialValues, setInitialValues, getDmtTxnData }) {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().substr(0, 10);
    };
    
    const [currentDate, setCurrentDate] = useState(getCurrentDate());
    const [operate, setOperater] = useState([]);
    const [key, setKey] = useState(0); // Add this line
    const [isFormReset, setIsFormReset] = useState(false);

    const param = useParams();
    const getOperate = async () => {
        try {
            const res = await getOperater(param?.id);
            setOperater(res?.data || []);
        } catch (error) {
            console.error("Error fetching operators:", error);
        }
    };

    const resetValues = {
        operator_id: '',
        member_code: '',
        name: '',
        start_date: '',
        end_date: '',
        category_id: param.id,
        user_id: '',
        biller_id: '',
        member_name: '',
        txn_id: '',
    };

    const handleReset = useCallback(async (resetForm) => {
        try {
            // Reset the form
            await resetForm();

            // Update the initialValues in the parent with reset values
            setInitialValues(resetValues);

            // Mark the form as reset
            setIsFormReset(true);

            // Force re-render of Formik
            setKey(prevKey => prevKey + 1);
        } catch (error) {
            console.error('Error resetting form:', error);
        }
    }, [resetValues, setInitialValues]);

    // Function to call the API after reset
    const handleSubmitAfterReset = useCallback(() => {
        if (isFormReset) {
            // Call the API with reset form values (0 to simulate reset query)
            getDmtTxnData(0);

            // Optionally, reset `isFormReset` if needed for future operations
            setIsFormReset(false);
        }
    }, [isFormReset, getDmtTxnData]);

    useEffect(() => {
        getOperate();
    }, []);  // Fetch operators on component mount

    // Ensure API call happens after form reset
    useEffect(() => {
        handleSubmitAfterReset();
    }, [isFormReset, handleSubmitAfterReset]);

    return (
        <div className="row m-4">
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-body p-0">
                        <div className="table-responsive active-projects style-1">
                            <div className="tbl-caption tbl-caption-2">
                                <h4 className="heading mb-0"><b>{title} Search</b></h4>
                            </div>
                            <Formik
                                key={key} // Add this line
                                initialValues={initialValues}
                                onSubmit={submitForm}
                                enableReinitialize
                            >
                                {({ values, handleChange, handleSubmit, resetForm, errors, touched, handleBlur, setFieldValue }) => (
                                    <Form className="tbl-captionn">
                                        <div className="row">
                                            <div className="col-xl-3 mb-3">
                                                <Field
                                                    as={CustomInputField}
                                                    type="date"
                                                    placeholder="Start Date"
                                                    name="start_date"
                                                    hasError={errors.start_date && touched.start_date}
                                                    errorMsg={errors.start_date}
                                                />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <Field
                                                    as={CustomInputField}
                                                    type="date"
                                                    placeholder="End Date"
                                                    name="end_date"
                                                    hasError={errors.end_date && touched.end_date}
                                                    errorMsg={errors.end_date}
                                                />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <Field as="select" 
                                                    className="form-select" 
                                                    name="operator_id"
                                                >
                                                    <option value="">Select Operator</option>
                                                    {operate.map((item) => (
                                                        <option key={item.operatorid} value={item.operatorid}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <Field
                                                    as={CustomInputField}
                                                    type="text"
                                                    placeholder="Member Code"
                                                    name="member_code"
                                                    hasError={errors.member_code && touched.member_code}
                                                    errorMsg={errors.member_code}
                                                />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <Field
                                                    as={CustomInputField}
                                                    type="text"
                                                    placeholder="Member Name"
                                                    name="member_name"
                                                    hasError={errors.member_name && touched.member_name}
                                                    errorMsg={errors.member_name}
                                                />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <Field
                                                    as={CustomInputField}
                                                    type="text"
                                                    placeholder="Transaction ID"
                                                    name="txn_id"
                                                    hasError={errors.txn_id && touched.txn_id}
                                                    errorMsg={errors.txn_id}
                                                />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <Field
                                                    as={CustomInputField}
                                                    type="text"
                                                    placeholder="Biller ID"
                                                    name="biller_id"
                                                    hasError={errors.biller_id && touched.biller_id}
                                                    errorMsg={errors.biller_id}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <div className='d-flex justify-content-start'>
                                                    <Button 
                                                        className="btn btn-primary pd-x-20 me-2" 
                                                        type="submit"
                                                    >
                                                        <i className="fas fa-search"></i> Search
                                                    </Button>
                                                    <Button 
                                                        className="btn btn-secondary pd-x-20" 
                                                        type="button" 
                                                        onClick={() => handleReset(resetForm)}
                                                    >
                                                        <i className="fas fa-refresh"></i> Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SameCompFilter;