import React, { useEffect, useState } from 'react';
import CustomInputField from '../../../../common/CustomInputField';
import { Formik } from 'formik';

function ServiceSearch({ serviceCateg, submitForm ,initialValues,setInitialValues}) {
    const handleReset = (resetForm) => {
        setInitialValues({
            name: "",
        service_type_id: ""
        })
        resetForm()
        submitForm(initialValues)
    };
    return (
        <>
            <div className="row m-4">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div className="tbl-caption tbl-caption-2">
                                    <h4 className="heading mb-0"><b>SERVICE  SEARCH</b></h4>
                                </div>
                                <Formik
                                    enableReinitialize
                                    initialValues={initialValues}
                                    onSubmit={submitForm}
                                    className="tbl-captionn"
                                >
                                    {(formik) => {
                                        const {
                                            values,
                                            handleChange,
                                            handleSubmit,
                                            resetForm,
                                            errors,
                                            touched,
                                            handleBlur,
                                            isValid,
                                            dirty,
                                        } = formik;
                                        return (
                                            <form className="tbl-captionn" onSubmit={handleSubmit}>
                                                <div className="row align-items-end">
                                                    <div className="col-xl-4 mb-3">
                                                        <label htmlFor="" className="form-label">Select Service Category</label>
                                                        <select
                                                            className="form-select"
                                                            aria-label="Default select example"
                                                            name="service_type_id"
                                                            value={values?.service_type_id}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option disabled>Select service category</option>
                                                            {serviceCateg && serviceCateg.map((category) => (
                                                                <option key={category.id} value={category._id}>
                                                                    {category.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.name && touched.name && (
                                                            <div className="invalid-feedback d-block">{errors.service_category}</div>
                                                        )}
                                                    </div>
                                                    <div className="col-xl-4 mb-3">
                                                        {/* <label htmlFor="" className="form-label">Select Service Name</label> */}
                                                        {/* <select
                                                            className="form-select"
                                                            aria-label="Default select example"
                                                            name="service_category"
                                                            value={values?.service_category}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option defaultValue="" disabled>Select service Name</option>
                                                            {data && data.map((category) => (
                                                                <option key={category._id} value={category._id}>
                                                                    {category.service_name}
                                                                </option>
                                                            ))}
                                                        </select> */}
                                                        {/*  {errors.service_category && touched.service_category && (
                                                            <div className="invalid-feedback d-block">{errors.service_category}</div>
                                                        )} */}
                                                        <CustomInputField
                                                            type="text"
                                                            placeholder="Service Name"
                                                            name="name"
                                                            value={values?.name}
                                                            hasError={errors.name && touched.name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.name}
                                                            id="name"
                                                        />
                                                    </div>
                                                    <div className="col-xl-4 mb-3">
                                                        <div className='d-flex justify-content-start'>
                                                            <button className="btn btn-success pd-x-20 color2 " type="submit">
                                                                <i className="fas fa-search" ></i> Search
                                                            </button>
                                                            <button
                                                                className="btn btn-primary pd-x-20 color2"
                                                                type="button"
                                                                onClick={() =>handleReset(resetForm)}
                                                            >
                                                                <i className="fas fa-refresh"></i> Reset
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceSearch;
