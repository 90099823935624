import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../common/breadcrumb/Breadcrumbs';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomInputField from '../../../common/CustomInputField';
import CustomPassInputField from '../../../common/CustomPassInputField';
import { addStaffpermision, clodinaryImage, getAreaSelecStaff, getBlockSelecStaff, getDepartmentSelecStaff, getDesignationSelecStaff, getFloorSelecStaff, getOfficeSelecStaff, getRoleSelecStaff, getStaffpermisionId, updateStaffpermision, getWardSelecStaff ,getStaffSelecAdd} from '../../../api/login/Login';
import { toast, ToastContainer } from 'react-toastify';
import { baseUrlImage } from '../../../baseUrl';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    mobile: Yup.number().typeError('Mobile number must be a number').required('Mobile number is required'),
    password: Yup.string().when('$mode', {
        is: 'add',
        then: () => Yup.string().required('Password is required'),
        otherwise: () => Yup.string().notRequired(),
    }),
    role_id: Yup.string().required('Role is required'),
    refer_id: Yup.string().when('role_id', {
        is: (val) => 
            // val === "67050568470d128eefaaade1" ||
         val === "670670b4e739c53ab8f748d8",
        then: () => Yup.string().required('Refer ID is required'),
        otherwise: () => Yup.string().notRequired(),
    }),
    building_id: Yup.string().required('Building is required'),
    floor_id: Yup.string().required('Floor is required'),
    area_id: Yup.string().required('Area is required'),
    block_id: Yup.string().required('Block is required'),
    ward_id: Yup.string().required('Ward is required'),
    office_id: Yup.string().required('Office is required'),
    department_id: Yup.string().required('Department is required'),
    designation_id: Yup.string().required('Designation is required'),
    slug: Yup.string().required('Slug is required'),
});

function AddPermision({ position }) {
    const breadCrumbsTitle = {
        id: "1",
        title_1: "Roles & Permissions",
        title_2: "Add Staff",
    };

    const [profileImage, setProfileImage] = useState();
    const [initialValues, setInitialValues] = useState({
        name: "",
        email: "",
        profile: "",
        password: "",
        role_id: "",
        building_id: "",
        office_id: "",
        block_id: "",
        ward_id: "",
        area_id: "",
        floor_id: "",
        department_id: "",
        designation_id: "",
        slug: "",
        refer_id: "",
    });
    const [buildingState, setBuildingState] = useState([]);
    const [officeState, setOfficeState] = useState([]);
    const [floorState, setFloorState] = useState([]);
    const [areaState, setAreaState] = useState([]);
    const [blockState, setBlockState] = useState([]);
    const [wardState, setWardState] = useState([]);
    const [departmentState, setDepartmentState] = useState([]);
    const [designationState, setDesignationState] = useState([]);
    const [roleState, setRoleState] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [showReferIdField, setShowReferIdField] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    const toastSuccessMessage = () => {
        toast.success(`${params?.id ? "Update" : "Add"} Staff Successfully.`, {
            position: "top-right",
        });
    };

    const toastErrorMessage = (message) => {
        toast.error(message, {
            position: "top-right",
        });
    };

    const submitForm = async (values) => {
        setSubmitting(true);
        try {
            const latitude = position?.latitude || 0;
            const longitude = position?.longitude || 0;

            const dataToSubmit = {
                ...values,
                latitude: latitude,
                longitude: longitude,
            };

            if (!params?.id) {
                try {
                    const res = await addStaffpermision(dataToSubmit);
                    if (res?.statusCode === '200') {
                        toastSuccessMessage();
                        setTimeout(() => {
                            navigate('/admin/staff-list');
                        }, 4000);
                    } else {
                        toastErrorMessage(res?.message);
                    }
                } catch (error) {
                    toastErrorMessage(error?.message || 'An error occurred');
                }
            } else {
                try {
                    const res = await updateStaffpermision(params.id, values);
                    if (res?.statusCode == "200") {
                        toastSuccessMessage();
                        setTimeout(() => {
                            navigate('/admin/staff-list');
                        }, 4000);
                    } else {
                        toastErrorMessage(res?.message);
                    }
                } catch (error) {
                    toastErrorMessage(error?.message || 'An error occurred');
                }
            }
        } catch (error) {
            console.error("Error:", error);
            toastErrorMessage("An error occurred while submitting the form");
        } finally {
            setSubmitting(false);
        }
    };

    const fetchDropdownData = async () => {
        try {
            const [buildingRes, officeRes, floorRes, areaRes, blockRes, wardRes, departmentRes, designationRes, roleRes] = await Promise.all([
                getStaffSelecAdd(),
                getOfficeSelecStaff(),
                getFloorSelecStaff(),
                getAreaSelecStaff(),
                getBlockSelecStaff(),
                getWardSelecStaff(),
                getDepartmentSelecStaff(),
                getDesignationSelecStaff(),
                getRoleSelecStaff()
            ]);

            setBuildingState(buildingRes?.data || []);
            setOfficeState(officeRes?.data || []);
            setFloorState(floorRes || []);
            setAreaState(areaRes?.data || []);
            setBlockState(blockRes?.data || []);
            setWardState(wardRes?.data || []);
            setDepartmentState(departmentRes?.data || []);
            setDesignationState(designationRes?.data || []);
            setRoleState(roleRes?.data || []);
        } catch (error) {
            console.error("Error fetching dropdown data:", error);
            toastErrorMessage("Error loading form data");
        }
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                if (params?.id) {
                    const response = await getStaffpermisionId(params.id);
                    const data = response?.data || {};
                    setInitialValues(data);
                    setShowReferIdField(
                        data.role_id === "6704f2073ec16b012bd14adc" || 
                        data.role_id === "67050568470d128eefaaade"
                    );
                } else {
                    setInitialValues({
                        name: "",
                        email: "",
                        profile: "",
                        password: "",
                        role_id: "",
                        building_id: "",
                        office_id: "",
                        block_id: "",
                        ward_id: "",
                        area_id: "",
                        floor_id: "",
                        department_id: "",
                        designation_id: "",
                        slug: "",
                        refer_id: "",
                    });
                }
            } catch (error) {
                console.error("Error fetching initial data:", error);
                toastErrorMessage("Error loading form data");
            }
        };

        fetchInitialData();
        fetchDropdownData();
    }, [params?.id]);

    const handleImageUpload = async (e) => {
        const imgs = new FormData();
        imgs.append("image", e.target.files[0]);
        try {
            const res = await clodinaryImage(imgs);
            setTimeout(() => {
                setProfileImage(res?.data);
            }, 3000);
        } catch (error) {
            toastErrorMessage("Profile upload failed");
        }
    };

    return (
        <>
            <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />
            <div style={{ margin: "14px" }}>
                <div className="card">
                    <div className="card-body p-0">
                        <div className="table-responsive active-projects style-1">
                            <div className="tbl-caption tbl-caption-2">
                                <h4 className="heading mb-0">
                                    {params?.id ? "UPDATE" : "ADD"} Staff
                                </h4>
                            </div>
                            <Formik
                                key={params?.id ? 'update' : 'add'}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                validationContext={{ 
                                    mode: params?.id ? 'edit' : 'add'  // This sets the context dynamically
                                }}
                                onSubmit={submitForm}
                                enableReinitialize
                                validateOnChange={false}
                                validateOnBlur={false}
                                context={{ isUpdate: !!params?.id }}
                            >
                                {({ values, handleChange, errors, touched, handleBlur, setFieldValue }) => (
                                    <Form className="tbl-captionn">
                                        <div className="row">
                                            <div className="col-xl-6 mb-3">
                                                <CustomInputField
                                                    type="text"
                                                    value={values.name}
                                                    hasError={errors.name && touched.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMsg={errors.name}
                                                    id="name"
                                                    name="name"
                                                    placeholder="Name"
                                                />
                                            </div>
                                            <div className="col-xl-6 mb-3">
                                                <CustomInputField
                                                    type="email"
                                                    value={values.email}
                                                    hasError={errors.email && touched.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMsg={errors.email}
                                                    id="email"
                                                    name="email"
                                                    placeholder="Email"
                                                />
                                            </div>
                                            <div className="col-xl-6 mb-3">
                                                <CustomInputField
                                                    type="number"
                                                    value={values.mobile}
                                                    hasError={errors.mobile && touched.mobile}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMsg={errors.mobile}
                                                    id="mobile"
                                                    name="mobile"
                                                    placeholder="Mobile"
                                                />
                                            </div>
                                            {!params?.id && (
                                                <div className="col-xl-6 mb-3">
                                                    <CustomPassInputField
                                                        type="password"
                                                        value={values.password}
                                                        hasError={errors.password && touched.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        errorMsg={errors.password}
                                                        id="password"
                                                        name="password"
                                                        placeholder="Password"
                                                    />
                                                </div>
                                            )}
                                            <div className="col-xl-6 mb-3">
                                                <CustomInputField
                                                    type="text"
                                                    value={values.slug}
                                                    hasError={errors.slug && touched.slug}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMsg={errors.slug}
                                                    id="slug"
                                                    name="slug"
                                                    placeholder="Slug"
                                                />
                                            </div>
                                            <div className="col-xl-6 mb-3">
                                                <div className='row align-items-center'>
                                                    <div className='col-xl-10'>
                                                        <CustomInputField
                                                            type="file"
                                                            onChange={handleImageUpload}
                                                            onBlur={handleBlur}
                                                            id="profile"
                                                            name="profile"
                                                            placeholder="Profile"
                                                        />
                                                    </div>
                                                    <div className='col-xl-2'>
                                                        {profileImage?.data?.url && (
                                                            <div className='profilepic'>
                                                                <picture>
                                                                    <img src={`${baseUrlImage}${profileImage?.data?.url}`} alt='profile_pic' />
                                                                </picture>
                                                            </div>
                                                        )}
                                                        {values.profile && !profileImage?.data?.url && (
                                                            <div className='profilepic'>
                                                                <picture>
                                                                    <img src={`${baseUrlImage}${values.profile}`} alt='profile_pic' />
                                                                </picture>
                                                            </div>
                                                        )}
                                                        {!values.profile && !profileImage?.data?.url && (
                                                            <div className='border p-2 rounded-circle'>
                                                                <img 
                                                                    id="avatar-image" 
                                                                    src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg" 
                                                                    alt="Profile Picture" 
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-6 mb-3">
                                                <Field 
                                                    as="select" 
                                                    name="role_id" 
                                                    className="form-select" 
                                                    onChange={(e) => {
                                                        const roleId = e.target.value;
                                                        setFieldValue('role_id', roleId);
                                                        const shouldShowReferField = 
                                                            // roleId === "67050568470d128eefaaade1" || 
                                                            roleId === "670670b4e739c53ab8f748d8";
                                                        setShowReferIdField(shouldShowReferField);
                                                        if (!shouldShowReferField) {
                                                            setFieldValue('refer_id', '');
                                                        }
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.role_id}
                                                >
                                                    <option value="">Select Role</option>
                                                    {roleState.map(item => (
                                                        <option key={item._id} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage 
                                                    name="role_id" 
                                                    component="div" 
                                                    className="text-danger" 
                                                />
                                            </div>

                                            {showReferIdField && (
                                                <div className="col-xl-6 mb-3">
                                                    <CustomInputField
                                                        type="text"
                                                        value={values.refer_id}
                                                        hasError={errors.refer_id && touched.refer_id}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        errorMsg={errors.refer_id}
                                                        id="refer_id"
                                                        name="refer_id"
                                                        placeholder="Refer ID"
                                                    />
                                                </div>
                                            )}

                                            {[
                                                { name: 'building_id', label: 'Building', options: buildingState },
                                                { name: 'floor_id', label: 'Floor', options: floorState },
                                                { name: 'area_id', label: 'Area', options: areaState },
                                                { name: 'block_id', label: 'Block', options: blockState },
                                                { name: 'ward_id', label: 'Ward', options: wardState },
                                                { name: 'office_id', label: 'Office', options: officeState },
                                                { name: 'department_id', label: 'Department', options: departmentState },
                                                { name: 'designation_id', label: 'Designation', options: designationState },
                                            ].map(({ name, label, options }) => (
                                                <div className="col-xl-6 mb-3" key={name}>
                                                    <Field 
                                                        as="select" 
                                                        name={name} 
                                                        className={`form-select ${errors[name] && touched[name] ? 'is-invalid' : ''}`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values[name]}
                                                    >
                                                        <option value="">Select {label}</option>
                                                        {options.map(item => (
                                                            <option key={item._id} value={item._id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage 
                                                        name={name} 
                                                        component="div" 
                                                        className="text-danger" 
                                                    />
                                                </div>
                                            ))}
                                        </div>

                                        <div className="mt-3">
                                            <Link 
                                                to="/admin/staff-list" 
                                                className="btn btn-danger light ms-1"
                                            >
                                                Cancel
                                            </Link>
                                            <button 
                                                type="submit" 
                                                className="btn btn-primary ms-2" 
                                                disabled={submitting}
                                            >
                                                {submitting ? 'Submitting...' : params?.id ? 'Update' : 'Add'}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer className="text-center" />
        </>
    );
}

export default AddPermision;