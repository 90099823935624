import React from 'react'
import Dashboard from '../../components/dashboard/Dashboard'

function Dashboardpage() {
  return (
    <>
      <Dashboard/>  
    </>
  )
}

export default Dashboardpage
