import React from 'react'
import RechargeReports from '../../../components/additionalReports/rechargeReports/RechargeReports'

function RechargeReportsPage() {
  return (
    <>
    <RechargeReports/>
    </>
  )
}

export default RechargeReportsPage