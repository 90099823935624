import React from 'react'
import Breadcrumbs from '../../../common/breadcrumb/Breadcrumbs'
import DmtcommschList from './dmtcommschList/DmtcommschList'

function BbpsCommSche() {
 
  return (
    <>
    <Breadcrumbs breadCrumbsTitle={""}/>
    <DmtcommschList/>
    </>

  )
}

export default BbpsCommSche