import React from 'react'
import BalanceTopupHis from '../../../components/additionalReports/balanceTopupHis/BalanceTopupHis'

function BalanceTopUpHisPage() {
  return (
    <>
    <BalanceTopupHis/>
    </>

  )
}

export default BalanceTopUpHisPage