// import { Button, Modal } from "react-bootstrap"
// import img from '../../assets/images/logo/merchant.paypanda-logo.png'
// function DearMearchantPopUp(props) {
//     return (
//         <>
//             <Modal
//                 {...props}
//                 size="lg"
//                 aria-labelledby="contained-modal-title-vcenter"
//                 centered
//                 className="popupfirst"
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title id="contained-modal-title-vcenter" style={{ fontWeight: '900', color: 'blue', fontSize: '25px' }}>
//                         Dear Merchants
//                     </Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div>
//                         <img src={img} alt="" style={{ width: '100%' }} />
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <p style={{ float: 'left', color: 'green', fontWeight: '700' }}>Now You can do the transaction by using money transfer 1.</p>
//                     <Button onClick={props.onHide}>Close</Button>
//                 </Modal.Footer>
//             </Modal>
//         </>
//     )
// }
// export default DearMearchantPopUp

import React from 'react';
import { Modal, Button, Typography } from 'antd';
import img from '../../assets/images/logo/merchant.paypanda-logo.png'
import './DearMerchantPopUp.css'; 

const { Title, Text } = Typography;

function DearMearchantPopUp({ visible, onCancel }) {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={[
        <div key="footer" className="modal-footer-custom">
          <Text className="transaction-text">
            Now you can do the transaction by using money transfer 1.
          </Text>
          <Button type="primary" onClick={onCancel}>
            Close
          </Button>
        </div>
      ]}
      width={800}
      centered
      className="merchant-modal"
      closeIcon={
        <span className="custom-close-icon">×</span>
      }
    >
      <Title level={3} className="modal-title">
        Dear Merchants
      </Title>
      
      <div className="modal-image-container">
        <img 
          src={img} 
          alt="Merchant Logo" 
          className="modal-image"
        />
      </div>
    </Modal>
  );
}

export default DearMearchantPopUp;