import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CustomInputField from '../../../../../common/CustomInputField';
import CustomTextArea from '../../../../../common/CustomTextArea';
import { baseUrlImage } from '../../../../../baseUrl';
import WithoutAtricCustomInputField from '../../../../../common/withoutAtrricCustomInp/WithoutAtricCustomInputField';
import { Alert } from "antd";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

function NewtabAddServiceMaster({ i, colodinaryImage, language, service_category, submitForm, handleChangeCus, item, params, profileImage, ProfileImageBanner ,isIconUploading}) {
    const [formData, setFormData] = useState(item);
    const [errors, setErrors] = useState({});


    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            ...item,
            language_id: item.language_id || prevState.language_id
        }));
    }, [item]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
            language_id: prevState.language_id // Ensure language_id is always preserved
        }));
        handleChangeCus(e, formData.language_id);
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.service_name) newErrors.service_name = 'Service Name is required';
        // if (!formData.short_description) newErrors.short_description = 'Short Description is required';
        // if (!formData.full_description) newErrors.full_description = 'Full Description is required';
        if (!formData.service_category) newErrors.service_category = 'Service Category is required';
        if (formData.is_active === null) newErrors.is_active = 'Active status is required';
        // if (!formData.meta_title) newErrors.meta_title = 'Meta Title is required';
        // if (!formData.meta_keyword) newErrors.meta_keyword = 'Meta Keyword is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log("Form submitted");
    //     if (validateForm()) {
    //         console.log("Validation passed");
    //         const dataToSubmit = { [formData.language_id]: formData };
    //         console.log("Submitting data:", dataToSubmit);
    //         submitForm(dataToSubmit);
    //     } else {
    //         console.log("Validation failed", errors);
    //     }
    // };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Validation passed");
            const updatedFormData = {
                ...formData,
                icon: profileImage || formData.icon
            };
            console.log("Submitting data:", updatedFormData);
            const dataToSubmit = { [updatedFormData.language_id]: updatedFormData };
            submitForm(dataToSubmit);
        }else {
                    console.log("Validation failed", errors);
                }
        
    };
    console.log("formData", formData)
    return (
        <form className="tbl-captionn" onSubmit={handleSubmit}>
            <div className="row">
                {/* <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-3">
                    <div className="form-group">
                        <label htmlFor={`service_name_${formData.language_id}`}>Service Name</label>
                        <input
                            type="text"
                            className={`form-control ${errors.service_name ? 'is-invalid' : ''}`}
                            id={`service_name_${formData.language_id}`}
                            name="service_name"
                            value={formData.service_name || ''}
                            onChange={handleChange}
                        />
                        {errors.service_name && <div className="invalid-feedback">{errors.service_name}</div>}
                    </div>
                </div> */}

                {/* Other form fields follow the same pattern as above */}
                <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-3">
                <div className="row align-items-center">
  <div className="col-xl-9">
    <div className="form-group">
      <label htmlFor={`icon_${formData.language_id}`}>Icon</label>
      <input
        type="file"
        className={`form-control ${errors.icon ? 'is-invalid' : ''}`}
        id={`icon_${formData.language_id}`}
        name="icon"
        onChange={(e) => colodinaryImage(e, formData.language_id, 'icon')}
      />
    </div>
  </div>
  <div className="col-xl-3">
    {isIconUploading ? (
      <Spinner animation="border" className="text-center" />
    ) : profileImage ? (
      <img 
        src={`${baseUrlImage}${profileImage}`} 
        alt="Icon" 
        style={{ width: '100px', height: '100px', objectFit: 'cover' }} 
      />
    ) : (
      <img 
        src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg" 
        alt="Default Icon" 
        style={{ width: '100px', height: '100px', objectFit: 'cover' }} 
      />
    )}
  </div>
  {(formData.icon || profileImage) && (
    <div className="col-xl-3">
      {formData.icon && <img src={`${baseUrlImage}${formData.icon}`} alt="Icon" style={{ width: '100px' }} />}
      {errors.icon && <div className="invalid-feedback">{errors.icon}</div>}
    </div>
  )}
</div>

                 </div>
                 </div>
          
            <div className='row'>
                <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-3">
                    <div className='col-xl-12'>
                        <div className='row align-items-center'>
                            <div className='col-xl-9'>
                                <div className="form-group">
                                    <label htmlFor={`banner_img_${formData.language_id}`}>Banner Image</label>
                                    <input
                                        type="file"
                                        className={`form-control ${errors.banner_img ? 'is-invalid' : ''}`}
                                        id={`banner_img_${formData.language_id}`}
                                        name="banner_img"
                                        onChange={(e) => colodinaryImage(e, formData.language_id, 'banner_img')}
                                    />

                                </div>


                            </div>
                            <div className='col-xl-3'>
                                {formData.banner_img && <img src={`${baseUrlImage}${formData.banner_img}`} alt="Icon" style={{ width: '100px' }} />}
                                {errors.banner_img && <div className="invalid-feedback">{errors.banner_img}</div>}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-3">
                    <CustomInputField
                        type="text"
                        placeholder="Service Name "
                        value={formData.service_name}
                        hasError={!!errors.service_name}
                        onChange={handleChange}
                        errorMsg={errors.service_name}
                        id="service_name"
                        name="service_name"
                    />
                </div>
            </div>
            <div className='row'>
                <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-3">
                    <CustomTextArea
                        placeholder="Short Description "
                        value={formData.short_description}
                        hasError={!!errors.short_description}
                        onChange={handleChange}
                        errorMsg={errors.short_description}
                        rows="3"
                        id="short_description"
                        name="short_description"
                    />
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-3">
                    <CustomTextArea
                        placeholder="Full Description"
                        value={formData.full_description}
                        hasError={!!errors.full_description}
                        onChange={handleChange}
                        errorMsg={errors.full_description}
                        rows="3"
                        id="full_description"
                        name="full_description"
                    />
                </div>
            </div>
            {/* <div className="col-xl-6 mb-3">
                    <div className="d-flex">
                        {ProfileImageBanner && (
                            <img
                                src={`${baseUrlImage}${ProfileImageBanner}`}
                                alt="profile"
                                width={50}
                                height={60}
                            />
                        )}
                        <WithoutAtricCustomInputField
                            type="file"
                            onChange={(e) => colodinaryImage(e, item.language_id, 'banner_img')}
                            id="banner_img"
                            name="banner_img"
                            placeholder="Banner Image"
                        />
                    </div>
                </div> */}

            <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-3">
                <select
                    className="form-select"
                    name="service_category"
                    value={formData?.service_category}
                    onChange={handleChange}
                >
                    <option value="">Select service category</option>
                    {service_category && service_category.map((category) => (
                        <option key={category._id} value={category._id}>
                            {category.name}
                        </option>
                    ))}
                </select>
                {errors.service_category && <div className="invalid-feedback d-block">{errors.service_category}</div>}
            </div>
            <div className="col-xl-6 mb-3">
                <select
                    className="form-select"
                    name="is_active"
                    value={formData.is_active}
                    onChange={handleChange}
                >
                    <option value="">Select status</option>
                    <option value={true}>Active</option>
                    <option value={false}>In Active</option>
                </select>
                {errors.is_active && <div className="invalid-feedback d-block">{errors.is_active}</div>}
            </div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-3">
                <WithoutAtricCustomInputField
                    type="text"
                    value={formData.meta_title}
                    hasError={!!errors.meta_title}
                    onChange={handleChange}
                    errorMsg={errors.meta_title}
                    id="meta_title"
                    name="meta_title"
                    placeholder="Meta Title"
                />
            </div>
            {/* <div className="col-xl-6 mb-3">
                    <WithoutAtricCustomInputField
                        type="file"
                        onChange={(e) => colodinaryImage(e, item.language_id, 'meta_image')}
                        id="meta_image"
                        name="meta_image"
                        placeholder="Meta Image"
                    />
                </div> */}
            <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-3">
                <WithoutAtricCustomInputField
                    type="text"
                    value={formData.meta_keyword}
                    // hasError={!!errors.meta_keyword}
                    onChange={handleChange}
                    // errorMsg={errors.meta_keyword}
                    id="meta_keyword"
                    name="meta_keyword"
                    placeholder="Meta Keyword"
                />
            </div>
              {params?.id && (
                    <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-3">
                        <CustomInputField
                            type="number"
                            placeholder="Sequence Number (1-20)"
                            value={formData.seq_no}
                            hasError={!!errors.seq_no}
                            onChange={handleChange}
                            errorMsg={errors.seq_no}
                            id="seq_no"
                            name="seq_no"
                            min="1"
                            max="20"
                        />
                    </div>
                    )}
            <div>
                <Link to='/admin/service-master' className="btn btn-danger light ms-1">Cancel</Link>
                {i === language?.length - 2 && (
                    <button type="button" className="btn btn-primary me-1">
                        Next
                    </button>
                )}
                {i === language?.length - 1 && (
                    <button
                        className="btn btn-primary me-1"
                        type="submit"
                    >
                        {params?.id ? "Update" : "Add"}
                    </button>
                )}
            </div>
        </form>
    );
}

export default NewtabAddServiceMaster;