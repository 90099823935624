import React from 'react'
import DmtReport from '../../../components/additionalReports/dmtReport/DmtReport'

function DmtReportsPages() {
  return (
    <>
    <DmtReport/>
    </>

  )
}

export default DmtReportsPages