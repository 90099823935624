import React, { useState } from 'react';
import { Card, Row, Col, Modal } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { baseUrlImage, baseUrVideos } from '../../../../baseUrl';
import dummyAdhaarFront from "../../../../assets/images/kyc/adhaarFront.png";
import dummyAdhaarBack from "../../../../assets/images/kyc/adhharBack.png";
import gst from "../../../../assets/images/kyc/kycCirtificate.png";
import pancard from "../../../../assets/images/kyc/panCard.png";
import bankProof from "../../../../assets/images/kyc/bankProof.png";
import kycVid from "../../../../assets/images/kyc/kycVideo.png";
import shopinside from "../../../../assets/images/kyc/shop-inside.jpeg";
import shopoutside from "../../../../assets/images/kyc/blankshop1.png";
import KycModals from './kycModals/KycModals';
import "../kycDetails/KycDetails.css"



const ModalPdfShow = ({ handleClose, url }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
  
    const handleScroll = (e) => {
      const element = e.target;
      const scrollPosition = element.scrollTop;
      const viewportHeight = element.clientHeight;
      const totalHeight = element.scrollHeight;
      
      const newPage = Math.ceil((scrollPosition / (totalHeight - viewportHeight)) * totalPages);
      if (newPage > 0 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    };
  
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>GST Document Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <div style={{ height: "80vh", width: "100%" }} onScroll={handleScroll}>
              <iframe
                src={`${baseUrlImage}${url}#toolbar=0`}
                style={{ width: "100%", height: "100%", border: "none" }}
                title="PDF Preview"
                onLoad={(e) => {
                  try {
                    const doc = e.target.contentDocument;
                    if (doc) {
                      setTotalPages(doc.body.getElementsByTagName('page').length || 1);
                    }
                  } catch (error) {
                    console.log('Could not determine total pages');
                  }
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </>
    );
  };
const DocumentCard = ({ title, imageSrc, dummyImage, onClick }) => (
  <Card 
    hoverable 
    className="h-100 shadow-sm"
    bodyStyle={{ padding: '12px' }}
  >
    <div className="text-center mb-3">
      <h5 className="fw-bold text-primary p-2 bg-light rounded">{title}</h5>
    </div>
    <div 
      className="position-relative cursor-pointer" 
      onClick={onClick}
      style={{ height: '200px', overflow: 'hidden' }}
    >
      <img
        src={imageSrc || dummyImage}
        alt={title}
        className="w-100 h-100 object-fit-cover"
      />
      {imageSrc && (
        <div className="position-absolute top-50 start-50 translate-middle">
          <EyeOutlined className="text-white fs-4" />
        </div>
      )}
      {!imageSrc && (
        <div className="position-absolute bottom-0 start-0 w-100 bg-light bg-opacity-75 p-2">
          <p className="mb-0 text-center text-muted">
            <small>{title} Not Found</small>
          </p>
        </div>
      )}
    </div>
  </Card>
);

const KycDetails = ({ initialValues, state }) => {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [previewModal, setPreviewModal] = useState({
    visible: false,
    image: null,
    title: ''
  });

  const handlePreview = (url, title) => {
    setPreviewModal({
      visible: true,
      image: url,
      title
    });
  };

  return (
    <div className="p-4">
      <Card className="shadow-sm">
        <h3 className="mb-4 ">KYC Documentation</h3>
        
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={12} lg={8} xl={6}>
            <DocumentCard
              title="Aadhaar Card Front"
              imageSrc={initialValues?.docs?.adhaar_front_card ? `${baseUrlImage}${initialValues.docs.adhaar_front_card}` : null}
              dummyImage={dummyAdhaarFront}
              onClick={() => handlePreview(initialValues?.docs?.adhaar_front_card, 'Aadhaar Front')}
            />
          </Col>

          <Col xs={24} sm={12} lg={8} xl={6}>
            <DocumentCard
              title="Aadhaar Card Back"
              imageSrc={initialValues?.docs?.adhaar_back_card ? `${baseUrlImage}${initialValues.docs.adhaar_back_card}` : null}
              dummyImage={dummyAdhaarBack}
              onClick={() => handlePreview(initialValues?.docs?.adhaar_back_card, 'Aadhaar Back')}
            />
          </Col>

          <Col xs={24} sm={12} lg={8} xl={6}>
            <DocumentCard
              title="PAN Card"
              imageSrc={initialValues?.docs?.pan_card ? `${baseUrlImage}${initialValues.docs.pan_card}` : null}
              dummyImage={pancard}
              onClick={() => handlePreview(initialValues?.docs?.pan_card, 'PAN Card')}
            />
          </Col>

          <Col xs={24} sm={12} lg={8} xl={6}>
            <DocumentCard
              title="Bank Proof"
              imageSrc={initialValues?.docs?.bank_proof ? `${baseUrlImage}${initialValues.docs.bank_proof}` : null}
              dummyImage={bankProof}
              onClick={() => handlePreview(initialValues?.docs?.bank_proof, 'Bank Proof')}
            />
          </Col>

          {initialValues?.docs?.gst && (
            <Col xs={24} sm={12} lg={8} xl={6}>
              <DocumentCard
                title="GST Certificate"
                imageSrc={initialValues?.docs?.gst ? `${baseUrlImage}${initialValues.docs.gst}` : null}
                dummyImage={gst}
                onClick={() => handlePreview(initialValues?.docs?.gst, 'GST Certificate')}
              />
            </Col>
          )}

          <Col xs={24} sm={12} lg={8} xl={6}>
            <DocumentCard
              title="Shop Inside"
              imageSrc={initialValues?.docs?.shop_internal_photo ? `${baseUrlImage}${initialValues.docs.shop_internal_photo}` : null}
              dummyImage={shopinside}
              onClick={() => handlePreview(initialValues?.docs?.shop_internal_photo, 'Shop Inside')}
            />
          </Col>

          <Col xs={24} sm={12} lg={8} xl={6}>
            <DocumentCard
              title="Shop Outside"
              imageSrc={initialValues?.docs?.shop_outside_photo ? `${baseUrlImage}${initialValues.docs.shop_outside_photo}` : null}
              dummyImage={shopoutside}
              onClick={() => handlePreview(initialValues?.docs?.shop_outside_photo, 'Shop Outside')}
            />
          </Col>

          <Col xs={24} sm={12} lg={8} xl={6}>
            <Card className="h-100 shadow-sm" bodyStyle={{ padding: '12px' }}>
              <div className="text-center mb-3">
                <h5 className="fw-bold text-primary p-2 bg-light rounded">KYC Video</h5>
              </div>
              <div 
                className="position-relative cursor-pointer" 
                style={{ height: '200px', overflow: 'hidden' }}
                onClick={() => setVideoModalOpen(true)}
              >
                {state?.kycVideo ? (
                  <>
                    <video 
                      src={`${baseUrVideos}${state.kycVideo}`}
                      className="w-100 h-100 object-fit-cover"
                    />
                    <div className="position-absolute top-50 start-50 translate-middle">
                      <div className="play-btn"></div>
                    </div>
                  </>
                ) : (
                  <div className="h-100 d-flex flex-column justify-content-center align-items-center bg-light">
                    <img src={kycVid} alt="Video placeholder" className="mb-2" style={{ width: '64px' }} />
                    <p className="text-muted mb-0"><small>KYC Video Not Found</small></p>
                  </div>
                )}
              </div>
            </Card>
          </Col>

          <Col xs={24} sm={12} lg={8} xl={6}>
            <Card className="h-100 shadow-sm" bodyStyle={{ padding: '12px' }}>
              <div className="text-center mb-3">
                <h5 className="fw-bold text-primary p-2 bg-light rounded">Location Details</h5>
              </div>
              <div className="p-3">
                {initialValues?.docs ? (
                  <>
                    <p className="mb-2">
                      <strong>Longitude: </strong>
                      {initialValues.docs.longitude}
                    </p>
                    <p className="mb-0">
                      <strong>Latitude: </strong>
                      {initialValues.docs.latitude}
                    </p>
                  </>
                ) : (
                  <p className="text-muted text-center mb-0">Location Not Found</p>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Card>

      <Modal
        visible={previewModal.visible}
        title={previewModal.title}
        footer={null}
        onCancel={() => setPreviewModal({ visible: false, image: null, title: '' })}
        width={800}
        centered
      >
        {previewModal.image && (
          <img
            src={`${baseUrlImage}${previewModal.image}`}
            alt={previewModal.title}
            style={{ width: '100%', height: 'auto' }}
          />
        )}
      </Modal>

      <KycModals 
        open={videoModalOpen}
        setOpen={setVideoModalOpen}
        kycVideo={state?.kycVideo || "KYC Videos Not Found"}
      />
    </div>
  );
};

export default KycDetails;