// export const baseUrl = 'https://api.paypanda.in/api/'
export const baseUrlImage = 'https://api.paypanda.in/api/cloudinary/'

export const baseUrVideos = 'https://api.paypanda.in/api/cloudinary/video/'


// old urls 

// export const baseUrl = 'https://api.paypanda.in/api/'
export const baseUrl = 'https://api.paypanda.in/api/' 

