import React from 'react'
import PanCardReport from '../../components/reportMaster/pancardReport/PanCardReport'

function PanCardReportPages() {
  return (
    <>
      <PanCardReport/>
    </>
  )
}

export default PanCardReportPages
