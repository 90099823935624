import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Collapse, Button, Input, Select, Form, Row, Col } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import Loadar from '../../../../common/loader/Loader';
import MultiSelect from './MultiSelect';
import "./ChargeSettings.css"
import {
  getPayoutChargeDetails,
  getchargesSettings,
  updateCharges,
  updateDmt,
  updatePayout,
  updateAeps,
  getdmtchargesSettings,
  getsetPayoutData,
  getDmtData
} from '../../../../api/login/Login';
import AEPSSettings from './Aepsettings';

const { Panel } = Collapse;
const { Option } = Select;

function ChargeSettings() {
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState(['charges']);
  const token = window.localStorage.getItem("userToken");

  const [chargeData, setChargeData] = useState({
    bankVerificationCharge: '',
    aeps_onBoardingCharge: '',
    minAepsToMainWallet: '',
  });

  const [dmtData, setDmtData] = useState({
    dmtOptions: [],
    dmtApiType: [],
    defaultApi: '',
  });

  const [payoutData, setPayoutData] = useState({
    payoutOptions: [],
    payoutApi: [],
    defaultPayoutApi: '',
  });

  const [aepsData, setAepsData] = useState({
    aeps_onBoardingDefaultBank: '',
  });

  useEffect(() => {
    fetchSectionData('charges');
    fetchSectionData('dmt');
    fetchSectionData('payout');
  }, []);

  const toastSuccessMessage = (message) => {
    toast.success(message, { position: 'top-center' });
  };

  const toastErrorMessage = (message) => {
    toast.error(message, { position: 'top-center' });
  };

  const handleCollapseChange = (keys) => {
    setActiveKey(keys);
    keys.forEach(key => {
      if (!activeKey.includes(key)) {
        fetchSectionData(key);
      }
    });
  };

  const fetchSectionData = async (section) => {
    setLoading(true);
    try {
      switch (section) {
        case 'charges':
          const chargeResponse = await getchargesSettings({ token });
          if (chargeResponse?.data) {
            setChargeData(chargeResponse.data);
          }
          break;

        case 'dmt':
          const dmtResponse = await getDmtData({ token });
          if (dmtResponse?.statusCode === "200" && dmtResponse.data && dmtResponse.data.length > 0) {
            const dmtSettingsData = dmtResponse.data[0];
            const dmtApiResponse = await getdmtchargesSettings({ token });
            if (dmtApiResponse?.data && Array.isArray(dmtApiResponse.data)) {
              const dmtOptions = dmtApiResponse.data.map((item) => ({
                id: item?._id,
                value: item?.slug,
                label: item?.name,
              }));
              dmtOptions.unshift({ value: "selectAll", label: "Select All" });
              setDmtData(prevState => ({
                ...prevState,
                dmtOptions,
                dmtApiType: dmtSettingsData.dmtApiType || [],
                defaultApi: dmtSettingsData.defaultApi || '',
              }));
            }
          }
          break;

        case 'payout':
          const payoutResponse = await getsetPayoutData({ token });
          if (payoutResponse?.statusCode === "200" && payoutResponse.data && payoutResponse.data.length > 0) {
            const payoutSettingsData = payoutResponse.data[0];
            const payoutApiResponse = await getPayoutChargeDetails({ token });
            if (payoutApiResponse?.data && Array.isArray(payoutApiResponse.data)) {
              const payoutOptions = payoutApiResponse.data.map((item) => ({
                id: item?._id,
                value: item?.slug,
                label: item?.vender_name,
              }));
              payoutOptions.unshift({ value: "selectAll", label: "Select All" });
              setPayoutData(prevState => ({
                ...prevState,
                payoutOptions,
                payoutApi: payoutSettingsData.payoutApi || [],
                defaultPayoutApi: payoutSettingsData.defaultPayoutApi || '',
              }));
            }
          }
          break;

        case 'aeps':
          const aepsResponse = await getchargesSettings({ token });
          if (aepsResponse?.data) {
            setAepsData({
              aeps_onBoardingDefaultBank: aepsResponse.data.aeps_onBoardingDefaultBank || '',
            });
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.error(`Error fetching ${section} data:`, error);
      toastErrorMessage(`Error fetching ${section} data`);
    } finally {
      setLoading(false);
    }
  }

  const handleChargesUpdate = async (values) => {
    try {
      setLoading(true);
      const numericValues = {
        aeps_onBoardingCharge: Number(values.aeps_onBoardingCharge),
        bankVerificationCharge: Number(values.bankVerificationCharge),
        minAepsToMainWallet: Number(values.minAepsToMainWallet)
      };
      console.log('Updating charges with values:', numericValues);
      const response = await updateCharges(numericValues);
      console.log('Updating charges response:', response);
      if (response?.data?.statusCode == "200") {
        toastSuccessMessage(response?.data?.message || 'Charges updated successfully');
        setChargeData(response.data.data);
      } else {
        toastErrorMessage(response.message || 'Error updating charges');
      }
    } catch (error) {
      console.error('Error updating charges:', error);
      toastErrorMessage('Error updating charges');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDmtUpdate = async () => {
    try {
      setLoading(true);
      const updatedValues = {
        dmtApiType: dmtData.dmtApiType,
        defaultApi: dmtData.defaultApi,
      };
      const response = await updateDmt(updatedValues);
      if (response?.statusCode === "200") {
        toastSuccessMessage(response.message || 'DMT settings updated successfully');
      } else {
        toastErrorMessage(response.message || 'Error updating DMT settings');
      }
    } catch (error) {
      console.error('Error updating DMT settings:', error);
      toastErrorMessage('Error updating DMT settings');
    } finally {
      setLoading(false);
    }
  };
  
  const handlePayoutUpdate = async () => {
    try {
      setLoading(true);
      const updatedValues = {
        payoutApi: payoutData.payoutApi,
        defaultPayoutApi: payoutData.defaultPayoutApi,
      };
      console.log('Updating Payout settings with values:', updatedValues);
      const response = await updatePayout(updatedValues);
      if (response?.statusCode === "200") {
        toastSuccessMessage(response.message || 'Payout settings updated successfully');
      } else {
        toastErrorMessage(response.message || 'Error updating Payout settings');
      }
    } catch (error) {
      console.error('Error updating Payout settings:', error);
      toastErrorMessage('Error updating Payout settings');
    } finally {
      setLoading(false);
    }
  };
  
  const handleAepsUpdate = async () => {
    try {
      setLoading(true);
      console.log('Updating AEPS settings with values:', aepsData);
      const response = await updateAeps(aepsData);
      if (response?.statusCode === "200") {
        toastSuccessMessage(response.message || 'AEPS settings updated successfully');
      } else {
        toastErrorMessage(response.message || 'Error updating AEPS settings');
      }
    } catch (error) {
      console.error('Error updating AEPS settings:', error);
      toastErrorMessage('Error updating AEPS settings');
    } finally {
      setLoading(false);
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setChargeData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
    },
    panel: {
      backgroundColor: '#f7f7f7',
      borderRadius: '8px',
      marginBottom: '20px',
    },
    panelHeader: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#333',
    },
    form: {
      padding: '20px',
    },
    formItem: {
      marginBottom: '20px',
    },
    input: {
      width: '100%',
      padding: '8px',
      borderRadius: '4px',
      border: '1px solid #d9d9d9',
    },
    button: {
      backgroundColor: '#1890ff',
      color: 'white',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '16px',
      transition: 'background-color 0.3s',
    },
    buttonDisabled: {
      backgroundColor: '#bfbfbf',
      cursor: 'not-allowed',
    },
  };

  return (
    <div style={styles.container}>
      {loading && <Loadar />}
      <Collapse
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        onChange={handleCollapseChange}
        activeKey={activeKey}
      >
        <Panel header={<span style={styles.panelHeader}>Charges</span>} key="charges" style={styles.panel}>
          {Object.keys(chargeData).length > 0 && (
            <Formik
              initialValues={chargeData}
              onSubmit={handleChargesUpdate}
              enableReinitialize
            >
              {({
                values,
                handleSubmit,
              }) => (
                <Form onFinish={handleSubmit} layout="vertical" style={styles.form}>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Account Verification Charge" style={styles.formItem}>
                        <Input
                          type="number"
                          name="bankVerificationCharge"
                          value={values.bankVerificationCharge}
                          onChange={handleInputChange}
                          style={styles.input}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="AEPS Onboarding Charge" style={styles.formItem}>
                        <Input
                          type="number"
                          name="aeps_onBoardingCharge"
                          value={values.aeps_onBoardingCharge}
                          onChange={handleInputChange}
                          style={styles.input}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Min AEPS To Main Wallet" style={styles.formItem}>
                        <Input
                          type="number"
                          name="minAepsToMainWallet"
                          value={values.minAepsToMainWallet}
                          onChange={handleInputChange}
                          style={styles.input}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button 
                      type="primary" 
                      htmlType="submit" 
                      disabled={!values.bankVerificationCharge || !values.aeps_onBoardingCharge || !values.minAepsToMainWallet}
                      style={{
                        ...styles.button,
                        ...(!values.bankVerificationCharge || !values.aeps_onBoardingCharge || !values.minAepsToMainWallet ? styles.buttonDisabled : {})
                      }}
                    >
                      Update Charges
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Formik>
          )}
        </Panel>

        <Panel header={<span style={styles.panelHeader}>DMT</span>} key="dmt" style={styles.panel}>
          <div style={styles.form}>
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <Form.Item label="Select Bank" style={styles.formItem}>
                  <MultiSelect
                    options={dmtData.dmtOptions}
                    selectedIds={dmtData.dmtApiType}
                    setSelectedIds={(selected) => setDmtData(prevState => ({
                      ...prevState,
                      dmtApiType: selected,
                    }))}
                    setDefaultApi={(value) => setDmtData(prevState => ({
                      ...prevState,
                      defaultApi: value,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item label="SELECT DEFAULT API" style={styles.formItem}>
                  <Select
                    value={dmtData.defaultApi}
                    onChange={(value) => setDmtData(prevState => ({ ...prevState, defaultApi: value }))}
                    style={styles.input}
                  >
                    {dmtData.dmtApiType.map(id => {
                      const option = dmtData.dmtOptions.find(opt => opt.id === id);
                      return option ? (
                        <Option key={option.id} value={option.id}>
                          {`${option.label} (${option.value})`}
                        </Option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Button 
              onClick={handleDmtUpdate} 
              disabled={dmtData.dmtApiType.length === 0}
              style={{
                ...styles.button,
                ...(dmtData.dmtApiType.length === 0 ? styles.buttonDisabled : {})
              }}
            >
              Update DMT
            </Button>
          </div>
        </Panel>

        <Panel header={<span style={styles.panelHeader}>Payout</span>} key="payout" style={styles.panel}>
        <div style={styles.form}>
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item label="Select Bank" style={styles.formItem}>
                <MultiSelect
                  options={payoutData.payoutOptions}
                  selectedIds={payoutData.payoutApi}
                  setSelectedIds={(selected) => setPayoutData(prevState => ({
                    ...prevState,
                    payoutApi: selected,
                  }))}
                  setDefaultApi={(value) => setPayoutData(prevState => ({
                    ...prevState,
                    defaultPayoutApi: value,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label="SELECT DEFAULT API" style={styles.formItem}>
                <Select
                  value={payoutData.defaultPayoutApi}
                  onChange={(value) => setPayoutData(prevState => ({ ...prevState, defaultPayoutApi: value }))}
                  style={styles.input}
                >
                  {payoutData.payoutApi.map(id => {
                    const option = payoutData.payoutOptions.find(opt => opt.id === id);
                    return option ? (
                      <Option key={option.id} value={option.id}>
                        {`${option.label} (${option.value})`}
                      </Option>
                    ) : null;
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Button 
            onClick={handlePayoutUpdate} 
            disabled={payoutData.payoutApi.length === 0}
            style={{
              ...styles.button,
              ...(payoutData.payoutApi.length === 0 ? styles.buttonDisabled : {})
            }}
          >
            Update Payout
          </Button>
        </div>
      </Panel>



        <Panel header={<span style={styles.panelHeader}>AEPS</span>} key="aeps" style={styles.panel}>
          {/* <div style={styles.form}>
            <Form.Item label="AEPS On Boarding Default Bank" style={styles.formItem}>
              <Select
                value={aepsData.aeps_onBoardingDefaultBank}
                onChange={(value) => setAepsData(prevState => ({ ...prevState, aeps_onBoardingDefaultBank: value }))}
                style={styles.input}
              >
                <Option value="bank1">BANK 1</Option>
                <Option value="bank2">BANK 2</Option>
               
              </Select>
            </Form.Item>
            <Button 
              onClick={handleAepsUpdate}
              style={styles.button}
            >
              Update AEPS
            </Button>
          </div> */}
          <AEPSSettings/>
        </Panel>
      </Collapse>
      <ToastContainer />
    </div>
  );
}

export default ChargeSettings;