import React from 'react'
import AepsIciciReport from '../../../components/additionalReports/aepsIciciReport/AepsIciciReport'

function AepsIcici() {
  return (
    <>
        <AepsIciciReport/>
    </>
  )
}

export default AepsIcici