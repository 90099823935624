// import React, { useState, useEffect } from 'react';
// import { Form, Select, Checkbox, Button, message, Row, Col, Card } from 'antd';
// import axios from 'axios'; // Assuming you're using axios for API calls

// const { Option } = Select;

// const AEPSSettings = () => {
//   const [services, setServices] = useState([]);
//   const [vendorLists, setVendorLists] = useState({});
//   const [bankList, setBankList] = useState([]);
//   const [selectedBanks, setSelectedBanks] = useState([]);
//   const [subBankLists, setSubBankLists] = useState({});

//   useEffect(() => {
//     fetchServices();
//     fetchBankList();
//   }, []);

//   const fetchServices = async () => {
//     try {
//       const response = await axios.get('http://localhost:3001/api/services');
//       setServices(response.data);
//       // Initialize vendor lists for each service
//       const initialVendorLists = {};
//       response.data.forEach(service => {
//         initialVendorLists[service.id] = [];
//       });
//       setVendorLists(initialVendorLists);
//     } catch (error) {
//       console.error('Error fetching services:', error);
//       message.error('Failed to fetch services');
//     }
//   };

//   const fetchVendorList = async (serviceId) => {
//     try {
//       const response = await axios.get(`http://localhost:3001/api/vendors/${serviceId}`);
//       setVendorLists(prev => ({ ...prev, [serviceId]: response.data }));
//     } catch (error) {
//       console.error(`Error fetching vendor list for service ${serviceId}:`, error);
//       message.error('Failed to fetch vendor list');
//     }
//   };

//   const fetchBankList = async () => {
//     try {
//       const response = await axios.get('http://localhost:3001/api/banks');
//       setBankList(response.data);
//     } catch (error) {
//       console.error('Error fetching bank list:', error);
//       message.error('Failed to fetch bank list');
//     }
//   };

//   const fetchSubBankList = async (bankId) => {
//     try {
//       const response = await axios.get(`http://localhost:3001/api/subbanks/${bankId}`);
//       setSubBankLists(prev => ({ ...prev, [bankId]: response.data }));
//     } catch (error) {
//       console.error(`Error fetching sub-bank list for bank ${bankId}:`, error);
//       message.error('Failed to fetch sub-bank list');
//     }
//   };

//   const handleVendorChange = (serviceId, vendorId) => {
//     // Handle vendor selection for a service
//     console.log(`Service ${serviceId} selected vendor: ${vendorId}`);
//   };

//   const handleBankSelection = (bankId, checked) => {
//     if (checked) {
//       setSelectedBanks(prev => [...prev, bankId]);
//       fetchSubBankList(bankId);
//     } else {
//       setSelectedBanks(prev => prev.filter(id => id !== bankId));
//       setSubBankLists(prev => {
//         const updated = { ...prev };
//         delete updated[bankId];
//         return updated;
//       });
//     }
//   };

//   const handleSubmit = async () => {
//     try {
//       const data = {
//         services: services.map(service => ({
//           id: service.id,
//           vendorId: vendorLists[service.id]?.find(v => v.selected)?.id
//         })),
//         selectedBanks: selectedBanks,
//         subBankLists: Object.entries(subBankLists).reduce((acc, [bankId, subBanks]) => {
//           acc[bankId] = subBanks.filter(sb => sb.selected).map(sb => sb.id);
//           return acc;
//         }, {})
//       };

//       await axios.post('http://localhost:3001/api/updateaepssettings', data);
//       message.success('AEPS settings updated successfully');
//     } catch (error) {
//       console.error('Error updating AEPS settings:', error);
//       message.error('Failed to update AEPS settings');
//     }
//   };

//   return (
//     <div style={{ padding: '20px' }}>
//       <h1>AEPS Settings</h1>

//       <Form layout="vertical" onFinish={handleSubmit}>
//         <Row gutter={[16, 16]}>
//           <Col xs={24} lg={12}>
//             <Card title="Services and Vendors" bordered={false}>
//               {services.map(service => (
//                 <Form.Item key={service.id} label={service.name}>
//                   <Select
//                     style={{ width: '100%' }}
//                     placeholder="Select vendor"
//                     onChange={(vendorId) => handleVendorChange(service.id, vendorId)}
//                     onFocus={() => fetchVendorList(service.id)}
//                   >
//                     {vendorLists[service.id]?.map(vendor => (
//                       <Option key={vendor.id} value={vendor.id}>{vendor.name}</Option>
//                     ))}
//                   </Select>
//                 </Form.Item>
//               ))}
//             </Card>
//           </Col>
          
//           <Col xs={24} lg={12}>
//             <Card title="Bank List" bordered={false}>
//               {bankList.map(bank => (
//                 <Card.Grid style={{ width: '100%', padding: '12px' }} key={bank.id}>
//                   <Checkbox
//                     onChange={(e) => handleBankSelection(bank.id, e.target.checked)}
//                   >
//                     <strong>{bank.name}</strong>
//                   </Checkbox>
//                   {selectedBanks.includes(bank.id) && subBankLists[bank.id] && (
//                     <div style={{ marginLeft: '20px', marginTop: '8px' }}>
//                       {subBankLists[bank.id].map(subBank => (
//                         <Checkbox
//                           key={subBank.id}
//                           onChange={(e) => {
//                             const updatedSubBanks = subBankLists[bank.id].map(sb =>
//                               sb.id === subBank.id ? { ...sb, selected: e.target.checked } : sb
//                             );
//                             setSubBankLists(prev => ({ ...prev, [bank.id]: updatedSubBanks }));
//                           }}
//                         >
//                           {subBank.name}
//                         </Checkbox>
//                       ))}
//                     </div>
//                   )}
//                 </Card.Grid>
//               ))}
//             </Card>
//           </Col>
//         </Row>

//         <Form.Item style={{ marginTop: '20px' }}>
//           <Button type="primary" htmlType="submit" size="large">
//             Update AEPS Settings
//           </Button>
//         </Form.Item>
//       </Form>
    
//     </div>
//   );
// };

// export default AEPSSettings;
import React, { useState, useEffect } from 'react';
import { Form, Select, Checkbox, Button, message, Row, Col, Card, Spin } from 'antd';
import { getServices, getVendorList, getBankList, getSubBankList, updateAEPSSettings } from '../../../../api/login/Login';

const { Option } = Select;

const AEPSSettings = () => {
  const [services, setServices] = useState([]);
  const [vendorLists, setVendorLists] = useState({});
  const [bankList, setBankList] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [subBankLists, setSubBankLists] = useState({});
  const [token, setToken] = useState('');
  
  // Add loading states
  const [isLoading, setIsLoading] = useState({
    services: false,
    vendors: {},
    banks: false,
    subBanks: {},
    submission: false
  });

  // Add error states
  const [errors, setErrors] = useState({
    services: null,
    vendors: {},
    banks: null,
    subBanks: {},
    submission: null
  });
  

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) {
      message.error('Authentication token not found. Please login again.');
      // Redirect to login or handle missing token
      return;
    }
    setToken(userToken);
    initializeData(userToken);
  }, []);

  const initializeData = async (userToken) => {
    await Promise.all([
      fetchServices(userToken),
      fetchBankList(userToken)
    ]);
  };

  const fetchServices = async (userToken) => {
    setIsLoading(prev => ({ ...prev, services: true }));
    setErrors(prev => ({ ...prev, services: null }));
    
    try {
      const response = await getServices(userToken);
      if (response?.data && Array.isArray(response.data)) {
        setServices(response.data);
        const initialVendorLists = {};
        response.data.forEach(service => {
          initialVendorLists[service.id] = [];
        });
        setVendorLists(initialVendorLists);
      } else {
        throw new Error('Invalid service data received');
      }
    } catch (error) {
      console.error('Error fetching services:', error);
      setErrors(prev => ({ ...prev, services: error.message || 'Failed to fetch services' }));
      message.error('Failed to fetch services. Please try refreshing the page.');
    } finally {
      setIsLoading(prev => ({ ...prev, services: false }));
    }
  };

  const fetchVendorList = async (serviceId) => {
    setIsLoading(prev => ({ ...prev, vendors: { ...prev.vendors, [serviceId]: true } }));
    setErrors(prev => ({ ...prev, vendors: { ...prev.vendors, [serviceId]: null } }));

    try {
      const response = await getVendorList(serviceId, token);
      if (response?.data) {
        setVendorLists(prev => ({ ...prev, [serviceId]: response.data }));
      } else {
        throw new Error('Invalid vendor data received');
      }
    } catch (error) {
      console.error(`Error fetching vendor list for service ${serviceId}:`, error);
      setErrors(prev => ({
        ...prev,
        vendors: { ...prev.vendors, [serviceId]: error.message || 'Failed to fetch vendors' }
      }));
      message.error(`Failed to fetch vendors for service ${serviceId}`);
    } finally {
      setIsLoading(prev => ({ ...prev, vendors: { ...prev.vendors, [serviceId]: false } }));
    }
  };

  const fetchBankList = async (userToken) => {
    setIsLoading(prev => ({ ...prev, banks: true }));
    setErrors(prev => ({ ...prev, banks: null }));

    try {
      const response = await getBankList(userToken);
      if (response?.data) {
        setBankList(response.data);
      } else {
        throw new Error('Invalid bank data received');
      }
    } catch (error) {
      console.error('Error fetching bank list:', error);
      setErrors(prev => ({ ...prev, banks: error.message || 'Failed to fetch banks' }));
      message.error('Failed to fetch bank list');
    } finally {
      setIsLoading(prev => ({ ...prev, banks: false }));
    }
  };
  const handleVendorChange = (serviceId, vendorId) => {
    setVendorLists(prev => ({
      ...prev,
      [serviceId]: prev[serviceId].map(vendor => 
        vendor.id === vendorId ? { ...vendor, selected: true } : { ...vendor, selected: false }
      )
    }));
  };

  const fetchSubBankList = async (bankId) => {
    setIsLoading(prev => ({ ...prev, subBanks: { ...prev.subBanks, [bankId]: true } }));
    setErrors(prev => ({ ...prev, subBanks: { ...prev.subBanks, [bankId]: null } }));

    try {
      const response = await getSubBankList(bankId, token);
      if (response?.data) {
        setSubBankLists(prev => ({ ...prev, [bankId]: response.data }));
      } else {
        throw new Error('Invalid sub-bank data received');
      }
    } catch (error) {
      console.error(`Error fetching sub-bank list for bank ${bankId}:`, error);
      setErrors(prev => ({
        ...prev,
        subBanks: { ...prev.subBanks, [bankId]: error.message || 'Failed to fetch sub-banks' }
      }));
      message.error(`Failed to fetch sub-banks for bank ${bankId}`);
    } finally {
      setIsLoading(prev => ({ ...prev, subBanks: { ...prev.subBanks, [bankId]: false } }));
    }
  };

  const handleSubmit = async () => {
    setIsLoading(prev => ({ ...prev, submission: true }));
    setErrors(prev => ({ ...prev, submission: null }));

    try {
      if (!token) {
        throw new Error('Authentication token not found');
      }

      const data = {
        services: services.map(service => ({
          id: service.id,
          vendorId: vendorLists[service.id]?.find(v => v.selected)?.id
        })).filter(service => service.vendorId), // Only include services with selected vendors
        selectedBanks: selectedBanks,
        subBankLists: Object.entries(subBankLists).reduce((acc, [bankId, subBanks]) => {
          if (subBanks) {
            acc[bankId] = subBanks.filter(sb => sb.selected).map(sb => sb.id);
          }
          return acc;
        }, {})
      };

      await updateAEPSSettings(data, token);
      message.success('AEPS settings updated successfully');
    } catch (error) {
      console.error('Error updating AEPS settings:', error);
      setErrors(prev => ({ ...prev, submission: error.message || 'Failed to update settings' }));
      message.error('Failed to update AEPS settings');
    } finally {
      setIsLoading(prev => ({ ...prev, submission: false }));
    }
  };

  if (isLoading.services || isLoading.banks) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
        <p>Loading AEPS settings...</p>
      </div>
    );
  }

  if (errors.services || errors.banks) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <h2>Error occurred please Try after some Time </h2>
        <p>{errors.services || errors.banks}</p>
        {/* <Button onClick={() => initializeData(token)}>Retry Loading</Button> */}
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <h1>AEPS Settings</h1>

      <Form layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <Card title="Services" bordered={false}>
              {services.length > 0 ? (
                services.map(service => (
                  <Form.Item key={service.id} label={service.name}>
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Select vendor"
                      onChange={(vendorId) => handleVendorChange(service.id, vendorId)}
                      onFocus={() => fetchVendorList(service.id)}
                      value={vendorLists[service.id]?.find(v => v.selected)?.id}
                      loading={isLoading.vendors[service.id]}
                      disabled={isLoading.vendors[service.id]}
                    >
                      {vendorLists[service.id]?.map(vendor => (
                        <Option key={vendor.id} value={vendor.id}>{vendor.name}</Option>
                      ))}
                    </Select>
                    {errors.vendors[service.id] && (
                      <div style={{ color: 'red' }}>{errors.vendors[service.id]}</div>
                    )}
                  </Form.Item>
                ))
              ) : (
                <p>No services available. Please try refreshing the page.</p>
              )}
            </Card>
          </Col>
          
          {/* Rest of the JSX remains the same */}
        </Row>

        <Form.Item style={{ marginTop: '20px' }}>
          <Button 
            type="primary" 
            htmlType="submit" 
            size="large"
            loading={isLoading.submission}
            disabled={isLoading.submission}
          >
            Update AEPS Settings
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AEPSSettings;