import React from 'react'
import TurboPaymentRequest from '../../../components/additionalReports/turboPaymentRequest/TurboPaymentRequest'

function TurboElinePage() {
  return (
    <>
    <TurboPaymentRequest/>
    </>

  )
}

export default TurboElinePage