import React from 'react'
import AepsCaash from '../../components/aeps-report/AepsCaash'

function AepsCAshReport() {
  return (
    <>
      <AepsCaash
      />
    </>
  )
}

export default AepsCAshReport
