import React, { useEffect, useState } from 'react'
import PayoutBeneFiciaryReportsList from './payoutBeneFiciaryReportsList/PayoutBeneFiciaryReportsList'
import { useParams } from 'react-router-dom'
import Breadcrumbs from '../../common/breadcrumb/Breadcrumbs'
import { payoutBeneficiaryReports } from '../../api/login/Login'
import PayoutBenificiaryReportsFillter from './payoutBeneficiaryReporrtsFillter/PayoutBenificiaryReportsFillter'

function PayoutBeneFiciaryReports() {
    const breadCrumbsTitle = {
        id: "1",
        title_1: "Payout Beneficiary Reports",
    }
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(0)
    const [aepsData, setAepsData] = useState()
    const [loading, setLoading] = useState(false)
    const [userData, setUserData] = useState(false)
    const token = window.localStorage.getItem('userToken')
    const param = useParams()
    const [initialValues, setIntialValues] = useState({
        start_date: "",
        end_date: "",
        approval_type: "",
        status: ""
    })
    const [operatorId, setOpratorID] = useState("")



    const submitForm = async (values) => {

        setLoading(true);
        setIntialValues({
            ...values, count,
        })
        setPage(0)
        try {

            const res = await payoutBeneficiaryReports({ ...values, page: 0, count, });
            setAepsData(res);
        } catch (error) {
            console.error("Error during AEPS transaction filter:", error);
        } finally {
            setLoading(false);
        }
    };

    const getDmtTxnData = async (num) => {
        setLoading(true)
        try {
            const res = await payoutBeneficiaryReports({ ...initialValues, page: num, count, });
            setAepsData(res);
            setLoading(false)
        } catch (error) {
            alert(error.message)
        }
    }




    const onChangeVal = (e) => {
        setPage(e - 1)
        setTimeout(() => {
            getDmtTxnData(e - 1)
        }, 1000);
    };

    useEffect(() => {
        getDmtTxnData(0)
    }, [])
    return (
        <>
            <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />
            <PayoutBenificiaryReportsFillter initialValues={initialValues} setIntialValues={setIntialValues} submitForm={submitForm} aepsData={aepsData} getDmtTxnData={getDmtTxnData} page={page} />
            <PayoutBeneFiciaryReportsList onChangeVal={onChangeVal} aepsData={aepsData} getDmtTxnData={getDmtTxnData} />
        </>
    )
}

export default PayoutBeneFiciaryReports
