import React from 'react'
import Reasons from '../../../components/dmtModule/reasons/Reasons'

function ReasonsPage() {
  return (
    <>
      <Reasons/>
    </>
  )
}

export default ReasonsPage
