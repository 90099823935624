import React from 'react'
import CreditReport from '../../components/reportMaster/creditReport/CreditReport'

function CreditReportPage() {
  return (
    <>
      <CreditReport/>
    </>
  )
}

export default CreditReportPage
