import { Link, useNavigate, useParams } from "react-router-dom"
import { Field, Formik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from "react";
import CustomInputField from "../../../common/CustomInputField";
import Breadcrumbs from "../../../common/breadcrumb/Breadcrumbs";
import { getvendor,addPayout } from "../../../api/login/Login";


function ChargesPayoutForm() {
    const breadCrumbsTitle = {
        id: "1",
        title_1: "Commision",
        title_2: "Add Payout Reports",
    }

    const [vendors, setVendors] = useState([]);
    const [initialValues, setInitialValues] = useState({
        vender_name: "",
        start_amt: "",
        end_amt: "",
        charge: "",
        charge_type: "",
        tax: "",
        tax_type: "",
        payout_txn_type: ""
    });

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchVendors();
    }, []);

    const fetchVendors = async () => {
        try {
            const response = await getvendor();
            if (response.statusCode === 200) {
                setVendors(response.data);
            }
        } catch (error) {
            console.error("Error fetching vendors:", error);
            toast.error("Failed to fetch vendors");
        }
    };

    const validate = (values) => {
        let errors = {};

        if (!values.vender_name) {
            errors.vender_name = "Vendor is required";
        }
        if (!values.start_amt) {
            errors.start_amt = "Min Amt is required";
        }
        if (!values.end_amt) {
            errors.end_amt = "Max Amt is required";
        }
        if (!values.charge) {
            errors.charge = "Charge is required";
        }
        if (!values.charge_type) {
            errors.charge_type = "Charge Type is required";
        }
        if (!values.tax) {
            errors.tax = "Tax is required";
        }
        if (!values.tax_type) {
            errors.tax_type = "Tax Type is required";
        }
        if (!values.payout_txn_type) {  // Add this validation
            errors.payout_txn_type = "Payout Transaction Type is required";
        }
        return errors;
    };

    const submitForm = async (values) => {
        
        try {   
            const response = await addPayout(values);
            console.log("response",response)
            if (response?.statusCode === 200) {
                toast.success(response?.message || "Payout charges added successfully");
                setTimeout(() => {
                    navigate('/admin/payout-charges');
                }, 2000);
            } else {
                toast.error( response?.message || "Failed to add payout charges");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error("Failed to add payout charges");
        }
    };

    return (
        <>
            <Breadcrumbs breadCrumbsTitle={breadCrumbsTitle} />
            <div className="row m-4">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1">
                                <div className="tbl-caption tbl-caption-2">
                                    <h4 className="heading mb-0">ADD PAYOUT</h4>
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    validate={validate}
                                    onSubmit={submitForm}
                                    enableReinitialize
                                >
                                    {(formik) => {
                                        const {
                                            values,
                                            handleChange,
                                            handleSubmit,
                                            errors,
                                            touched,
                                            handleBlur,
                                            isValid,
                                            dirty,
                                        } = formik;
                                        return (
                                            <form className="tbl-captionn" onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-xl-6 mb-3">
                                                        <Field 
                                                            as="select" 
                                                            name="vender_name" 
                                                            className="form-select" 
                                                            onChange={handleChange} 
                                                            onBlur={handleBlur}
                                                        >
                                                            <option value="">Select Vendor</option>
                                                            {vendors.map(vendor => (
                                                                <option key={vendor._id} value={vendor.vender_name}>
                                                                    {vendor.vender_name}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                        {errors.vender_name && touched.vender_name && (
                                                            <div className="text-danger">{errors.vender_name}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-xl-6 mb-3">
                                                        <CustomInputField
                                                            type="number"
                                                            value={values?.start_amt}
                                                            hasError={errors.start_amt && touched.start_amt}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.start_amt}
                                                            id="start_amt"
                                                            name="start_amt"
                                                            placeholder="Min Amount"
                                                        />
                                                    </div>

                                                    <div className="col-xl-6 mb-3">
                                                        <CustomInputField
                                                            type="number"
                                                            value={values?.end_amt}
                                                            hasError={errors.end_amt && touched.end_amt}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.end_amt}
                                                            id="end_amt"
                                                            name="end_amt"
                                                            placeholder="Max Amount"
                                                        />
                                                    </div>

                                                    <div className="col-xl-6 mb-3">
                                                        <CustomInputField
                                                            type="number"
                                                            value={values?.charge}
                                                            hasError={errors.charge && touched.charge}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.charge}
                                                            id="charge"
                                                            name="charge"
                                                            placeholder="Charge"
                                                        />
                                                    </div>

                                                    <div className="col-xl-6 mb-3">
                                                        <Field 
                                                            as="select" 
                                                            name="charge_type" 
                                                            className="form-select"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option value="">Select Charge Type</option>
                                                            <option value="Percent">Percent</option>
                                                            <option value="Flat">Flat</option>
                                                        </Field>
                                                        {errors.charge_type && touched.charge_type && (
                                                            <div className="text-danger">{errors.charge_type}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-xl-6 mb-3">
                                                        <CustomInputField
                                                            type="number"
                                                            value={values?.tax}
                                                            hasError={errors.tax && touched.tax}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.tax}
                                                            id="tax"
                                                            name="tax"
                                                            placeholder="Tax"
                                                        />
                                                    </div>

                                                    <div className="col-xl-6 mb-3">
                                                        <Field 
                                                            as="select" 
                                                            name="tax_type" 
                                                            className="form-select"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option value="">Select Tax Type</option>
                                                            <option value="percentage">Percentage</option>
                                                            <option value="fixed">Fixed</option>
                                                        </Field>
                                                        {errors.tax_type && touched.tax_type && (
                                                            <div className="text-danger">{errors.tax_type}</div>
                                                        )}
                                                    </div>
                                                    <div className="col-xl-6 mb-3">
                                                        <Field
                                                            as="select"
                                                            name="payout_txn_type"
                                                            className="form-select"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option value="">Select Payout Transaction Type</option>
                                                            <option value="NEFT">NEFT</option>
                                                            <option value="IMPS">IMPS</option>
                                                            {/* <option value="RTGS">RTGS</option>  */}
                                                        </Field>
                                                        {errors.payout_txn_type && touched.payout_txn_type && (
                                                            <div className="text-danger">{errors.payout_txn_type}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-xl-6 mb-4 mt-2">
                                                        <div>
                                                            <Link to='/admin/payout-charges' className="btn btn-danger light ms-1">Cancel</Link>
                                                            <button
                                                                className="btn btn-primary me-1"
                                                                type="submit"
                                                                disabled={!isValid || !dirty}
                                                            >
                                                                Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer className="text-center" />
        </>
    )
}

export default ChargesPayoutForm