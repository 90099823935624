import React from 'react'
import PostPaidBill from '../../../components/additionalReports/postPaidBill/PostPaidBill'

function PostPaidBillPage() {
  return (
    <>
    <PostPaidBill/>
    </>

  )
}

export default PostPaidBillPage