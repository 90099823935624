import LanguageList from "./languageList/LanguageList"


function Language() {
    return (
        <>
            <LanguageList />
        </>
    )
}
export default Language