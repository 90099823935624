import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Formik } from 'formik';
import Loadar from '../../common/loader/Loader';
import CustomPassInputField from '../../common/CustomPassInputField';
import { changePin, sendForgotPinOtp } from '../../api/login/Login';
import Alert from '../../common/AlertMessages';
import Button from 'react-bootstrap/Button';
import ForgotPinModal from './forgotPin/ForgotPinForm';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify styles
import { useNavigate } from 'react-router-dom';


function ChangePin() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [forgetText, setForgetText] = useState("FORGOT PIN");
    const [errorMessage, setErrorMessage] = useState(false);
    const token = window.localStorage.getItem("userToken");
    const [modalShow, setModalShow] = useState(false);
    const [initialValues, setInitialValues] = useState({
        user_id: token,
        oldPin: '',
        newPin: '',
        confirmPin: ''
    });

    const [resendEnabled, setResendEnabled] = useState(false);
    const [timer, setTimer] = useState(120);

    useEffect(() => {
        let interval;
        if (resendEnabled === false && modalShow) {

            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setResendEnabled(true);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [modalShow, resendEnabled]);


    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const validate = (values) => {
        let errors = {};
        const regexPassword = /^\d{4}$/;

        if (!values?.oldPin) {
            errors.oldPin = "Old PIN is required";
        }

        if (!values?.newPin) {
            errors.newPin = "New PIN is required";
        } else if (!regexPassword.test(values?.newPin)) {
            errors.newPin = "Enter a valid 4-digit PIN.";
        }

        if (!values?.confirmPin) {
            errors.confirmPin = "Confirm PIN is required";
        } else if (values?.newPin !== values?.confirmPin) {
            errors.confirmPin = "Confirm PIN must match New PIN";
        }

        return errors;
    };

    const toastSuccessMessage = (message) => {
        toast.success("PIN CHANGED SUCCESSFULLY", {
            position: "top-right",
        });
    };

    const toastSuccessMessageOtpSentSuccess = () => {
        toast.success("OTP SENT SUCCESSFULLY TO REGISTERED MOBILE NO", {
            position: "top-right",
        });
    };

    const toastSuccessMessage_resend = () => {
        toast.success("OTP RESENT SUCCESSFULLY", {
            position: "top-right",
        });
    };

    const toastErrorMessage = (message) => {
        toast.error(`${message}`, {
            position: "top-right",
        });
    };
    const toastSuccessMessage2 = (message) => {
        toast.success(`${message}`, {
            position: "top-right",
        });
    };

    const submitForm = async (values) => {
        try {
            setLoading(true);
            const res = await changePin(values);
 
            if (res.statusCode == "200") {
                toastSuccessMessage();
                setTimeout(() => {
                    navigate("/admin");
                }, 600);
               
                setInitialValues({
                    user_id: token,
                    oldPin: '',
                    newPin: '',
                    confirmPin: ''
                });
                
            } else if (res.statusCode == "403") {
                toastErrorMessage(res?.message);
            }
            else{
                toastErrorMessage(res?.message);
            }
            setLoading(false);
        } catch (error) {
            setErrorMessage(error.message);
            setLoading(false);
        }
    };

    const handleForgotPin = async () => {
        setModalShow(true);
        setResendEnabled(false);
        setTimer(120);

        try {
            const res = await sendForgotPinOtp(token);
            // toastSuccessMessageOtpSentSuccess();
            if (res.statusCode == "200") {
                toastSuccessMessageOtpSentSuccess();
              
              
            } else {
                toastErrorMessage(res?.message || "Failed to rensend OTP. Please try again.");
            }
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    const handleForgotPin_resend = async () => {
        setTimer(120);
        try {
            const res = await sendForgotPinOtp(token);
         
            if (res.statusCode == "200") {
                toastSuccessMessage_resend();
                setResendEnabled(false); // Disable the resend button
            setTimer(120); // Restart timer to 120 seconds
              
              
            } else {
                toastErrorMessage(res?.message || "Failed to rensend OTP. Please try again.");
            }
        } catch (error) {
            setErrorMessage(error.message);
        }
    };
    const resetTimer = () => {
        setResendEnabled(false);
        setTimer(120); // Reset the timer back to 120 seconds
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={submitForm}
                className="tbl-captionn"
                enableReinitialize
            >
                {(formik) => {
                    const {
                        values,
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        handleBlur,
                        isValid,
                        dirty,
                    } = formik;
                    return (
                        <div className="row m-4">
                            {loading && <Loadar />}
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive active-projects style-1">
                                            <div className="tbl-caption">
                                                <h4 className="heading mb-0">Change PIN</h4>
                                                <div>
                                                    <Button className="py-0 px-2 bg-danger rounded-2 fs-6" onClick={handleForgotPin} >
                                                        Forgot Tpin
                                                    </Button>
                                                </div>
                                            </div>
                                            <form className="tbl-captionn" onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-xl-6 mb-3">
                                                        <CustomPassInputField
                                                            type="password"
                                                            placeholder="Enter Your Old PIN"
                                                            value={values.oldPin}
                                                            hasError={errors.oldPin && touched.oldPin}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.oldPin}
                                                            id="oldPin"
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 mb-3">
                                                        <CustomPassInputField
                                                            type="password"
                                                            placeholder="Enter New PIN"
                                                            value={values.newPin}
                                                            hasError={errors.newPin && touched.newPin}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.newPin}
                                                            id="newPin"
                                                        />
                                                    </div>
                                                    <div className="col-xl-6 mb-3">
                                                        <CustomPassInputField
                                                            type="password"
                                                            placeholder="Enter Confirm PIN"
                                                            value={values.confirmPin}
                                                            hasError={errors.confirmPin && touched.confirmPin}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMsg={errors.confirmPin}
                                                            id="confirmPin"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-center'>
                                                    <button className="btn btn-primary me-1" type="submit" disabled={!isValid || !dirty}>
                                                        Change PIN
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer className="text-center text-uppercase" />
                        </div>
                    );
                }}
            </Formik>
            <ForgotPinModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                handleForgotPin={handleForgotPin_resend}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                resendEnabled={resendEnabled}
                timer={formatTime(timer)}
                resetTimer={resetTimer} 
            />
        </>
    );
}

export default ChangePin;
