import React from 'react'
import SummaryReports from './summaryReports/SummaryReports'

function SalesReports() {
  return (
    <>
     <SummaryReports/>
    </>
  )
}

export default SalesReports
