import React from 'react'
import DebitReport from '../../components/reportMaster/debitReport/DebitReport'

function DebitReportPage() {
  return (
    <>
      <DebitReport/>
    </>
  )
}

export default DebitReportPage
