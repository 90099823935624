import React from 'react'
import QuickDhanTxn from '../../components/aepsMaster/quickDhanTxn/QuickDhanTxn'

function QuickDhanTxnPage() {
    return (
        <>
            <QuickDhanTxn />
        </>
    )
}

export default QuickDhanTxnPage
