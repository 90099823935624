import React from 'react'
import AlltransationsReport from '../../components/reportMaster/alltransationsReports/AlltransationsReport'

function AlltransportationReportsPage() {
  return (
    <>
      <AlltransationsReport/>
    </>
  )
}

export default AlltransportationReportsPage
