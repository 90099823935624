// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
// import { 
//   FaStore, FaUserTie, FaUsers, FaMoneyBillWave, FaClipboardList, 
//   FaExchangeAlt, FaPercentage, FaFileAlt, FaCalculator, FaChartBar, 
//   FaIdCard, FaBook, FaWallet 
// } from 'react-icons/fa';
// import './Dashboard.css';
// import { ledgerReport } from '../../api/login/Login';
// import { useNavigate } from 'react-router-dom';

// const DashboardCards = ({ count, dasboradData, walletdata }) => {
//   console.log("dasboradData", dasboradData);
//   console.log("walletdata", walletdata);
//   console.log("count", count);

//   const [activeTab, setActiveTab] = useState('members');
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const data = await ledgerReport();
//         console.log('Ledger report data:', data);
//       } catch (error) {
//         console.error('Error fetching commission service report data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   // Function to get icon based on member type
//   const getMemberIcon = (memberType) => {
//     switch (memberType?.toLowerCase()) {
//       case 'retailer':
//         return <FaStore />;
//       case 'distributor':
//         return <FaUserTie />;
//       default:
//         return <FaUsers />;
//     }
//   };

//   // Function to get color based on index
//   const getColor = (index) => {
//     const colors = ['#FFA500', '#32CD32', '#9370DB', '#4a90e2', '#e67e22', '#3498db', '#2ecc71', '#1abc9c', '#8e44ad', '#e74c3c'];
//     return colors[index % colors.length];
//   };

//   // Cards for wallets tab
//   const walletCards = [
//     { 
//       icon: <FaWallet />, 
//       color: '#4a90e2', 
//       title: 'Main Wallet Balance', 
//       data: walletdata?.total_user_main_wallet || '0.00',
//       navigateTo: '/admin/all-commision'
//     },
//     { 
//       icon: <FaWallet />, 
//       color: '#e67e22', 
//       title: 'AEPS Wallet Balance', 
//       data: walletdata?.total_user_aeps_wallet || '0.00',
//       navigateTo: '/admin/aepswallet'
//     }
//   ];

//   // Cards for transaction statistics
//   const transactionCards = [
//     { icon: <FaMoneyBillWave />, color: '#3498db', title: 'Domestic Money Transfer', data: dasboradData?.dmt?.success || 0 },
//     { icon: <FaClipboardList />, color: '#2ecc71', title: 'Total Fund Requests Received', data: dasboradData?.paymentRequest?.totalAmount || 0 },
//     { icon: <FaExchangeAlt />, color: '#1abc9c', title: 'Total Fund Transfer', data: dasboradData?.paymentTransfer || 0 },
//     { icon: <FaPercentage />, color: '#8e44ad', title: 'Total Commission', data: dasboradData?.commission?.companyProfit || 0 },
//     { icon: <FaIdCard />, color: '#e74c3c', title: 'KYC Pending', data: dasboradData?.userKycPending?.totalAmount || 0 }
//   ];

//   // Cards for report statistics
//   const reportCards = [
//     { icon: <FaFileAlt />, color: '#f39c12', title: 'Commission Report', navigateTo: '/admin/business-report' },
//     { icon: <FaCalculator />, color: '#d35400', title: 'TDS Report', navigateTo: '/admin/tds-report' },
//     { icon: <FaChartBar />, color: '#16a085', title: 'Charge Report', navigateTo: '/admin/charge-report' },
//     { icon: <FaBook />, color: '#2980b9', title: 'Ledger Report', navigateTo: '/admin/ledger-report' }
//   ];

//   const renderMemberCards = () => {
//     if (!Array.isArray(count) || count.length === 0) {
//       return <p>No member data available</p>;
//     }

//     return (
//       <Row className="g-4">
//         {count.map((item, index) => (
//           <Col key={index} xs={12} sm={6} md={4} lg={4} className="mb-4">
//             <div
//               className="dashboard-card"
//               style={{ backgroundColor: getColor(index) }}
//               onClick={() => {
//                 if (item._id && item.memberType) {
//                   navigate(`/admin/member-list/${item._id}/${item.memberType}`);
//                 } else {
//                   navigate('/admin');
//                 }
//               }}
//             >
//               <div className="icon-wrapper">
//                 {React.cloneElement(getMemberIcon(item.memberType), { className: 'card-icon' })}
//               </div>
//               <div className="card-content">
//                 <h2 className="card-count">{item.count || '0'}</h2>
//                 <p className="card-title">{item.memberType || 'Unknown'}</p>
//               </div>
//             </div>
//           </Col>
//         ))}
//       </Row>
//     );
//   };

//   // Render function for cards
//   const renderCards = (cardData, items = []) => {
//     return (
//       <Row className="g-4">
//         {cardData.map((card, index) => {
//           const item = items[index] || {};
//           console.log("Rendering card:", card, item);
          
//           return (
//             <Col key={index} xs={12} sm={6} md={4} lg={4} className="mb-4">
//               <div
//                 className="dashboard-card"
//                 style={{ backgroundColor: card.color || getColor(index) }}
//                 onClick={() => {
//                   if (card.navigateTo) {
//                     navigate(card.navigateTo);
//                   } else {
//                     navigate('/admin');
//                   }
//                 }}
//               >
//                 <div className="icon-wrapper">
//                   {React.cloneElement(card.icon || <FaUsers />, { className: 'card-icon' })}
//                 </div>
//                 <div className="card-content">
//                   <h2 className="card-count">{card.data || '0'}</h2>
//                   <p className="card-title">{card.title || ''}</p>
//                 </div>
//               </div>
//             </Col>
//           );
//         })}
//       </Row>
//     );
//   };

//   return (
//     <div className="dashboard-container">
//       <Tabs
//         activeKey={activeTab}
//         onSelect={(k) => setActiveTab(k)}
//         className="mb-3 dashboard-tabs"
//       >
//         <Tab eventKey="members" title="Member Statistics">
//           {renderMemberCards()}
//         </Tab>
//         <Tab eventKey="wallets" title="Wallet Balances">
//           {renderCards(walletCards)}
//         </Tab>
//         <Tab eventKey="transactions" title="Transaction Statistics">
//           {renderCards(transactionCards)}
//         </Tab>
//         <Tab eventKey="reports" title="Report Statistics">
//           {renderCards(reportCards)}
//         </Tab>
//       </Tabs>
//     </div>
//   );
// };

// export default DashboardCards;

import React, { useState, useEffect } from 'react';
import { 
  FaStore, FaUserTie, FaUsers, FaMoneyBillWave, FaClipboardList, 
  FaExchangeAlt, FaPercentage, FaFileAlt, FaCalculator, FaChartBar, 
  FaIdCard, FaBook, FaWallet 
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ledgerReport } from '../../api/login/Login';
import './Dashboard.css';
const DashboardCards = ({ count, dasboradData, walletdata }) => {
  console.log("dasboradData",dasboradData?.dmt?.success)
  const [activeTab, setActiveTab] = useState('members');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ledgerReport();
        console.log('Ledger report data:', data);
      } catch (error) {
        console.error('Error fetching commission service report data:', error);
      }
    };

    fetchData();
  }, []);

  const getMemberIcon = (memberType) => {
    switch (memberType?.toLowerCase()) {
      case 'retailer': return <FaStore />;
      case 'distributor': return <FaUserTie />;
      default: return <FaUsers />;
    }
  };

  const getColor = (index) => {
    const colors = [
      'card-gradient-blue',
      'card-gradient-green',
      'card-gradient-purple',
      'card-gradient-orange',
      'card-gradient-pink',
      'card-gradient-teal'
    ];
    return colors[index % colors.length];
  };

  const walletCards = [
    { 
      icon: <FaWallet />, 
      colorClass: 'card-gradient-green', 
      title: 'Main Wallet Balance', 
      data: walletdata?.total_user_main_wallet || '0.00',
      navigateTo: '/admin/all-commision'
    },
    { 
      icon: <FaWallet />, 
      colorClass: 'card-gradient-teal', 
      title: 'AEPS Wallet Balance', 
      data: walletdata?.total_user_aeps_wallet || '0.00',
      navigateTo: '/admin/aepswallet'
    }
  ];

  const transactionCards = [
    { icon: <FaMoneyBillWave />, colorClass: 'card-gradient-teal', title: 'Domestic Money Transfer', data: dasboradData?.dmt?.success || 0 },
    { icon: <FaClipboardList />, colorClass: 'card-gradient-green', title: 'Total Fund Requests', data: dasboradData?.paymentRequest?.totalAmount || 0 },
    { icon: <FaExchangeAlt />, colorClass: 'card-gradient-blue', title: 'Total Fund Transfer', data: dasboradData?.paymentTransfer || 0 },
    { icon: <FaPercentage />, colorClass: 'card-gradient-purple', title: 'Company Profit', data: dasboradData?.commission?.companyProfit || 0 },
    { icon: <FaIdCard />, colorClass: 'card-gradient-pink', title: 'KYC Pending', data: dasboradData?.userKycPending?.totalAmount || 0 }
  ];

  const reportCards = [
    { icon: <FaFileAlt />, colorClass: 'card-gradient-orange', title: 'Commission Report', navigateTo: '/admin/business-report' },
    { icon: <FaCalculator />, colorClass: 'card-gradient-red', title: 'TDS Report', navigateTo: '/admin/tds-report' },
    { icon: <FaChartBar />, colorClass: 'card-gradient-teal', title: 'Charge Report', navigateTo: '/admin/charge-report' },
    { icon: <FaBook />, colorClass: 'card-gradient-blue', title: 'Ledger Report', navigateTo: '/admin/ledger-report' }
  ];

  const renderMemberCards = () => {
    if (!Array.isArray(count) || count.length === 0) {
      return (
        <div className="dashboard-empty">
          No member data available
        </div>
      );
    }

    return (
      <div className="dashboard-grid">
        {count.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              if (item._id && item.memberType) {
                navigate(`/admin/member-list/${item._id}/${item.memberType}`);
              } else {
                navigate('/admin');
              }
            }}
            className={`dashboard-card ${getColor(index)}`}
          >
            <div className="card-content">
              <div className="card-icon-wrapper">
                {React.cloneElement(getMemberIcon(item.memberType), { className: 'card-icon' })}
              </div>
              <div className="card-info">
                <h3 className="card-value">{item.count || ''}</h3>
                <p className="card-label">{item.memberType || 'Unknown'}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderCards = (cardData) => {
    return (
      <div className="dashboard-grid">
        {cardData.map((card, index) => (
          <div
            key={index}
            onClick={() => card.navigateTo && navigate(card.navigateTo)}
            className={`dashboard-card ${card.colorClass}`}
          >
            <div className="card-content">
              <div className="card-icon-wrapper">
                {React.cloneElement(card.icon, { className: 'card-icon' })}
              </div>
              <div className="card-info">
                <h3 className="card-value">{card.data || ''}</h3>
                <p className="card-label">{card.title}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-tabs">
        {[
          { key: 'members', label: 'Member Statistics' },
          { key: 'wallets', label: 'Wallet Balances' },
          { key: 'transactions', label: 'Transaction Statistics' },
          { key: 'reports', label: 'Report Statistics' }
        ].map((tab) => (
          <button
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            className={`dashboard-tab ${activeTab === tab.key ? 'active' : ''}`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="dashboard-content">
        {activeTab === 'members' && renderMemberCards()}
        {activeTab === 'wallets' && renderCards(walletCards)}
        {activeTab === 'transactions' && renderCards(transactionCards)}
        {activeTab === 'reports' && renderCards(reportCards)}
      </div>
    </div>
  );
};

export default DashboardCards;