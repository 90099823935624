import React from 'react'
import FastTagReports from '../../../components/additionalReports/fastTagReports/FastTagReports'

function FastTagReportsPage() {
  return (
    <>
    <FastTagReports/>
    </>

  )
}

export default FastTagReportsPage