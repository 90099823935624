import React from 'react'
import PaymetGatewayReport from '../../../components/additionalReports/paymentGatwayRep/PaymetGatewayReport'

function PaymentGatewayPage() {
  return (
    <>
    <PaymetGatewayReport/>
    </>
  )
}

export default PaymentGatewayPage