import React, { useState, useEffect } from 'react';
import { 
  Table, 
  Card, 
  message, 
  Spin, 
  Empty, 
  Pagination, 
  Typography,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Space
} from 'antd';
import { SearchOutlined, ReloadOutlined ,DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getReversepaylist ,getReversepaylistexcel } from '../../api/login/Login';
import * as XLSX from 'xlsx';
import { DatePicker } from 'antd';
const { Title } = Typography;
const { Option } = Select;

const ReversePaymentReport = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  const [filters, setFilters] = useState({});

  // Fetch data function
  const fetchData = async (page = 1, pageSize = 10, searchParams = {}) => {
    setLoading(true);
    setError(null);
    try {
      const response = await getReversepaylist({
        params: {
          service_id: '',
          package_id: '',
          count: pageSize,
          page: page - 1,
          ...searchParams
        }
      });

      if (!response.data.error) {
        setData(response.data.data.requestList || []);
        setPagination({
          ...pagination,
          current: page,
          total: response.data.data.totalCount || 0
        });
      } else {
        throw new Error(response.data.message || 'Failed to fetch data');
      }
    } catch (err) {
      setError(err.message || 'Something went wrong');
      message.error(err.message || 'Failed to fetch reverse payment data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (values) => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== undefined && value !== '')
    );
  
    // Convert date values to strings
    if (filteredValues.start_date) {
      filteredValues.start_date = moment(filteredValues.start_date).format('YYYY-MM-DD');
    }
    if (filteredValues.end_date) {
      filteredValues.end_date = moment(filteredValues.end_date).format('YYYY-MM-DD');
    }
  
    setFilters(filteredValues);
    fetchData(1, pagination.pageSize, filteredValues);
  };
  

  const handleReset = () => {
    form.resetFields();
    setFilters({});
    fetchData(1, pagination.pageSize);
  };

  const handlePageChange = (page, pageSize) => {
    fetchData(page, pageSize, filters);
  };

  // const handleDownloadExcel = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await getReversepaylistexcel({ params: filters });
  //     if (!response.data.error) {
  //       const data = response.data.data.requestList || [];
  //       // Map fields for Excel
  //       const excelData = data.map((item) => ({
  //         ...item,
  //         start_date: moment(item.start_date).format('YYYY-MM-DD'),
  //         end_date: moment(item.end_date).format('YYYY-MM-DD'),
  //       }));
  //       const worksheet = XLSX.utils.json_to_sheet(excelData);
  //       const workbook = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(workbook, worksheet, 'Reverse Payments');
  //       XLSX.writeFile(workbook, 'Reverse_Payment_Report.xlsx');
  //       message.success('Excel file downloaded successfully');
  //     } else {
  //       throw new Error(response.data.message || 'Failed to download Excel');
  //     }
  //   } catch (err) {
  //     message.error(err.message || 'Failed to download Excel');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDownloadExcel = async () => {
    setLoading(true);
    try {
      const response = await getReversepaylistexcel({ params: filters });
      if (!response.data.error) {
        const data = response.data.data.requestList || [];
        // Map fields for Excel, excluding _id
        const excelData = data.map(({ _id, ...rest }) => ({
          ...rest,
          start_date: moment(rest.start_date).format('YYYY-MM-DD'),
          end_date: moment(rest.end_date).format('YYYY-MM-DD'),
        }));
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Reverse Payments');
        XLSX.writeFile(workbook, 'Reverse_Payment_Report.xlsx');
        message.success('Excel file downloaded successfully');
      } else {
        throw new Error(response.data.message || 'Failed to download Excel');
      }
    } catch (err) {
      message.error(err.message || 'Failed to download Excel');
    } finally {
      setLoading(false);
    }
  };
  
  

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, __, index) => (pagination.current - 1) * pagination.pageSize + index + 1
    },
    {
      title: 'Transaction ID',
      dataIndex: 'txn_id',
      key: 'txn_id',
      render: (text) => text || '-'
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (text) => text || '-'
    },
    {
      title: 'Member Code',
      dataIndex: 'member_code',
      key: 'member_code',
      render: (text) => text || '-'
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      key: 'user_type',
      render: (text) => text || '-'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => <span>₹{text?.toFixed(2) || '0.00'}</span>
    },
    {
      title: 'Staff Name',
      dataIndex: 'staffName',
      key: 'staffName',
      render: (text) => text || '-'
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => text || '-'
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => text || '-'
    }
  ];

  return (
    <Card className="m-4">
      <Card className="m-4" title={<Title level={4} className="mb-0">Reverse Payment Report</Title>}>
        
      </Card>
      
      <div className="p-4">
        <Form
          form={form}
          onFinish={handleSearch}
          className="mb-4"
          layout="vertical"
        >
           <Row gutter={16}>
           <Col xs={24} sm={12} md={6} lg={6}>
    <Form.Item name="start_date" label="Start Date">
      <DatePicker
        style={{ width: '100%' }}
        format="YYYY-MM-DD"
        placeholder="Select start date"
      />
    </Form.Item>
  </Col>
  <Col xs={24} sm={12} md={6} lg={6}>
    <Form.Item name="end_date" label="End Date">
      <DatePicker
        style={{ width: '100%' }}
        format="YYYY-MM-DD"
        placeholder="Select end date"
      />
    </Form.Item>
  </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
              <Form.Item name="amount" label="Amount">
                <Input
                  placeholder="Enter amount"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
              <Form.Item name="txn_id" label="Transaction ID">
                <Input
                  placeholder="Enter transaction ID"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
              <Form.Item name="username" label="Username">
                <Input
                  placeholder="Enter username"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
              <Form.Item name="member_code" label="Member Code">
                <Input
                  placeholder="Enter member code"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
              <Form.Item name="user_type" label="User Type">
                <Select placeholder="Select user type" allowClear>
                  <Option value="Retailer">Retailer</Option>
                  <Option value="Distributor">Distributor</Option>
                  <Option value="Super Distributor">Super Distributor</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
              <Form.Item name="staffName" label="Staff Name">
                <Input
                  placeholder="Enter staff name"
                />
              </Form.Item>
            </Col>
          </Row>
          
          <Row>
            <Col span={24}>
              <Space>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                  Search
                </Button>
                <Button onClick={handleReset} icon={<ReloadOutlined />}>
                  Reset
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>

        <div className='btn-align-right' style={{ 
    display: 'flex', 
    justifyContent: 'flex-end', 
    marginBottom: '1rem' 
  }}>
        <Button 
          type="primary" 
          icon={<DownloadOutlined />} 
          onClick={handleDownloadExcel}
          className="mb-4"
        >
          Download Excel
        </Button>
        </div>

        {error && (
          <div className="alert alert-danger mb-4">
            {error}
          </div>
        )}

        <Spin spinning={loading}>
          {data.length > 0 ? (
            <>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record._id}
                className="custom-table"
                scroll={{ x: true }}
              />
              <div className="mt-4 d-flex justify-content-between align-items-center">
                <div>
                  Total {pagination.total} entries
                </div>
                <Pagination
                  current={pagination.current}
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                  showQuickJumper
                  showTotal={(total) => `Total ${total} items`}
                />
              </div>
            </>
          ) : (
            <Empty
              description={
                <span>
                  {loading ? 'Loading data...' : 'No reverse payment data found'}
                </span>
              }
            />
          )}
        </Spin>
      </div>
    </Card>
  );
};

export default ReversePaymentReport;