import React, { useEffect, useState } from 'react';
import { 
  Card, DatePicker, Button, Row, Col, Alert, Spin, Typography, 
  Statistic, Progress, Space, Badge 
} from 'antd';
import { 
  DollarCircleOutlined, 
  BankOutlined,
  WalletOutlined,
  IdcardOutlined,
  CreditCardOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  PayCircleOutlined,
  TransactionOutlined,
  BarcodeOutlined
} from '@ant-design/icons';
import { DashboardGet } from '../../api/login/Login';
import dayjs from 'dayjs';
import ServicesList from './ServicesList';
const { Title, Text } = Typography;




const TransactionDashboard = ({ initialDashboardData }) => {
    const today = dayjs();
    console.log("initialDashboardData",initialDashboardData)
  const [selectedDate, setSelectedDate] = useState(today);
  const [currentData, setCurrentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
//   const today = new Date();  // Make sure today is defined
//   const currentdate = `${today.getFullYear()}-${(today.getMonth() + 1)
//     .toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`


  // Safely get nested object values
  const safeGetValue = (obj, path, defaultValue = 0) => {
    try {
      return path.split('.').reduce((acc, key) => 
        (acc && acc[key] !== undefined) ? acc[key] : defaultValue, obj);
    } catch (error) {
      console.error(`Error accessing path ${path}:`, error);
      return defaultValue;
    }
  };

  useEffect(() => {
    if (initialDashboardData) {
      console.log("Received initialDashboardData:", initialDashboardData);
      setCurrentData(initialDashboardData);
    }
  }, [initialDashboardData]);



  const calculateTotal = (data, key) => {
    try {
      if (!data || typeof data !== 'object') {
        console.warn('Invalid data provided to calculateTotal');
        return 0;
      }
      return Object.values(data).reduce((acc, curr) => {
        const value = safeGetValue(curr, key, 0);
        return acc + (Number.isFinite(value) ? value : 0);
      }, 0);
    } catch (error) {
      console.error('Error calculating total:', error);
      return 0;
    }
  };

  const handleSearch = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const formattedDate = selectedDate ? selectedDate.format('YYYY-MM-DD') : '';
      const response = await DashboardGet({ data: formattedDate });
      if (!response?.data) {
        throw new Error('No data received from server');
      }
      setCurrentData(response.data);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch dashboard data';
      setError(`Error: ${errorMessage}. Please try again later.`);
      console.error('Dashboard fetch error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearDates = async () => {
    setSelectedDate(null);
    setIsLoading(true);
    setError(null);
    try {
      const response = await DashboardGet({ data: '' });
      if (!response?.data) {
        throw new Error('No data received from server');
      }
      setCurrentData(response.data);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to reset dashboard';
      setError(`Error: ${errorMessage}`);
      console.error('Dashboard reset error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const serviceMetrics = [
    {
      key: 'dmt',
      title: 'DMT Transfers',
      icon: <DollarCircleOutlined className="service-icon" />,
      color: '#1890ff'
    },
    {
      key: 'CashDeposit',
      title: 'Cash Deposits',
      icon: <BankOutlined className="service-icon" />,
      color: '#52c41a'
    },
    {
      key: 'CashWithdrew',
      title: 'Cash Withdrawals',
      icon: <WalletOutlined className="service-icon" />,
      color: '#722ed1'
    },
    {
      key: 'adhaarPay',
      title: 'Aadhar Pay',
      icon: <IdcardOutlined className="service-icon" />,
      color: '#fa8c16'
    },
    {
      key: 'cms',
      title: 'CMS',
      icon: <CreditCardOutlined className="service-icon" />,
      color: '#eb2f96'
    },
    {
      key: 'paymentRequest',
      title: 'Payment Request',
      icon: <PayCircleOutlined className="service-icon" />,
      color: '#13c2c2'
    },
    {
      key: 'payout',
      title: 'Payout',
      icon: <TransactionOutlined className="service-icon" />,
      color: '#faad14'
    },
    {
      key: 'quickDhan',
      title: 'Quick Dhan',
      icon: <BarcodeOutlined className="service-icon" />,
      color: '#2f54eb'
    }
  ];

  const renderMetricCard = (service) => {
    try {
      const data = safeGetValue(currentData, service.key, {});
      const total = safeGetValue(data, 'totalAmount', 0);
      const success = safeGetValue(data, 'success', 0) || safeGetValue(data, 'approved', 0);
      const failed = safeGetValue(data, 'failed', 0) || safeGetValue(data, 'rejected', 0);
      const pending = safeGetValue(data, 'pending', 0);
      const successRate = total ? (success / total * 100).toFixed(1) : 0;

      return (
        <Col xs={24} md={12} lg={6} key={service.key}>
          <Card 
            className="metric-card"
            title={
              <Space>
                {service.icon}
                <Text strong>{service.title}</Text>
              </Space>
            }
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Statistic 
                  title="Total Transactions"
                  value={total}
                  valueStyle={{ color: service.color }}
                />
                <Progress 
                  percent={Number(successRate)}
                  strokeColor={service.color}
                  size="small"
                  className="mt-2"
                />
              </Col>
              <Col span={24}>
                <Row gutter={16}>
                  <Col span={8}>
                    <Badge status="success" text="Success" />
                    <div className="status-value">{success}</div>
                  </Col>
                  <Col span={8}>
                    <Badge status="error" text="Failed" />
                    <div className="status-value">{failed}</div>
                  </Col>
                  <Col span={8}>
                    <Badge status="warning" text="Pending" />
                    <div className="status-value">{pending}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      );
    } catch (error) {
      console.error(`Error rendering metric card for ${service.key}:`, error);
      return (
        <Col xs={24} md={12} lg={6} key={service.key}>
          <Alert
            message={`Error displaying ${service.title}`}
            description="Failed to load metric data"
            type="error"
            showIcon
          />
        </Col>
      );
    }
  };

  if (!currentData) {
    return null;
  }

  return (
    <div className="dashboard-container">
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
          className="mb-4"
          closable
        />
      )}

      <Card className="filter-card">
        <Row gutter={16} align="middle">
          <Col xs={24} md={6}>
            <Title level={4} className="mb-3 mb-md-0">
              Transaction Dashboard
            </Title>
          </Col>
          <Col xs={24} md={12}>
            <Space className="w-100">
              <DatePicker
                value={selectedDate}
                onChange={setSelectedDate}
                disabledDate={(current) => current && current > new Date()}
                placeholder="Select date"
                className="custom-datepicker"
              />
              <Button type="primary" onClick={handleSearch} loading={isLoading}>
                Search
              </Button>
              <Button onClick={handleClearDates} disabled={isLoading}>
                Clear
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>

      <Spin spinning={isLoading}>
      <Row gutter={[16, 16]} className="mt-4">
          <Col xs={24}>
            <Card className="summary-card">
              <Title level={4}>Overall Performance</Title>
              <Row gutter={[32, 16]}>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title="Total Success Rate"
                    value={calculateTotal(currentData, 'success')}
                    suffix="%"
                    valueStyle={{ color: '#52c41a' }}
                    prefix={<CheckCircleOutlined />}
                  />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title="Total Transactions"
                    value={calculateTotal(currentData, 'totalAmount')}
                    valueStyle={{ color: '#1890ff' }}
                    prefix={<DollarCircleOutlined />}
                  />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title="Failed Transactions"
                    value={calculateTotal(currentData, 'failed')}
                    valueStyle={{ color: '#ff4d4f' }}
                    prefix={<CloseCircleOutlined />}
                  />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title="Pending Transactions"
                    value={calculateTotal(currentData, 'pending')}
                    valueStyle={{ color: '#faad14' }}
                    prefix={<ClockCircleOutlined />}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mt-4">
          {serviceMetrics.map(renderMetricCard)}
        </Row>
        {/* Add this inside your existing Spin component */}
<Row gutter={[16, 16]} className="mt-4">
  <Col xs={24}>
    <ServicesList services={currentData?.services || []} />
   
  </Col>
</Row>

        {/* <Row gutter={[16, 16]} className="mt-4">
          <Col xs={24}>
            <Card className="summary-card">
              <Title level={4}>Overall Performance</Title>
              <Row gutter={[32, 16]}>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title="Total Success Rate"
                    value={calculateTotal(currentData, 'success')}
                    suffix="%"
                    valueStyle={{ color: '#52c41a' }}
                    prefix={<CheckCircleOutlined />}
                  />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title="Total Transactions"
                    value={calculateTotal(currentData, 'totalAmount')}
                    valueStyle={{ color: '#1890ff' }}
                    prefix={<DollarCircleOutlined />}
                  />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title="Failed Transactions"
                    value={calculateTotal(currentData, 'failed')}
                    valueStyle={{ color: '#ff4d4f' }}
                    prefix={<CloseCircleOutlined />}
                  />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title="Pending Transactions"
                    value={calculateTotal(currentData, 'pending')}
                    valueStyle={{ color: '#faad14' }}
                    prefix={<ClockCircleOutlined />}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row> */}
      </Spin>

      <style jsx>{`
        .dashboard-container {
          padding: 24px;
          background: #f5f7fa;
          
        }
        .filter-card {
          background: white;
          border-radius: 12px;
          box-shadow: 0 2px 12px rgba(0,0,0,0.03);
        }
        .metric-card {
          height: 100%;
          border-radius: 12px;
          box-shadow: 0 2px 12px rgba(0,0,0,0.03);
          transition: all 0.3s ease;
        }
        .metric-card:hover {
          transform: translateY(-3px);
          box-shadow: 0 4px 16px rgba(0,0,0,0.06);
        }
        .summary-card {
          border-radius: 12px;
          box-shadow: 0 2px 12px rgba(0,0,0,0.03);
        }
        .service-icon {
          font-size: 20px;
        }
        .status-value {
          font-size: 18px;
          font-weight: 600;
          margin-top: 4px;
        }
        :global(.ant-card-head) {
          border-bottom: none;
          padding-bottom: 0;
        }
        :global(.custom-datepicker) {
          width: 200px;
        }
        :global(.ant-badge-status-dot) {
          width: 8px;
          height: 8px;
        }
        :global(.ant-progress-inner) {
          background-color: #f0f2f5;
        }
      `}</style>
    </div>
  );
};

export default TransactionDashboard;