import React from 'react'
import QuickDhan from '../../components/aepsMaster/quickDhan/QuickDhan'

function QuickDhanPage() {
  return (
    <>
      <QuickDhan/>
    </>
  )
}

export default QuickDhanPage
