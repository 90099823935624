// import React, { useEffect, useState } from 'react'
// import { FaRegEdit } from 'react-icons/fa'
// import { Link } from 'react-router-dom'
// import { Pagination, Popconfirm, message } from 'antd'
// import Loadar from '../../../common/loader/Loader'
// function ChargesPayoutLists() {
//     const [data, setData] = useState()
//     const [loading, setLoading] = useState(false);
//     const [count, setCount] = useState(10)
//     const [page, setPage] = useState(0)
//     const [totalCount, setTotalCount] = useState()

 
//     return (
//         <>
//             {loading && <Loadar />}
//             <div className="row m-4">
//                 <div className="col-xl-12">
//                     <div className="card">
//                         <div className="card-body p-0">
//                             <div className="table-responsive active-projects style-1">
//                                 <div className="tbl-caption">
//                                     <h4 className="heading mb-0">Payout Charges List</h4>
//                                     <div>
//                                         <Link className="btn btn-primary btn-sm" to="/admin/add-payout-charges" role="button" aria-controls="offcanvasExample">+ add Payout</Link>
//                                         {/* <button type="button" className="btn btn-secondary btn-sm" >
//                                         + Invite Employee
//                                     </button> */}
//                                     </div>
//                                 </div>
//                                 <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer"><div className="dt-buttons"><button className="dt-button buttons-excel buttons-html5 btn btn-sm border-0" tabIndex={0} aria-controls="empoloyees-tblwrapper" type="button"><span><i className="fa-solid fa-file-excel" /> Export Report</span></button> </div><table id="empoloyees-tblwrapper" className="table dataTable no-footer" role="grid" aria-describedby="empoloyees-tblwrapper_info">
//                                     <thead>
//                                         <tr role="row">

//                                             <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '203.45px' }}>
//                                                 Vendor Id
//                                             </th>
//                                             <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Employee Name: activate to sort column ascending" style={{ width: '203.45px' }}>
//                                                 Min Amount
//                                             </th>
//                                             <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Department: activate to sort column ascending" style={{ width: '156.475px' }}>
//                                                 Max  Amount</th>
//                                             <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Email Address: activate to sort column ascending" style={{ width: '141.087px' }}>
//                                                 Charges
//                                             </th>
//                                             <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '161.675px' }}>
//                                                 Charges Type</th>

//                                             <th className="sorting" tabIndex={0} aria-controls="empoloyees-tblwrapper" rowSpan={1} colSpan={1} aria-label="Contact Number: activate to sort column ascending" style={{ width: '161.675px' }}>
//                                                 Action</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
                                     



//                                     </tbody>
//                                 </table>
//                                     < div className="dataTables_info" id="empoloyees-tblwrapper_info" role="status" aria-live="polite">
//                                         Total {totalCount} entries
//                                     </div>
//                                     <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
//                                         <Pagination
//                                             // showSizeChanger
//                                             // onShowSizeChange={''}

//                                             defaultCurrent={1}
//                                             onChange={"onChangeVal"}
//                                             total={totalCount}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div >
//         </>
//     )
// }

// export default ChargesPayoutLists




import React, { useEffect, useState } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Pagination, Popconfirm, message } from 'antd'
import Loadar from '../../../common/loader/Loader'
import { getpayoutlist } from '../../../api/login/Login'

function ChargesPayoutLists() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)

    const fetchData = async () => {
        try {
            setLoading(true)
            const response = await getpayoutlist(page, count)
            
            if (response?.data?.data) {
                // Access the nested data array from the response
                setData(response.data.data)
                // If there's a totalCount in the API response, use it
                setTotalCount(response.data.totalCount || response.data.data.length)
                
                if (response.data.statusCode !== 200) {
                    throw new Error(response.data.message || 'Failed to fetch data')
                }
            } else {
                throw new Error('Invalid response format')
            }
        } catch (error) {
            message.error(error.message || 'Failed to fetch payout list')
            console.error('Error fetching payout list:', error)
            setData([]) // Clear data on error
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [page, count])

    const onChangeVal = (pageNumber, pageSize) => {
        setPage(pageNumber)
        setCount(pageSize)
    }

    const handleDelete = async (id) => {
        try {
            // Implement delete functionality if needed
            message.success('Record deleted successfully')
            fetchData()
        } catch (error) {
            message.error('Failed to delete record')
        }
    }

    // Render loading state
    if (loading) {
        return <Loadar />
    }

    return (
        <div className="row m-4">
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-body p-0">
                        <div className="table-responsive active-projects style-1">
                            <div className="tbl-caption">
                                <h4 className="heading mb-0">Payout Charges List</h4>
                                <div>
                                    <Link className="btn btn-primary btn-sm" to="/admin/add-payout-charges" role="button">
                                        + Add Payout
                                    </Link>
                                </div>
                            </div>
                            <div id="empoloyees-tblwrapper_wrapper" className="dataTables_wrapper no-footer">
                                <div className="dt-buttons">
                                    <button className="dt-button buttons-excel buttons-html5 btn btn-sm border-0" type="button">
                                        <span><i className="fa-solid fa-file-excel" /> Export Report</span>
                                    </button>
                                </div>
                                <table id="empoloyees-tblwrapper" className="table dataTable no-footer">
                                    <thead>
                                        <tr role="row">
                                        {/* <th>Serial No.</th> */}
                                            <th>Vendor Id</th>
                                            <th>Start Amount</th>
                                            <th>End Amount</th>
                                            <th>Charges</th>
                                            <th>Charges Type</th>
                                            <th>Transction Type</th>
                                            {/* <th>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 ? (
                                            data.map((item, index) => (
                                                <tr key={item.id || index}>
                                                     {/* <td>{item.index +1}</td> */}
                                                    <td>{item?.vender_id}</td>
                                                    <td>{item?.start_amt}</td>
                                                    <td>{item?.end_amt}</td>
                                                    <td>{item?.charge}</td>
                                                    <td>{item?.charge_type}</td>
                                                    <td>{item?.payout_txn_type}</td>
                                                    {/* <td>
                                                        <div className="d-flex gap-2">
                                                            <Link to={`/admin/edit-payout-charges/${item.id}`}>
                                                                <button className="btn btn-primary btn-sm">
                                                                    <FaRegEdit />
                                                                </button>
                                                            </Link>
                                                            <Popconfirm
                                                                title="Are you sure you want to delete this record?"
                                                                onConfirm={() => handleDelete(item.id)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <button className="btn btn-danger btn-sm">
                                                                    <i className="fa-solid fa-trash"></i>
                                                                </button>
                                                            </Popconfirm>
                                                        </div>
                                                    </td> */}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6" className="text-center">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {data.length > 0 && (
                                    <div className="d-flex justify-content-between align-items-center mt-3">
                                        <div className="dataTables_info">
                                            Total {totalCount} entries
                                        </div>
                                        <div className="dataTables_paginate">
                                            <Pagination
                                                current={page}
                                                pageSize={count}
                                                total={totalCount}
                                                onChange={onChangeVal}
                                                showSizeChanger={false}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChargesPayoutLists